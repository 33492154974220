import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Alert,
  AlertTitle,
  Input,
  Checkbox,
  Autocomplete,
  Card,
  CardHeader,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveRecruitmentRequest,
  rejectRecruitmentRequest,
  verifyRecruitmentRequest,
} from "../../../store/recruitment/actions";
import { getEntities } from "../../../store/structure/actions";
import { getUserDetails } from "../../../store/profile/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import { a11yProps } from "../../common/components/TabPanel";
import UserResumeDetail from "../../profile/UserResumeDetail";
import ViewRecruitmentAttachments from "./ViewRecruitmentAttachments";
import ProvisionalAppointmentLetter from "../../documents/LetterFormat/ProvisionalAppointmentLetter";
import PdfViewer from "../../common/components/PdfViewer";
import ModifyRecruitmentLetterDialog from "./ModifyRecruitmentLetterDialog";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { removeDuplicates } from "../../common/components/Utils";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AppointmentLetter from "../../documents/LetterFormat/AppointmentLetter";

const ApproveRecruitmentRequestDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    selectedRequest,
    approveRecruitmentRequest,
    rejectRecruitmentRequest,
    getUserDetails,
    recruitmentRequest,
    entities,
    getEntities,
    verifyRecruitmentRequest,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [tabValue, setTabValue] = useState(0);
  const [showRecruitmentLetter, setShowRecruitmentLetter] = useState(false);
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [onViewNPSCDocument, setOnViewNPSCDocument] = useState(false);

  const [recruitmentRequestDetail, setRecruitmentRequestDetail] = useState(
    recruitmentRequest?.details || null
  );
  const [recruitmentRequestAttachments, setRecruitmentRequestAttachments] =
    useState(recruitmentRequest?.attachments || []);

  useEffect(() => {
    setRecruitmentRequestDetail(recruitmentRequest?.details || null);
    setRecruitmentRequestAttachments(recruitmentRequest?.attachments || []);
  }, [recruitmentRequest]);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);

  useEffect(() => {
    getUserDetails(selectedRequest.userId, () => {});
  }, []);

  const [formData, setFormData] = useState({
    requestId: recruitmentRequestDetail.id,
    effectiveDate:recruitmentRequestDetail.effectiveDate? moment(recruitmentRequestDetail.effectiveDate).format(
      "YYYY-MM-DD"
    ):null,
    docLetterNo: recruitmentRequestDetail.docLetterNo,
    cc: null,
  });

  const [ccData, setCCData] = useState(
    recruitmentRequestDetail &&
      recruitmentRequestDetail &&
      recruitmentRequestDetail.cc
      ? JSON.parse(recruitmentRequestDetail.cc)
      : []
  );

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
    effectiveDateError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      maxCharHasError: false,
      effectiveDateError: false,
    };
    if (!formData.docLetterNo) {
      error.docLetterNoError = true;
      error.hasError = true;
    }

    if (!formData.effectiveDate) {
      error.effectiveDateError = true;
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      showError("Please check required fields");
      return true;
    }
    return false;
  };

  const [maxWidth, setMaxWidth] = useState("md");

  const handleChange = (event, newValue) => {
    if (+newValue === 2) setMaxWidth("lg");
    else setMaxWidth("md");
    setTabValue(newValue);
  };

  const [showRejectRequestForm, setShowRejectRequestForm] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [reason, setReason] = useState("");
  const [pkiPassword, setPkiPassword] = useState("");
  const onClose = () => {
    setApproveRequestForm(false);
    setShowRejectRequestForm(false);
    setShowApproveRequestForm(false);
    setReason("");
    setPkiPassword("");
  };
  const onVerify = () => {
    if (!formValidator()) {
      formData.cc = JSON.stringify(ccData);

      verifyRecruitmentRequest(formData.requestId, formData, onClose);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>{recruitmentRequestDetail?.requestType || ""}</span> /{" "}
            <span className="text-primary">
              {recruitmentRequestDetail?.employeeNames || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          <div className="row">
            <div className="col-lg-9">
              <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className="text-capitalize"
                  label="View Request Letter"
                  {...a11yProps(0)}
                />
                <Tab
                  disabled={recruitmentRequestDetail.requestTypeId !== 1}
                  className="text-capitalize"
                  label="Attachments
             "
                  {...a11yProps(1)}
                />

                <Tab
                  className="text-capitalize"
                  label="Profile"
                  {...a11yProps(2)}
                />
                {recruitmentRequestDetail.requestTypeId !== 1 && (
                  <Tab
                    className="text-capitalize"
                    label="More.."
                    {...a11yProps(3)}
                  />
                )}
              </Tabs>
            </div>
          </div>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          {showRecruitmentLetter && (
            <ModifyRecruitmentLetterDialog
              showRecruitmentLetter={showRecruitmentLetter}
              setShowRecruitmentLetter={setShowRecruitmentLetter}
              recruitmentRequestDetail={recruitmentRequestDetail}
            />
          )}

          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            {tabValue === 0 && (
              <>
                <div className="mx-0 w-full">
                  {recruitmentRequestDetail.statusId === 3 &&
                    !!recruitmentRequestDetail?.docReferenceId && (
                      <>
                        <PdfViewer
                          url={
                            process.env.REACT_APP_FILES_URL +
                            "/preview/" +
                            recruitmentRequestDetail?.docReferenceId
                          }
                        />
                      </>
                    )}
                  {recruitmentRequestDetail.statusId !== 3 &&
                    recruitmentRequestDetail.requestTypeId === 1 && (
                      <>
                        {[1, 2].includes(
                          +recruitmentRequestDetail.statusId
                        ) && (
                          <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            disabled={loading}
                            onClick={() => {
                              setShowRecruitmentLetter(true);
                            }}
                            className="m-2 text-capitalized float-right"
                          >
                            Edit Letter
                          </Button>
                        )}
                        <ProvisionalAppointmentLetter
                          recipientName={
                            recruitmentRequestDetail.lastName +
                            " " +
                            recruitmentRequestDetail.firstName
                          }
                          docLetterNo={recruitmentRequestDetail.docLetterNo}
                          recipientEmail={recruitmentRequestDetail.email}
                          recipientPhone={recruitmentRequestDetail.phoneNumber}
                          institutionName={recruitmentRequestDetail.entityName}
                          recruitmentInstitutionName={
                            recruitmentRequestDetail.entityName
                          }
                          recruitmentPositionName={
                            recruitmentRequestDetail.positionName
                          }
                          enableToFillLetter={
                            +recruitmentRequestDetail.statusId === 1 &&
                            hasPermissions(["IS_DOCUMENT_VERIFIER"])
                              ? true
                              : false
                          }
                          subTitle={
                            "Dear " +
                            (recruitmentRequestDetail.genderId === "F"
                              ? "Madam"
                              : "Sir")
                          }
                          ccData={
                            recruitmentRequestDetail.cc
                              ? JSON.parse(recruitmentRequestDetail.cc)
                              : []
                          }
                          effectiveDate={moment(
                            recruitmentRequestDetail.effectiveDate
                          ).format("LL")}
                          competentAuthorityName={
                            recruitmentRequestDetail.signerNames
                          }
                          competentAuthorityPosition={
                            recruitmentRequestDetail.signerPosition
                          }
                          signContent={
                            <table>
                              <tr>
                                <td
                                  style={{
                                    position: "relative",

                                    width: "20%",
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                >
                                  {!!hasPermissions([
                                    recruitmentRequestDetail.permission,
                                  ]) &&
                                  recruitmentRequestDetail.statusId === 1 ? (
                                    <>
                                      <SignLetter />
                                    </>
                                  ) : (
                                    <>
                                      <span className="d-block text-danger">
                                        Not signed
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td
                                  style={{
                                    width: "20%",
                                    fontSize: "12px",
                                    textAlign: "left",
                                  }}
                                ></td>
                              </tr>
                            </table>
                          }
                          docLetterNoInput={
                            <>
                              <Input
                                autoFocus
                                error={!!errors.docLetterNoError}
                                value={formData.docLetterNo}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add doc No",
                                }}
                                onChange={(e) => {
                                  const docLetterNo = e.target.value;
                                  setFormData({
                                    ...formData,
                                    docLetterNo,
                                  });

                                  setErrors({
                                    ...errors,
                                    docLetterNoError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          ccSelectForm={
                            <>
                              <Autocomplete
                                size="small"
                                fullWidth
                                multiple
                                id="ccData"
                                options={entities.map(
                                  ({ name, institutionEmail, id }) => ({
                                    name,
                                    institutionEmail,
                                    id,
                                  })
                                )}
                                value={ccData}
                                onChange={(e, cc) => {
                                  const uniqueCc = removeDuplicates(cc);
                                  setCCData(uniqueCc);
                                  const ccHasError = false;
                                  setErrors({ ...errors, ccHasError });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      checked={
                                        selected ||
                                        !!ccData.find(
                                          ({ id }) => id === option.id
                                        )
                                      }
                                    />
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select the institution to copy"}
                                    placeholder={
                                      "Select the institution to copy"
                                    }
                                  />
                                )}
                              />

                              {errors.ccHasError && (
                                <small className="text-danger mb-1">
                                  Please, select the institution to copy
                                </small>
                              )}
                            </>
                          }
                          effectiveDateInput={
                            <>
                              <Input
                                type="date"
                                error={!!errors.effectiveDateError}
                                value={formData.effectiveDate}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add reference date",
                                }}
                                onChange={(e) => {
                                  const effectiveDate = e.target.value;
                                  setFormData({
                                    ...formData,
                                    effectiveDate,
                                  });

                                  setErrors({
                                    ...errors,
                                    effectiveDateError: false,
                                  });
                                }}
                              />
                            </>
                          }
                        />
                      </>
                    )}

                  {recruitmentRequestDetail.statusId !== 3 &&
                    recruitmentRequestDetail.requestTypeId === 2 && (
                      <>
                        {[1, 2].includes(
                          +recruitmentRequestDetail.statusId
                        ) && (
                          <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            disabled={loading}
                            onClick={() => {
                              setShowRecruitmentLetter(true);
                            }}
                            className="m-2 text-capitalized float-right"
                          >
                            Edit Letter
                          </Button>
                        )}
                        <AppointmentLetter
                          provisionalDocLetterNo={
                            recruitmentRequestDetail.provisionalDocLetterNo
                          }
                          provisionalEffectiveOn={moment(
                            recruitmentRequestDetail.provisionalEffectiveOn
                          ).format("LL")}
                          provisionalDocLetterDate={moment(
                            recruitmentRequestDetail.provisionalApprovedOn
                          ).format("LL")}
                          npscDocLetterNo={
                            recruitmentRequestDetail.npscDocLetterNo
                          }
                          npsclDocLetterDate={moment(
                            recruitmentRequestDetail.npscApprovedOn
                          ).format("LL")}
                          recipientName={
                            recruitmentRequestDetail.lastName +
                            " " +
                            recruitmentRequestDetail.firstName
                          }
                          docLetterNo={recruitmentRequestDetail.docLetterNo}
                          recipientEmail={recruitmentRequestDetail.email}
                          recipientPhone={recruitmentRequestDetail.phoneNumber}
                          institutionName={recruitmentRequestDetail.entityName}
                          recruitmentInstitutionName={
                            recruitmentRequestDetail.entityName
                          }
                          recruitmentPositionName={
                            recruitmentRequestDetail.positionName
                          }
                          enableToFillLetter={
                            +recruitmentRequestDetail.statusId === 1 &&
                            hasPermissions(["IS_DOCUMENT_VERIFIER"])
                              ? true
                              : false
                          }
                          subTitle={
                            "Dear " +
                            (recruitmentRequestDetail.genderId === "F"
                              ? "Madam"
                              : "Sir")
                          }
                          ccData={
                            recruitmentRequestDetail.cc
                              ? JSON.parse(recruitmentRequestDetail.cc)
                              : []
                          }
                          effectiveDate={moment(
                            recruitmentRequestDetail.effectiveDate
                          ).format("LL")}
                          competentAuthorityName={
                            recruitmentRequestDetail.signerNames
                          }
                          competentAuthorityPosition={
                            recruitmentRequestDetail.signerPosition
                          }
                          signContent={
                            <table>
                              <tr>
                                <td
                                  style={{
                                    position: "relative",

                                    width: "20%",
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                >
                                  {!!hasPermissions([
                                    recruitmentRequestDetail.permission,
                                  ]) &&
                                  recruitmentRequestDetail.statusId === 1 ? (
                                    <>
                                      <SignLetter />
                                    </>
                                  ) : (
                                    <>
                                      <span className="d-block text-danger">
                                        Not signed
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td
                                  style={{
                                    width: "20%",
                                    fontSize: "12px",
                                    textAlign: "left",
                                  }}
                                ></td>
                              </tr>
                            </table>
                          }
                          docLetterNoInput={
                            <>
                              <Input
                                autoFocus
                                error={!!errors.docLetterNoError}
                                value={formData.docLetterNo}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add doc No",
                                }}
                                onChange={(e) => {
                                  const docLetterNo = e.target.value;
                                  setFormData({
                                    ...formData,
                                    docLetterNo,
                                  });

                                  setErrors({
                                    ...errors,
                                    docLetterNoError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          ccSelectForm={
                            <>
                              <Autocomplete
                                size="small"
                                fullWidth
                                multiple
                                id="ccData"
                                options={entities.map(
                                  ({ name, institutionEmail, id }) => ({
                                    name,
                                    institutionEmail,
                                    id,
                                  })
                                )}
                                value={ccData}
                                onChange={(e, cc) => {
                                  const uniqueCc = removeDuplicates(cc);
                                  setCCData(uniqueCc);
                                  const ccHasError = false;
                                  setErrors({ ...errors, ccHasError });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      checked={
                                        selected ||
                                        !!ccData.find(
                                          ({ id }) => id === option.id
                                        )
                                      }
                                    />
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select the institution to copy"}
                                    placeholder={
                                      "Select the institution to copy"
                                    }
                                  />
                                )}
                              />

                              {errors.ccHasError && (
                                <small className="text-danger mb-1">
                                  Please, select the institution to copy
                                </small>
                              )}
                            </>
                          }
                          effectiveDateInput={
                            <>
                              <Input
                                type="date"
                                error={!!errors.effectiveDateError}
                                value={formData.effectiveDate}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add reference date",
                                }}
                                onChange={(e) => {
                                  const effectiveDate = e.target.value;
                                  setFormData({
                                    ...formData,
                                    effectiveDate,
                                  });

                                  setErrors({
                                    ...errors,
                                    effectiveDateError: false,
                                  });
                                }}
                              />
                            </>
                          }
                        />
                      </>
                    )}
                </div>
              </>
            )}

            {tabValue === 1 && (
              <>
                <ViewRecruitmentAttachments
                  attachments={recruitmentRequestAttachments}
                  canEdit={
                    recruitmentRequestDetail.statusId === 1 &&
                    hasPermissions(["IS_HR"])
                  }
                />
              </>
            )}
            {tabValue === 2 && (
              <>
                <UserResumeDetail
                  canEdit={false}
                  allowedToEditEmail={false}
                  allowedToEditEmploymentDate={false}
                />
              </>
            )}

            {tabValue === 3 && (
              <>
                {!!recruitmentRequestDetail.provisionalDocReferenceId && (
                  <div className="row w-100">
                    <div className="col-12 ">
                      {onViewProvDocument && (
                        <PreviewPdfUrl
                          showDocument={onViewProvDocument}
                          setShowDocument={() => setOnViewProvDocument(false)}
                          document={{
                            name: `Submission of Recruitment Letter`,
                            url:
                              process.env.REACT_APP_FILES_URL +
                              "/preview/" +
                              recruitmentRequestDetail.provisionalDocReferenceId,
                          }}
                        />
                      )}

                      <Card
                        className={`mb-2 w-100 rounded hoverable-card`}
                        elevation={1}
                        variant="outlined"
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <span
                              className={
                                !!recruitmentRequestDetail.provisionalDocReferenceId
                                  ? "font-weight-bold text-success"
                                  : "font-weight-bold text-primary"
                              }
                            >
                              <TextSnippetIcon />
                            </span>
                          }
                          title={
                            <Typography>
                              <strong
                                className={
                                  !!recruitmentRequestDetail.provisionalDocReferenceId
                                    ? "text-success"
                                    : ""
                                }
                              >
                                View Provisional Appointment Letter
                              </strong>
                            </Typography>
                          }
                          action={
                            <>
                              <div
                                className="d-flex flex-row align-items-center pull-right"
                                style={{ borderRadius: "4px" }}
                              >
                                <IconButton
                                  // disableRipple
                                  // className="ml-n2"
                                  size="small"
                                  onClick={() => {
                                    setOnViewProvDocument(true);
                                  }}
                                >
                                  <FilePresentIcon
                                    // fontSize="large"
                                    style={{
                                      color:
                                        !!recruitmentRequestDetail.provisionalDocReferenceId
                                          ? "#fe4066"
                                          : "#c57c7cb8",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </>
                          }
                        />
                      </Card>
                    </div>
                  </div>
                )}

                {!!recruitmentRequestDetail.npscDocReferenceId && (
                  <div className="row w-100">
                    <div className="col-12 ">
                      {onViewNPSCDocument && (
                        <PreviewPdfUrl
                          showDocument={onViewNPSCDocument}
                          setShowDocument={() => setOnViewNPSCDocument(false)}
                          document={{
                            name: `Response of Recruitment Report`,
                            url:
                              process.env.REACT_APP_FILES_URL +
                              "/preview/" +
                              recruitmentRequestDetail.npscDocReferenceId,
                          }}
                        />
                      )}

                      <Card
                        className={`mb-2 w-100 rounded hoverable-card`}
                        elevation={1}
                        variant="outlined"
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <span
                              className={
                                !!recruitmentRequestDetail.npscDocReferenceId
                                  ? "font-weight-bold text-success"
                                  : "font-weight-bold text-primary"
                              }
                            >
                              <TextSnippetIcon />
                            </span>
                          }
                          title={
                            <Typography>
                              <strong
                                className={
                                  !!recruitmentRequestDetail.npscDocReferenceId
                                    ? "text-success"
                                    : ""
                                }
                              >
                                View Response of Recruitment Report
                              </strong>
                            </Typography>
                          }
                          action={
                            <>
                              <div
                                className="d-flex flex-row align-items-center pull-right"
                                style={{ borderRadius: "4px" }}
                              >
                                <IconButton
                                  // disableRipple
                                  // className="ml-n2"
                                  size="small"
                                  onClick={() => {
                                    setOnViewNPSCDocument(true);
                                  }}
                                >
                                  <FilePresentIcon
                                    // fontSize="large"
                                    style={{
                                      color:
                                        !!recruitmentRequestDetail.npscDocReferenceId
                                          ? "#fe4066"
                                          : "#c57c7cb8",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </>
                          }
                        />
                      </Card>
                    </div>
                  </div>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        {recruitmentRequestDetail.statusId === 4 && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Alert severity="error">
                <strong>Reason:</strong>{" "}
                {recruitmentRequestDetail.statusComment}
              </Alert>
            </DialogActions>
          </>
        )}
        {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
          recruitmentRequestDetail.statusId === 1 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    onVerify();
                  }}
                  className="btn btn-xs"
                >
                  Verify
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={loading}
                  onClick={() => {
                    setShowRejectRequestForm(true);
                  }}
                  type="button"
                  className="mr-3 ml-3"
                >
                  Reject
                </Button>
              </DialogActions>
            </>
          )}

        {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
          recruitmentRequestDetail.statusId === 2 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowApproveRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={loading}
                  onClick={() => {
                    setShowRejectRequestForm(true);
                  }}
                  type="button"
                  className="mr-3 ml-3"
                >
                  Reject
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      {showApproveRequestForm && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>

              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            approveRecruitmentRequest(
              { requestId: recruitmentRequestDetail.id, password: pkiPassword },
              onClose
            );
          }}
        />
      )}

      {showRejectRequestForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this request?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequestForm}
          onYes={() => {
            rejectRecruitmentRequest(
              {
                requestId: recruitmentRequestDetail.id,
                reason: reason,
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, recruitmentRequest, entities }) => {
  return {
    loading,
    recruitmentRequest,
    entities,
  };
};
export default connect(mapStateToProps, {
  approveRecruitmentRequest,
  rejectRecruitmentRequest,
  getUserDetails,
  getEntities,
  verifyRecruitmentRequest,
})(ApproveRecruitmentRequestDialog);
