import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DashboardBarChart,
  DashboardDoughnutChart,
  DashboardLineChart,
} from "../../admin/components/StructureDashboard";
import { getMonitorDashboard } from "../../../store/rbm/actions";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import StackedBarChartRoundedIcon from "@mui/icons-material/StackedBarChartRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";

const InstitutionDashboard = (props) => {
  const { getMonitorDashboard } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [barChartOptions, setBarChartOptions] = useState({
    chartType: "bar",
    isHorizontal: false,
    isStacked: false,
  });

  const handleLoadData = () => {
    const query = {
      unitId: "",
      entitySectorId: "",
      planningEntityId: "",
      showAll: false,
    };
    getMonitorDashboard(query, (status, data) => {
      setChartData(data);
      setLoadingData(status);
    });
  };

  useEffect(() => {
    if (!chartData) {
      handleLoadData();
    }

    return () => {
      setChartData(null);
      setLoadingData(true);
    };
  }, []);

  return (
    <>
      <div className="row justify-content-center w-100 my-3 px-4">
        <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2 pb-3">
          <DashboardDoughnutChart
            chartTitle="Assigned vs. Unassigned Items"
            chartData={chartData?.itemAssignmentStatus}
            loading={loadingData}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2 pb-3">
          <DashboardDoughnutChart
            chartTitle="Assigned vs. Unassigned Employees"
            chartData={chartData?.employeeAssignmentStatus}
            loading={loadingData}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2 pb-3">
          <DashboardDoughnutChart
            chartTitle="Reporting Progress"
            chartData={chartData?.reportingProgress}
            loading={loadingData}
          />
        </div>
      </div>

      <div className="border rounded p-2">
        <div className="d-flex align-items-center justify-content-end mb-2">
          <ToggleButtonGroup
            value={barChartOptions.chartType}
            exclusive
            onChange={(event, newType) =>
              setBarChartOptions((prev) => ({
                ...prev,
                chartType: newType,
              }))
            }
            size="small"
            color="primary"
            aria-label="chart type"
            disabled={loadingData}
          >
            <ToggleButton value="bar" aria-label="stacked bar chart">
              <StackedBarChartRoundedIcon fontSize="inherit" />
            </ToggleButton>
            <ToggleButton value="line" aria-label="line chart">
              <ShowChartRoundedIcon fontSize="inherit" />
            </ToggleButton>
            <ToggleButton value="list" aria-label="table data">
              <ListAltRoundedIcon fontSize="inherit" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {barChartOptions.chartType === "bar" && (
          <DashboardBarChart
            chartData={chartData?.quarterCharts}
            loading={loadingData || !chartData?.quarterCharts}
            chartTitle="Quarter Score Average"
            isStackedChart={barChartOptions.isStacked}
            isHorizontalBar={barChartOptions.isHorizontal}
            ignoreBorder
          />
        )}

        {barChartOptions.chartType === "line" && (
          <DashboardLineChart
            chartData={chartData?.quarterCharts}
            loading={loadingData || !chartData?.quarterCharts}
            chartTitle="Quarter Score Average"
            // ignoreBorder
          />
        )}

        {barChartOptions.chartType === "list" && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 440, overflow: "hidden" }}
          >
            <Table
              size="small"
              aria-label="Quarter Score Average monthly counts"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Item Types</TableCell>
                  {(chartData?.quarterCharts?.labels || []).map(
                    (label, index) => (
                      <TableCell align="right" key={index + label}>
                        {label}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(chartData?.quarterCharts?.datasets || []).map(
                  (item, index) => (
                    <TableRow
                      key={index + item.label}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {item.label}
                      </TableCell>
                      {item.data.map((totalItem, itemIndex) => (
                        <TableCell
                          align="right"
                          key={index + itemIndex + totalItem}
                        >
                          {(totalItem || 0).toFixed(2)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, { getMonitorDashboard })(
  InstitutionDashboard
);
