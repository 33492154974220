import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  ListItemButton,
  ListItemText,
  ListSubheader,
  List,
  Button,
} from "@mui/material";
import {
  downloadEmployeesDeductionList,
  uploadEmployeeDeductions,
} from "../../../store/structure/actions";

import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../toastify";
import readXlsxFile from "read-excel-file";

const requiredKeys = ["DeductionId","EmployeeId", "Names", "Creditor", "Amount"];

const ImportEmployeesDeductionDialog = (props) => {
  const {
    user,
    showImportEmployeesDeductionDialog,
    setShowImportEmployeesDeductionDialog,
    downloadEmployeesDeductionList,
    uploadEmployeeDeductions,
  } = props;

  const onClose = () => {
    setShowImportEmployeesDeductionDialog(false);
  };

  const [isLoader, setIsLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [results, setResults] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [detailForm, setDetailForm] = useState({
    file: "",
  });
  const handleClick = () => {
    setOpen(!open);
  };
  const handleUploadedFile = async (event) => {
    let file = event.target.files[0];
    setUploadErrors([]);

    const fileName = file.name.toLowerCase();
    if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      setDetailForm({
        ...detailForm,
        file,
      });
      setResults([]);
      try {
        const rows = await readXlsxFile(file);
        let tempErrors = [];
        let tempLecturers = [];
        if (rows.length > 0) {
          const header = rows[0].map((col) => col.toLowerCase().trim());
          // Check if all required keys are present
          // Map header indices to required keys
          const headerMapping = {};
          requiredKeys.forEach((key) => {
            const index = header.indexOf(key.toLowerCase());
            if (index !== -1) {
              headerMapping[key] = index;
            }
          });

          // Check for missing keys in the header
          const missingKeys = requiredKeys.filter(
            (key) => !(key in headerMapping)
          );
          if (missingKeys.length === 0) {
            // Process data rows
            rows.slice(1).forEach((cols, rowIndex) => {
              // Create a employeesDeduction object using the header mapping
              let employeesDeduction = {};
              Object.keys(headerMapping).forEach((key) => {
                employeesDeduction[key] = cols[headerMapping[key]] || "";
              });

              // Validate employeesDeduction data
              let errors = {};
              const line = rowIndex + 1;
              requiredKeys.forEach((key) => {
                if (!employeesDeduction[key]) {
                  errors[key] = `${key} is missing or empty`;
                } else if (
                  key.toLowerCase() === "deductionid" &&
                  (typeof employeesDeduction[key] !== "number" ||
                    !Number.isInteger(employeesDeduction[key])) &&
                  (isNaN(employeesDeduction[key]) ||
                    employeesDeduction[key] === null ||
                    employeesDeduction[key] === "")
                ) {
                  errors[
                    key
                  ] = `This DeductionId ${employeesDeduction[key]} must be a valid integer.`;
                } else if (
                  key.toLowerCase() === "amount" &&
                  (typeof employeesDeduction[key] !== "number" ||
                    !Number.isInteger(employeesDeduction[key])) &&
                  (isNaN(employeesDeduction[key]) ||
                    employeesDeduction[key] === null ||
                    employeesDeduction[key] === "")
                ) {
                  errors[
                    key
                  ] = `This amount ${employeesDeduction[key]} must be a valid integer.`;
                }
              });

              // Push errors to tempErrors if any
              if (Object.keys(errors).length > 0) {
                tempErrors.push({
                  line: line, // Add 2 to account for header row and 0-based index
                  errors,
                });
              }

              // Add the employeesDeduction to tempLecturers
              tempLecturers.push(employeesDeduction);
            });
            setResults(tempLecturers);
            setUploadErrors(tempErrors);
          } else {
            showError(`Missing required keys in header:, ${missingKeys}`);
          }
        } else {
          showError("Excel file uploaded is empty");
        }
      } catch (error) {
        showError("Error reading the Excel file");
      }
    } else {
      showError("Please provide a valid Excel file");
    }
    fileInputRef.current.value = "";
  };

  const onSave = async () => {
    if (!!detailForm.file) {
      if (!results.length) {
        showError("Excel file uploaded does not have records");
        return false;
      }

      if (!!uploadErrors.length) {
        showError("Please correct error(s) found!");
        return false;
      }
      // console.log(results)
      uploadEmployeeDeductions(
        {
          results: results,
        },
        onClose,
        setIsUploading
      );
    } else {
      showError("No file uploaded");
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showImportEmployeesDeductionDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <span className="ml-0 text-dark">
              {" "}
              Import employees deduction |{" "}
            </span>{" "}
            <span className="ml-0">
              {user.selectedEntitySector.entityName}
            </span>{" "}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3 row"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              height: "75vh",
              display: "table-cell",
              width: "100vw",
              padding: "10px",
              // verticalAlign: "middle",
            }}
          >
            <div className="col-lg-12 mt-3">
              <div className="row">
                <div className="col-lg-6 mt-0 ">
                  <span className="mx-0 my-2 d-flex justify-content-start p-1">
                    Upload Employees Deductions(* only excel file)
                  </span>
                </div>
                <div className="col-lg-6 mt-0 ">
                  <Button
                    variant="text"
                    onClick={() => {
                      downloadEmployeesDeductionList(setIsDownloading);
                    }}
                    className="mx-0 my-2 d-flex justify-content-end p-1 float-right"
                    disabled={isDownloading}
                  >
                    {isDownloading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                          style={{ fontSize: "28px" }}
                        ></span>

                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      <span
                        className="material-icons mr-2"
                        style={{ fontSize: "28px" }}
                      >
                        cloud_download
                      </span>
                    )}
                    {isDownloading ? (
                      <>Downloading....</>
                    ) : (
                      <> Download Template</>
                    )}
                  </Button>
                </div>
              </div>

              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-paperclip"></i>
                  </span>
                </div>

                <input
                  type="file"
                  name="file"
                  className="form-control"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  placeholder="Select excel file"
                  onChange={(e) => handleUploadedFile(e)}
                />
                <button
                  disabled={!detailForm.file || isUploading}
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase ml-2  px-4"
                >
                  {isUploading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                        style={{ fontSize: "28px" }}
                      ></span>
                      <span className="sr-only">Loading...</span>
                      Wait....
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <>
                {!!uploadErrors.length && (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        borderRadius: "2px",
                        border: "1px solid red",
                      }}
                      component="div"
                      subheader={
                        <ListSubheader
                          component="div"
                          style={{ borderBottom: "1px solid red" }}
                        >
                          Error{uploadErrors.length > 1 && "s"} found(
                          {uploadErrors.length})
                        </ListSubheader>
                      }
                    >
                      {uploadErrors.map((error, index) => (
                        <ListItemButton key={index} onClick={handleClick}>
                          <ListItemText
                            primary={
                              <span className="text-danger">
                                <p className="pl-1">
                                  <span className="pl-1">
                                    {error.line &&
                                      "Line no." + error.line + ":"}
                                  </span>
                                </p>

                                {requiredKeys.map((key) => (
                                  <p className="m-1" key={key}>
                                    {error.errors[key]}
                                  </p>
                                ))}
                              </span>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </>
                )}
              </>
              <div
                style={{
                  marginLeft: "1em",
                  marginRight: "1em",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                  height: "55vh",
                  overflowY: "scroll",
                }}
              >
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      {requiredKeys.map((key, index) => (
                        <th key={index} scope="col">
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {results.map((row, index) => (
                      <tr key={index}>
                        {requiredKeys.map((key) => (
                          <td key={key}>{row[key] || "N/A"}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-2"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  downloadEmployeesDeductionList,
  uploadEmployeeDeductions,
})(ImportEmployeesDeductionDialog);
