import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import {
  updateRequestSanctionLetter,
  reviewCaseSanctionRequest,
  approveRequestSanctionLetter,
  closeCase,
  initiateSanctionAfterCommittee,
  getDisciplineLetterNo
} from "../../../store/discipline/actions";
import {
  removeDuplicates,
  textCapitalized,
} from "../../common/components/Utils";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import CommonLetter from "../../documents/LetterFormat/CommonLetter";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getHeadOfUnit } from "../../../store/structure/actions";
const steps = ["Fill Request Form", "Fill Request Letter"];

const CommitteeReportFormDialog = (props) => {
  const {
    loading,
    showCommitteeReportForm,
    setShowCommitteeReportForm,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    caseRequest,
    enableToFillLetter,
    action = "EDIT",
    actionStatusId = 0,
    closeModal,
    reviewCaseSanctionRequest,
    approveRequestSanctionLetter,
    isSentToDisciplinaryCommittee,
    currentSanction,
    closeCase,
    initiateSanctionAfterCommittee,
    getHeadOfUnit,
    headOfUnit,
    getDisciplineLetterNo,
    disciplineLetterNo
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [reason, setReason] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [showCaseReviewerForm, setShowCaseReviewerForm] = useState(false);
  const [showCaseApproverForm, setShowCaseApproverForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");
  const [pdfAttachment, setPdfAttachment] = useState(null);
  const fileInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [ccData, setCCData] = useState(
    caseRequest && !!caseRequest.sanctionLetterCc
      ? JSON.parse(caseRequest?.sanctionLetterCc || [])
      : []
  );
 

  const [formData, setFormData] = useState({
    caseId: caseRequest?.id || "",
    caseSanctionId: currentSanction?.id || "",
    docLetterNo: caseRequest?.sanctionLetterNo || disciplineLetterNo,
    reason: null,
    explanationFeedbackLetterNo:
      caseRequest?.explanationFeedbackLetterNo || "N/A",
    reportCommitteeSummary: caseRequest?.reportCommitteeSummary || "",
    cc:
      caseRequest && !!caseRequest.sanctionLetterCc
        ? JSON.parse(caseRequest?.sanctionLetterCc || [])
        : [],
    reportCommitteeDate: caseRequest.reportCommitteeDate
      ? moment(caseRequest?.reportCommitteeDate || null).format("YYYY-MM-DD")
      : null,
    explanationFeedbackLetterDate: caseRequest.explanationFeedbackLetterDate
      ? moment(caseRequest?.explanationFeedbackLetterDate || null).format(
          "YYYY-MM-DD"
        )
      : null,

    isProvisionSuspensionLetterRequired:
      caseRequest?.isProvisionSuspensionLetterRequired || false,
    pdfAttachment: null,
  });
  useEffect(() => {
   if(!["CLOSE_CASE"].includes(action)) getDisciplineLetterNo();
  }, []);
  useEffect(() => {
    if (!entities.length && !["CLOSE_CASE"].includes(action)) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail && !["CLOSE_CASE"].includes(action)) getRequestDocumentSignerDetails();
    if (!headOfUnit) getHeadOfUnit(caseRequest.unitId);
  }, []);

  useEffect(() => {
    if (!["CLOSE_CASE"].includes(action) && !caseRequest?.sanctionLetterNo) {
      setFormData({ ...formData, docLetterNo: disciplineLetterNo });
    }
  }, [disciplineLetterNo]);

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    effectiveDateError: false,
    reportCommitteeSummaryHasError: false,
    pdfAttachmentHasError: false,
    reasonHasError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      effectiveDateError: false,
      reportCommitteeSummaryHasError: false,
      pdfAttachmentHasError: false,
      reasonHasError: false,
    };
    if (!formData.docLetterNo && ["VERIFY", "APPROVE"].includes(action)) {
      error.docLetterNoError = true;
      error.hasError = true;
    }

    if (!pdfAttachment && ["GIVE_SANCTION", "CLOSE_CASE"].includes(action)) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }

    if (!formData.effectedOn && activeStep===1 && ["GIVE_SANCTION"].includes(action)) {
      error.effectiveDateError = true;
      error.hasError = true;
    }
    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!formData.reportCommitteeDate) {
      error.effectiveDateError = true;
      error.hasError = true;
    }
    if (!formData.reportCommitteeSummary) {
      error.reportCommitteeSummaryHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill the required field");
      return true;
    }
    return false;
  };
  const onClose = () => {
    setShowCommitteeReportForm(false);
  };
  const onCloseAll = () => {
    setShowCommitteeReportForm(false);
    closeModal();
  };
  const onSave = () => {
    if (!formValidator()) {
      const payload = new FormData();
      formData.file = pdfAttachment;
      formData.cc = JSON.stringify(ccData);
      const keys = Object.keys(formData);
      for (const key of keys) {
        if (key === "file" && formData[key]) payload.append(key, formData[key]);
        else payload.append(`${key}`, `${formData[key]}`);
      }
      // console.log(formData);
      if (["GIVE_SANCTION"].includes(action)) {
        initiateSanctionAfterCommittee(payload, onCloseAll);
      } else {
        closeCase(payload, onCloseAll);
      }
    }
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };

  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    setErrors({ ...errors, pdfAttachmentHasError: false });
    setPdfAttachment(null);
    let pdfAttachment = event.target.files[0];

    if (
      convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf" &&
      pdfAttachment?.type !== "application/pdf"
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
      fileInputRef.current.value = "";
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
      fileInputRef.current.value = "";
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };

      reader.readAsDataURL(pdfAttachment);

      setPdfAttachment(pdfAttachment);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCommitteeReportForm}
        fullWidth
        maxWidth={action === "CLOSE_CASE" ? "sm" : "md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            {action === "CLOSE_CASE" ? (
              <>Close the case</>
            ) : (
              <>
                <span>Request {currentSanction?.name || ""} Letter</span>
              </>
            )}{" "}
            /{" "}
            <span className="text-primary">
              {textCapitalized(caseRequest?.lastName)}
              {textCapitalized(caseRequest?.firstName)}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          {action === "GIVE_SANCTION" && (
            <div className="mt-4">
              <Box sx={{ width: "100%", mt: 0 }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    // stepProps.completed = isCompleted;

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>
          )}
        </DialogTitle>

        <DialogContent className="pb-2">
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            {activeStep === 0 && (
              <>
                <div className="card service-card valid mb-2 mt-1">
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                    Summary of Internal Discipline Committee Decision
                  </div>
                  <div className="card-body">
                    <div className="p-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Please specify the date of the Internal Disciplinary Committee's approved report."
                          inputFormat="dd/MM/yyyy"
                          // disabled={
                          //   !exitType ||
                          //   (exitType && exitType.effectiveInDays > 0)
                          // }
                          value={formData.reportCommitteeDate}
                          onChange={(date) => {
                            setFormData({
                              ...formData,
                              reportCommitteeDate: date,
                            });

                            setErrors({
                              ...errors,
                              effectiveDateError: false,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => e.preventDefault()}
                              required={true}
                              fullWidth
                              size="small"
                              {...params}
                            />
                          )}
                        />

                        {errors.effectiveDateError && (
                          <small className="text-danger mt-1">
                           The date is required{" "}
                          </small>
                        )}
                      </LocalizationProvider>
                    </div>

                    <div className="p-2">
                      <TextField
                        fullWidth
                        size="small"
                        name="reportCommitteeSummary"
                        required={true}
                        multiline
                        rows={3}
                        value={formData.reportCommitteeSummary}
                        label={`Please specify the summary of the committee's conclusions. `}
                        placeholder={`Please specify the summary of the committee's conclusions.`}
                        inputProps={{ maxLength: 300 }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            reportCommitteeSummary: e.target.value,
                          });

                          setErrors({
                            ...errors,
                            reportCommitteeSummaryHasError: false,
                          });
                        }}
                      />
                      <strong className="text-right float-right text-primary w-100">
                        Should not exceed the 300-character limit.
                      </strong>
                      {errors.reportCommitteeSummaryHasError && (
                        <small className="text-danger mb-1">
                          Please, type the summary of the committee's conclusions.
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card service-card valid mb-2 mt-1">
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                   Please attach the report of the Internal Disciplinary Committee.
                  </div>
                  <div className="card-body">
                    <div className={`p-2`}>
                      <Button fullWidth component="label" size="md">
                        <div className="w-100">
                          <input
                            type="file"
                            accept="application/pdf"
                            placeholder="Please attach the report of the Internal Disciplinary Committee."
                            onChange={(e) => handleUploadedPDFFile(e)}
                            ref={fileInputRef}
                            className="w-100"
                          />
                        </div>

                        {errors.pdfAttachmentHasError && (
                          <div className="w-100">
                            <small className="text-danger mt-1">
                              Please, attachment is required
                            </small>
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="card service-card valid mb-2 mt-1">
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem" }}
                  >
                    Your Comment.
                  </div>
                  <div className="card-body">
                    <TextField
                      fullWidth
                      size="small"
                      name="reason"
                      required={true}
                      multiline
                      rows={3}
                      value={formData.reason}
                      label={`Your Comment.`}
                      placeholder={`Your Comment.`}
                      inputProps={{ maxLength: 250 }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          reason: e.target.value,
                        });

                        setErrors({
                          ...errors,
                          reasonHasError: false,
                        });
                      }}
                    />
                    <strong className="text-right float-right text-primary w-100">
                      Should not exceed the 250-character limit.
                    </strong>
                    {errors.reasonHasError && (
                      <small className="text-danger mb-1">
                        Please, type the comment.
                      </small>
                    )}
                  </div>
                </div>
              </>
            )}
            {activeStep === 1 && action !== "CLOSE_CASE" && (
              <>
                <CommonLetter
                  locationName={caseRequest.approverLocation}
                  caseSanctionId={formData.caseSanctionId}
                  recipientName={
                    caseRequest.lastName + " " + caseRequest.firstName
                  }
                  recipientEmail={caseRequest.email}
                  recipientPhone={caseRequest.phoneNumber}
                  institutionName={caseRequest.entityName}
                  docLetterNo={formData.docLetterNo}
                  explanationFeedbackLetterNo={
                    formData.explanationFeedbackLetterNo
                  }

                  provisionalSuspensionNo={caseRequest.provisionalSuspensionNo}
                  provisionalSuspensionDate={moment(caseRequest.provisionalSuspensionDate).format(
                    "LL"
                  )}

                  reportCommitteeSummary={formData.reportCommitteeSummary}
                  reportCommitteeDate={moment(
                    formData.reportCommitteeDate
                  ).format("LL")}
                  effectiveDate={moment(formData.effectedOn).format("LL")}
                  explanationFeedbackLetterDate={moment(
                    formData.explanationFeedbackLetterDate
                  ).format("LL")}
                  subTitle={
                    "Dear " +
                    (caseRequest.lastName + " " + caseRequest.firstName)
                  }
                  competentAuthorityName={
                    !!requestDocumentSignerDetail
                      ? requestDocumentSignerDetail.competentAuthorityUser.names
                      : ""
                  }
                  competentAuthorityPosition={
                     !!requestDocumentSignerDetail
                      ? requestDocumentSignerDetail?.competentAuthorityUser
                          ?.positionName
                      : ""
                  }
                  enableToFillLetter={enableToFillLetter}
                  docLetterNoInput={
                    <>
                      <Input
                        autoFocus
                        error={!!errors.docLetterNoError}
                        value={formData.docLetterNo}
                        style={{ width: "150px" }}
                        inputProps={{
                          "aria-label": "add doc No",
                        }}
                        onChange={(e) => {
                          const docLetterNo = e.target.value;
                          setFormData({
                            ...formData,
                            docLetterNo,
                          });

                          setErrors({
                            ...errors,
                            docLetterNoError: false,
                          });
                        }}
                      />
                    </>
                  }
                  effectiveDateInput={
                    <>
                      <Input
                        type="date"
                        error={!!errors.effectiveDateError}
                        value={
                          formData.effectedOn ||
                          caseRequest?.sanctionEffectedOn ||
                          null
                        }
                        style={{ width: "150px" }}
                        inputProps={{
                          "aria-label": "add reference date",
                        }}
                        onChange={(e) => {
                          const effectedOn = e.target.value;
                          setFormData({
                            ...formData,
                            effectedOn,
                          });

                          setErrors({
                            ...errors,
                            effectiveDateError: false,
                          });
                        }}
                      />
                    </>
                  }
                  ccSelectForm={
                    <>
                      <Autocomplete
                        size="small"
                        fullWidth
                        multiple
                        id="ccData"
                        options={entities.map(
                          ({ name, institutionEmail, id,ccName }) => ({
                            name,
                            institutionEmail,
                            id,ccName
                          })
                        )}
                        value={ccData}
                        onChange={(e, cc) => {
                          const uniqueCc = removeDuplicates(cc);
                          setCCData(uniqueCc);
                          const ccHasError = false;
                          setErrors({ ...errors, ccHasError });
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={
                                selected ||
                                !!ccData.find(({ id }) => id === option.id)
                              }
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Select the institution to copy"}
                            placeholder={"Select the institution to copy"}
                          />
                        )}
                      />

                      {errors.ccHasError && (
                        <small className="text-danger mb-1">
                          Please, select the institution to copy
                        </small>
                      )}
                    </>
                  }
                  ccData={ccData}
                  providingExplanationLetterDate={moment(
                    caseRequest.requestExplanationDocLetterDate
                  ).format("LL")}
                  providingExplanationLetterNo={
                    caseRequest.requestExplanationDocLetterNo
                  }
                  employeeExplanationLetterDate={moment(
                    caseRequest.employeeExplanationLetterDate
                  ).format("LL")}
                  isSentToDisciplinaryCommittee={caseRequest.isSentToDisciplinaryCommittee}
                  isProvisionSuspensionLetterRequired={
                    caseRequest.isProvisionSuspensionLetterRequired
                  }
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            position: "relative",

                            width: "20%",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {!!hasPermissions([
                            "IS_HEAD_OF_INSTITUTION",
                            "IS_HEAD_OF_UNIT",
                          ]) &&
                          ((caseRequest?.request?.decisionLevel === 2 &&
                            [12].includes(
                              caseRequest?.request?.caseStatusId
                            )) ||
                            (caseRequest?.request?.decisionLevel === 1 &&
                              [11].includes(
                                caseRequest?.request?.caseStatusId
                              ))) ? (
                            <>
                              <SignLetter />
                            </>
                          ) : (
                            <>
                              <span className="d-block text-danger">
                                Not signed
                              </span>
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>

        {!!hasPermissions([
          "IS_HR",
          "IS_DOCUMENT_VERIFIER",
          "IS_HEAD_OF_UNIT",
          "IS_HEAD_OF_INSTITUTION",
        ]) &&
          enableToFillLetter && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                {activeStep === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      setActiveStep(0);
                    }}
                    className="btn btn-xs"
                  >
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (["EDIT", "CLOSE_CASE"].includes(action)) {
                      onSave();
                    }
                    if (["GIVE_SANCTION"].includes(action)) {
                      if (!formValidator()) {
                        if (activeStep === 0) setActiveStep(1);
                        if (activeStep === 1) {
                          onSave();
                        }
                      }
                    }
                    if (["REVIEW", "VERIFY"].includes(action)) {
                      if (!formValidator()) {
                        setShowCaseReviewerForm(true);
                      }
                    }
                    if (["APPROVE"].includes(action)) {
                      if (!formValidator()) {
                        setShowCaseApproverForm(true);
                      }
                    }
                  }}
                  className="btn btn-xs"
                >
                  {loading
                    ? "Wait.."
                    : ["CLOSE_CASE"].includes(action)
                    ? "Submit"
                    : activeStep === 0
                    ? "Next"
                    : "Submit"}
                </Button>
              </DialogActions>
            </>
          )}
        {showCaseReviewerForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseReviewerForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className={`text-success`}>confirm</strong> this case?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReviewerForm}
            onYes={() => {
              formData.cc = JSON.stringify(ccData);
              formData.reason = reason;
              formData.caseStatusId = actionStatusId;
              reviewCaseSanctionRequest(formData, onCloseAll);
            }}
          />
        )}

        {showCaseApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showCaseApproverForm}
            message={
              <>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={setShowCaseApproverForm}
            onYes={() => {
              if (!formData.docLetterNo) {
                showError("Document No is required");
                return false;
              }
              formData.cc = JSON.stringify(ccData);
              formData.password = pkiPassword;
              formData.caseStatusId = actionStatusId;
              approveRequestSanctionLetter(formData, onCloseAll);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
  headOfUnit,
  disciplineLetterNo
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
    headOfUnit,
    disciplineLetterNo
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  updateRequestSanctionLetter,
  reviewCaseSanctionRequest,
  approveRequestSanctionLetter,
  closeCase,
  initiateSanctionAfterCommittee,
  getHeadOfUnit,
  getDisciplineLetterNo
})(CommitteeReportFormDialog);
