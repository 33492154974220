import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Slide from "@mui/material/Slide";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { currencyFormat } from "../../common/components/Utils";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import {
  downloadPDFPayroll,
  getPayrollDetails,
} from "../../../store/payroll/actions";
import { isEmpty } from "lodash";
import SignPayrollDialog from "./SignPayrollDialog";
import axios from "axios";
import RejectPayrollDialog from "./RejectPayrollDialog";
import IndividualSupportingDocumentDialog from "./IndividualSupportingDocumentDialog";

const PayrollPreviewSalaryDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    showPayroll,
    setShowPayroll,
    selectedPayroll,
    payrollPreviewed,
    downloadPDFPayroll,
    getPayrollDetails,
    payrollDetails: inputPayrollDetails,
    type,
    viewType,
    env,
  } = props;

  const [payrollDetails, setPayrollDetails] = useState([]);

  useEffect(() => {
    setPayrollDetails(inputPayrollDetails[selectedPayroll.id]);
  }, [inputPayrollDetails]);

  const getTotalAllowances = () =>
    +selectedPayroll.grossSalary + +selectedPayroll.exchangeFees;

  const getTotalWithhelds = () =>
    +selectedPayroll.pensionEmployee +
    +selectedPayroll.maternityEmployee +
    +selectedPayroll.medicalEmployee +
    +selectedPayroll.mmiEmployee +
    +selectedPayroll.cbhi +
    +selectedPayroll.tpr +
    +selectedPayroll.withHolds;

  const getTotalDispatch = () =>
    +selectedPayroll.pensionEmployee +
    +selectedPayroll.pensionEmployer +
    +selectedPayroll.professionalRisk +
    +selectedPayroll.maternityEmployee +
    +selectedPayroll.maternityEmployer +
    +selectedPayroll.medicalEmployee +
    +selectedPayroll.medicalEmployer +
    +selectedPayroll.mmiEmployee +
    +selectedPayroll.mmiEmployer +
    +selectedPayroll.cbhi +
    +selectedPayroll.tpr +
    +selectedPayroll.withHolds +
    +selectedPayroll.diplomatConvertedNet;

  const getTotalPatronalContributions = () =>
    +selectedPayroll.pensionEmployer +
    +selectedPayroll.professionalRisk +
    +selectedPayroll.maternityEmployer +
    +selectedPayroll.medicalEmployer +
    +selectedPayroll.mmiEmployer;

  const [formatedPayrollDetails, setFormatPayrollDetails] = useState([]);
  const [payrollChangeList, setPayrollChangeList] = useState({});

  const formatPayrollDetails = () => {
    const units = [];

    payrollDetails.payList.forEach((payrollDetail) => {
      const unitIndex = units.findIndex(
        (unit) => unit.unitId === payrollDetail.unitId
      );

      if (unitIndex < 0)
        units.push({
          unitId: payrollDetail.unitId,
          unitName: payrollDetail.unitName,
          basePay: payrollDetail.basePay,
          fsa: payrollDetail.fsa,
          transportAllowance: payrollDetail.transportAllowance,
          taxableAllowance: payrollDetail.taxableAllowance,
          grossSalary: payrollDetail.grossSalary,
          tpr: payrollDetail.tpr,
          pensionEmployee: payrollDetail.pensionEmployee,
          pensionEmployer: payrollDetail.pensionEmployer,
          professionalRisk: payrollDetail.professionalRisk,
          medicalEmployee: payrollDetail.medicalEmployee,
          medicalEmployer: payrollDetail.medicalEmployer,
          maternityEmployee: payrollDetail.maternityEmployee,
          maternityEmployer: payrollDetail.maternityEmployer,
          cbhi: payrollDetail.cbhi,
          partialNet: payrollDetail.partialNet,
          withHolds: payrollDetail.withHolds,
          netSalary: payrollDetail.netSalary,
          diplomatUsdNet: payrollDetail.diplomatUsdNet,
          diplomatConvertedNet: payrollDetail.diplomatConvertedNet,
          employees: [payrollDetail],
        });
      else {
        units[unitIndex].basePay += +payrollDetail.basePay;
        units[unitIndex].fsa += +payrollDetail.fsa;
        units[unitIndex].transportAllowance +=
          +payrollDetail.transportAllowance;
        units[unitIndex].taxableAllowance += +payrollDetail.taxableAllowance;
        units[unitIndex].grossSalary += +payrollDetail.grossSalary;
        units[unitIndex].tpr += +payrollDetail.tpr;
        units[unitIndex].pensionEmployee += +payrollDetail.pensionEmployee;
        units[unitIndex].pensionEmployer += +payrollDetail.pensionEmployer;
        units[unitIndex].professionalRisk += +payrollDetail.professionalRisk;
        units[unitIndex].medicalEmployee += +payrollDetail.medicalEmployee;
        units[unitIndex].medicalEmployer += +payrollDetail.medicalEmployer;
        units[unitIndex].maternityEmployee += +payrollDetail.maternityEmployee;
        units[unitIndex].maternityEmployer += +payrollDetail.maternityEmployer;
        units[unitIndex].cbhi += +payrollDetail.cbhi;
        units[unitIndex].partialNet += +payrollDetail.partialNet;
        units[unitIndex].withHolds += +payrollDetail.withHolds;
        units[unitIndex].netSalary += +payrollDetail.netSalary;
        units[unitIndex].diplomatUsdNet += +payrollDetail.diplomatUsdNet;
        units[unitIndex].diplomatConvertedNet +=
          +payrollDetail.diplomatConvertedNet;
        units[unitIndex].employees.push(payrollDetail);
      }
    });

    setFormatPayrollDetails(units);
  };

  const formatChangeList = (listOfChanges) => {
    let formatList = {
      basePay: 0,
      fsa: 0,
      transportAllowance: 0,
      taxableAllowance: 0,
      exchangeFees: 0,
      grossSalary: 0,
      pensionEmployer: 0,
      professionalRisk: 0,
      medicalEmployer: 0,
      maternityEmployer: 0,
      prevBasePay: 0,
      prevHousingAllowance: 0,
      prevTransportAllowance: 0,
      prevTaxableAllowance: 0,
      prevExchangeFees: 0,
      prevGrossSalary: 0,
      prevPensionEmployer: 0,
      prevProfessionalRisk: 0,
      prevMedicalEmployer: 0,
      prevMaternityEmployer: 0,
      differance: 0,
      changeList: [],
    };

    listOfChanges.forEach((employee) => {
      formatList.basePay += +employee.basePay;
      formatList.fsa += +employee.fsa;
      formatList.transportAllowance += +employee.transportAllowance;
      formatList.taxableAllowance += +employee.taxableAllowance;
      formatList.exchangeFees += +employee.exchangeFees;
      formatList.grossSalary += +employee.grossSalary;
      formatList.pensionEmployer += +employee.pensionEmployer;
      formatList.professionalRisk += +employee.professionalRisk;
      formatList.medicalEmployer += +employee.medicalEmployer;
      formatList.maternityEmployer += +employee.maternityEmployer;
      formatList.prevBasePay += employee.prevBasePay;
      formatList.prevFsa += employee.prevFsa;
      formatList.prevTransportAllowance += employee.prevTransportAllowance;
      formatList.prevTaxableAllowance += employee.prevTaxableAllowance;
      formatList.prevExchangeFees += employee.prevExchangeFees;
      formatList.prevGrossSalary += employee.prevGrossSalary;
      formatList.prevPensionEmployer += employee.prevPensionEmployer;
      formatList.prevProfessionalRisk += employee.prevProfessionalRisk;
      formatList.prevMedicalEmployer += employee.prevMedicalEmployer;
      formatList.prevMaternityEmployer += employee.prevMaternityEmployer;
      formatList.differance +=
        +employee.grossSalary +
        +employee.exchangeFees +
        +employee.pensionEmployer +
        +employee.professionalRisk +
        +employee.medicalEmployer +
        +employee.maternityEmployer -
        (+employee.prevGrossSalary +
          +employee.prevExchangeFees +
          +employee.prevPensionEmployer +
          +employee.prevProfessionalRisk +
          +employee.prevMedicalEmployer +
          +employee.prevMaternityEmployer);
      formatList.changeList.push(employee);
    });

    return formatList;
  };

  useEffect(() => {
    if (
      !!payrollDetails &&
      !!payrollDetails.payList &&
      payrollDetails.payList.length
    )
      formatPayrollDetails();
  }, [payrollDetails]);

  const getPayrollChangeList = () => {
    if (
      !!payrollDetails &&
      !!payrollDetails.changeList &&
      payrollDetails.changeList.length
    )
      return {
        news: formatChangeList(
          payrollDetails.changeList.filter((item) => item.status === "New")
        ),
        promoDemos: formatChangeList(
          payrollDetails.changeList.filter(
            (item) => item.status === "Promotion/Demotion"
          )
        ),
        removeds: formatChangeList(
          payrollDetails.changeList.filter((item) => item.status === "Removed")
        ),
      };
    else
      return {
        news: [],
        promoDemos: [],
        removeds: [],
      };
  };

  const [showSignPayrollDialog, setShowSignPayrollDialog] = useState(false);
  const [signingUser, setSigningUser] = useState({});
  const [rejectionLevel, setRejectionLevel] = useState("");
  const [showRejectPayrollDialog, setShowRejectPayrollDialog] = useState(false);

  const firstSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "GENERATED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const secondSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "VERIFIED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const thirdSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "APPROVED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const [userSignature, setUserSignature] = useState(null);
  const [isGettingSignature, setIsGettingSignature] = useState(false);

  const getUserSignature = async (setShowSignPayrollDialog) => {
    try {
      setIsGettingSignature(true);
      const { data } = await axios.get("/api/auth/user-signatures");
      setUserSignature(data);
      setShowSignPayrollDialog(true);
      setIsGettingSignature(false);
    } catch (error) {
      setShowSignPayrollDialog(true);
      setIsGettingSignature(false);
    }
  };

  // useEffect(() => {
  //   if (!!showSignPayrollDialog) getUserSignature();
  // }, [showSignPayrollDialog]);

  const [
    showIndividualSupportingDocumentDialog,
    setShowIndividualSupportingDocumentDialog,
  ] = useState(null);

  return (
    <>
      <div id="main-content" style={{ marginTop: 12 }}>
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <td colSpan={4}></td>
              <td colSpan={4}>
                <table
                  style={{
                    border: "solid 1px #4f81bc",
                    textAlign: "center",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          border: "solid 1px #4f81bc",
                          backgroundColor: "#4f81bc",
                          color: "#fff",
                        }}
                      >
                        PAYROLL
                      </th>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {selectedPayroll.payrollTypeName}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {selectedPayroll.periodName}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {selectedPayroll.payrollGroupName} /{" "}
                        {selectedPayroll.sourceOfFundName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <th
                colSpan={6}
                style={{
                  width: "50%",
                  borderLeft: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                Payroll Summary
              </th>
              <th
                style={{
                  backgroundColor: "#fff",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              />
              <th
                colSpan={4}
                style={{
                  width: "50%",
                  backgroundColor: "#4f81bc",
                  borderRight: "1px solid #4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                Payroll Change Summary
              </th>
            </tr>
            <tr>
              <td colSpan={11} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Allowances
              </th>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Withheld
              </th>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Salary Dispatch
              </th>
              <th style={{ backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "19%",
                  padding: "1.45px 8px",
                  fontSize: "12px",
                  textAlign: "right",
                  borderTop: "1px solid #4f81bc",
                  borderLeft: "1px solid #4f81bc",
                  backgroundColor: "#fff",
                }}
              />
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "right",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {payrollDetails?.changeSummary?.prevPeriodId}
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "right",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {selectedPayroll.periodId}
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "right",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Difference
              </th>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Base Pay:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.basePay)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.pensionEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployee +
                    +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk
                )}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  // borderTop: "1px solid #4f81bc",
                }}
              >
                Number
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {payrollDetails?.changeSummary?.prevNumberOfEmployees}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {selectedPayroll?.numberOfEmployees}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {+selectedPayroll?.numberOfEmployees -
                  +(payrollDetails?.changeSummary?.prevNumberOfEmployees || 0)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                FSA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.fsa)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity Leave:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.maternityEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity Leave:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.maternityEmployee +
                    +selectedPayroll.maternityEmployer
                )}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Base Pay
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(payrollDetails?.changeSummary?.prevBasePay)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(selectedPayroll?.basePay)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.basePay -
                    +payrollDetails?.changeSummary?.prevBasePay
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Transport:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.transportAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.medicalEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployee +
                    +selectedPayroll.medicalEmployer
                )}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                FSA
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+payrollDetails?.changeSummary?.prevFsa)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll?.fsa)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.fsa -
                    +payrollDetails?.changeSummary?.prevFsa
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Taxable:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.taxableAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.mmiEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.mmiEmployee + +selectedPayroll.mmiEmployer
                )}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Transport Allowance
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevTransportAllowance
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll?.transportAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.transportAllowance -
                    +payrollDetails?.changeSummary?.prevTransportAllowance
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Exchange Fees:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.exchangeFees)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Mutuelle:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.cbhi)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Mutuelle:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.cbhi)}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Taxable Allowance
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevTaxableAllowance
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll?.taxableAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.taxableAllowance -
                    +payrollDetails?.changeSummary?.prevTaxableAllowance
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalAllowances())}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                TPR:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.tpr)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                TPR:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.tpr)}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Exchange Fees
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevExchangeFees
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.exchangeFees)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.exchangeFees -
                    +payrollDetails?.changeSummary?.prevExchangeFees
                )}
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Patronal Contribution
              </th>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Non Statutory:
              </td>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.withHolds)}
              </td>

              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Other Withheld:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.withHolds)}
              </td>
              <td rowSpan={2} />

              <td
                rowSpan={2}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Pension
              </td>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevPensionEmployer +
                    +payrollDetails?.changeSummary?.prevProfessionalRisk
                )}
              </td>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk
                )}
              </td>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk -
                    (+payrollDetails?.changeSummary?.prevPensionEmployer +
                      +payrollDetails?.changeSummary?.prevProfessionalRisk)
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Net Salary:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.diplomatConvertedNet)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.totalPensionSalaryEmployer)}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalWithhelds())}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalDispatch())}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Medical Insurance
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevMedicalEmployer +
                    +payrollDetails?.changeSummary?.prevMMIEmployer
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.medicalEmployer +
                    +selectedPayroll?.mmiEmployer
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.medicalEmployer +
                    +selectedPayroll?.mmiEmployer -
                    (+payrollDetails?.changeSummary?.prevMedicalEmployer +
                      +payrollDetails?.changeSummary?.prevMMIEmployer)
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.maternityEmployer)}
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                Cost of Living
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  borderTop: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                : {selectedPayroll.costOfLiving}
              </td>

              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity Leave
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevMaternityEmployer
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll?.maternityEmployer)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.maternityEmployer -
                    +payrollDetails?.changeSummary?.prevMaternityEmployer
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.medicalEmployer)}
              </td>

              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  // borderTop: "1px solid #4f81bc",
                  // borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                Diplomat exchange rate ({selectedPayroll.currencyId})
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  // borderTop: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                : {selectedPayroll.diplomatExchangeRate}
              </td>

              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              >
                {/* Lumpsum */}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              >
                {/* {currencyFormat(+payrollDetails?.changeSummary?.prevLumpsum)} */}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              >
                {/* {currencyFormat(+selectedPayroll?.lumpsum)} */}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "1.45px 8px",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              >
                {/* {currencyFormat(
                  +selectedPayroll?.lumpsum -
                    +payrollDetails?.changeSummary?.prevLumpsum
                )} */}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.mmiEmployer)}
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  // borderTop: "1px solid #4f81bc",
                  // borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                BNR exhange rate
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  // borderTop: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                : {selectedPayroll.exchangeRate}
              </td>

              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderRight: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalPatronalContributions())}
              </td>

              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderBottom: "1px solid #4f81bc",
                  // borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                DTS exchange Rate
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  borderBottom: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#deedf98c",
                }}
              >
                : {selectedPayroll.dtsExchangeRate}
              </td>
              <td />
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  verticalAlign: "top",
                }}
              />
            </tr>
            <tr>
              <td colSpan={11} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={11}
                style={{
                  width: "50%",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                General Totals
              </th>
            </tr>
            <tr>
              <td colSpan={11} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +getTotalAllowances() + +getTotalPatronalContributions()
                )}
              </th>
              <th style={{ backgroundColor: "#fff" }} />

              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(+getTotalDispatch())}
              </th>
              <th colSpan={2} style={{ backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "19%",
                  padding: "1.45px 8px",
                  fontSize: "12px",
                  textAlign: "right",
                  backgroundColor: "#fff",
                }}
              />
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +payrollDetails?.changeSummary?.prevBasePay +
                    +payrollDetails?.changeSummary?.prevFsa +
                    +payrollDetails?.changeSummary?.prevTransportAllowance +
                    +payrollDetails?.changeSummary?.prevTaxableAllowance +
                    +payrollDetails?.changeSummary?.prevPensionEmployer +
                    +payrollDetails?.changeSummary?.prevProfessionalRisk +
                    +payrollDetails?.changeSummary?.prevMedicalEmployer +
                    +payrollDetails?.changeSummary?.prevMMIEmployer +
                    +payrollDetails?.changeSummary?.prevMaternityEmployer +
                    +payrollDetails?.changeSummary?.prevExchangeFees
                )}
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.basePay +
                    +selectedPayroll?.fsa +
                    +selectedPayroll?.transportAllowance +
                    +selectedPayroll?.taxableAllowance +
                    +selectedPayroll?.pensionEmployer +
                    +selectedPayroll?.professionalRisk +
                    +selectedPayroll?.medicalEmployer +
                    +selectedPayroll?.mmiEmployer +
                    +selectedPayroll?.maternityEmployer +
                    +selectedPayroll?.exchangeFees
                )}
              </th>
              <th
                style={{
                  width: "10%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +selectedPayroll?.basePay +
                    +selectedPayroll?.fsa +
                    +selectedPayroll?.transportAllowance +
                    +selectedPayroll?.taxableAllowance +
                    +selectedPayroll?.pensionEmployer +
                    +selectedPayroll?.professionalRisk +
                    +selectedPayroll?.medicalEmployer +
                    +selectedPayroll?.mmiEmployer +
                    +selectedPayroll?.maternityEmployer +
                    +selectedPayroll?.exchangeFees -
                    (+payrollDetails?.changeSummary?.prevBasePay +
                      +payrollDetails?.changeSummary?.prevFsa +
                      +payrollDetails?.changeSummary?.prevTransportAllowance +
                      +payrollDetails?.changeSummary?.prevTaxableAllowance +
                      +payrollDetails?.changeSummary?.prevPensionEmployer +
                      +payrollDetails?.changeSummary?.prevProfessionalRisk +
                      +payrollDetails?.changeSummary?.prevMedicalEmployer +
                      +payrollDetails?.changeSummary?.prevMMIEmployer +
                      +payrollDetails?.changeSummary?.prevMaternityEmployer +
                      +payrollDetails?.changeSummary?.prevExchangeFees)
                )}
              </th>
            </tr>
          </tbody>
        </table>
        {payrollDetails?.changeList?.length > 0 && (
          <table
            className="table table-sm table-borderless table-hover"
            style={{
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            <tbody style={{ borderRadius: "15px" }}>
              <tr>
                <th
                  colSpan={28}
                  style={{
                    borderLeft: "1px solid #e68753",
                    borderRight: "1px solid #e68753",
                    backgroundColor: "#e68753",
                    color: "white",
                    textAlign: "center",
                    fontSize: "18px",
                    padding: 3,
                  }}
                >
                  Payroll Change List ({payrollDetails?.changeList?.length || 0}
                  )
                </th>
              </tr>

              {getPayrollChangeList()?.news?.changeList?.length > 0 && (
                <>
                  <tr>
                    <th
                      colSpan={28}
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#5555df",
                      }}
                    >
                      {getPayrollChangeList()?.news?.changeList?.length || 0}{" "}
                      New
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      EmployeeId
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Names
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      unit
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      BasePay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      OtherAll
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Base Pay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Other All
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #e68753",
                      }}
                    >
                      Differance
                    </th>
                  </tr>
                  {(getPayrollChangeList()?.news?.changeList || []).map(
                    (item, index) => (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        onClick={() =>
                          setShowIndividualSupportingDocumentDialog(item)
                        }
                      >
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                          }}
                        >
                          {item.id}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.lastName}{" "}
                          {item.firstName.replace("(Acting)", "")}
                          {item.firstName.includes("(Acting)") && (
                            <strong className="text-primary">(Acting)</strong>
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            maxWidth: "128px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.unitName || "N/A"}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevLevel}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevDays}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevBasePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevFsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTransportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTaxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevExchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevPensionEmployer +
                              +item.prevProfessionalRisk
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMedicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMaternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevGrossSalary +
                              +item.prevExchangeFees +
                              +item.prevPensionEmployer +
                              +item.prevProfessionalRisk +
                              +item.prevMedicalEmployer +
                              +item.prevMaternityEmployer
                          )}
                        </td>
                        <th
                          style={{
                            fontSize: "7px",
                            backgroundColor: "#fff",
                            borderLeft: "1px solid #e68753",
                          }}
                        />
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.level}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.days}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.basePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.fsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.transportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.taxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.exchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {+item.pensionEmployer + +item.professionalRisk}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.medicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.maternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer -
                              (+item.prevGrossSalary +
                                +item.prevExchangeFees +
                                +item.prevPensionEmployer +
                                +item.prevProfessionalRisk +
                                +item.prevMedicalEmployer +
                                +item.prevMaternityEmployer)
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "center",
                            borderRight: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {env !== "prod" && (
                            <Tooltip
                              title={`${
                                !item.hasSupportingDocument
                                  ? "Not supported"
                                  : "Supported"
                              }`}
                            >
                              <span>
                                {!item.hasSupportingDocument && (
                                  <i className="fas fa-exclamation-triangle text-danger"></i>
                                )}
                                {!!item.hasSupportingDocument && (
                                  <i className="fas fa-check-circle text-success"></i>
                                )}
                              </span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    )
                  )}

                  <tr>
                    <td
                      colSpan={28}
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        backgroundColor: "#fff",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        fontWeight: "bold",
                        backgroundColor: "#e68753",
                        color: "#fff",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevBasePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevFsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevTransportAllowance ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevTaxableAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevExchangeFees || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.news?.prevPensionEmployer || 0
                        ) +
                          +(
                            getPayrollChangeList()?.news
                              ?.prevProfessionalRisk || 0
                          )
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevMedicalEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.prevMaternityEmployer ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(getPayrollChangeList()?.news?.prevGrossSalary || 0) +
                          +(
                            getPayrollChangeList()?.news?.prevExchangeFees || 0
                          ) +
                          +(
                            getPayrollChangeList()?.news?.prevPensionEmployer ||
                            0
                          ) +
                          +(
                            getPayrollChangeList()?.news
                              ?.prevProfessionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.news?.prevMedicalEmployer ||
                            0
                          ) +
                          +(
                            getPayrollChangeList()?.news
                              ?.prevMaternityEmployer || 0
                          )
                      )}
                    </td>
                    <th
                      colSpan={3}
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.basePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(+getPayrollChangeList()?.news?.fsa || 0)}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.transportAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.taxableAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.exchangeFees || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(getPayrollChangeList()?.news?.pensionEmployer || 0) +
                          +(getPayrollChangeList()?.news?.professionalRisk || 0)
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.medicalEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.news?.maternityEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(getPayrollChangeList()?.news?.grossSalary || 0) +
                          +(getPayrollChangeList()?.news?.exchangeFees || 0) +
                          +(
                            getPayrollChangeList()?.news?.pensionEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.news?.professionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.news?.medicalEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.news?.maternityEmployer || 0
                          )
                      )}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderRight: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        getPayrollChangeList()?.news?.differance || 0
                      )}
                    </td>
                  </tr>
                </>
              )}

              {getPayrollChangeList()?.promoDemos?.changeList?.length > 0 && (
                <>
                  <tr>
                    <th
                      colSpan={28}
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#5555df",
                      }}
                    >
                      {getPayrollChangeList()?.promoDemos?.changeList?.length ||
                        0}{" "}
                      Promotion/Demotion
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      EmployeeId
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Names
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      unit
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      BasePay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      OtherAll
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Base Pay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Other All
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #e68753",
                      }}
                    >
                      Differance
                    </th>
                  </tr>
                  {(getPayrollChangeList()?.promoDemos?.changeList || []).map(
                    (item, index) => (
                      <tr
                        className="cursor-pointer"
                        key={item.id}
                        onClick={() =>
                          setShowIndividualSupportingDocumentDialog(item)
                        }
                      >
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                          }}
                        >
                          {item.id}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.lastName}{" "}
                          {item.firstName.replace("(Acting)", "")}
                          {item.firstName.includes("(Acting)") && (
                            <strong className="text-primary">(Acting)</strong>
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            maxWidth: "128px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.unitName || "N/A"}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevLevel}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevDays}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevBasePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevFsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTransportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTaxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevExchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevPensionEmployer +
                              +item.prevProfessionalRisk
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMedicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMaternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevGrossSalary +
                              +item.prevExchangeFees +
                              +item.prevPensionEmployer +
                              +item.prevProfessionalRisk +
                              +item.prevMedicalEmployer +
                              +item.prevMaternityEmployer
                          )}
                        </td>
                        <th
                          style={{
                            fontSize: "7px",
                            backgroundColor: "#fff",
                            borderLeft: "1px solid #e68753",
                          }}
                        />
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.level}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.days}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.basePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.fsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.transportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.taxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.exchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {+item.pensionEmployer + +item.professionalRisk}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.medicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.maternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer
                          )}
                        </td>

                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer -
                              (+item.prevGrossSalary +
                                +item.prevExchangeFees +
                                +item.prevPensionEmployer +
                                +item.prevProfessionalRisk +
                                +item.prevMedicalEmployer +
                                +item.prevMaternityEmployer)
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderRight: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {/** -vv */}
                          {env !== "prod" && (
                            <Tooltip
                              title={`${
                                !item.hasSupportingDocument
                                  ? "Not supported"
                                  : "Supported"
                              }`}
                            >
                              <span>
                                {!item.hasSupportingDocument && (
                                  <i className="fas fa-exclamation-triangle text-danger"></i>
                                )}
                                {!!item.hasSupportingDocument && (
                                  <i className="fas fa-check-circle text-success"></i>
                                )}
                              </span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        backgroundColor: "#fff",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        fontWeight: "bold",
                        backgroundColor: "#e68753",
                        color: "#fff",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.prevBasePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.prevFsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.prevTransportAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.prevTaxableAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.prevExchangeFees ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.promoDemos
                            ?.prevPensionEmployer || 0
                        ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevProfessionalRisk || 0
                          )
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.prevMedicalEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.prevMaternityEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.promoDemos?.prevGrossSalary ||
                          0
                        ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevExchangeFees || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevPensionEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevProfessionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevMedicalEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.prevMaternityEmployer || 0
                          )
                      )}
                    </td>
                    <th
                      colSpan={3}
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.basePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.fsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.transportAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.taxableAllowance ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.exchangeFees || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.promoDemos?.pensionEmployer ||
                          0
                        ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.professionalRisk || 0
                          )
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos?.medicalEmployer ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.promoDemos
                          ?.maternityEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.promoDemos?.grossSalary || 0
                        ) +
                          +(
                            getPayrollChangeList()?.promoDemos?.exchangeFees ||
                            0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.pensionEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.professionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.medicalEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.promoDemos
                              ?.maternityEmployer || 0
                          )
                      )}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderRight: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        getPayrollChangeList()?.promoDemos?.differance || 0
                      )}
                    </td>
                  </tr>
                </>
              )}

              {getPayrollChangeList()?.removeds?.changeList?.length > 0 && (
                <>
                  <tr>
                    <th
                      colSpan={28}
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#5555df",
                      }}
                    >
                      {getPayrollChangeList()?.removeds?.changeList?.length ||
                        0}{" "}
                      Removed
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      EmployeeId
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Names
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      unit
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      BasePay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      OtherAll
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "left",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Days
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Base Pay
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      FSA
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Transport
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Other All
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Exchange
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      CSR
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Medical
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Maternity
                    </th>
                    <th
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        borderLeft: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                      }}
                    >
                      Gross
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #e68753",
                      }}
                    >
                      Differance
                    </th>
                  </tr>
                  {(getPayrollChangeList()?.removeds?.changeList || []).map(
                    (item, index) => (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        onClick={() =>
                          setShowIndividualSupportingDocumentDialog(item)
                        }
                      >
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                          }}
                        >
                          {item.id}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.lastName}{" "}
                          {item.firstName.replace("(Acting)", "")}
                          {item.firstName.includes("(Acting)") && (
                            <strong className="text-primary">(Acting)</strong>
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                            maxWidth: "128px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.unitName || "N/A"}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevLevel}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.prevDays}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevBasePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevFsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTransportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevTaxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevExchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevPensionEmployer +
                              +item.prevProfessionalRisk
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMedicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.prevMaternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.prevGrossSalary +
                              +item.prevExchangeFees +
                              +item.prevPensionEmployer +
                              +item.prevProfessionalRisk +
                              +item.prevMedicalEmployer +
                              +item.prevMaternityEmployer
                          )}
                        </td>
                        <th
                          style={{
                            fontSize: "7px",
                            backgroundColor: "#fff",
                            borderLeft: "1px solid #e68753",
                          }}
                        />
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "left",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.level}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {item.days}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.basePay)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.fsa)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.transportAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.taxableAllowance)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.exchangeFees)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {+item.pensionEmployer + +item.professionalRisk}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.medicalEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(item.maternityEmployer)}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer
                          )}
                        </td>

                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {currencyFormat(
                            +item.grossSalary +
                              +item.exchangeFees +
                              +item.pensionEmployer +
                              +item.professionalRisk +
                              +item.medicalEmployer +
                              +item.maternityEmployer -
                              (+item.prevGrossSalary +
                                +item.prevExchangeFees +
                                +item.prevPensionEmployer +
                                +item.prevProfessionalRisk +
                                +item.prevMedicalEmployer +
                                +item.prevMaternityEmployer)
                          )}
                        </td>
                        <td
                          style={{
                            fontSize: "7px",
                            textAlign: "right",
                            borderLeft: "1px solid #e68753",
                            borderRight: "1px solid #e68753",
                            borderBottom: "1px solid #e68753",
                            borderTop: "1px solid #e68753",
                          }}
                        >
                          {/** -vv */}
                          {env !== "prod" && (
                            <Tooltip
                              title={`${
                                !item.hasSupportingDocument
                                  ? "Not supported"
                                  : "Supported"
                              }`}
                            >
                              <span>
                                {!item.hasSupportingDocument && (
                                  <i className="fas fa-exclamation-triangle text-danger"></i>
                                )}
                                {!!item.hasSupportingDocument && (
                                  <i className="fas fa-check-circle text-success"></i>
                                )}
                              </span>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      colSpan={28}
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        backgroundColor: "#fff",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        fontWeight: "bold",
                        backgroundColor: "#e68753",
                        color: "#fff",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.prevBasePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.prevFsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds
                          ?.prevTransportAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds
                          ?.prevTaxableAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.prevExchangeFees || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.removeds
                            ?.prevPensionEmployer || 0
                        ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevProfessionalRisk || 0
                          )
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds
                          ?.prevMedicalEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds
                          ?.prevMaternityEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.removeds?.prevGrossSalary || 0
                        ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevExchangeFees || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevPensionEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevProfessionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevMedicalEmployer || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.prevMaternityEmployer || 0
                          )
                      )}
                    </td>
                    <th
                      colSpan={3}
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fff",
                        borderLeft: "1px solid #e68753",
                      }}
                    />
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.basePay || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.fsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.transportAllowance ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.taxableAllowance || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.fsa || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(
                          getPayrollChangeList()?.removeds?.pensionEmployer || 0
                        ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.professionalRisk || 0
                          )
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.medicalEmployer || 0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +getPayrollChangeList()?.removeds?.maternityEmployer ||
                          0
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        +(getPayrollChangeList()?.removeds?.grossSalary || 0) +
                          +(
                            getPayrollChangeList()?.removeds?.exchangeFees || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds?.pensionEmployer ||
                            0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.professionalRisk || 0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds?.medicalEmployer ||
                            0
                          ) +
                          +(
                            getPayrollChangeList()?.removeds
                              ?.maternityEmployer || 0
                          )
                      )}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "7px",
                        textAlign: "right",
                        borderLeft: "1px solid #e68753",
                        borderRight: "1px solid #e68753",
                        borderTop: "1px solid #e68753",
                        borderBottom: "1px solid #e68753",
                        backgroundColor: "#f3f3f3",
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormat(
                        getPayrollChangeList()?.removeds?.differance || 0
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )}
        <table
          className="table table-sm table-borderless table-hover"
          style={{ marginTop: 24, marginBottom: 8 }}
        >
          <tbody>
            <tr>
              <th
                colSpan={23}
                style={{
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                Payroll List ({payrollDetails?.payList?.length || 0})
              </th>
            </tr>
            <tr>
              <td
                colSpan={23}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  backgroundColor: "#fff",
                }}
              />
            </tr>
            {formatedPayrollDetails.map((unit, index) => (
              <Fragment key={index}>
                <tr>
                  <td
                    colSpan={23}
                    style={{
                      fontSize: "7px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      color: "#fff",
                    }}
                  >
                    {unit.unitName} ({unit.employees.length})
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={23}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <th
                    colSpan={6}
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                  <th
                    colSpan={4}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Allowances
                  </th>
                  <th
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Social Sec.
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #fff",
                      color: "#fff",
                    }}
                  >
                    Maternity
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #fff",
                      color: "#fff",
                    }}
                  >
                    Medical Insu.
                  </th>
                  <th
                    colSpan={6}
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    EmployeeId
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Names
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Position
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Level
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    AccountNo
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Days
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    BasePay
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    FSA
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Transport
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Other
                  </th>

                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    TPR
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Mutuelle
                  </th>

                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Partial Net
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Withheld
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Net FRW
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Net {selectedPayroll.currencyId}
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Net Converted
                  </th>
                </tr>

                {unit.employees.map((employee, index) => (
                  <Fragment key={index}>
                    <tr>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.employeeId}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.lastName} {employee.firstName}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                          maxWidth: "192px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {employee.positionName.startsWith("Ag.") && (
                          <>
                            <strong className="text-primary">Ag.</strong>
                            {employee.positionName.replace("Ag.", "")}
                          </>
                        )}
                        {!employee.positionName.startsWith("Ag.") && (
                          <>{employee.positionName}</>
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.level}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.accountNumber}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.noOfDays}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.basePay)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.fsa)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.transportAllowance)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.taxableAllowance)}
                      </td>

                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.tpr)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.pensionEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(
                          +employee.pensionEmployer + +employee.professionalRisk
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.maternityEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.maternityEmployer)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.medicalEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.medicalEmployer)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.cbhi)}
                      </td>

                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.partialNet)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.withHolds)}
                      </td>

                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.netSalary)}
                      </td>

                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.diplomatUsdNet)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderRight: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.diplomatConvertedNet)}
                      </td>
                    </tr>
                  </Fragment>
                ))}
                <tr>
                  <td
                    colSpan={25}
                    style={{
                      fontSize: "7px",
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: "7px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      fontWeight: "bold",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Total
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      fontWeight: "bold",
                      backgroundColor: "#f3f3f3",
                    }}
                  >
                    Number: {unit.employees.length}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.basePay)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.fsa)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.transportAllowance)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.taxableAllowance)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.tpr)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.pensionEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(
                      +unit.pensionEmployer + +unit.professionalRisk
                    )}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.maternityEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.maternityEmployer)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.medicalEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.medicalEmployer)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.cbhi)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.partialNet)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.withHolds)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.netSalary)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.diplomatUsdNet)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.diplomatConvertedNet)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={23}
                    style={{
                      padding: "6px 0px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                      color: "#fff",
                    }}
                  ></td>
                </tr>
              </Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: "7px",
                  backgroundColor: "#fff",
                }}
              />
              <td
                colSpan={2}
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  fontWeight: "bold",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                  padding: "6px 8px",
                }}
              >
                Grand Totals
              </td>
              <td
                colSpan={2}
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  fontWeight: "bold",
                  backgroundColor: "#dcdbe6",
                }}
              >
                Number: {selectedPayroll.numberOfEmployees}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.basePay)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.fsa)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.transportAllowance)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.taxableAllowance)}
              </td>

              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(+selectedPayroll.tpr)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.pensionEmployee)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.maternityEmployee)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.maternityEmployer)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployee +
                    +selectedPayroll.mmiEmployee
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployer +
                    +selectedPayroll.mmiEmployer
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.cbhi)}
              </td>

              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.partialNet)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.withHolds)}
              </td>

              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.netSalary)}
              </td>

              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.diplomatUsdNet)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.diplomatConvertedNet)}
              </td>
            </tr>
          </tbody>
        </table>

        <table style={{ marginTop: 24, marginBottom: 8 }}>
          <tbody>
            <tr>
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Generated By
              </th>
              <th style={{ width: "15%", backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Verified by
              </th>
              <th style={{ width: "15%", backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Approved by
              </th>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {firstSignatory()?.names} <br />
                {firstSignatory()?.position}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {secondSignatory()?.names}
                <br />
                {secondSignatory()?.position}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {thirdSignatory()?.names}
                <br />
                {thirdSignatory()?.position}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed && (
                  <img
                    style={{ width: "192px" }}
                    src={"data:image/jpg;base64," + firstSignatory()?.signature}
                  />
                )}
                {!firstSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!firstSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {firstSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed && !!secondSignatory()?.signed && (
                  <img
                    style={{ width: "192px" }}
                    src={
                      "data:image/jpg;base64," + secondSignatory()?.signature
                    }
                  />
                )}
                {!secondSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!secondSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {secondSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed &&
                  !!secondSignatory()?.signed &&
                  !!thirdSignatory()?.signed && (
                    <img
                      style={{ width: "192px" }}
                      src={
                        "data:image/jpg;base64," + thirdSignatory()?.signature
                      }
                    />
                  )}
                {!thirdSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!thirdSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {thirdSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !firstSignatory()?.signed &&
                  !secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!firstSignatory() &&
                  !!firstSignatory()?.canSign &&
                  viewType !== "admin" && (
                    <Button
                      disabled={isGettingSignature}
                      color="primary"
                      variant="contained"
                      style={{
                        borderRadius: "8px",
                        height: "32px",
                        alignItems: "center",
                      }}
                      size="sm"
                      onClick={() => {
                        setSigningUser(firstSignatory());
                        getUserSignature(setShowSignPayrollDialog);
                      }}
                    >
                      <span className="material-icons mr-1">draw</span> Sign
                    </Button>
                  )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !!firstSignatory()?.signed &&
                  !secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!secondSignatory() &&
                  !!secondSignatory().canSign &&
                  selectedPayroll.statusId === "GENERATED" &&
                  viewType !== "admin" && (
                    <>
                      <Button
                        disabled={isGettingSignature}
                        color="primary"
                        variant="contained"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setSigningUser(secondSignatory());
                          getUserSignature(setShowSignPayrollDialog);
                        }}
                      >
                        <span className="material-icons mr-1">draw</span> Sign
                      </Button>

                      <Button
                        disabled={isGettingSignature}
                        color="error"
                        variant="contained"
                        className="ml-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setRejectionLevel("REJECTED_BY_VERIFICATOR");
                          setShowRejectPayrollDialog(true);
                        }}
                      >
                        <span className="material-icons mr-1">close</span>{" "}
                        Reject
                      </Button>
                    </>
                  )}

                {selectedPayroll.statusId === "REJECTED_BY_VERIFICATOR" && (
                  <span className="badge badge-danger">
                    {selectedPayroll.statusName}
                  </span>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !!firstSignatory()?.signed &&
                  !!secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!thirdSignatory() &&
                  !!thirdSignatory()?.canSign &&
                  selectedPayroll.statusId === "VERIFIED" &&
                  viewType !== "admin" && (
                    <>
                      <Button
                        disabled={isGettingSignature}
                        color="primary"
                        variant="contained"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setSigningUser(thirdSignatory());
                          getUserSignature(setShowSignPayrollDialog);
                        }}
                      >
                        <span className="material-icons mr-1">draw</span> Sign
                      </Button>

                      <Button
                        disabled={isGettingSignature}
                        color="error"
                        variant="contained"
                        className="ml-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setRejectionLevel("REJECTED_BY_CBM");
                          setShowRejectPayrollDialog(true);
                        }}
                      >
                        <span className="material-icons mr-1">close</span>{" "}
                        Reject
                      </Button>
                    </>
                  )}

                {selectedPayroll.statusId === "REJECTED_BY_CBM" && (
                  <span className="badge badge-danger">
                    {selectedPayroll.statusName}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showSignPayrollDialog && (
        <SignPayrollDialog
          selectedPayroll={selectedPayroll}
          showSignPayrollDialog={showSignPayrollDialog}
          setShowSignPayrollDialog={setShowSignPayrollDialog}
          signingUser={signingUser}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
        />
      )}

      {showRejectPayrollDialog && (
        <RejectPayrollDialog
          selectedPayroll={selectedPayroll}
          showRejectPayrollDialog={showRejectPayrollDialog}
          setShowRejectPayrollDialog={setShowRejectPayrollDialog}
          // signingUser={signingUser}
          rejectionLevel={rejectionLevel}
          // setUserSignature={setUserSignature}
        />
      )}

      {!!showIndividualSupportingDocumentDialog && env !== "prod" && (
        <IndividualSupportingDocumentDialog
          showDialog={!!showIndividualSupportingDocumentDialog}
          setShowDialog={setShowIndividualSupportingDocumentDialog}
          employee={showIndividualSupportingDocumentDialog}
          type={type}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  payrollPreviewed,
  payrollDetails,
  env,
}) => {
  return { user, loading, onWait, payrollPreviewed, payrollDetails, env };
};
export default connect(mapStateToProps, {
  downloadPDFPayroll,
  getPayrollDetails,
})(PayrollPreviewSalaryDialog);
