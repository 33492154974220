import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Input,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  cancelEmployeeExitRequest,
  updateExitRequest,
} from "../../../store/exit/actions";

import {
  getUnitPositions,
  getEntities,
} from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";

import { isEmpty } from "lodash";
import moment from "moment";
import { showError } from "../../toastify";
import CommonLetter from "../../documents/LetterFormat/CommonLetter";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeDuplicates } from "../../common/components/Utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const EditExitRequestLetterDialog = (props) => {
  const {
    loading,
    onWait,
    showEmployeeExitForm,
    setShowEmployeeExitForm,
    updateExitRequest,
    employeeExitRequest,
    getEntities,
    entities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
  } = props;

  useEffect(() => {
    if (requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  //getEntities();

  const [exitType, setExitType] = useState(null);
  const [entity, setEntity] = useState(null);
  const [unit, setUnit] = useState(null);
  const [position, setPosition] = useState(null);

  const [ccData, setCCData] = useState(
    JSON.parse(employeeExitRequest.cc) || []
  );

  const [employeeExitForm, setEmployeeExitForm] = useState({
    employeeId: employeeExitRequest.employeeId,
    employeePositionId: employeeExitRequest.employeePositionId,
    positionId: employeeExitRequest.positionId,
    exitTypeId: employeeExitRequest.exitTypeId,
    effectiveDate:
     moment(employeeExitRequest.effectiveDate).format("YYYY-MM-DD"),
    blacklistPeriod: 0,
    reason: "",
    referenceLetterId: employeeExitRequest.referenceLetterId,
    referenceLetterDate: moment(employeeExitRequest.referenceLetterDate).format(
      "YYYY-MM-DD"
    ),
    provisionalRefLetterId: employeeExitRequest.provisionalRefLetterId,
    provisionalRefLetterDate: employeeExitRequest.provisionalRefLetterDate,
    summaryConclusionsCommittee:
      employeeExitRequest.summaryConclusionsCommittee,
    reportInternalDisciplinaryCommittee:
      employeeExitRequest.reportInternalDisciplinaryCommittee,
    providingFeedbackRefLetterDate:
      employeeExitRequest.providingFeedbackRefLetterDate,
    providingFeedbackRefLetterId:
      employeeExitRequest.providingFeedbackRefLetterId,
    providingExplanationLetterId:
      employeeExitRequest.providingExplanationLetterId,
    providingExplanationLetterDate:
      employeeExitRequest.providingExplanationLetterDate,
    employeeExplanationLetterDate:
      employeeExitRequest.employeeExplanationLetterDate,
    secondmentPositionName: employeeExitRequest.secondmentPositionName,
    secondmentInstitutionName: employeeExitRequest.secondmentInstitutionName,
    docLetterNo: employeeExitRequest.docLetterNo,
    docReferenceId: !!requestDocumentSignerDetail?.docReferenceId
      ? requestDocumentSignerDetail.docReferenceId
      : null,
    cc: JSON.parse(employeeExitRequest.cc),
  });

  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    reasonHasError: false,
    effectiveDateHasError: false,
    entityHasError: false,
    unitHasError: false,
    positionHasError: false,
    referenceLetterIdError: false,
    referenceLetterDateError: false,
    provisionalRefLetterDateError: false,
    provisionalRefLetterIdError: false,
    summaryConclusionsCommitteeError: false,
    reportInternalDisciplinaryCommitteeError: false,
    providingFeedbackRefLetterDateError: false,
    providingFeedbackRefLetterIdError: false,
    providingExplanationLetterIdError: false,
    providingExplanationLetterDateError: false,
    employeeExplanationLetterDateError: false,
    secondmentInstitutionNameError: false,
    secondmentPositionNameError: false,
    effectiveDateError:false,
    docLetterNoError: false,
    ccHasError: false,
  });
  const formValidator1 = () => {
    const error = {
      referenceLetterIdError: false,
      referenceLetterDateError: false,
      provisionalRefLetterIdError: false,
      provisionalRefLetterDateError: false,
      summaryConclusionsCommitteeError: false,
      reportInternalDisciplinaryCommitteeError: false,
      providingFeedbackRefLetterDateError: false,
      providingFeedbackRefLetterIdError: false,
      providingExplanationLetterIdError: false,
      providingExplanationLetterDateError: false,
      employeeExplanationLetterDateError: false,
      secondmentInstitutionNameError: false,
      secondmentPositionNameError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      effectiveDateError: false,
    };

    // docLetterNoError:false,
    // if (
    //   !employeeExitForm.docLetterNo &&
    //   exitType &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.referenceLetterId &&
      ![8,9].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.effectiveDate &&
      !employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.referenceLetterDate &&
      ![8,9].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterDateError = true;
      error.hasError = true;
    }
    // start removal from office
    // if (
    //   !employeeExitForm.provisionalRefLetterId &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.provisionalRefLetterDate &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.reportInternalDisciplinaryCommittee &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.reportInternalDisciplinaryCommitteeError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.summaryConclusionsCommittee &&
      [8].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.summaryConclusionsCommitteeError = true;
      error.hasError = true;
    }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.providingExplanationLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.employeeExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.employeeExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.secondmentInstitutionName &&
      [10].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.secondmentInstitutionNameError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.secondmentPositionName &&
      [10].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.secondmentPositionNameError = true;
      error.hasError = true;
    }

    // if (!ccData.length && !!exitType.enableToGenerateLetter) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }
    setErrors(error);

    if (error.hasError) {
      showError(`Please fill required field!`);
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowEmployeeExitForm(false);
  };

  const onSave = () => {
    if (!formValidator1()) {
      updateExitRequest(
        {
          ...employeeExitForm,
          requestId: employeeExitRequest.id,
          employeePositionId: employeeExitRequest.employeePositionId,
          cc: ccData || [],
        },
        onClose
      );
    }
  };
  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeExitForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">
                  EXIT REQUEST{" "}
                  {employeeExitRequest && employeeExitRequest?.exitType
                    ? " OF " + employeeExitRequest.exitType
                    : ""}
                </span>
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-2">
          <div className="py-3">
            <div className="mx-2 w-full">
              <CommonLetter
                exitTypeId={employeeExitRequest.exitTypeId}
                recipientPositionName={employeeExitRequest.positionName}
                institutionName={employeeExitRequest?.entityName}
                transferredInstitutionName={employeeExitRequest?.toEntityName}
                transferredPositionName={employeeExitRequest?.toPositionName}
                recipientPhone={employeeExitRequest.phoneNumber}
                recipientEmail={employeeExitRequest.email}
                currentPositionName={employeeExitRequest.positionName}
                docReferenceId={employeeExitRequest?.docReferenceId || ""}
                effectiveInDays={employeeExitRequest.effectiveInDays}
                effectiveDate={moment(employeeExitForm.effectiveDate).format(
                  "LL"
                )}
                recipientName={
                  employeeExitRequest.lastName.toUpperCase() +
                  " " +
                  employeeExitRequest.firstName
                }
                subTitle={
                  "Dear " +
                  (employeeExitRequest.genderId === "F" ? "Madam" : "Sir")
                }
                competentAuthorityName={
                  !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail.competentAuthorityUser.names
                    : ""
                }
                competentAuthorityPosition={
                  !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail?.competentAuthorityUser
                        ?.positionName
                    : ""
                }
                enableToFillLetter={true}
                docLetterNoInput={
                  <>
                    <Input
                      autoFocus
                      error={!!errors.docLetterNoError}
                      value={employeeExitForm.docLetterNo}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add doc No",
                      }}
                      onChange={(e) => {
                        const docLetterNo = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          docLetterNo,
                        });

                        setErrors({
                          ...errors,
                          docLetterNoError: false,
                        });
                      }}
                    />
                  </>
                }
                docReferenceIdInput={
                  <>
                    <Input
                      autoFocus
                      error={!!errors.referenceLetterIdError}
                      value={employeeExitForm.referenceLetterId}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference no",
                      }}
                      onChange={(e) => {
                        const referenceLetterId = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          referenceLetterId,
                        });

                        setErrors({
                          ...errors,
                          referenceLetterIdError: false,
                        });
                      }}
                    />
                  </>
                }
                docReferenceDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.referenceLetterDateError}
                      value={employeeExitForm.referenceLetterDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference date",
                      }}
                      onChange={(e) => {
                        const referenceLetterDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          referenceLetterDate,
                        });

                        setErrors({
                          ...errors,
                          referenceLetterDateError: false,
                        });
                      }}
                    />
                  </>
                }
                effectiveDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.effectiveDateError}
                      value={employeeExitForm.effectiveDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference date",
                      }}
                      onChange={(e) => {
                        const effectiveDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          effectiveDate,
                        });

                        setErrors({
                          ...errors,
                          effectiveDateError: false,
                        });
                      }}
                    />
                  </>
                }
                provisionalRefLetterIdInput={
                  <>
                    <Input
                      error={!!errors.provisionalRefLetterIdError}
                      value={employeeExitForm.provisionalRefLetterId}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add provisional reference no",
                      }}
                      onChange={(e) => {
                        const provisionalRefLetterId = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          provisionalRefLetterId,
                        });

                        setErrors({
                          ...errors,
                          provisionalRefLetterIdError: false,
                        });
                      }}
                    />
                  </>
                }
                provisionalRefLetterDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.provisionalRefLetterDateError}
                      value={employeeExitForm.provisionalRefLetterDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference date",
                      }}
                      onChange={(e) => {
                        const provisionalRefLetterDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          provisionalRefLetterDate,
                        });

                        setErrors({
                          ...errors,
                          provisionalRefLetterDateError: false,
                        });
                      }}
                    />
                  </>
                }
                reportInternalDisciplinaryCommitteeInput={
                  <>
                    <Input
                      error={!!errors.reportInternalDisciplinaryCommitteeError}
                      value={
                        employeeExitForm.reportInternalDisciplinaryCommittee
                      }
                      style={{ width: "100%" }}
                      placeholder="<<……Report of Internal Disciplinary Committee of ……>>"
                      inputProps={{
                        "aria-label":
                          "<<……Report of Internal Disciplinary Committee of ……>>",
                      }}
                      onChange={(e) => {
                        const reportInternalDisciplinaryCommittee =
                          e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          reportInternalDisciplinaryCommittee,
                        });

                        setErrors({
                          ...errors,
                          reportInternalDisciplinaryCommitteeError: false,
                        });
                      }}
                    />
                  </>
                }
                summaryConclusionsCommitteeInput={
                  <>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      maxLength={500}
                      name="summaryConclusionsCommittee"
                      label="Type the summary of the conclusions of the committee.."
                      variant="outlined"
                      placeholder="Type the summary of the conclusions of the committee.."
                      value={employeeExitForm.summaryConclusionsCommittee}
                      onChange={(e) => {
                        const summaryConclusionsCommittee =
                          e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          summaryConclusionsCommittee,
                        });

                        setErrors({
                          ...errors,
                          summaryConclusionsCommitteeError: false,
                        });
                      }}
                    />
                     {errors.summaryConclusionsCommitteeError && (
                            <small className="text-danger mb-1">
                              This field is required
                            </small>
                          )}
                  </>
                }

                providingFeedbackRefLetterIdInput={
                  <>
                    <Input
                      error={!!errors.provisionalRefLetterIdError}
                      value={employeeExitForm.providingFeedbackRefLetterId}
                      style={{ width: "250px" }}
                      inputProps={{
                        "aria-label": "providingFeedbackRefLetterId",
                      }}
                      onChange={(e) => {
                        const providingFeedbackRefLetterId = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          providingFeedbackRefLetterId,
                        });

                        setErrors({
                          ...errors,
                          providingFeedbackRefLetterIdError: false,
                        });
                      }}
                    />
                  </>
                }
                providingFeedbackRefLetterDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.providingFeedbackRefLetterDateError}
                      value={employeeExitForm.providingFeedbackRefLetterDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "providingFeedbackRefLetterDate",
                      }}
                      onChange={(e) => {
                        const providingFeedbackRefLetterDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          providingFeedbackRefLetterDate,
                        });

                        setErrors({
                          ...errors,
                          providingFeedbackRefLetterDateError: false,
                        });
                      }}
                    />
                  </>
                }
                employeeExplanationLetterDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.employeeExplanationLetterDateError}
                      value={employeeExitForm.employeeExplanationLetterDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "employeeExplanationLetterDate",
                      }}
                      onChange={(e) => {
                        const employeeExplanationLetterDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          employeeExplanationLetterDate,
                        });

                        setErrors({
                          ...errors,
                          employeeExplanationLetterDateError: false,
                        });
                      }}
                    />
                  </>
                }
                providingExplanationLetterIdInput={
                  <>
                    <Input
                      error={!!errors.providingExplanationLetterIdError}
                      value={employeeExitForm.providingExplanationLetterId}
                      style={{ width: "250px" }}
                      inputProps={{
                        "aria-label": "providingExplanationLetterId",
                      }}
                      onChange={(e) => {
                        const providingExplanationLetterId = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          providingExplanationLetterId,
                        });

                        setErrors({
                          ...errors,
                          providingExplanationLetterIdError: false,
                        });
                      }}
                    />
                  </>
                }
                providingExplanationLetterDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.providingExplanationLetterDateError}
                      value={employeeExitForm.providingExplanationLetterDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "providingExplanationLetterDate",
                      }}
                      onChange={(e) => {
                        const providingExplanationLetterDate = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          providingExplanationLetterDate,
                        });

                        setErrors({
                          ...errors,
                          providingExplanationLetterDateError: false,
                        });
                      }}
                    />
                  </>
                }
                secondmentInstitutionNameInput={
                  <>
                    <Input
                      error={!!errors.secondmentInstitutionNameError}
                      value={employeeExitForm.secondmentInstitutionName}
                      style={{ width: "250px" }}
                      inputProps={{
                        "aria-label": "secondmentInstitutionName",
                      }}
                      onChange={(e) => {
                        const secondmentInstitutionName = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          secondmentInstitutionName,
                        });

                        setErrors({
                          ...errors,
                          secondmentInstitutionNameError: false,
                        });
                      }}
                    />
                  </>
                }
                secondmentPositionNameInput={
                  <>
                    <Input
                      error={!!errors.secondmentPositionNameError}
                      value={employeeExitForm.secondmentPositionName}
                      style={{ width: "250px" }}
                      inputProps={{
                        "aria-label": "secondmentPositionName",
                      }}
                      onChange={(e) => {
                        const secondmentPositionName = e.target.value;
                        setEmployeeExitForm({
                          ...employeeExitForm,
                          secondmentPositionName,
                        });

                        setErrors({
                          ...errors,
                          secondmentPositionNameError: false,
                        });
                      }}
                    />
                  </>
                }
                ccSelectForm={
                  <>
                    <Autocomplete
                      size="small"
                      fullWidth
                      multiple
                      id="ccData"
                      options={entities.map(
                        ({ name, institutionEmail, id }) => ({
                          name,
                          institutionEmail,
                          id,
                        })
                      )}
                      value={ccData}
                      onChange={(e, cc) => {
                        const uniqueCc = removeDuplicates(cc);
                        setCCData(uniqueCc);
                        const ccHasError = false;
                        setErrors({ ...errors, ccHasError });
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={
                              selected ||
                              !!ccData.find(({ id }) => id === option.id)
                            }
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select the institution to copy"}
                          placeholder={"Select the institution to copy"}
                        />
                      )}
                    />

                    {errors.ccHasError && (
                      <small className="text-danger mb-1">
                        Please, select the institution to copy
                      </small>
                    )}
                  </>
                }
                reasonSuspension={employeeExitForm.reason}
                ccData={ccData}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center pb-3">
          <Button
            color="success"
            variant="contained"
            disabled={loading || onWait}
            onClick={onSave}
            size="md"
          >
            {onWait ? "Wait.." : <>Submit</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  exitTypes,
  employeeExitRequest,
  units,
  positions,
  entities,
  requestDocumentSignerDetail,
}) => {
  return {
    loading,
    onWait,
    exitTypes,
    employeeExitRequest,
    units,
    positions,
    entities,
    requestDocumentSignerDetail,
  };
};
export default connect(mapStateToProps, {
  updateExitRequest,
  cancelEmployeeExitRequest,
  getUnitPositions,
  getEntities,
  getRequestDocumentSignerDetails,
})(EditExitRequestLetterDialog);
