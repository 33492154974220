import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Alert,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";
import { connect } from "react-redux";
import {
  addNewEntity,
  getEntitiesOversights,
  getEntityClasses,
  getSalaryStructure,
  getSharedScopes,
  getUnitTypes,
  updateEntity,
  setAsRSSBIntegrated,
} from "../../../store/structure/actions";
import { getSystemAllowances } from "../../../store/payroll/actions";
import ManageSalaryStructureDialog from "../components/ManageSalaryStructureDialog";
import ViewOrganizationStructure from "../components/ViewOrganizationStructure";
import EntitySectorAllowances from "../components/EntitySectorAllowances";
import SearchBox from "../../common/components/SearchBox";
import HiveRoundedIcon from "@mui/icons-material/HiveRounded";
import { stopEntity } from "../../../store/structure/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getLocations, getSectors } from "../../../store/admin/actions";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import LetterTemplateDialog from "../../documents/LetterTemplateDialog";

const Oversight = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Oversight";
  });
  const {
    user,
    loading,
    getEntitiesOversights,
    entitiesOversights,
    getSalaryStructure,
    sharedScopes,
    getSharedScopes,
    systemAllowances,
    getSystemAllowances,
    stopEntity,
    getLocations,
    getSectors,
    unitTypes,
    getUnitTypes,
    entityClasses,
    getEntityClasses,
    addNewEntity,
    updateEntity,
    setAsRSSBIntegrated,
  } = props;

  const [locations, setLocations] = useState(null);
  const [sectors, setSectors] = useState(null);
  const [filteredItems, setFilteredItems] = useState([...entitiesOversights]);
  const [searchTeam, setSearchTeam] = useState("");
  const [showClosed, setShowClosed] = useState(false);
  const [entityForm, setEntityForm] = useState(null);
  const [formData, setFormData] = useState({
    costCenterId: "",
    sector: null,
    name: "",
    acronym: "",
    tinNumber: "",
    locationDistrict: null,
    locationSector: null,
    lineMinistry: null,
    unitType: null,
    entityClass: null,
    institutionEmail: "",
    institutionPhone: "",
    websiteUrl: "",
    poBox: "",
    twitterPage: "",
  });
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    if (!systemAllowances.length) getSystemAllowances();
    if (!sharedScopes.length) getSharedScopes();
    if (!entitiesOversights.length) getEntitiesOversights();
    // for form management
    if (!locations) getLocations(setLocations);
    if (!sectors) getSectors(setSectors);
    // if (!sectors) getSectors(setSectors);
    if (!unitTypes.length) getUnitTypes();
    if (!entityClasses.length) getEntityClasses();

    return () => {
      // setLocations(null);
      setFilteredItems([]);
      setSearchTeam("");
      setEntityForm(null);
      setShowClosed(false);
      setFormError(null);
      // setSectors(null);
      setFormData({
        costCenterId: "",
        sector: null,
        name: "",
        acronym: "",
        tinNumber: "",
        locationDistrict: null,
        locationSector: null,
        lineMinistry: null,
        unitType: null,
        entityClass: null,
        institutionEmail: "",
        institutionPhone: "",
        websiteUrl: "",
        poBox: "",
        twitterPage: "",
      });
    };
  }, []);

  useEffect(() => {
    const filtered = [...entitiesOversights].filter(
      ({
        name,
        entitySector,
        tinNumber,
        institutionEmail,
        institutionPhone,
        acronym,
        websiteUrl,
        poBox,
        twitterPage,
      }) =>
        (
          (name || "") +
          (tinNumber || "") +
          (institutionEmail || "") +
          (institutionPhone || "") +
          (acronym || "") +
          (websiteUrl || "") +
          (poBox || "") +
          (twitterPage || "")
        )
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredItems(filtered);
    // paginate(filtered);
  }, [searchTeam, entitiesOversights]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const onFilterClosed = (data) => {
    if (showClosed) return data.filter((item) => item.endedOn !== null);
    return data.filter((item) => item.endedOn === null);
  };

  const handleOpenForm = (action = null, data = null) => {
    if (action === "UPDATE" && data) {
      const thisSector = data.entitySectors[0].sector;
      const thisEntityClass = data.entitySectors[0].entityClass;
      const thisUnityType = unitTypes.find(
        (item) => item.id === data.unitTypeId
      );
      const lineMinistry = entitiesOversights.find(
        (item) => item.id === data.lineMinistryId
      );

      const sector = locations?.sectors?.find(
        (item) => item.id === data.locationSectorId
      );
      const district =
        sector &&
        locations.districts.find((item) => item.id === sector.districtId);

      setFormData({
        entityId: data.id,
        costCenterId: data.id.split("-")[0],
        sector: thisSector || null,
        name: data.name,
        acronym: data.acronym || "",
        tinNumber: data.tinNumber || "",
        locationDistrict: district || null,
        locationSector: sector || null,
        lineMinistry: lineMinistry || null,
        unitType: thisUnityType || null,
        entityClass: thisEntityClass || null,
        institutionEmail: data.institutionEmail || "",
        institutionPhone: data.institutionPhone || "",
        poBox: data.poBox || "",
        websiteUrl: data.websiteUrl || "",
        twitterPage: data.twitterPage || "",
      });
      setEntityForm(action);
    } else setEntityForm(action);
  };

  const onCloseEntityForm = () => {
    if (!loading) {
      setEntityForm(null);
      setFormData({
        costCenterId: "",
        sector: null,
        name: "",
        acronym: "",
        tinNumber: "",
        locationDistrict: null,
        locationSector: null,
        lineMinistry: null,
        unitType: null,
        entityClass: null,
        institutionEmail: "",
        institutionPhone: "",
        poBox: "",
        websiteUrl: "",
        twitterPage: "",
      });
    }
  };

  const handleSubmit = () => {
    if (!formData.name.length) return showError("Entity name is required!");

    if (!formData.costCenterId.length)
      return showError("Cost center id is required!");

    if (isNaN(formData.costCenterId))
      return showError("Cost center id must be numbers!");

    if (formData.costCenterId.length !== 10)
      return showError("Cost center id must be 10 numbers!");

    if (!formData.entityClass) return showError("Entity class is required!");

    if (!formData.sector) return showError("Entity sector is required!");

    if (!formData.unitType) return showError("Unity type is required!");

    if (!formData.locationSector) return showError("Location is required!");

    if (!formData.institutionEmail.length)
      return showError("Institution email is required!");

    if (!formData.institutionPhone.length)
      return showError("Institution phone is required!");

    if (!formData.poBox.length) return showError("PoBox is required!");

    if (!formData.websiteUrl.length) return showError("Website is required!");

    if (!formData.twitterPage.length) return showError("Twitter is required!");

    if (!formData.tinNumber.length)
      return showError("Entity Tin Number is required!");

    if (isNaN(formData.tinNumber))
      return showError("Entity Tin Number must be numbers!");

    if (formData.tinNumber.length !== 9)
      return showError("Entity Tin Number must be 9 numbers!");

    if (entityForm === "UPDATE") {
      updateEntity(
        formData.entityId,
        {
          sectorId: formData.sector.id,
          name: formData.name,
          acronym: formData.acronym.length > 0 ? formData.acronym : null,
          locationSectorId: formData.locationSector.id,
          lineMinistryId: formData.lineMinistry
            ? formData.lineMinistry.id
            : null,
          unitTypeId: formData.unitType ? formData.unitType.id : null,
          institutionEmail: formData.institutionEmail,
          institutionPhone: formData.institutionPhone,
          entityClassId: formData.entityClass.id,

          poBox: formData.poBox,
          websiteUrl: formData.websiteUrl,
          twitterPage: formData.twitterPage,
        },
        (status) => status && onCloseEntityForm()
      );
    } else {
      addNewEntity(
        {
          costCenterId: formData.costCenterId, // 10 number
          sectorId: formData.sector.id, // 10 number
          name: formData.name,
          acronym: formData.acronym.length > 0 ? formData.acronym : null,
          tinNumber: formData.tinNumber,
          locationSectorId: formData.locationSector.id,
          lineMinistryId: formData.lineMinistry
            ? formData.lineMinistry.id
            : null,
          unitTypeId: formData.unitType ? formData.unitType.id : null,
          institutionEmail: formData.institutionEmail,
          institutionPhone: formData.institutionPhone,
          entityClassId: formData.entityClass.id,

          poBox: formData.poBox,
          websiteUrl: formData.websiteUrl,
          twitterPage: formData.twitterPage,
        },
        (status) => status && onCloseEntityForm()
      );
    }
  };

  const LOCATION_SECTORS =
    locations && formData.locationDistrict
      ? locations?.sectors?.filter(
          (item) => item.districtId === formData.locationDistrict.id
        )
      : [];

  const LINE_MINISTRY = entitiesOversights
    ? entitiesOversights.filter(
        (item) =>
          item?.entitySectors?.length === 1 &&
          item?.entitySectors[0]?.sectorId === 2
      )
    : [];

  const filterUnitTypes = () =>
    unitTypes.filter(({ id }) =>
      [1, 8, 9, 12, 13, 14, 15, 19, 20, 21].includes(id)
    );

  return (
    <>
      <div
        style={{
          marginLeft: "1em",
          marginRight: "1em",
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "65vh",
          overflowX: "auto",
        }}
      >
        <table className="table table-sm  table-hover table-striped fixTableHead">
          <thead>
            <tr>
              <th className="py-2">No</th>
              <th className="py-2">
                <Box
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  className="align-items-center"
                >
                  <span className="badge badge-secondary">
                    {onFilterClosed(filteredItems).length}
                  </span>{" "}
                  <span className={`mx-2 ${showClosed ? "text-danger" : ""}`}>
                    {showClosed ? "Closed" : "Active"} institutions{" "}
                  </span>
                  <SearchBox onSearch={onSearch} placeholder="Search…" />
                </Box>
              </th>
              <th className="py-2">Tin Number</th>
              <th className="py-2">Line Ministry</th>
              <th className="py-2">Email</th>
              <th className="py-2">Phone</th>
              <th className="">RSSBIntegrated</th>
              <th className="py-2" style={{ width: "23%" }}>
                <span className="d-flex align-items-center justify-content-between">
                  <span>Sectors</span>

                  <span>
                    <ButtonGroup
                      size="small"
                      aria-label="small button group"
                      className="ml-3"
                    >
                      <Tooltip title="Download">
                        <span>
                          <IconButton
                            disabled
                            className="p-0 mr-3"
                            color="primary"
                          >
                            <span
                              className="material-icons"
                              style={{ fontSize: "28px" }}
                            >
                              cloud_download
                            </span>
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Button
                        onClick={() => handleOpenForm("CREATE")}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                        }}
                        disabled={loading}
                      >
                        <span className="material-icons">add</span>
                        Add new institution
                      </Button>
                      <Button
                        onClick={() => setShowClosed(!showClosed)}
                        variant="outlined"
                        size="small"
                        disableElevation
                        className="mr-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          border: showClosed
                            ? `1px solid rgb(7, 142, 206)`
                            : `1px solid rgb(206, 7, 7)`,
                          backgroundColor: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          color: showClosed
                            ? "rgb(7, 142, 206)"
                            : "rgb(206, 7, 7)",
                        }}
                        disabled={loading || !sectors || !locations}
                      >
                        {/* <span className="material-icons">add</span> */}
                        {showClosed ? "Show active" : "Show closed"}
                      </Button>
                    </ButtonGroup>
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          {!entitiesOversights.length ? (
            <TableSkeleton cols={8} rows={11} />
          ) : (
            <tbody>
              {onFilterClosed(filteredItems).map((entity, esIndex) => (
                <EntityItem
                  key={esIndex}
                  esIndex={esIndex}
                  entity={entity}
                  getSalaryStructure={getSalaryStructure}
                  loading={loading}
                  stopEntity={stopEntity}
                  handleOpenForm={handleOpenForm}
                  setAsRSSBIntegrated={setAsRSSBIntegrated}
                />
              ))}
            </tbody>
          )}
        </table>
      </div>

      <Dialog
        onClose={onCloseEntityForm}
        aria-labelledby="customized-dialog-title"
        open={entityForm ? true : false}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {entityForm === "UPDATE"
              ? "Update current entity"
              : "Add new entity"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseEntityForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-0">
          <div
            className="elevated rounded p-3 "
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              {/* first side */}
              <div className="col-12 col-md-6">
                {/* ENTITY NAME */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Entity name"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        name: e.target.value.toUpperCase(),
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "ENTITY_NAME" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* COST CENTER ID */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Cost center ID"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.costCenterId}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setFormData({
                          ...formData,
                          costCenterId: e.target.value,
                        });
                        setFormError(null);
                      }
                    }}
                    InputProps={{
                      readOnly: entityForm === "UPDATE" ? true : false,
                    }}
                  />
                  {formError && formError.element === "COST_CENTER_ID" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* ENTITY CLASS */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={loading || entityClasses === null}
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, entityClass) => (
                      <Box component="li" {...props}>
                        {entityClass?.name}
                      </Box>
                    )}
                    options={entityClasses || []}
                    size="small"
                    value={formData.entityClass || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Entity class"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "Entity class",
                        }}
                      />
                    )}
                    onChange={(event, entityClass) => {
                      setFormData({
                        ...formData,
                        entityClass: entityClass || null,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "ENTITY_CLASS" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* ENTITY SECTOR */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={loading || sectors === null ? true : false}
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, sector) => (
                      <Box component="li" {...props}>
                        {sector?.name}
                      </Box>
                    )}
                    options={sectors || []}
                    size="small"
                    value={formData.sector || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sector"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "sector",
                        }}
                      />
                    )}
                    onChange={(event, sector) => {
                      setFormData({
                        ...formData,
                        sector: sector || null,
                      });
                      setFormError(null);
                    }}
                    readOnly={entityForm === "UPDATE" ? true : false}
                  />
                  {formError && formError.element === "ENTITY_SECTOR" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* LINE MINISTRY */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={
                      loading ||
                      sectors === null ||
                      formData?.sector === null ||
                      formData?.sector?.id === 2
                    }
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, lineMinistry) => (
                      <Box component="li" {...props}>
                        {lineMinistry?.name}
                      </Box>
                    )}
                    options={LINE_MINISTRY}
                    size="small"
                    value={formData.lineMinistry || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Line ministry"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "lineMinistry",
                        }}
                      />
                    )}
                    onChange={(event, lineMinistry) => {
                      setFormData({
                        ...formData,
                        lineMinistry: lineMinistry || null,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "LINE_MINISTRY" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* ENTITY TYPE */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={loading}
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, type) => (
                      <Box component="li" {...props}>
                        {type?.name}
                      </Box>
                    )}
                    options={filterUnitTypes() || []}
                    size="small"
                    value={formData.unitType || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "unityType",
                        }}
                      />
                    )}
                    onChange={(event, unityType) => {
                      setFormData({
                        ...formData,
                        unitType: unityType || null,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "ENTITY_TYPE" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* INSTITUTION WEBSITE */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Institution website link"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.websiteUrl}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        websiteUrl: e.target.value,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "INSTITUTION_WEBSITE" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>
              </div>
              {/* second side */}
              <div className="col-12 col-md-6">
                {/* ENTITY ACRONYM */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Entity acronym"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.acronym}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        acronym: e.target.value.toUpperCase(),
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "ENTITY_ACRONYM" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* ENTITY TIN NUMBER */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Entity Tin Number"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.tinNumber}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setFormData({ ...formData, tinNumber: e.target.value });
                        setFormError(null);
                      }
                    }}
                    InputProps={{
                      readOnly: entityForm === "UPDATE" ? true : false,
                    }}
                  />
                  {formError && formError.element === "ENTITY_TIN_NUMBER" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* ENTITY LOCATION DISTRICT */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={loading || locations === null}
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, field) => (
                      <Box component="li" {...props}>
                        {field?.name}
                      </Box>
                    )}
                    options={locations?.districts || []}
                    size="small"
                    value={formData.locationDistrict || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location district"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "district",
                        }}
                      />
                    )}
                    onChange={(event, district) => {
                      setFormData({
                        ...formData,
                        locationDistrict: district || null,
                      });
                      setFormError(null);
                    }}
                  />
                </div>

                {/* ENTITY LOCATION SECTOR */}
                <div className="mb-3">
                  <Autocomplete
                    disabled={
                      loading ||
                      locations === null ||
                      formData.locationDistrict === null
                    }
                    // disablePortal
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, field) => (
                      <Box component="li" {...props}>
                        {field?.name}
                      </Box>
                    )}
                    options={LOCATION_SECTORS}
                    size="small"
                    value={formData.locationSector || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location sector"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "sector",
                        }}
                      />
                    )}
                    onChange={(event, sector) => {
                      setFormData({
                        ...formData,
                        locationSector: sector || null,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError &&
                    formError.element === "ENTITY_LOCATION_SECTOR" && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "13px" }}
                      >
                        {formError.msg}
                      </span>
                    )}
                </div>

                {/* INSTITUTION EMAIL */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Institution email"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.institutionEmail}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        institutionEmail: e.target.value,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "INSTITUTION_EMAIL" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* INSTITUTION PHONE */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Institution phone"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.institutionPhone}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        institutionPhone: e.target.value,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "INSTITUTION_PHONE" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>

                {/* INSTITUTION PO.BOX */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Institution po.box"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.poBox}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        poBox: e.target.value,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "INSTITUTION_POBOX" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>
                {/* INSTITUTION TWITTER */}
                <div className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Institution twitter page"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    value={formData.twitterPage}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        twitterPage: e.target.value,
                      });
                      setFormError(null);
                    }}
                  />
                  {formError && formError.element === "INSTITUTION_TWITTER" && (
                    <span className="text-danger" style={{ fontSize: "13px" }}>
                      {formError.msg}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-3">
          <Button
            onClick={handleSubmit}
            type="button"
            variant="contained"
            className="px-5"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  entitiesOversights,
  sharedScopes,
  systemAllowances,
  unitTypes,
  entityClasses,
}) => {
  return {
    user,
    loading,
    entitiesOversights,
    sharedScopes,
    systemAllowances,
    unitTypes,
    entityClasses,
  };
};
export default connect(mapStateToProps, {
  getEntitiesOversights,
  getSalaryStructure,
  getSharedScopes,
  getSystemAllowances,
  stopEntity,
  getLocations,
  getSectors,
  getUnitTypes,
  getEntityClasses,
  addNewEntity,
  updateEntity,
  setAsRSSBIntegrated,
})(Oversight);

const EntityItem = (props) => {
  const {
    loading,
    entity,
    esIndex,
    getSalaryStructure,
    stopEntity,
    handleOpenForm,
    setAsRSSBIntegrated,
  } = props;

  return (
    <>
      <tr
        style={{
          background: esIndex % 2 === 0 ? "" : "#f6f7f7",
          borderBottom: "2px dotted rgb(7, 142, 206)!important",
        }}
      >
        <td>{esIndex + 1}</td>

        <td>
          {entity.name} {entity.acronym && <>({entity.acronym})</>}
        </td>
        <td>{entity.tinNumber}</td>
        <td>{entity?.lineMinistry?.name || ""} </td>
        <td>{entity.institutionEmail}</td>
        <td>{entity.institutionPhone}</td>
        <td className="text-center" style={{ backgroundColor: "#e9ecef" }}>
          <input
            type="checkbox"
            name="isRSSBIntegrated"
            checked={entity.isRSSBIntegrated}
            onChange={(event) => {
              if (!entity.isRSSBIntegrated)
                setAsRSSBIntegrated({
                  entityId: entity.id,
                  status: event.target.checked,
                });
            }}
          />
        </td>

        <td className="p-0">
          <table className="table table-sm mb-0 ml-0">
            <tbody>
              {entity.entitySectors.map((entitySector, index) => (
                <EntitySectorItem
                  entity={entity}
                  key={index}
                  entitySector={entitySector}
                  index={index}
                  getSalaryStructure={getSalaryStructure}
                  loading={loading}
                  stopEntity={stopEntity}
                  handleOpenForm={handleOpenForm}
                />
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

const EntitySectorItem = (props) => {
  const {
    loading,
    entity,
    entitySector,
    index,
    getSalaryStructure,
    stopEntity,
    handleOpenForm,
  } = props;

  const [stopThisEntity, setStopThisEntity] = useState(null);
  const [reason, setReason] = useState("");
  const [formError, setFormError] = useState(null);
  const [showSalaryStructure, setShowSalaryStructure] = useState(false);
  const [showLetterTemplate, setShowLetterTemplate] = useState(false);
  const [showEntitySectorAllowanceDialog, setShowEntitySectorAllowanceDialog] =
    useState(false);

  const [showOrganizationStructure, setShowOrganizationStructure] =
    useState(false);

  const [selectedEntitySector, setSelectedEntitySector] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    if (!stopThisEntity) {
      setFormError({
        element: "GENERAL",
        msg: "You must select the entity first!",
      });
      return;
    }
    if (!reason.length) {
      setFormError({
        element: "REASON",
        msg: "You must provided a reason for your decision!",
      });
      return;
    }
    stopEntity(
      {
        id: stopThisEntity.id,
        action: stopThisEntity.endedOn ? "open" : "close",
        reason,
      },
      (status) => {
        if (status) {
          setReason("");
          setStopThisEntity(null);
          setReason("");
          setFormError(null);
        }
      }
    );
  };

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <tr
        style={{
          background: index % 2 === 0 ? "" : "#f6f7f7",
          borderBottom: "2px dotted rgb(7, 142, 206)!important",
        }}
      >
        <td>
          {entitySector.isLocked && (
            <i className="fas fa-lock text-danger mr-1"></i>
          )}

          {entitySector.sector.name}
        </td>
        <td className="text-right" style={{ verticalAlign: "middle" }}>
          <span
            onClick={handleOpenMenu}
            className="material-icons text-info ml-1"
            style={{ cursor: "pointer" }}
          >
            more_vert
          </span>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleOpenForm("UPDATE", entity);
                  handleCloseMenu();
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">edit_note</span> Edit
              </MenuItem>

              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  setSelectedEntitySector(entitySector);
                  setShowOrganizationStructure(true);
                  handleCloseMenu();
                }}
                className="text-dark font-weight-light"
              >
                <span style={{ transform: "rotate(270deg)" }}>
                  <span
                    style={{
                      transform: "rotateY(180deg)",
                    }}
                    className="material-icons mr-1"
                  >
                    account_tree
                  </span>
                </span>
                Organization Structure
              </MenuItem>

              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  setSelectedEntitySector(entitySector);

                  getSalaryStructure(
                    entitySector.id,
                    () => setShowSalaryStructure(true),
                    () => handleCloseMenu(true),
                    true
                  );
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">point_of_sale</span>{" "}
                Salary Structure
              </MenuItem>

              <Divider className="my-1" />
            </span>
            {hasPermissions(["IS_ADMIN"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    setSelectedEntitySector(entitySector);
                    setShowEntitySectorAllowanceDialog(true);
                    handleCloseMenu();
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">bookmark_add</span>{" "}
                  Allowances
                </MenuItem>

                <Divider className="my-1" />
              </span>
            )}
            {hasPermissions(["IS_ADMIN"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    setSelectedEntitySector(entitySector);
                    setShowLetterTemplate(true);
                    handleCloseMenu();
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">format_shapes</span>{" "}
                  Letter Template
                </MenuItem>

                <Divider className="my-1" />
              </span>
            )}
            <span>
              {entity.endedOn ? (
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    setStopThisEntity(entity);
                    handleCloseMenu();
                  }}
                  className="text-success font-weight-light"
                >
                  <span className="material-icons mr-1">
                    check_circle_outline
                  </span>
                  Activate
                </MenuItem>
              ) : (
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    setStopThisEntity(entity);
                    handleCloseMenu();
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-1">block</span>
                  Close Entity
                </MenuItem>
              )}
            </span>
          </Menu>
        </td>
      </tr>
      {showLetterTemplate && (
        <LetterTemplateDialog
          showLetterTemplate={showLetterTemplate}
          setShowLetterTemplate={setShowLetterTemplate}
          entity={entity}
          selectedEntitySector={selectedEntitySector}
        />
      )}
      {showSalaryStructure && (
        <ManageSalaryStructureDialog
          entity={entity}
          setSelectedEntitySector={setSelectedEntitySector}
          selectedEntitySector={selectedEntitySector}
          showSalaryStructure={showSalaryStructure}
          setShowSalaryStructure={setShowSalaryStructure}
        />
      )}

      {showOrganizationStructure && (
        <ViewOrganizationStructure
          entity={entity}
          setSelectedEntitySector={setSelectedEntitySector}
          selectedEntitySector={selectedEntitySector}
          showOrganizationStructure={showOrganizationStructure}
          setShowOrganizationStructure={setShowOrganizationStructure}
        />
      )}

      {showEntitySectorAllowanceDialog && (
        <EntitySectorAllowances
          entity={entity}
          showEntitySectorAllowanceDialog={showEntitySectorAllowanceDialog}
          setShowEntitySectorAllowanceDialog={
            setShowEntitySectorAllowanceDialog
          }
          selectedEntitySector={selectedEntitySector}
        />
      )}

      <Dialog
        onClose={() => {
          !loading && setStopThisEntity(null);
          !loading && setReason("");
          !loading && setFormError(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={Boolean(stopThisEntity && stopThisEntity.id === entity.id)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {stopThisEntity && stopThisEntity.endedOn
              ? "Are you sure you want to activate this Entity?"
              : "Are you sure you want to stop this Entity?"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              !loading && setStopThisEntity(null);
              !loading && setReason("");
              !loading && setFormError(null);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {stopThisEntity && (
            <div className="d-flex d-row align-items-center mb-4">
              <HiveRoundedIcon
                fontSize="small"
                className="text-primary font-weight-bold"
              />
              <span className="mx-1">{stopThisEntity.name}</span>
            </div>
          )}
          {formError && formError.element === "GENERAL" && (
            <Alert severity="error">{formError.msg}</Alert>
          )}

          <TextField
            id="outlined-basic"
            label="Reason of decision"
            variant="outlined"
            size="small"
            className="w-100"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
              setFormError(null);
            }}
            multiline
            rows={4}
          />
          {formError && formError.element === "REASON" && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError.msg}
            </span>
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={handleChangeStatus}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
