import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  loadStructureDashboard,
  loadPositionByLevel,
  loadEmployeeByLevel,
  loadEntitiesBySector,
} from "../../../store/admin/actions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import ReactPaginate from "react-paginate";
import CustomProgressLine from "../../rbm/components/CustomProgressLine";
import SearchBox from "../../common/components/SearchBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const StructureDashboard = (props) => {
  const {
    loading,
    adminStructure,
    loadStructureDashboard,
    loadPositionByLevel,
    loadEmployeeByLevel,
    loadEntitiesBySector,
  } = props;
  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [viewPositions, setViewPositions] = useState(null);
  const [viewEmployees, setViewEmployees] = useState(null);
  const [viewEntities, setViewEntities] = useState(null);
  const [loadingModal, setLoadingModal] = useState(null);
  const [filterEmployee, setFilterEmployee] = useState("");
  //PAGINATION
  const [sectorItemOffset, setSectorItemOffset] = useState(0);
  const [positionItemOffset, setPositionItemOffset] = useState(0);
  const [employeeItemOffset, setEmployeeItemOffset] = useState(0);
  const itemsPerPage = 50;

  useEffect(() => {
    document.title = "IPPIS - Admin Structure | Dashboard";
    if (!adminStructure.structureStatistic) {
      loadStructureDashboard(setLoadingStatistics);
    } else setLoadingStatistics(false);
    return () => {
      setLoadingStatistics(true);
      setLoadingData(false);
      setViewPositions(null);
      setViewEmployees(null);
      setViewEntities(null);
      setLoadingModal(null);
    };
  }, []);

  if (adminStructure.structureStatistic === null) return null;

  const {
    TOTAL_POSITION,
    TOTAL_POST,
    TOTAL_MALE_EMPLOYEE,
    TOTAL_FEMALE_EMPLOYEE,
    TOTAL_EMPLOYEE,
    TOTAL_VACANT,
  } = adminStructure?.structureStatistic?.bigTotal || 0;
  const EMP_STATUS_PIE_CHART = {
    labels: ["Employee", "Vacant"],
    datasets: [
      {
        label: "# of Votes",
        data: [TOTAL_EMPLOYEE, TOTAL_VACANT],
        backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 206, 86, 0.5)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const LEVEL_EMP_STATUS_PIE_CHART = {
    labels: ["Employee", "Vacant"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          viewPositions ? viewPositions.data.bigTotal.TOTAL_EMPLOYEE : 0,
          viewPositions ? viewPositions.data.bigTotal.TOTAL_VACANT : 0,
        ],
        backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 206, 86, 0.5)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const GENDER_PIE_CHART = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "# of Votes",
        data: [TOTAL_FEMALE_EMPLOYEE, TOTAL_MALE_EMPLOYEE],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const LEVEL_GENDER_PIE_CHART = {
    labels: ["Female", "Male"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          viewPositions ? viewPositions.data.bigTotal.TOTAL_FEMALE_EMPLOYEE : 0,
          viewPositions ? viewPositions.data.bigTotal.TOTAL_MALE_EMPLOYEE : 0,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const YEARS_DOUGHNUT_CHART = {
    labels: ["Bellow 18", "18-35", "36-50", "51-65", "Above 65"],
    datasets: [
      {
        data: [
          adminStructure.structureStatistic
            ? adminStructure.structureStatistic.empYears.totalUnder18
            : 0,
          adminStructure.structureStatistic
            ? adminStructure.structureStatistic.empYears.totalUnder35
            : 0,
          adminStructure.structureStatistic
            ? adminStructure.structureStatistic.empYears.totalUnder50
            : 0,
          adminStructure.structureStatistic
            ? adminStructure.structureStatistic.empYears.totalUnder65
            : 0,
          adminStructure.structureStatistic
            ? adminStructure.structureStatistic.empYears.totalAbove65
            : 0,
        ],
        backgroundColor: [
          "rgba(80, 181, 88,0.6)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 206, 0, 0.5)",
          "rgba(80, 0, 88,0.6)",
        ],
        borderColor: [
          "rgba(80, 181, 88, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 206, 0, 1)",
          "rgba(80, 0, 88, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const LEVEL_YEARS_DOUGHNUT_CHART = {
    labels: ["Bellow 18", "18-35", "36-50", "51-65", "Above 65"],
    datasets: [
      {
        data: [
          viewPositions ? viewPositions.data.empYears.totalUnder18 : 0,
          viewPositions ? viewPositions.data.empYears.totalUnder35 : 0,
          viewPositions ? viewPositions.data.empYears.totalUnder50 : 0,
          viewPositions ? viewPositions.data.empYears.totalUnder65 : 0,
          viewPositions ? viewPositions.data.empYears.totalAbove65 : 0,
        ],
        backgroundColor: [
          "rgba(80, 181, 88,0.6)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 206, 0, 0.5)",
          "rgba(80, 0, 88,0.6)",
        ],
        borderColor: [
          "rgba(80, 181, 88, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 206, 0, 1)",
          "rgba(80, 0, 88, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleViewPositions = (level) => {
    setLoadingModal({ type: "POSITION", key: level.levelId });
    const data = (adminStructure.positions ?? []).find(
      (item) => item.level.levelId === level.levelId
    );
    if (data) {
      setViewPositions(data);
      setLoadingData(false);
      setLoadingModal(null);
    } else
      loadPositionByLevel(level, (status, data) => {
        data && data.data.positions.length && setViewPositions(data);
        setLoadingData(status);
        !status && setLoadingModal(null);
      });
  };

  const handleViewEmployee = (levelId) => {
    setFilterEmployee("");
    setLoadingModal({ type: "EMPLOYEE", key: levelId });
    const data = (adminStructure.employees ?? []).find(
      (item) => item.levelId === levelId
    );
    if (data) {
      setViewEmployees(data);
      setLoadingModal(null);
    } else
      loadEmployeeByLevel(levelId, (status, data) => {
        setViewEmployees(data);
        setLoadingData(status);
        !status && setLoadingModal(null);
      });
  };

  const handleViewEntities = (sectorId) => {
    setLoadingModal({ type: "ENTITY", key: sectorId });
    const data = (adminStructure.entities ?? []).find(
      (item) => item.sectorId === sectorId
    );
    if (data) {
      setViewEntities(data);
      setLoadingModal(null);
    } else
      loadEntitiesBySector(sectorId, (status, data) => {
        setViewEntities(data);
        setLoadingData(status);
        !status && setLoadingModal(null);
      });
  };

  // PAGINATION FOR MODELS =============
  // SECTOR MODAL----------------
  const SECTOR_PAGE_COUNT = Math.ceil(
    (viewEntities ? viewEntities.data : []).length / itemsPerPage
  );
  const SECTOR_PAGINATION_FN = (items) => {
    const endOffset = sectorItemOffset + itemsPerPage;
    return items.slice(sectorItemOffset, endOffset);
  };
  const handleSectorPageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % viewEntities
        ? viewEntities.data.length
        : 0;

    setSectorItemOffset(newOffset);
  };
  // POSITION MODAL----------------
  const POSITION_PAGE_COUNT = Math.ceil(
    (viewPositions ? viewPositions.data.positions : []).length / itemsPerPage
  );
  const POSITION_PAGINATION_FN = (items) => {
    const endOffset = positionItemOffset + itemsPerPage;
    return items.slice(positionItemOffset, endOffset);
  };
  const handlePositionPageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % viewPositions
        ? viewPositions.data.positions.length
        : 0;

    setPositionItemOffset(newOffset);
  };
  // EMPLOYEE MODAL----------------
  const EMP_PAGE_COUNT = Math.ceil(
    (viewEmployees && viewEmployees.data ? viewEmployees.data : []).length /
      itemsPerPage
  );
  const EMP_PAGINATION_FN = (items) => {
    const endOffset = employeeItemOffset + itemsPerPage;
    return items.slice(employeeItemOffset, endOffset);
  };
  const EMP_FILTER_FN = (data) => {
    if (filterEmployee.length) {
      return data.filter(
        (item) =>
          (item.firstName || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase()) ||
          (item.lastName || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase()) ||
          (item.email || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase()) ||
          (item.genderId || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase()) ||
          ((item.genderId || "").toLowerCase() === "m" &&
            filterEmployee.toLowerCase() === "male") ||
          ((item.genderId || "").toLowerCase() === "f" &&
            filterEmployee.toLowerCase() === "female") ||
          (item.phoneNumber || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase()) ||
          (item.positionName || "")
            .toLowerCase()
            .includes(filterEmployee.toLowerCase())
      );
    }
    return data;
  };
  const handleEmployeePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % viewEmployees
        ? viewEmployees.data.length
        : 0;

    setEmployeeItemOffset(newOffset);
  };

  return (
    <div className="container-fluid">
      {/* labels */}
      <div className="d-flex flex-wrap justify-content-center w-100">
        <DashboardLabel
          labelTitle="Entities"
          labelValue={
            adminStructure.structureStatistic
              ? adminStructure.structureStatistic.totalEntities
              : 0
          }
        />
        <DashboardLabel
          labelTitle="Sectors"
          labelValue={
            adminStructure.structureStatistic
              ? adminStructure.structureStatistic.totalSectors
              : 0
          }
        />
        <DashboardLabel labelTitle="Post" labelValue={TOTAL_POST} />
        <DashboardLabel labelTitle="Position" labelValue={TOTAL_POSITION} />
        <DashboardLabel labelTitle="Employee" labelValue={TOTAL_EMPLOYEE} />
        <DashboardLabel labelTitle="Vacant(s)" labelValue={TOTAL_VACANT} />
      </div>

      {/* pie and doughnut charts */}
      {adminStructure.structureStatistic && YEARS_DOUGHNUT_CHART && (
        <div className="row justify-content-center w-100 my-3 px-4">
          <div className="col-3">
            <DashboardPieChart
              pieTitle="Employment status"
              pieData={EMP_STATUS_PIE_CHART}
            />
          </div>
          <div className="col-3">
            <DashboardPieChart
              pieTitle="Employee by gender"
              pieData={GENDER_PIE_CHART}
            />
          </div>
          <div className="col-3">
            <DashboardDoughnutChart
              chartTitle="Employee years"
              chartData={YEARS_DOUGHNUT_CHART}
            />
          </div>
        </div>
      )}

      <div className="row justify-content-between my-5">
        {/* table levels */}
        <div className="col-8">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Structure levels
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Level
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Positions
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Posts
                  </th>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Employees
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Vacant(s)
                  </th>
                </tr>
              </thead>
              {adminStructure.structureStatistic && !loadingStatistics ? (
                <tbody style={{ fontSize: "13px" }}>
                  {adminStructure.structureStatistic.levelStatistic.map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          className="text-center"
                          style={{ borderLeft: "3px solid #dee2e6" }}
                        >
                          {item.levelName}
                        </td>
                        <td
                          className={`text-center ${
                            item.numberOfPositions > 0 &&
                            !loading &&
                            !loadingData
                              ? "td-clickable"
                              : ""
                          }`}
                          style={{ borderLeft: "3px solid #dee2e6" }}
                          onClick={() =>
                            item.numberOfPositions > 0 &&
                            !loading &&
                            !loadingData &&
                            !loadingModal &&
                            handleViewPositions({
                              levelId: item.levelId,
                              levelName: item.levelName,
                            })
                          }
                        >
                          {item.numberOfPositions}
                          {item.numberOfPositions > 0 &&
                            (loadingModal &&
                            loadingModal.type === "POSITION" &&
                            loadingModal.key === item.levelId ? (
                              <div className={`float-right`}>
                                <CircularProgress color="info" size={15} />
                              </div>
                            ) : (
                              <div className={`float-right td-clickable-icon`}>
                                <IconButton
                                  aria-label="view positions"
                                  size="small"
                                >
                                  <RemoveRedEyeRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            ))}
                        </td>
                        <td
                          className={`text-center`}
                          style={{ borderLeft: "3px solid #dee2e6" }}
                        >
                          {item.numberOfPosts}
                        </td>
                        <td
                          className={`text-center ${
                            item.numberOfEmployee > 0 &&
                            !loading &&
                            !loadingData
                              ? "td-clickable"
                              : ""
                          }`}
                          style={{ borderLeft: "3px solid #dee2e6" }}
                          onClick={() =>
                            item.numberOfPositions > 0 &&
                            !loading &&
                            !loadingData &&
                            !loadingModal &&
                            handleViewEmployee(item.levelId)
                          }
                        >
                          {item.numberOfEmployee}
                          {item.numberOfEmployee > 0 &&
                            (!loadingModal ||
                            loadingModal.type !== "EMPLOYEE" ||
                            loadingModal.key !== item.levelId ? (
                              <div className={`float-right td-clickable-icon`}>
                                <IconButton
                                  aria-label="view employee"
                                  size="small"
                                >
                                  <RemoveRedEyeRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            ) : (
                              <div className={`float-right`}>
                                <CircularProgress color="info" size={15} />
                              </div>
                            ))}
                        </td>
                        <td
                          className={`text-center`}
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderRight: "3px solid #dee2e6",
                          }}
                        >
                          {item.totalVacant}
                        </td>
                      </tr>
                    )
                  )}

                  {adminStructure.structureStatistic.levelStatistic.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        Total
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {TOTAL_POSITION}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {TOTAL_POST}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {TOTAL_EMPLOYEE}
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {TOTAL_VACANT}
                      </th>
                    </tr>
                  )}
                </tbody>
              ) : (
                <TableSkeleton cols={5} />
              )}
            </table>
          </div>
        </div>

        <div className="col-4">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Structure sectors
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Sectors
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Entities
                  </th>
                </tr>
              </thead>
              {adminStructure.structureStatistic && !loadingStatistics ? (
                <tbody style={{ fontSize: "13px" }}>
                  {adminStructure.structureStatistic.sectorWithEntity.map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          className="text-center"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderBottom: "1px solid #dee2e6",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          className={`text-center ${
                            item.numberOfEntity > 0 && !loading && !loadingData
                              ? "td-clickable"
                              : ""
                          }`}
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderRight: "3px solid #dee2e6",
                          }}
                          onClick={() =>
                            item.numberOfEntity > 0 &&
                            !loading &&
                            !loadingData &&
                            handleViewEntities(item.id)
                          }
                        >
                          {item.numberOfEntity}
                          {item.numberOfEntity > 0 &&
                            (!loadingModal ||
                            loadingModal.type !== "ENTITY" ||
                            loadingModal.key !== item.id ? (
                              <div className={`float-right td-clickable-icon`}>
                                <IconButton
                                  aria-label="view entities"
                                  size="small"
                                >
                                  <RemoveRedEyeRoundedIcon fontSize="inherit" />
                                </IconButton>
                              </div>
                            ) : (
                              <div className={`float-right`}>
                                <CircularProgress color="info" size={15} />
                              </div>
                            ))}
                        </td>
                      </tr>
                    )
                  )}

                  <tr>
                    <th
                      style={{ border: "0px solid #dee2e6" }}
                      className="text-center bg-white "
                    ></th>
                    <th
                      style={{ border: "3px solid #dee2e6" }}
                      className="text-center bg-light "
                    >
                      {adminStructure.structureStatistic.totalEntitySector}
                    </th>
                  </tr>
                </tbody>
              ) : (
                <TableSkeleton cols={2} />
              )}
            </table>
          </div>
        </div>
      </div>

      {viewPositions &&
        viewPositions.data &&
        viewPositions.data.positions &&
        viewPositions.data.positions.length && (
          <Dialog
            onClose={() => setViewPositions(null)}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle className="text-primary">
              <Typography variant="overline" display="block">
                Organization Structure{" "}
                <b>level {viewPositions.level.levelName}</b>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setViewPositions(null)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </DialogTitle>

            <DialogContent>
              {/* labels */}
              <div className="d-flex flex-wrap justify-content-center w-100">
                <DashboardLabel
                  labelTitle="Post"
                  labelValue={viewPositions.data.bigTotal.TOTAL_POST}
                />
                <DashboardLabel
                  labelTitle="Position"
                  labelValue={viewPositions.data.bigTotal.TOTAL_POSITION}
                />
                <DashboardLabel
                  labelTitle="Employee"
                  labelValue={viewPositions.data.bigTotal.TOTAL_EMPLOYEE}
                />
                <DashboardLabel
                  labelTitle="Vacant(s)"
                  labelValue={viewPositions.data.bigTotal.TOTAL_VACANT}
                />
              </div>

              {/* pie and doughnut charts */}
              <div className="row justify-content-center w-100 my-3 px-4">
                <div className="col-4">
                  <DashboardPieChart
                    pieTitle="Employment status"
                    pieData={LEVEL_EMP_STATUS_PIE_CHART}
                  />
                </div>
                <div className="col-4">
                  <DashboardPieChart
                    pieTitle="Employee by gender"
                    pieData={LEVEL_GENDER_PIE_CHART}
                  />
                </div>
                <div className="col-4">
                  <DashboardDoughnutChart
                    chartTitle="Employee years"
                    chartData={LEVEL_YEARS_DOUGHNUT_CHART}
                  />
                </div>
              </div>

              <table className={`table table-sm fixTableHead`}>
                <caption
                  style={{ backgroundColor: "#e9ecef" }}
                  className="mb-2"
                >
                  List of positions on{" "}
                  <b>level {viewPositions.level.levelName}</b>
                </caption>
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th
                      className="text-left"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Position
                    </th>
                    <th
                      className="text-left"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Entity
                    </th>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Post
                    </th>
                    <th
                      className="text-center"
                      style={{ borderLeft: "3px solid #dee2e6" }}
                    >
                      Employee
                    </th>
                    <th
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderRight: "3px solid #dee2e6",
                      }}
                    >
                      Vacant(s)
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "13px" }}>
                  {POSITION_PAGINATION_FN(viewPositions.data.positions).map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          align="left"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderBottom: `${
                              index + 1 === viewPositions.data.positions.length
                                ? "3px"
                                : ""
                            } solid #dee2e6`,
                          }}
                        >
                          {item.positionName}
                        </td>
                        <td
                          align="left"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderBottom: `${
                              index + 1 === viewPositions.data.positions.length
                                ? "3px"
                                : ""
                            } solid #dee2e6`,
                          }}
                        >
                          {item.entityName}
                          {item.entityAcronym ? ` (${item.entityAcronym})` : ""}
                        </td>
                        <td
                          align="center"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderBottom: `${
                              index + 1 === viewPositions.data.positions.length
                                ? "3px"
                                : ""
                            } solid #dee2e6`,
                          }}
                        >
                          {item.positionPost}
                        </td>
                        <td
                          align="center"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderBottom: `${
                              index + 1 === viewPositions.data.positions.length
                                ? "3px"
                                : ""
                            } solid #dee2e6`,
                          }}
                        >
                          {item.numberOfEmployee}
                        </td>
                        <td
                          align="center"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderRight: "3px solid #dee2e6",
                            borderBottom: `${
                              index + 1 === viewPositions.data.positions.length
                                ? "3px"
                                : ""
                            } solid #dee2e6`,
                          }}
                        >
                          {item.positionPost - item.numberOfEmployee}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              {POSITION_PAGE_COUNT > 1 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <span>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </span>
                  }
                  previousLabel={
                    <span>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1 d-none d-md-inline">Previous</span>
                    </span>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePositionPageClick}
                  pageCount={POSITION_PAGE_COUNT}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination mt-2"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
            </DialogContent>

            <DialogActions></DialogActions>
          </Dialog>
        )}
      {viewEmployees && viewEmployees.data && viewEmployees.data.length && (
        <Dialog
          onClose={() => setViewEmployees(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of employees
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewEmployees(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <caption>
                <SearchBox
                  onSearch={(e) => setFilterEmployee(e)}
                  placeholder="Search employee..."
                  disabled={viewEmployees.data.length > 2 ? true : false}
                />
              </caption>
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-left">Employee</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">Phone</th>
                  <th className="text-left">Position</th>
                  <th className="text-left">Start time</th>
                </tr>
              </thead>
              <tbody>
                {EMP_PAGINATION_FN(EMP_FILTER_FN(viewEmployees.data)).map(
                  (item, index) => (
                    <tr key={index}>
                      <td align="left">
                        {item.lastName + " " + item.firstName}
                      </td>
                      <td align="left">{item.email}</td>
                      <td align="left">{item.phoneNumber}</td>
                      <td align="left">{item.positionName}</td>
                      <td align="left">
                        {moment(item.startDate).format("LL")}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {EMP_PAGE_COUNT > 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <span>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </span>
                }
                previousLabel={
                  <span>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1 d-none d-md-inline">Previous</span>
                  </span>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handleEmployeePageClick}
                pageCount={EMP_PAGE_COUNT}
                renderOnZeroPageCount={null}
                containerClassName="pagination mt-2"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
      {viewEntities && viewEntities.data && viewEntities.data.length && (
        <Dialog
          onClose={() => setViewEntities(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of entities by sector
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewEntities(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-left">Entity name</th>
                  <th className="text-left">Acronym</th>
                </tr>
              </thead>
              <tbody>
                {SECTOR_PAGINATION_FN(viewEntities.data).map((item, index) => (
                  <tr key={index}>
                    <td align="left">{item.entityName}</td>
                    <td align="left">{item.entityAcronym}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {SECTOR_PAGE_COUNT > 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <span>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </span>
                }
                previousLabel={
                  <span>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1 d-none d-md-inline">Previous</span>
                  </span>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handleSectorPageClick}
                pageCount={SECTOR_PAGE_COUNT}
                renderOnZeroPageCount={null}
                containerClassName="pagination mt-2"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = ({ loading, adminStructure }) => {
  return { loading, adminStructure };
};
const mapDispatchToProps = {
  loadStructureDashboard,
  loadPositionByLevel,
  loadEmployeeByLevel,
  loadEntitiesBySector,
};
export default connect(mapStateToProps, mapDispatchToProps)(StructureDashboard);

export const DashboardLabel = ({
  labelTitle = "Title",
  labelHint = "",
  labelValue,
  valueType = "normal",
  showProgressBar = false,
  loading = false,
}) => {
  const _value = Number(
    labelValue !== null && labelValue !== undefined && !isNaN(+labelValue)
      ? +labelValue
      : 0
  );

  return (
    <div
      style={{ color: "#ffe6aa" }}
      className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
      title={labelHint || labelTitle}
    >
      <div className="fw-semibold font-weight-bold text-center">
        {labelTitle}
      </div>
      <div
        style={{ color: "#9cff71" }}
        className="fs-6 font-weight-bolder text-center"
      >
        {loading ? (
          <Skeleton variant="text" sx={{ bgcolor: "grey.400" }} />
        ) : valueType === "percentage" ? (
          `${_value.toFixed(2)}%`
        ) : (
          _value
        )}
      </div>
      {valueType === "percentage" && showProgressBar ? (
        loading ? (
          <Skeleton variant="text" sx={{ bgcolor: "grey.400", height: 6 }} />
        ) : (
          <CustomProgressLine value={_value} hidePercentage />
        )
      ) : null}
    </div>
  );
};

DashboardLabel.propTypes = {
  labelTitle: PropTypes.string,
  labelValue: PropTypes.number,
};

export const DashboardPieChart = ({
  pieTitle = "Employee by gender",
  pieData,
  loading = false,
}) => {
  return (
    <>
      {!loading && !!pieData?.labels ? (
        <div className="border rounded p-2 dashboard-card">
          <Pie
            redraw={true}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
                title: {
                  display: true,
                  text: pieTitle,
                },
              },
            }}
            data={pieData}
          />
        </div>
      ) : (
        <SkeletonPieChart title={pieTitle} />
      )}
    </>
  );
};

export const DashboardDoughnutChart = ({
  chartTitle = "Employee years",
  chartData,
  loading = false,
}) => {
  return (
    <>
      {!loading && !!chartData?.labels ? (
        <div className="border rounded p-2 dashboard-card">
          <Doughnut
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
                title: {
                  display: true,
                  text: chartTitle,
                },
              },
            }}
            data={chartData}
          />
        </div>
      ) : (
        <SkeletonPieChart title={chartTitle} />
      )}
    </>
  );
};

export const DashboardLineChart = ({
  chartTitle = "Line chart variations",
  chartData,
  loading = false,
  ignoreBorder = false,
}) => {
  return (
    <div
      className={`${!ignoreBorder ? "border rounded p-2" : ""} dashboard-card`}
    >
      {!loading && !!chartData?.labels ? (
        <Line
          redraw={true}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
              },
              title: {
                display: true,
                text: chartTitle,
              },
            },

            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
          data={chartData}
        />
      ) : (
        <table>
          <TableSkeleton cols={1} rows={8} />
        </table>
      )}
    </div>
  );
};

export const DashboardBarChart = ({
  chartTitle,
  chartData,
  loading = false,
  isStackedChart = false,
  isHorizontalBar = false,
  ignoreBorder = false,
}) => {
  return (
    <div
      className={`${!ignoreBorder ? "border rounded p-2" : ""} dashboard-card`}
    >
      {!loading && !!chartData?.labels ? (
        <Bar
          redraw={true}
          options={{
            indexAxis: isHorizontalBar ? "y" : undefined,
            elements: {
              bar: {
                borderWidth: isHorizontalBar ? 2 : undefined,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
              },
              title: {
                display: chartTitle && true,
                text: chartTitle,
              },
            },

            scales: {
              x: {
                stacked: isStackedChart,
              },
              y: {
                stacked: isStackedChart,
              },
            },

            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
          data={chartData}
        />
      ) : (
        <table>
          <TableSkeleton cols={1} rows={8} />
        </table>
      )}
    </div>
  );
};

export const SkeletonPieChart = ({ title }) => {
  return (
    <div className="border rounded p-2 dashboard-card text-center">
      <span style={{ fontSize: "12px", fontWeight: "700" }}>{title}</span>
      <Skeleton
        variant="circular"
        width={150}
        height={150}
        className="mx-auto mt-2 mb-3"
      />
    </div>
  );
};
