import exitTypes from "./action-types";
import axios from "axios";
import download from "downloadjs";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...exitTypes };

export const saveRequest = (formData, setShowEmployeeExitForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      const { data } = await axios.post("/api/exit/exit-requests", formData);

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: data.exitRequest,
      });

      setShowEmployeeExitForm(false);

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showSuccess("Exit request submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const saveOrUpdateDraftRequest = (formData, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      callback(false, null);
      const { data } = await axios.post(
        "/api/exit/modify-draft-requests",
        formData
      );

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: data.exitRequest,
      });

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      callback(true, data.exitRequest);
    } catch (error) {
      callback(false, null);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
export const submitFinalRequest = (formData, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      callback(false);
      const { data } = await axios.post(
        "/api/exit/submit-final-requests",
        formData
      );

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: data.exitRequest,
      });

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      callback(true);
    } catch (error) {
      callback(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const updateExitRequest = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      const { data } = await axios.put(
        "/api/exit/exit-requests/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: data.exitRequest,
      });

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });
      onClose(false);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showSuccess("Exit request updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
export const getEmployeeExitRequest = (
  employeePositionId,
  setShowEmployeeExitForm,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/exit/employee-exit-requests/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data,
      });

      setShowEmployeeExitForm(true);
      handleCloseMenu();

      dispatch({ type: types.END_LOADING });
      //showSuccess("Exit requested submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const cancelEmployeeExitRequest = (request, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/exit/employee-exit-requests/" + request.id
      );

      dispatch({
        type: types.DELETE_MY_REQUEST,
        data: request,
      });
      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data,
      });
      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: null,
      });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Exit requested canceled successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveBlacklist = (blacklistForm, setBlacklistForm, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/exit/add-blacklist",
        blacklistForm
      );

      dispatch({
        type: types.ADD_BLACKLIST,
        data,
      });
      setBlacklistForm(false);
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Added On BLACKLIST Successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const rejectRequest = (rejectExitForm, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/exit/reject-request",
        rejectExitForm
      );

      dispatch({
        type: types.UPDATE_REQUEST,
        data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Rejected successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveExitRequest = (approveRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/approve-exit-request",
        approveRequest
      );

      dispatch({
        type: types.UPDATE_REQUEST,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const signExitRequest = (approveRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/sign-exit-request",
        approveRequest
      );

      dispatch({
        type: types.UPDATE_REQUEST,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const verifyExitRequest = (request, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/verify-exit-request/" + request.requestId,
        request
      );

      dispatch({
        type: types.UPDATE_REQUEST,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Verified Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
const approveRehabilitation = (
  approveRehabilitation,
  setApproveForm,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/rehabilitations",
        approveRehabilitation
      );

      dispatch({
        type: types.UPDATE_APPROVE_REHABILITATION,
        data,
      });

      setApproveForm(null);
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Updated Successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExitRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      //const data = [{ id: 1, name: "X" }];
      const { data } = await axios.get("/api/exit/requests");
      dispatch({
        type: types.SET_EXIT_REQUESTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getBlacklists = (setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/exit/blacklists");
      dispatch({
        type: types.SET_BLACKLISTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const onRemoveEmployeeOnBlacklist = (
  formData,
  blacklist,
  setShowRemoveToBlacklistDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/exit/remove-blacklist", formData);

      dispatch({
        type: types.DELETE_BLACKLIST,
        data: blacklist,
      });

      setShowRemoveToBlacklistDialog(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSearchedEmployee = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/employee/" + employeeId);
      dispatch({
        type: types.SET_SEARCH_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getMyRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/my-requests");
      dispatch({
        type: types.SET_MY_REQUESTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getOngoingRequests = (setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/exit/ongoing-exit-requests");
      dispatch({
        type: types.SET_APPROVED_REQUESTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApprovedRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/entity-sector-exit-requests");
      dispatch({
        type: types.SET_APPROVED_REQUESTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExitTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/exit-types");
      dispatch({
        type: types.SET_EXIT_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getHrExitTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/hr-exit-types");
      dispatch({
        type: types.SET_HR_EXIT_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getInitiators = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/initiators");
      dispatch({
        type: types.SET_INITIATORS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getBlacklistReasons = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/blacklist-reason");

      dispatch({
        type: types.SET_BLACKLIST_REASON,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRehabilitationRequests = (setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/exit/rehabilitations");

      dispatch({
        type: types.SET_REHABILITATION_REQUESTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getExitRequestAttachments = (requestId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/exit/request-attachments/" + requestId
      );

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateExitRequestAttachment = (id, payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/request-attachments/" + id,
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const addExitRequestAttachment = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/exit/request-attachments",
        payload
      );
      showSuccess("Attachment saved successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const deleteMyExitRequest = (
  myRequest,
  setConfirmRemoveRequest,
  setSelectedRequest
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.delete(
        "/api/exit/my-requests/" + myRequest.id
      );
      dispatch({
        type: types.DELETE_MY_REQUEST,
        data: myRequest,
      });
      setConfirmRemoveRequest(false);
      setSelectedRequest(null);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updateBlacklist = (
  updateBlacklistForm,
  setUpdateBlacklistForm,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/blacklists",
        updateBlacklistForm
      );

      dispatch({
        type: types.UPDATE_APPROVE_REQUEST,
        data,
      });

      setUpdateBlacklistForm(null);
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Updated Successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const AddExitType = (dataForm, callback) => {
  callback(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/exit/exit-types", dataForm);
      dispatch({
        type: types.ADD_EXIT_TYPE,
        data,
      });
      callback(true);
      dispatch({ type: types.END_LOADING });
      showSuccess("Added Successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

export const respondRehabilitationRequest = (
  rehabilitationId,
  statusId,
  statusComment,
  cb
) => {
  return async (dispatch) => {
    cb(true, false);
    try {
      dispatch({ type: types.START_LOADING });
      await axios.put(
        `/api/exit/rehabilitationRequest-status/${rehabilitationId}`,
        { statusId, statusComment }
      );

      const { data } = await axios.get("/api/exit/rehabilitations");

      dispatch({
        type: types.SET_REHABILITATION_REQUESTS,
        data,
      });
      showSuccess(
        `Request ${statusId === 2 ? "approved" : "rejected"} successfully!`
      );
      dispatch({ type: types.END_LOADING });
      cb(false, true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(false, false);
    }
  };
};

export const getCountBlacklistedActiveEmployees = (cb) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/exit/count-blacklist-active-employee"
      );

      cb(data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadCSVBlacklistActiveEmployees = (setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/exit/cvs-blacklist-active-employees",
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `BLACKLIST_ACTIVE_EMPLOYEES.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
