import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import {
  getApprovedRequests,
  getEmployeeExitRequest,
} from "../../../store/exit/actions";

import ApproveExitDialog from "../components/ApproveExitDialog";
import RejectExitDialog from "../components/RejectExitDialog";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ViewExitDialog from "../components/ViewExitDialog";
import { useTheme } from "@mui/material/styles";
const ExitRequests = (props) => {
  const {
    user,
    approvedRequests,
    getApprovedRequests,
    loading,
    getEmployeeExitRequest,
    env,
  } = props;
  const [value, setValue] = useState(0);
  const [searchTeam, setSearchTeam] = useState("");
  const [filteredExitRequests, setFilteredExitRequests] = useState([
    ...approvedRequests,
  ]);
  const theme = useTheme();
  const [rejectRequestForm, setRejectRequestForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getApprovedRequests();
  }, []);

  const [filteredRequests, setfilteredRequests] = useState([
    ...approvedRequests,
  ]);

  useEffect(() => {
    const filtered = [...approvedRequests].filter(
      ({
        exitType,
        effectiveDate,
        employeeNames,
        employeePosition,
        requestStatus,
      }) =>
        (exitType || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (effectiveDate || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (employeeNames || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (employeePosition || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (requestStatus || "").toLowerCase().includes(searchTeam.toLowerCase())
    );

    setfilteredRequests(filtered);
    paginate(filtered);
  }, [searchTeam, approvedRequests]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredRequests);
  }, [itemOffset, itemsPerPage, approvedRequests, filteredRequests]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredRequests.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>EXIT</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>Histories</span>
                </span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!approvedRequests.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <RejectExitDialog
                rejectRequestForm={rejectRequestForm}
                setRejectRequestForm={setRejectRequestForm}
                selectedRequest={selectedRequest}
                setSelectedRequest={setSelectedRequest}
              />

              {!!paginatedItems && !!paginatedItems.length && (
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">EmployeeId</th>
                      <th scope="col">Employee Names</th>
                      <th scope="col">Employee Position</th>
                      <th scope="col">Exit Type</th>
                      <th scope="col">Requested By</th>

                      <th scope="col">Requested On</th>
                      <th scope="col">Effective Date</th>

                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {paginatedItems.map((approvedRequest, index) => (
                      <ApprovedExitRequestItem
                        key={index}
                        approvedRequest={approvedRequest}
                        index={index}
                        setRejectRequestForm={setRejectRequestForm}
                        setSelectedRequest={setSelectedRequest}
                        getEmployeeExitRequest={getEmployeeExitRequest}
                        env={env}
                      />
                    ))}
                  </tbody>
                </table>
              )}

              <div
                className="justify-content-center"
                style={{ overflow: "hidden" }}
              >
                {!loading &&
                  !!paginatedItems.length &&
                  approvedRequests.length >= itemsPerPage && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  )}
              </div>

              {!filteredRequests.length && !loading && (
                <div className="text-center">
                  <p className="lead">No Request found</p>
                </div>
              )}

              {loading && !approvedRequests.length && (
                <div className="text-center">
                  {/* <img src={loadingGif} height="128" alt="loading" /> */}
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ env, user, loading, approvedRequests }) => {
  return { env, user, loading, loading, approvedRequests };
};
export default connect(mapStateToProps, {
  getApprovedRequests,
  getEmployeeExitRequest,
})(ExitRequests);

const ApprovedExitRequestItem = (props) => {
  const {
    index,
    loading,
    approvedRequest,
    setRejectRequestForm,
    setSelectedRequest,
    getEmployeeExitRequest,
    env,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [approveRequestForm, setApproveRequestForm] = useState(false);
  const [showRequestAction, setShowRequestAction] = useState("View");
  const [showLetter, setsShowLetter] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <tr key={approvedRequest.id}>
        <td>{approvedRequest.employeeId}</td>

        <td>
          {approvedRequest.lastName} {approvedRequest.firstName}
        </td>
        <td>{approvedRequest.positionName}</td>
        <td>{approvedRequest.exitType}</td>
        <td>{approvedRequest.requestedBy}</td>
        <td>{moment(approvedRequest.dateRequested).format("LLL")}</td>
        <td>{moment(approvedRequest.effectiveDate).format("LLL")}</td>
        <td>
          <span
            className={`badge badge-${
              approvedRequest.statusId === 1
                ? "info"
                : approvedRequest.statusId === 3
                ? "danger"
                : approvedRequest.statusId === 2
                ? "success"
                : approvedRequest.statusId === 1003
                ? "warning"
                : "primary"
            }`}
          >
            {approvedRequest.statusName}
          </span>
        </td>
        <td className="text-right">
          <>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              // onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "auto",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    getEmployeeExitRequest(
                      approvedRequest.employeePositionId,
                      setApproveRequestForm,
                      handleCloseMenu
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">info</span>
                  View details
                </MenuItem>
              </span>
            </Menu>
          </>
        </td>
      </tr>
      {showLetter &&
        approvedRequest.statusId === 2 &&
        !!approvedRequest?.docReferenceId && (
          <PreviewPdfUrl
            showDocument={showLetter}
            setShowDocument={setsShowLetter}
            document={{
              name: `View ${approvedRequest.exitType} Letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                approvedRequest?.docReferenceId,
            }}
          />
        )}
      {!!approveRequestForm && env !== "prod" && (
        <ViewExitDialog
          approveRequestForm={approveRequestForm}
          setApproveRequestForm={setApproveRequestForm}
        />
      )}
      {!!approveRequestForm && env === "prod" && (
        <ApproveExitDialog
          showRequestAction={showRequestAction}
          approveRequestForm={approveRequestForm}
          setApproveRequestForm={setApproveRequestForm}
          setSelectedRequest={setSelectedRequest}
          selectedRequest={approvedRequest}
        />
      )}
    </>
  );
};
