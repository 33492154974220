import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { getApplicantSessionLogs } from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import { useEffect } from "react";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

const ApplicantExamSessionLogsDialog = (props) => {
  const {
    loading,
    showApplicantExamSessionLogs,
    setShowApplicantExamSessionLogs,
    selectedSession,
    getApplicantSessionLogs,
    applicantSessionLogs,
  } = props;
  const [applicantSessionLogData, setApplicantSessionLogData] = useState([
    ...applicantSessionLogs,
  ]);
  useEffect(() => {
    getApplicantSessionLogs(selectedSession.id, (data) => {
      if (!!data.length) setApplicantSessionLogData([...data]);
    });
  }, [selectedSession]);

  const onClose = () => {
    setShowApplicantExamSessionLogs(false);
  };

  const formattingDate = (dateTimeString) => {
    const [datePart, rawTimePart] = dateTimeString.split("T");
    const timePart = rawTimePart.replace(/\.\d+Z$/, "");
    return datePart + " " + timePart;
  };
  console.log(applicantSessionLogData);
  return (
    <>
      <Dialog open={showApplicantExamSessionLogs} fullWidth maxWidth="md">
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Applicant exam session logs.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "550px",
            }}
          >
            <PreLoader isLoading={loading} />

            <div
              style={{
                width: "100%",
              }}
            >
              <p className="contact_p">
                <>
                  {applicantSessionLogData.map((applicantSessionLog, index) => (
                    <Timeline
                      key={index}
                      position="alternate"
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.2,
                        },
                      }}
                    >
                      <TimelineItem
                        style={{
                          width: "100%",
                        }}
                      >
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                        >
                          <h2
                            style={{
                              fontSize: ".85rem",
                              fontWeight: 600,
                              overflowWrap: "break-word",
                              marginBottom: "8px",
                              textAlign: "left",
                              paddingRight: "8px",
                              width: "200px",
                            }}
                          >
                            <span className="text-primary">
                              {applicantSessionLog?.action
                                ?.replace(/_/g, " ")
                                .toUpperCase()}
                            </span>
                          </h2>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />

                          <TimelineConnector />
                        </TimelineSeparator>
                        {/* <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot variant="outlined" color="secondary" />

                            <TimelineConnector />
                          </TimelineSeparator> */}
                        <TimelineContent>
                          <div className="mt-2">
                            <p>
                              <div className="row">
                                <div className="col-6">
                                  <span
                                    className="float-left"
                                    style={{ fontSize: "9px" }}
                                  >
                                    {applicantSessionLog.user.firstName}{" "}
                                    {applicantSessionLog.user.lastName}
                                  </span>
                                </div>
                                <div className="col-6">
                                  <span
                                    className="float-right"
                                    style={{ fontSize: "9px" }}
                                  >
                                    {formattingDate(
                                      applicantSessionLog.createdOn
                                    )}
                                  </span>
                                </div>
                              </div>
                            </p>
                            <p
                              style={{
                                fontSize: ".85rem",
                                fontWeight: 400,
                                color: "#344457",
                                width: "100%",
                              }}
                            >
                              {applicantSessionLog.comment}
                            </p>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))}
                </>
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, applicantSessionLogs }) => {
  return {
    loading,
    user,
    applicantSessionLogs,
  };
};
export default connect(mapStateToProps, {
  getApplicantSessionLogs,
})(ApplicantExamSessionLogsDialog);
