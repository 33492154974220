import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";
import LetterTemplateHeader from "../LetterTemplateHeader";
import LetterTemplateFooter from "../LetterTemplateFooter";

const ExitLetter = (props) => {
  const {
    enableToFillLetter = false,
    docLetterNo = "",
    signContent = "",
    subTitle = "Dear Sir/Madam",
    recipientContent,
    entitySectorId,
    letterBodyContentInput = "",
    letterBodyContent = "",
    title = "",
    copiedInstitutionContentInput = "",
    copiedInstitutionContent = "",
    ccSelectForm = "",
    loadFooterContent,
    disableToEditLetterHeader
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter2">
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <LetterTemplateHeader
              disableToEdit={disableToEditLetterHeader}
              entitySectorId={entitySectorId}
            />
            <div className="subletterhead">
              <table>
                <tr>
                  <td style={{ width: "70%" }}></td>
                  <td style={{ width: "30%" }}>
                    <p
                      className="date"
                      style={{
                        fontWeight: "bold",
                        float: "left",
                        textAlign: "left",
                      }}
                    >
                      Kigali, on {moment(new Date()).format("LL")}
                      <br />N<sup>o</sup>:
                      <span style={{ fontWeight: "bold" }}>{docLetterNo}</span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div className="recipient">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "13.6px",
                  lineHeight: "1.5",
                }}
              >
                {recipientContent}
              </p>
            </div>
            <div className="content2 mb-2">
              <p style={{ fontWeight: "bold" }}>{title}</p>
              <p>{subTitle}</p>
              <p>
                {enableToFillLetter ? (
                  letterBodyContentInput
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: letterBodyContent,
                      }}
                    ></div>
                  </>
                )}{" "}
              </p>
            </div>
            {!enableToFillLetter && <>{signContent}</>}
            <div className="cc">
              {enableToFillLetter ? (
                copiedInstitutionContentInput
              ) : (
                <>
                  <p style={{ fontWeight: "bold" }}>Cc:</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: copiedInstitutionContent,
                    }}
                  ></div>
                </>
              )}{" "}
              {enableToFillLetter && (
                <>
                  <br /> {ccSelectForm}
                </>
              )}
            </div>
          </div>
          <LetterTemplateFooter loadFooterContent={loadFooterContent} />
        </div>
      </div>
    </>
  );
};

export default ExitLetter;
