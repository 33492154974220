import { Link, useLocation } from "react-router-dom";
import SupportButton from "./SupportButton";
import { Chip, Divider } from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
const Footer = (props) => {
  const { recruitmentUrl, selfServiceUrl } = props;
  const location = useLocation();
  return (
    <footer
      id="footer"
      className="text-center  pt-2"
      style={{
        backgroundColor: "#f6f8fa",
        borderTop: "1px solid rgba(0, 0, 0, 0.125)",
      }}
    >
      <span className="text-uppercase" style={{ fontSize: "12px" }}>
        &copy;{new Date().getFullYear()}{" "}
        <a
          href="https://www.gov.rw"
          target="_blank"
          rel="noreferrer"
          className="text-dark"
        >
          Government of Rwanda
        </a>
      </span>

      <div className="" style={{ fontSize: "10px" }}>
        <a
          className=" my-sm-0  text-uppercase"
          href={recruitmentUrl}
          target="_blank"
        >
          E-Recruitment
        </a>{" "}
        |{" "}
        <a
          className=" my-sm-0  text-uppercase"
          href={selfServiceUrl}
          target="_blank"
        >
          Self-service
        </a>{" "}
        |{" "}
        <Link className=" my-sm-0  text-uppercase" to="#">
          About IPPIS
        </Link>
      </div>
      <SupportButton
        content={
          <>
            <p className="text-left">
              We're glad you're here! <br />
              We're ready to assist you with whatever you need.
              <Divider className="py-2" />
              <div className="mt-2">
                Please{" "}
                <a className="text-blue-100 btn-link" href={`tel:9997`}>
                  call us at:
                  <strong className="px-2">9997</strong>
                </a>{" "}
                or message us at:{" "}
                <a
                  className="text-blue-100 btn-link"
                  href={`https://web.whatsapp.com/send?phone=+250785569363&text=How%20May%20I%20help%20You?`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>0785569363</strong> (via WhatsApp for IPPIS inquiries)
                </a>
              </div>
            </p>
            {(location.pathname.includes("/training/approved-requests") ||
              location.pathname.includes("/training/in-progress-requests") ||
              location.pathname.includes("/training/rejected-requests")) && (
              <p>
                <Divider className="py-1" />
                <span className="text-secondary  mt-2">
                  <span className="text-left" style={{ fontWeight: 800 }}>
                    Training Support Team
                  </span>
                  <div className="mt-2" style={{ fontSize: "12px" }}>
                    Mbabazi Comfort:{" "}
                    <span className="text-primary" style={{ fontWeight: 800 }}>
                      +(250) 788 444 903{" "}
                    </span>{" "}
                    <br />
                    Amerika Camarade:
                    <span className="text-primary" style={{ fontWeight: 800 }}>
                      +(250) 788 545 669{" "}
                    </span>
                  </div>
                </span>
              </p>
            )}
          </>
        }
        title={"Welcome"}
      />
    </footer>
  );
};

export default Footer;
