import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveEmployeeRequest,
  rejectEmployeeRequest,
} from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import WorkCertificateLetter from "../../documents/LetterFormat/WorkCertificateLetter";
import SignLetter from "../../documents/LetterFormat/SignLetter";

const ApproveEmployeeRequestDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    rejectEmployeeRequest,
    selectedRequest,
    approveEmployeeRequest,
    employeeId,
    showRequestAction,
  } = props;
  const [showDraftLetter, setShowDraftLetter] = useState(false);
  const [rejectEmployeeRequestForm, setRejectEmployeeRequestForm] = useState({
    requestId: selectedRequest.id,
    status: 3,
    statusComment: "",
  });

  const [approveExitForm, setApproveExitForm] = useState({
    id: null,
    comment: null,
    password: "",
  });

  const [errors, setErrors] = useState({
    rejectReasonHasError: false,
  });
  const formValidator = () => {
    const error = {
      rejectReasonHasError: false,
      hasError: false,
    };

    if (!rejectEmployeeRequestForm.statusComment) {
      error.rejectReasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onClose = () => {
    setApproveRequestForm(false);
    setShowConfirmationDialog(false);
  };
  const onSave = () => {
    approveEmployeeRequest(approveExitForm, setApproveExitForm, onClose);
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [statusId, setStatusId] = useState("");
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={
          showRequestAction === "Approve" || !!showDraftLetter ? "md" : "sm"
        }
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Employee request</span> /{" "}
            <span className="text-primary">{selectedRequest.title}</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {showRequestAction === "Approve" || !!showDraftLetter ? (
            <>
              {showRequestAction !== "Approve" && (
                <>
                  <Button
                    onClick={() => setShowDraftLetter(false)}
                    variant="outlined"
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>{" "}
                  <br />
                </>
              )}

              <div className="mx-2 w-full">
                {selectedRequest.requestType === "WorkCertificate" && (
                  <WorkCertificateLetter
                    signerNames={selectedRequest.signerNames}
                    signerPosition={selectedRequest.signerPosition}
                    signerEntity={selectedRequest.signerEntity}
                    employeeNames={selectedRequest.employeeNames}
                    employeePosition={selectedRequest.positionName}
                    employmentDate={moment(
                      selectedRequest.employmentDate
                    ).format("Do MMMM YYYY")}
                    employmentEndDate={
                      !!selectedRequest.isToDate
                        ? "date"
                        : moment(selectedRequest.employmentEndDate).format(
                            "Do MMMM YYYY"
                          )
                    }
                    signContent={
                      <table>
                        <tr>
                          <td
                            style={{
                              width: "2%",
                            }}
                          ></td>
                          <td
                            style={{
                              position: "relative",
                              left: "-66px",

                              width: "20%",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            {!!hasPermissions([selectedRequest.permission]) &&
                            selectedRequest.statusId === 1 &&
                            showRequestAction === "Approve" ? (
                              <>
                                <SignLetter
                                  onConfirmSignature={(el) => {
                                    if (!el.password) return false; 
                                    approveEmployeeRequest(
                                      {
                                        password: el.password,
                                        employeePositionId:
                                          selectedRequest.employeePositionId,
                                        statusId: 2,
                                        statusComment:
                                          "Approved by " + !!selectedRequest
                                            ? selectedRequest?.signerNames
                                            : "",
                                        requestId: selectedRequest.id,
                                      },
                                      onClose
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <span className="d-block text-danger">
                                  Not signed
                                </span>
                              </>
                            )}
                          </td>
                          <td
                            style={{
                              width: "20%",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          ></td>
                        </tr>
                      </table>
                    }
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <table>
                <tbody>
                  <tr>
                    <th scope="row">Employee</th>
                    <td>
                      {selectedRequest.lastName +
                        " " +
                        selectedRequest.firstName}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">RequestType</th>
                    <td>
                      <strong className="text-primary">
                        {selectedRequest.requestType}
                      </strong>
                    </td>
                  </tr>
                  {selectedRequest.reason && (
                    <tr>
                      <th scope="row">Reason</th>
                      <td>{selectedRequest.reason}</td>
                    </tr>
                  )}

                  <tr>
                    <th scope="row">EmploymentDate</th>
                    <td>
                      {moment(selectedRequest.employmentDate).format("LL")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Requested on</th>
                    <td>{moment(selectedRequest.createdOn).format("LLL")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Request by</th>
                    <td>{selectedRequest.requestedBy}</td>
                  </tr>
                  {!!selectedRequest.approvedNames && (
                    <tr>
                      <th scope="row">ApprovedBy</th>
                      <td>{selectedRequest.approvedNames}</td>
                    </tr>
                  )}
                  {!!selectedRequest.approvedOn && (
                    <tr>
                      <th scope="row">ApprovedOn</th>
                      <td>{moment(selectedRequest.approvedOn).format("LL")}</td>
                    </tr>
                  )}

                  {!!selectedRequest.rejectedNames && (
                    <tr>
                      <th scope="row">RejectedBy</th>
                      <td>{selectedRequest.rejectedNames}</td>
                    </tr>
                  )}
                  {!!selectedRequest.rejectedOn && (
                    <tr>
                      <th scope="row">RejectedOn</th>
                      <td>{moment(selectedRequest.rejectedOn).format("LL")}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {selectedRequest.statusId === 1 && (
                        <>
                          <span className="badge badge-info">Pending</span>

                          <Button
                            variant="outlined"
                            className="ml-3"
                            onClick={() => setShowDraftLetter(true)}
                            startIcon={<VisibilityIcon />}
                          >
                            View letter
                          </Button>
                        </>
                      )}
                      {selectedRequest.statusId === 2 && (
                        <span className="badge badge-success">Approved</span>
                      )}
                      {selectedRequest.statusId === 3 && (
                        <span className="badge badge-danger">Rejected</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {showRequestAction === "Reject" && (
            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="statusComment"
                  autoFocus
                  label="Why you reject this request?"
                  variant="outlined"
                  placeholder="Why you reject this request?"
                  value={rejectEmployeeRequestForm.statusComment}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const statusComment = e.target.value;
                    setRejectEmployeeRequestForm({
                      ...rejectEmployeeRequestForm,
                      statusComment,
                    });
                    const rejectReasonHasError = false;
                    setErrors({ ...errors, rejectReasonHasError });
                  }}
                />

                {errors.rejectReasonHasError && (
                  <div className="text-danger mt-2">
                    Rejection reason is required{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </DialogContent>
        {selectedRequest.statusId === 1 && hasPermissions(["IS_DAF"]) && (
          <DialogActions>
            <Stack direction="row" spacing={2} className="mx-auto">
              {showRequestAction === "Reject" && (
                <Button
                  variant="contained"
                  color="warning"
                  endIcon={<span className="material-icons mr-1">cancel</span>}
                  onClick={() => {
                    if (!formValidator()) setShowConfirmationDialog(true);
                  }}
                >
                  Reject
                </Button>
              )}
            </Stack>
          </DialogActions>
        )}
      </Dialog>

      {showConfirmationDialog && (
        <ConfirmationDialog
          noPreFormat={true}
          confirmationDialog={showConfirmationDialog}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${statusId === 2 ? "success" : "danger"}`}
              >
                {statusId === 2 ? "approve" : "reject"}
              </strong>{" "}
              the exit request for{" "}
              <strong>
                {selectedRequest.lastName + " " + selectedRequest.firstName}
              </strong>{" "}
              ?
            </>
          }
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            if (statusId === 2)
              approveEmployeeRequest(
                {
                  employeePositionId: selectedRequest.employeePositionId,
                  statusId: 2,
                  statusComment:
                    "Approved by " + !!selectedRequest
                      ? selectedRequest?.signerNames
                      : "",
                  requestId: selectedRequest.id,
                },
                onClose
              );
            else rejectEmployeeRequest(rejectEmployeeRequestForm, onClose);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  approveEmployeeRequest,
  rejectEmployeeRequest,
})(ApproveEmployeeRequestDialog);
