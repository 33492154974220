import React from "react";

const InstitutionDetails = ({ headOfInstitution, userSignature }) => {
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td style={{ verticalAlign: "middle" }}>
           
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    { userSignature && headOfInstitution?.isDelegated && (
                      <td style={{ width: "2%", verticalAlign: "middle" }}>
                        For
                      </td>
                    )}
                    <td style={{ width: "28%" }}>
                      {userSignature? <img
                        src={`data:image/jpg;base64,${userSignature.signature}`}
                        alt="User Signature"
                        style={{
                          width: "190px",
                          height: "auto",
                          position: "relative",
                          marginLeft: "-48px",
                        }}
                      />:<><span className="d-block text-danger">
                      Not signed
                    </span></>}
                     
                    </td>
                    <td style={{ width: "70%", verticalAlign: "middle" }}>
                      <div style={{ textAlign: "left" }}></div>
                    </td>
                  </tr>
                </tbody>
              </table>
           
          </td>
          <td></td>
        </tr>
        <tr>
          <td colSpan="2">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "70%", verticalAlign: "top" }}>
                    <p className="mt-4 py-0"
                      style={{
                        fontSize: "13px",
                        fontWeight: 800,
                        fontFamily: "Times New Roman",
                      }}
                    >
                      {headOfInstitution?.firstName || ""}{" "}
                      {headOfInstitution?.lastName || ""}
                    </p>
                    {headOfInstitution?.isMinster ? (
                      <>
                        <p className="mb-3"
                          style={{
                            fontSize: "15px",
                            fontWeight: 800,
                            position: "relative",
                            fontFamily: "Times New Roman",
                          }}
                        >
                          {headOfInstitution?.positionName || ""}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="mt-4 py-0" style={{ fontSize: "15px" }}>
                          {headOfInstitution?.positionName}
                        </p>
                        <p className="mb-3"
                          style={{
                            fontSize: "15px",
                            fontWeight: 800,
                            position: "relative",
                            fontFamily: "Times New Roman",
                          }}
                        >
                            {headOfInstitution?.institutionName || ""}
                        </p>
                      </>
                    )}
                  </td>
                  <td
                    className="emblem"
                    style={{ width: "30%", verticalAlign: "top" }}
                  >
                    <div
                      style={{
                        width: "90px",
                        height: "90px",
                        float: "right",
                        marginRight: "52px",
                        marginTop: "-35px",
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InstitutionDetails;
