export const defaultState = {
  exitRequests: [],
  myRequests: [],
  approvedRequests: [],
  exitTypes: [],
  initiators: [],
  blacklistReasons: [],
  searchEmployee: null,
  request: null,
  rejectRequest: null,
  approveRequest: null,
  blacklists: [],
  rehabilitationRequests: [],
  approveRehabilitations: [],
  hrExitTypes: [],
  employeeExitRequest: null,
  exitRequestAttachments:[]
};
