import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ExitRequest from "./pages/ExitRequest";
import BlackList from "./pages/BlackList";
import Rehabilitation from "./pages/Rehabilitation";
import ExitType from "./pages/ExitType";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";
import PrivateRoute from "../common/guard/PrivateRoute";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import History from "./pages/History";

const defaultRouters = [
  {
    to: "/exit/exit-requests",
    permissions: ["IS_HR", "IS_CBM","IS_HEAD_OF_INSTITUTION","IS_DOCUMENT_VERIFIER"],
  },
  {
    to: "/exit/blacklist",
    permissions: ["IS_BLACKLIST_MANAGER"],
  },
];

const Layout = () => {
  const { hasPermissions } = useRequiredAuth();

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions)) {
        return router.to;
      }
    }

    return "/unauthorized";
  };

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/exit/dashboard" component={Dashboard} />
        <PrivateRoute
          children={<ExitRequest />}
          permissions={["IS_HR", "IS_CBM","IS_HEAD_OF_INSTITUTION","IS_DOCUMENT_VERIFIER"]}
          path="/exit/exit-requests"
        />
          <PrivateRoute
          children={<History />}
          permissions={["IS_HR", "IS_CBM","IS_HEAD_OF_INSTITUTION","IS_DOCUMENT_VERIFIER"]}
          path="/exit/history"
        />
        <Route path="/exit/user-guide" component={UserGuide} />
        <PrivateRoute
          children={<BlackList />}
          permissions={["IS_BLACKLIST_MANAGER"]}
          path="/exit/blacklist"
        />
        <PrivateRoute
          children={<Rehabilitation />}
          permissions={["IS_BLACKLIST_MANAGER"]}
          path="/exit/rehabilitations"
        />
        <Route
          children={() => <Redirect to={renderDefaultRoute()} />}
          path="/exit"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
