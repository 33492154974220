import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { blue, red } from "@mui/material/colors";
import { getPSRPUserToken, selectModule } from "../../../store/common/actions";
import { useRequiredAuth } from "../guard/requiredAuth";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const ModulesMenu = (props) => {
  const { selectModule, user, ippisModules, selectedModule, getPSRPUserToken } =
    props;
  const { hasPermissions } = useRequiredAuth();
  const history = useHistory();
  const location = useLocation();
  const [isPSRPNeedRedirect, setIsPSRPNeedRedirect] = useState(false);
  const [chooseRole, setChooseRole] = useState(false);
  const [selectedPSRPToken, setSelectedPSRPToken] = useState(null);
  const handlePSRPRedirect = (role, token) => {
    return window.open(
      `https://services.mifotra.gov.rw/ssauth/${role}/${token}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <div className="d-flex modules-menu  align-items-center flex-wrap ">
        {chooseRole && (
          <ConfirmationDialog
            canRemoveDialogAction={true}
            confirmationDialog={chooseRole}
            size="xl"
            message={
              <>
                <p>
                  <div className="card bg-white mb-2 mt-4 border-0">
                    <div className="card-body px-1 py-0 text-center">
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              const token = selectedPSRPToken;
                              setSelectedPSRPToken(null);
                              setIsPSRPNeedRedirect(false);
                              setChooseRole(false);
                              handlePSRPRedirect("admin", token);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: blue[500] }}>
                                {/* <EmailRoundedIcon /> */}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Login as Admin" />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              const token = selectedPSRPToken;
                              setSelectedPSRPToken(null);
                              setIsPSRPNeedRedirect(false);
                              setChooseRole(false);
                              handlePSRPRedirect("user", token);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: blue[500] }}>
                                {/* <EmailRoundedIcon /> */}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Login as User" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </p>
              </>
            }
            setConfirmationDialog={setChooseRole}
            onYes={() => {}}
          />
        )}

        {ippisModules?.map((mod, index) => (
          <React.Fragment key={index}>
            {(user?.selectedEntitySector?.permissions[mod.permit] ||
              user?.selectedEntitySector?.permissions.IS_ADMIN) &&
              mod.isActive && (
                <>
                  {(mod.id !== "TRAINING" ||
                    (!!user.selectedEntitySector.canUseTrainingModule &&
                      mod.id === "TRAINING") ||
                    (user.selectedEntitySector.sectorId === 2 &&
                      mod.id === "TRAINING")) &&
                    (mod.id !== "RECRUITMENT" ||
                      (!!user?.canAccessRecruitment &&
                        mod.id === "RECRUITMENT")) && (
                      <button
                        className={`mr-1 py-0 btn btn-link btn-sm  ${
                          location.pathname.startsWith(`${mod?.to}`)
                            ? " active  "
                            : " "
                        }`}
                        onClick={() => selectModule(mod, history)}
                      >
                        <span className="d-inline-flex align-items-center">
                          <img
                            src={require(`../../assets/icons/${mod.icon}`)}
                            width="24"
                            alt=""
                            className="mr-1"
                          />
                          {mod.shortName}
                        </span>
                      </button>
                    )}
                </>
              )}
          </React.Fragment>
        ))}
        <button
          disabled={isPSRPNeedRedirect}
          className={`mr-1 py-0 btn btn-link btn-sm   ${
            isPSRPNeedRedirect ? " active  " : " "
          }`}
          onClick={() => {
            getPSRPUserToken((isLoading, data) => {
              setIsPSRPNeedRedirect(isLoading);
              if (!!data) {
                if (!!data.isPSRPAdmin) {
                  setSelectedPSRPToken(data.token);
                  setChooseRole(true);
                } else handlePSRPRedirect("user", data.token);
              }
            });
          }}
        >
          <span className="d-inline-flex align-items-center py-0">
            <span className="material-icons ">launch</span>
            {isPSRPNeedRedirect ? "Redirect..." : "PSRP Portal"}
          </span>
        </button>
        {hasPermissions(["IS_ADMIN"]) && (
          <button
            className={`mr-1 py-0 btn btn-link btn-sm  ${
              location.pathname.includes("/admin") ? " active  " : " "
            }`}
            onClick={() => {
              selectModule(
                {
                  ...selectedModule,
                  shortName: "Administration",
                  fullName: "System Administration",
                  to: "/admin",
                },
                history
              );
              history.push("/admin");
            }}
          >
            <span className="d-inline-flex align-items-center py-0">
              <span className="material-icons ">security</span>
              Admin
            </span>
          </button>
        )}

        {hasPermissions(["IS_SUPPORTER", "IS_ADMIN"]) && (
          <button
            className={`mr-1 py-0 btn btn-link btn-sm  ${
              location.pathname.includes("/support") ? " active  " : " "
            }`}
            onClick={() => {
              selectModule(
                {
                  ...selectedModule,
                  shortName: "Support",
                  fullName: "System Support",
                  to: "/support",
                },
                history
              );

              history.push("/support");
            }}
          >
            <span className="d-inline-flex align-items-center py-0">
              <span className="material-icons ">help</span>
              Support
            </span>
          </button>
        )}

        <button
          className={`mr-1 py-0 btn btn-link btn-sm   ${
            location.pathname.includes("/user/profile") ? " active  " : " "
          }`}
          onClick={() => {
            selectModule(
              {
                ...selectedModule,
                shortName: "My Profile",
                fullName: "My Profile",
                to: "/user/profile",
              },
              history
            );

            history.push("/user/profile");
          }}
        >
          <span className="d-inline-flex align-items-center py-0">
            <span className="material-icons ">account_circle</span>
            My Profile
          </span>
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, ippisModules, selectedModule }) => {
  return {
    user,
    ippisModules,
    selectedModule,
  };
};
export default connect(mapStateToProps, { selectModule, getPSRPUserToken })(
  ModulesMenu
);
