import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  LinearProgress,
  ListItem,
  ListItemText,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getNonAddedPositionCompetencies,
  savePositionCompetency,
} from "../../../store/structure/actions";
import { showError } from "../../toastify";

const AddPositionCompetencyDialog = (props) => {
  const {
    loading,
    onWait,
    nonAddedPositionCompetencies,
    getNonAddedPositionCompetencies,
    savePositionCompetency,

    positionId,
    showCompetencyForm,
    setShowCompetencyForm,
    positionCompetencies,
  } = props;

  const [formData, setFormData] = useState({
    competencyId: null,
    positionId: positionId,
    added: true,
  });
  const [competency, setCompetency] = useState(null);

  const [errors, setErrors] = useState({
    competencyHasError: false,
  });

  useEffect(() => {
    // if (!nonAddedPositionCompetencies.length)
    getNonAddedPositionCompetencies(positionId);

    // Cleanup function to cancel the asynchronous task if the component is unmounted
    return () => {
      // Cancel or clean up any asynchronous tasks here
      // For example, if you're using Axios for API calls, you can cancel the request
      // axiosCancelToken.cancel();
    };
  }, [positionId]);

  const formValidator = () => {
    const error = {
      competencyHasError: false,
      hasError: false,
    };

    if (!competency) {
      error.competencyHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      savePositionCompetency(formData, competency, onClose);
    }
  };

  const onClose = () => {
    setFormData({
      competencyId: null,
      positionId: positionId,
      added: true,
    });
    setShowCompetencyForm(false);
    setCompetency(null);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCompetencyForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Add New Position Competency
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        {onWait && !loading && <LinearProgress />}

        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12">
                <Autocomplete
                  size="small"
                  className="mt-3"
                  id="competency"
                  defaultValue={null}
                  value={competency || null}
                  options={nonAddedPositionCompetencies}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, c) => {
                    setCompetency(c);
                    const competencyId = c?.id || null;
                    setFormData({ ...formData, competencyId });
                  }}
                  getOptionLabel={(option) =>
                    `${option.id} - ${option.competencyName}`
                  }
                  renderOption={(props, competency) => (
                    // <Box component="li" {...props}>
                    //   {competency.competencyName}
                    // </Box>

                    <ListItem dense {...props}>
                      <ListItemText
                        primary={`${competency.id} - ${competency.competencyName}`}
                        secondary={
                          <>
                            {!!competency.psyDomainName && (
                              <small className="text-primary">
                                <i className="fas fa-chevron-right mr-1"></i>
                                <>{competency.psyDomainName}</>
                                {" / "}
                                {competency.psySubDomainName}
                              </small>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Competency"
                      {...params}
                      label="Select Competency"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.competencyHasError && (
                  <div className="text-danger mb-2">
                    Competency is required{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={onWait || loading}
          >
            {onWait ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  nonAddedPositionCompetencies,
  positionCompetencies,
}) => {
  return {
    loading,
    onWait,
    nonAddedPositionCompetencies,
    positionCompetencies,
  };
};
export default connect(mapStateToProps, {
  getNonAddedPositionCompetencies,
  savePositionCompetency,
})(AddPositionCompetencyDialog);
