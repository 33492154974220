import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Alert,
  AlertTitle,
  Checkbox,
  Autocomplete,
  Input,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveResponseOfNPSCReport,
  rejectNPSCReport,
  verifyResponseOfNPSCReport,
  getNPSCReport,
} from "../../../store/recruitment/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import SubmissionRecruitmentReportLetter from "../../documents/LetterFormat/SubmissionRecruitmentReportLetter";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SubmitReportDialog from "./SubmitReportDialog";
import PdfViewer from "../../common/components/PdfViewer";
import RespondRecruitmentReportLetter from "../../documents/LetterFormat/RespondRecruitmentReportLetter";
import { removeDuplicates } from "../../common/components/Utils";
import ModifyNPSCReportRespondingLetterDialog from "./ModifyNPSCReportRespondingLetterDialog";
const ApproveNPSCRespondLetterDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    approveResponseOfNPSCReport,
    rejectNPSCReport,
    verifyResponseOfNPSCReport,
    npscReport,
    getRequestDocumentSignerDetails,
    requestDocumentSignerDetail,
    getNPSCReport,
    onCloseReport,
    entities,
    getEntities,
  } = props;
  const { hasPermissions } = useRequiredAuth();

  const [showSubmissionReport, setShowSubmissionReport] = useState(false);

  const [npscReportDetail, setNPSCReportDetail] = useState(
    npscReport?.details || null
  );

  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  const [formData, setFormData] = useState({
    requestId: npscReportDetail ? npscReportDetail.id : "",
    advertisementId: npscReportDetail.advertisementId,
    npscDocLetterNo: npscReportDetail ? npscReportDetail.npscDocLetterNo : "",
    npscDocContent:
      npscReportDetail && npscReportDetail.npscDocContent
        ? npscReportDetail.npscDocContent
        : "",
    npscCCAddress: null,
  });
  // After assessing your report, the National Public Service Commission would like to inform you that it has been approved and advised you to hold Mr/Ms ...... appointment because he has applied before his graduation; therefore he had no required degree. You will be  communicated in due time the date of post-recruitment to ensure compliance with the above-mentioned Law.
  const [ccData, setCCData] = useState(
    npscReportDetail && npscReportDetail.npscCCAddress
      ? JSON.parse(npscReportDetail.npscCCAddress)
      : []
  );
  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      docContentHasError: false,
      maxCharHasError: false,
    };
    if (!formData.npscDocLetterNo) {
      error.docLetterNoError = true;
      error.hasError = true;
    }
    if (!formData.npscDocContent) {
      error.docContentHasError = true;
      error.hasError = true;
    }

    // if (!ccData.length) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };
  const [maxWidth, setMaxWidth] = useState("lg");

  const [showRejectRequestForm, setShowRejectRequestForm] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [showVerifyRequestForm, setShowVerifyRequestForm] = useState(false);
  const [reason, setReason] = useState("");
  const [pkiPassword, setPkiPassword] = useState("");
  const onClose = () => {
    setApproveRequestForm(false);
    setShowRejectRequestForm(false);
    setShowApproveRequestForm(false);
    setReason("");
    setPkiPassword("");
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Response of Recruitment Report</span> /{" "}
            <span className="text-primary mr-2">
              {npscReportDetail?.positionName || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            {[4].includes(+npscReportDetail?.statusId) && (
              <Button
                variant="outlined"
                color="info"
                size="small"
                disabled={loading}
                onClick={() => {
                  getNPSCReport(
                    npscReportDetail.id,
                    setShowSubmissionReport,
                    () => {}
                  );
                }}
                className="m-2 text-capitalized float-right"
              >
                Edit Letter
              </Button>
            )}
            {!!npscReportDetail?.npscDocReferenceId ? (
              <>
                <PdfViewer
                  url={
                    process.env.REACT_APP_FILES_URL +
                    "/preview/" +
                    npscReportDetail?.npscDocReferenceId
                  }
                />
              </>
            ) : (
              <>
                <RespondRecruitmentReportLetter
                  enableToFillLetter={[3].includes(+npscReportDetail?.statusId)}
                  docContent={npscReportDetail.npscDocContent}
                  docLetterNo={npscReportDetail.npscDocLetterNo}
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            position: "relative",

                            width: "20%",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                          +npscReportDetail?.statusId === 4 ? (
                            <>
                              <SignLetter />
                            </>
                          ) : (
                            <>
                              <span className="d-block text-danger">
                                Not signed
                              </span>
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  }
                  institutionName={
                    npscReportDetail.signerEntity.toUpperCase() || ""
                  }
                  competentAuthorityName={npscReportDetail.signerNames}
                  competentAuthorityPosition={npscReportDetail.signerPosition}
                  ccData={ccData}
                  headOfInstitutionPosition={
                    npscReportDetail.headOfInstitutionPosition2 === "Minister"
                      ? npscReportDetail.headOfInstitutionPosition
                      : npscReportDetail.headOfInstitutionPosition2
                  }
                  headOfInstitutionEntity={
                    npscReportDetail.headOfInstitutionPosition2 === "Minister"
                      ? ""
                      : npscReportDetail.headOfInstitutionEntity
                  }
                  dear={npscReportDetail.headOfInstitutionPosition2}
                  referenceLetterId={npscReportDetail.docLetterNo}
                  employeePositionName={npscReportDetail.positionName}
                  docLetterNoInput={
                    <>
                      <Input
                        autoFocus
                        error={!!errors.docLetterNoError}
                        value={formData.npscDocLetterNo}
                        style={{ width: "150px" }}
                        inputProps={{
                          "aria-label": "add doc No",
                        }}
                        onChange={(e) => {
                          const npscDocLetterNo = e.target.value;
                          setFormData({
                            ...formData,
                            npscDocLetterNo,
                          });

                          setErrors({
                            ...errors,
                            docLetterNoError: false,
                          });
                        }}
                      />
                    </>
                  }
                  docContentTextEditor={
                    <>
                      <TextField
                        fullWidth
                        size="small"
                        multiline
                        error={!!errors.docContentHasError}
                        rows={4}
                        name="npscDocContent"
                        maxLength={500}
                        label="Doc. Content"
                        variant="outlined"
                        placeholder="Doc. Content"
                        value={formData.npscDocContent}
                        onChange={(e) => {
                          const npscDocContent = e.target.value;
                          setFormData({
                            ...formData,
                            npscDocContent,
                          });

                          setErrors({
                            ...errors,
                            docContentHasError: false,
                          });
                        }}
                      />
                      {errors.docContentHasError && (
                        <small className="text-danger mb-1">
                          This field is required
                        </small>
                      )}
                    </>
                  }
                  ccSelectForm={
                    <>
                      <Autocomplete
                        size="small"
                        fullWidth
                        multiple
                        id="ccData"
                        options={entities.map(
                          ({ name, institutionEmail, id }) => ({
                            name,
                            institutionEmail,
                            id,
                          })
                        )}
                        value={ccData}
                        onChange={(e, cc) => {
                          const uniqueCc = removeDuplicates(cc);
                          setCCData(uniqueCc);
                          const ccHasError = false;
                          setErrors({ ...errors, ccHasError });
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={
                                selected ||
                                !!ccData.find(({ id }) => id === option.id)
                              }
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Select the institution to copy"}
                            placeholder={"Select the institution to copy"}
                          />
                        )}
                      />

                      {errors.ccHasError && (
                        <small className="text-danger mb-1">
                          Please, select the institution to copy
                        </small>
                      )}
                    </>
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>

        {!!hasPermissions(["IS_NPSC"]) && +npscReportDetail?.statusId === 3 && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={loading}
                onClick={() => {
                  if (!formValidator()) {
                    setShowVerifyRequestForm(true);
                  }
                }}
                className="btn btn-xs"
              >
                Verify
              </Button>
              <Button
                variant="contained"
                color="error"
                type="button"
                disabled={loading}
                onClick={() => {
                  setShowRejectRequestForm(true);
                }}
                className="btn btn-xs"
              >
                Reject
              </Button>
            </DialogActions>
          </>
        )}

        {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
          +npscReportDetail?.statusId === 4 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowApproveRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Sign
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowRejectRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Reject
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      {showApproveRequestForm && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>
              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            if (!npscReportDetail.npscDocLetterNo) {
              showError("Document No is required");
              return false;
            }
            if (!npscReportDetail.npscDocContent) {
              showError("Document Content is required");
              return false;
            }
            approveResponseOfNPSCReport(
              { requestId: npscReportDetail.id, password: pkiPassword },
              onClose,
              onCloseReport
            );
          }}
        />
      )}

      {showRejectRequestForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this request?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequestForm}
          onYes={() => {
            rejectNPSCReport(
              {
                requestId: npscReportDetail.id,
                reason: reason,
                statusId:6
              },
              onClose,
              onCloseReport
            );
          }}
        />
      )}
      {showSubmissionReport && (
        <ModifyNPSCReportRespondingLetterDialog
          showSubmissionReport={showSubmissionReport}
          setShowSubmissionReport={setShowSubmissionReport}
          advertisement={npscReportDetail}
          onCloseSubmitReport={onClose}
        />
      )}

      {showVerifyRequestForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showVerifyRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-primary">confirm</strong> this request?
              </p>
              <form>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="Reason"
                  label="Type your reason .."
                  variant="outlined"
                  placeholder="Type your reason  .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowVerifyRequestForm}
          onYes={() => {
            formData.npscCCAddress = JSON.stringify(ccData);
            verifyResponseOfNPSCReport(
              {
                requestId: npscReportDetail.id,
                reason: reason,
                ...formData,
              },
              onClose,
              onCloseReport
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  npscReport,
  requestDocumentSignerDetail,
  entities,
}) => {
  return {
    loading,
    npscReport,
    requestDocumentSignerDetail,
    entities,
  };
};
export default connect(mapStateToProps, {
  approveResponseOfNPSCReport,
  rejectNPSCReport,
  verifyResponseOfNPSCReport,
  getRequestDocumentSignerDetails,
  getNPSCReport,
  getEntities,
})(ApproveNPSCRespondLetterDialog);
