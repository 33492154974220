import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Autocomplete,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  saveContract,
  approveContract,
} from "../../../../store/contractuals/actions";
import { showError } from "../../../toastify";
import { useEffect } from "react";
import axios from "axios";
import defaultPofile from "../../../assets/default-profile.jpg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import NumberFormat from "react-number-format";
import { currencyFormat } from "../../../common/components/Utils";
import PaymentsIcon from "@mui/icons-material/Payments";
import { isEmpty } from "lodash";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import moment from "moment";
import { de } from "date-fns/locale";

const EmployeeContract = (props) => {
  const {
    loading,
    user,
    defaultData,
    showContractDialog,
    setShowContractDialog,
    saveContract,
    position,
    employeeId,
    selectedEmployeeProfilePicture,
    currencies,
    medicalInsuranceTypes,
    selectedContract,
    approveContract,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    disabled = false,
  } = props;

  const [currency, setCurrency] = useState(null);
  const [file, setFile] = useState(null);
  const [editFile, setEditFile] = useState(false);
  const [medicalInsuranceType, setMedicalInsuranceType] = useState({});
  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);
  const [costCenter, setCostCenter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [defaultSalarySettings, setDefaultSalarySettings] = useState({
    basePay: defaultData?.basePay || 0,
    hasHousingAllowance: false,
    housingAmount: 0,
    housingCalculationMethod: null,
    hasTransportAllowance: false,
    transportAmount: 0,
    transportCalculationMethod: null,
    lumpsumAmount: 0,
    lumpsumCalculationMethod: null,
    otherAmount: 0,
    otherCalculationMethod: null,
    fieldId: null,
    fieldName: null,
  });

  const getDefaultSalarySettings = async (employeePositionId) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/contractuals/position-allowance/${employeePositionId}`
      );
      setDefaultSalarySettings({ basePay: defaultData?.basePay || 0, ...data });
      setIsLoading(false);
    } catch (error) {
      showError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (defaultData?.basePay !== undefined) {
      getDefaultSalarySettings(defaultData?.employeePositionId);
    } else setIsLoading(false);
  }, [defaultData]);

  useEffect(() => {
    setCurrency(
      !selectedContract.id
        ? currencies.find((currency) => currency?.id === "RWF")
        : currencies.find(
            (currency) => currency?.id === selectedContract.currencyId
          )
    );

    setMedicalInsuranceType(
      medicalInsuranceTypes.find(
        (medicalInsuranceType) =>
          medicalInsuranceType.id === selectedContract.medicalInsuranceTypeId
      )
    );
  }, []);

  const [formData, setFormData] = useState({
    basePay: 0,
    housingAllowance: 0,
    transportAllowance: 0,
    taxableAllowance: 0,
    lumpsumAllowance: 0,
    hasHousingAllowance: false,
    hasTransportAllowance: false,
    fromDate: null,
    toDate: null,
  });

  const [errors, setErrors] = useState({
    currencyHasError: false,
    amountHasError: false,
    fileHasError: false,
    fileError: "",
    sourceOfFundHasError: false,
    costCenterHasError: false,
    payrollGroupHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      currencyHasError: false,
      amountHasError: false,
      fileHasError: false,
      fileError: "",
      sourceOfFundHasError: false,
      costCenterHasError: false,
      payrollGroupHasError: false,
      hasError: false,
    };

    if (!currency) {
      error.currencyHasError = true;
      error.hasError = true;
    }

    if (
      +formData.basePay +
        +formData.housingAllowance +
        +formData.transportAllowance +
        +formData.taxableAllowance +
        +formData.lumpsumAllowance <
      1
    ) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!formData.fromDate) {
      error.fromDateHasError = true;
      error.hasError = true;
    }

    if (!formData.toDate) {
      error.toDateHasError = true;
      error.hasError = true;
    }

    if (!!formData.fromDate && new Date(formData.fromDate) > new Date()) {
      error.fromDateHasError = true;
      error.fromDateError = "Future date is not allowed";
      error.hasError = true;
    }

    if (
      !!formData.toDate &&
      new Date(formData.toDate) <
        new Date(formData.fromDate).setDate(
          new Date(formData.fromDate).getDate() + 1
        )
    ) {
      error.toDateHasError = true;
      error.toDateError = "Invalid Date";
      error.hasError = true;
    }

    // if ((!file && !selectedContract?.url) || (!file && editFile)) {
    //   error.fileHasError = true;
    //   error.hasError = true;
    // }

    if (!costCenter) {
      error.costCenterHasError = true;
      error.hasError = true;
    }

    if (!payrollGroup) {
      error.payrollGroupHasError = true;
      error.hasError = true;
    }

    if (!sourceOfFund) {
      error.sourceOfFundHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const getGrossSalary = () =>
    +(+formData.basePay * (currency?.rate || 1)).toFixed(0) +
    +(+formData.housingAllowance * (currency?.rate || 1)).toFixed(0) +
    +(+formData.transportAllowance * (currency?.rate || 1)).toFixed(0) +
    +(+formData.taxableAllowance * (currency?.rate || 1)).toFixed(0);

  const getTprLumpSum = () =>
    +formData.lumpsumAllowance * (currency?.rate || 1) * 0.3;

  const getNetLumpSum = () =>
    (
      (+formData.lumpsumAllowance * (currency?.rate || 1) -
        +formData.lumpsumAllowance * (currency?.rate || 1) * 0.3) /
      (currency?.rate || 1)
    ).toFixed(0);

  const getPansionEmployee = () =>
    !!selectedContract?.payCSR
      ? (+formData.basePay +
          +formData.housingAllowance +
          +formData.taxableAllowance +
          +formData.transportAllowance +
          +formData.lumpsumAllowance) *
        (currency?.rate || 1) *
        0.06
      : 0;

  const getPansionEmployer = () =>
    !!selectedContract?.payCSR
      ? Math.round(
          (+formData.basePay +
            +formData.housingAllowance +
            +formData.taxableAllowance +
            +formData.transportAllowance +
            +formData.lumpsumAllowance) *
            (currency?.rate || 1) *
            0.06
        ) +
        Math.round(
          (+formData.basePay +
            +formData.housingAllowance +
            +formData.taxableAllowance) *
            (currency?.rate || 1) *
            0.02
        )
      : 0;

  const getMaternityEmployee = () =>
    !!selectedContract?.payMaternity
      ? (+formData.basePay +
          +formData.housingAllowance +
          +formData.taxableAllowance) *
        (currency?.rate || 1) *
        0.003
      : 0;
  const getMaternityEmployer = () =>
    !!selectedContract?.payMaternity
      ? (+formData.basePay +
          +formData.housingAllowance +
          +formData.taxableAllowance) *
        (currency?.rate || 1) *
        0.003
      : 0;

  const getMedicalEmployee = () =>
    !!selectedContract?.payMedical
      ? +formData.basePay *
        (currency?.rate || 1) *
        (medicalInsuranceType?.employeeRate || 0)
      : 0;
  const getMedicalEmployer = () =>
    !!selectedContract?.payMedical
      ? +formData.basePay *
        (currency?.rate || 1) *
        (medicalInsuranceType?.employerRate || 0)
      : 0;

  const getTPR = () => {
    if (getGrossSalary() <= 60000 || !selectedContract?.payTPR) return 0;
    else if (getGrossSalary() > 60000 && getGrossSalary() <= 100000)
      return (getGrossSalary() - 60000) * 0.1;
    else if (getGrossSalary() > 100000 && getGrossSalary() <= 200000)
      return (getGrossSalary() - 100000) * 0.2 + 4000;
    else return (getGrossSalary() - 200000) * 0.3 + 24000;
  };

  const getMutuelle = () =>
    !!selectedContract?.payCBHI
      ? (getGrossSalary() -
          (getPansionEmployee() +
            getMedicalEmployee() +
            getMaternityEmployee() +
            getTPR())) *
        parseFloat(user?.setting?.CBHI_RATE || 0.005)
      : 0;

  const getPartialNet = () =>
    (
      (+getGrossSalary() - +getTotalEmployeeWithholds()) /
      (currency?.rate || 1)
    ).toFixed(0);

  const getTotalEmployeeWithholds = () =>
    +getTPR().toFixed(0) +
    +getPansionEmployee().toFixed(0) +
    +getMedicalEmployee().toFixed(0) +
    +getMaternityEmployee().toFixed(0) +
    +getMutuelle().toFixed(0);

  const getTotalPatronalContribution = () =>
    +getPansionEmployer().toFixed(0) +
    +getMaternityEmployer().toFixed(0) +
    +getMedicalEmployer().toFixed(0);

  const onSave = () => {
    //
    if (!formValidator()) {
      const tempFormData = new FormData();
      tempFormData.append("id", selectedContract?.id || "");
      tempFormData.append(
        "employeePositionId",
        selectedContract.employeePositionId
      );
      tempFormData.append(
        "startOn",
        moment(formData.fromDate).format("YYYY-MM-DD")
      );
      tempFormData.append(
        "endOn",
        moment(formData.toDate).format("YYYY-MM-DD")
      );
      tempFormData.append("basePay", +formData.basePay);
      tempFormData.append("housingAllowance", +formData.housingAllowance);
      tempFormData.append("transportAllowance", +formData.transportAllowance);
      tempFormData.append("otherAllowance", +formData.taxableAllowance);
      tempFormData.append("lumpsum", +formData.lumpsumAllowance);
      tempFormData.append("hasHousingAllowance", +formData.hasHousingAllowance);
      tempFormData.append(
        "hasTransportAllowance",
        +formData.hasTransportAllowance
      );
      tempFormData.append("costCenterId", costCenter?.id);
      tempFormData.append("payrollGroupId", payrollGroup?.id);
      tempFormData.append("sourceOfFundId", sourceOfFund?.id);
      tempFormData.append("currencyId", currency.id);
      tempFormData.append("rate", +currency.rate);
      tempFormData.append("file", file);
      tempFormData.append(
        "editFile",
        (!!selectedContract?.url && editFile) || !!file || ""
      );

      saveContract(tempFormData, selectedContract?.id || "");
    }
  };

  const isSPIUContract = () => defaultSalarySettings?.fieldId === 20;

  const getLumpsum = (type, isSPIU = false) => {
    const basePay = isSPIU
      ? defaultSalarySettings?.basePay || 0
      : +(selectedContract.basePay || defaultSalarySettings?.basePay || 0);
    if (type === "transport") {
      if (/*!isSPIU &&*/ selectedContract.transportAllowance) {
        return selectedContract.transportAllowance; //.toFixed(0);
      }
      // if (
      //   (isSPIU || !selectedContract.transportAllowance) &&
      //   defaultSalarySettings?.transportAmount &&
      //   defaultSalarySettings.hasTransportAllowance
      // ) {
      //   return (
      //     defaultSalarySettings?.transportAmount *
      //     (defaultSalarySettings?.transportCalculationMethod === 2
      //       ? basePay
      //       : 1)
      //   ).toFixed(0);
      // }
      if (
        /*isSPIU || */ !selectedContract.transportAllowance &&
        !!!defaultSalarySettings?.transportAmount &&
        defaultSalarySettings.hasTransportAllowance
      ) {
        return (
          ((basePay * 1.0) / 6.4)
            // parseFloat(user?.setting?.TRANSPORT_RATE || 6.4)
            .toFixed(0)
        );
      }
      return defaultSalarySettings?.transportAmount; //.toFixed(0);
    }

    if (type === "housing") {
      if (/*!isSPIU &&*/ selectedContract.housingAllowance) {
        return selectedContract.housingAllowance; //.toFixed(0);
      }
      // if (
      //   (isSPIU || !selectedContract.housingAllowance) &&
      //   defaultSalarySettings?.housingAmount &&
      //   defaultSalarySettings.hasHousingAllowance
      // ) {
      //   return (
      //     defaultSalarySettings?.housingAmount *
      //     (defaultSalarySettings?.housingCalculationMethod === 2 ? basePay : 1)
      //   ); //.toFixed(0);
      // }
      if (
        /*isSPIU ||*/ !selectedContract.housingAllowance &&
        !!!defaultSalarySettings?.housingAmount &&
        defaultSalarySettings.hasHousingAllowance
      ) {
        return ((basePay * 1.0) / 5.0).toFixed(0); //parseFloat(user?.setting?.HOUSING_RATE || 5.0); //.toFixed(0);
      }
      return defaultSalarySettings?.housingAmount; //.toFixed(0);
    }

    return 0;
  };

  useEffect(() => {
    if (!isEmpty(selectedContract)) {
      if (isSPIUContract()) {
        setFormData({
          basePay: defaultSalarySettings?.basePay || 0,
          housingAllowance: getLumpsum("housing", true),
          transportAllowance: getLumpsum("transport", true),
          taxableAllowance: defaultSalarySettings?.otherAmount || 0,
          lumpsumAllowance: defaultSalarySettings?.lumpsumAmount || 0,
          fromDate: selectedContract.startOn,
          toDate: selectedContract.endOn,
          hasHousingAllowance:
            defaultSalarySettings?.hasHousingAllowance || false,
          hasTransportAllowance:
            defaultSalarySettings?.hasTransportAllowance || false,
        });
      } else {
        setFormData({
          basePay:
            selectedContract.basePay || defaultSalarySettings?.basePay || 0,
          housingAllowance: getLumpsum("housing"),
          transportAllowance: getLumpsum("transport"),
          taxableAllowance:
            selectedContract.otherAllowance ||
            defaultSalarySettings?.otherAmount ||
            0,
          lumpsumAllowance:
            selectedContract.lumpsum ||
            defaultSalarySettings?.lumpsumAmount ||
            0,
          fromDate: selectedContract.startOn,
          toDate: selectedContract.endOn,
          hasHousingAllowance:
            selectedContract.hasHousingAllowance ||
            defaultSalarySettings?.hasHousingAllowance ||
            false,
          hasTransportAllowance:
            selectedContract.hasTransportAllowance ||
            defaultSalarySettings?.hasTransportAllowance ||
            false,
        });
      }

      if (!!selectedContract.url) setEditFile(false);

      setSourceOfFund(
        sourceOfFunds.find(
          ({ id }) => id === selectedContract.sourceOfFundId
        ) || null
      );
      setCostCenter(
        costCenters.find(({ id }) => id === selectedContract.costCenterId) ||
          null
      );
      setPayrollGroup(
        payrollGroups.find(
          ({ id, employeeGroupId }) =>
            id === selectedContract.payrollGroupId &&
            employeeGroupId === selectedContract.employeeGroupId
        ) || null
      );
    }
  }, [selectedContract, defaultSalarySettings]);

  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    link.click();
  }

  const [confirmApproveContract, setConfirmApproveContract] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const isExpired = () => selectedContract.status === "Expired";

  const isFormDisabled = () =>
    selectedContract.isApproved && !selectedContract.enableEdit; //||
  // selectedContract.status === "Expired" ||
  // !isHR();

  const isHR = () =>
    user.selectedEntitySector.permissions.IS_ADMIN ||
    user.selectedEntitySector.permissions.IS_HR;
  const isDAF = () =>
    user.selectedEntitySector.permissions.IS_ADMIN ||
    user.selectedEntitySector.permissions.IS_DAF;
  const isCBM = () =>
    user.selectedEntitySector.permissions.IS_ADMIN ||
    user.selectedEntitySector.permissions.IS_CBM;

  const payrollGroupDropdown = () => {
    return payrollGroups.filter(
      (payrollGroup) =>
        payrollGroup.employeeGroupId === selectedContract?.employeeGroupId ||
        !selectedContract?.employeeGroupId
    );
  };
  // const [edited, setEdited] = useState(false);

  return (
    <>
      <div className="row  align-items-start ">
        <div className="col-2  pr-0 justify-content-center">
          <p className="text-center text-uppercase font-weight-bold">
            {selectedContract?.lastName} <br />
            {selectedContract?.firstName}
          </p>
          <div
            style={{
              minHeight: "128px",
              overflow: "hidden",
            }}
            className="text-center"
          >
            {selectedEmployeeProfilePicture && (
              <img
                className="mt-0 rounded"
                src={"data:image/jpg;base64," + selectedEmployeeProfilePicture}
                style={{
                  border: "1px solid #a6d3df",
                  height: "128px",
                  // width: "130px",
                  overflow: "hidden",
                  // borderRadius: "50%",
                }}
              />
            )}
            {!selectedEmployeeProfilePicture && (
              <img
                className="mt-0  rounded text-center"
                src={defaultPofile}
                style={{
                  // border: "1px solid #a6d3df",
                  height: "128px",
                  width: "100px",
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>

          <div className="text-primary text-center">
            <strong>ID:{selectedContract.employeeId || "N/A"}</strong> <br />
            <span className="text-dark">
              {selectedContract.positionName}
            </span>{" "}
            <br />
          </div>
          <div className={`text-center  `}>
            {(!selectedContract?.url || editFile) && (
              <>
                <small>Upload pdf contract</small>
                <Button
                  disabled={isFormDisabled() || isLoading}
                  className="d-flex justify-content-start"
                  fullWidth
                  component="label"
                  style={{
                    borderRadius: "0px",
                    height: "35px",
                    border: `1px solid #6c757d`,
                    color: "#fff",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                    backgroundColor: "#6c757d",
                    "&:hover": {
                      backgroundColor: "#6c757d",
                    },
                  }}
                  size="md"
                >
                  <input
                    disabled={disabled || isFormDisabled()}
                    accept="application/pdf"
                    type="file"
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        fileHasError: false,
                        fileError: "",
                      });
                      const file = e.target.files[0];
                      setFile(file || null);
                      setEditFile(true);
                    }}
                  />
                </Button>

                {errors.fileHasError && (
                  <small className="text-danger">
                    {errors.fileError || "Cotract is required"}
                  </small>
                )}
              </>
            )}

            {selectedContract?.url && !editFile && (
              <button
                type="button"
                className="btn btn-block btn-secondary mt-4 btn-sm d-inline-flex justify-content-center   align-items-center "
                onClick={() => {
                  downloadURI(selectedContract.url, selectedContract.fileName);
                }}
                disabled={isLoading}
              >
                <span className="material-icons">download</span>
                Contract file
              </button>
            )}
            {selectedContract?.url && !isFormDisabled() && (
              <button
                onClick={() => setEditFile(!editFile)}
                disabled={isLoading}
                type="button"
                className={`btn btn-sm text-${
                  !editFile ? "primary" : "danger"
                } `}
              >
                {!editFile && <i className="fas fa-edit mr-1"></i>}
                {editFile && <i className="fas fa-window-close mr-1"></i>}
                Edit file
              </button>
            )}
          </div>
        </div>

        <div className="col-4 row  no-gutters align-items-start ">
          <div className="col-6 pr-1">
            <Autocomplete
              disabled={isFormDisabled() || isLoading}
              size="small"
              id="currencyId"
              name="currencyId"
              defaultValue={null}
              value={currency || null}
              options={currencies}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, currency) => {
                setCurrency(currency || null);

                setFormData({
                  ...formData,
                  currencyId: currency?.id || "",
                });

                setErrors({ ...errors, currencyHasError: false });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, currency) => (
                <Box component="li" {...props}>
                  {currency?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select currency"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "currency",
                  }}
                />
              )}
            />

            {errors.currencyHasError && (
              <small className="text-danger mb-3">
                Please, select currency
              </small>
            )}
          </div>
          <div className="col-6 pl-1">
            <TextField
              disabled={isFormDisabled() || isLoading}
              style={{ backgroundColor: "#eee" }}
              fullWidth
              size="small"
              label="(RWF) Rate "
              name="currencyRate"
              variant="outlined"
              value={currency?.rate || "N/A"}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div className="row col-12 no-gutters mt-2 ">
            <div className="mb-2 col-12 ">
              <div className="">
                <NumberFormat
                  disabled={isFormDisabled() || isSPIUContract() || isLoading}
                  fullWidth
                  size="small"
                  label="Base Pay"
                  name="basePay"
                  variant="outlined"
                  placeholder={`${currency?.id} 0`}
                  prefix={`${currency?.id} `}
                  customInput={TextField}
                  value={formData.basePay}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.basePay === "")
                      setFormData({
                        ...formData,
                        basePay: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.basePay === 0)
                      setFormData({
                        ...formData,
                        basePay: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const basePay = e.target.value.split(`${currency?.id} `);

                    setFormData({
                      ...formData,
                      basePay: !!basePay[1] ? basePay[1].replace(/,/g, "") : 0,
                      housingAllowance: formData.hasHousingAllowance
                        ? (+basePay[1].replace(/,/g, "") / 5.0).toFixed(0)
                        : formData.housingAllowance,
                      transportAllowance: formData.hasTransportAllowance
                        ? (+basePay[1].replace(/,/g, "") / 6.4).toFixed(0)
                        : formData.transportAllowance,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <FormGroup
                row
                className=" d-flex justify-content-center border border-info bg-light"
              >
                <FormControlLabel
                  className="mb-0"
                  control={
                    <Checkbox
                      size="small"
                      disabled={
                        isFormDisabled() || isSPIUContract() || isLoading
                      }
                      checked={formData.hasHousingAllowance}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          hasHousingAllowance: event.target.checked
                            ? true
                            : false,

                          housingAllowance: event.target.checked
                            ? (+formData.basePay / 5.0).toFixed(0)
                            : 0,
                        });
                      }}
                    />
                  }
                  label="Has Housing"
                />
                <FormControlLabel
                  className="mb-0"
                  control={
                    <Checkbox
                      size="small"
                      disabled={
                        isFormDisabled() || isSPIUContract() || isLoading
                      }
                      checked={formData.hasTransportAllowance}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          hasTransportAllowance: event.target.checked
                            ? true
                            : false,

                          transportAllowance: event.target.checked
                            ? (+formData.basePay / 6.4).toFixed(0)
                            : 0,
                        });
                      }}
                    />
                  }
                  label="Has Transport"
                />
              </FormGroup>
            </div>

            <div className="mb-3 col-6 pr-1">
              <div className="">
                <NumberFormat
                  disabled={isFormDisabled() || isSPIUContract() || isLoading}
                  fullWidth
                  size="small"
                  label="Housing"
                  name="housingAllowance"
                  variant="outlined"
                  placeholder={`${currency?.id} 0`}
                  prefix={`${currency?.id} `}
                  customInput={TextField}
                  value={formData.housingAllowance}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.housingAllowance === "")
                      setFormData({
                        ...formData,
                        housingAllowance: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.housingAllowance === 0)
                      setFormData({
                        ...formData,
                        housingAllowance: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const housingAllowance = e.target.value.split(
                      `${currency?.id} `
                    );

                    setFormData({
                      ...formData,
                      housingAllowance: !!housingAllowance[1]
                        ? housingAllowance[1].replace(/,/g, "")
                        : 0,
                    });
                  }}
                />
              </div>
            </div>

            <div className="mb-3 col-6 pl-1">
              <div className="">
                <NumberFormat
                  disabled={isFormDisabled() || isSPIUContract() || isLoading}
                  fullWidth
                  size="small"
                  label="Transport"
                  name="transportAllowance"
                  variant="outlined"
                  placeholder={`${currency?.id} 0`}
                  prefix={`${currency?.id} `}
                  customInput={TextField}
                  value={formData.transportAllowance}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.transportAllowance === "")
                      setFormData({
                        ...formData,
                        transportAllowance: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.transportAllowance === 0)
                      setFormData({
                        ...formData,
                        transportAllowance: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const transportAllowance = e.target.value.split(
                      `${currency?.id} `
                    );

                    setFormData({
                      ...formData,
                      transportAllowance: !!transportAllowance[1]
                        ? transportAllowance[1].replace(/,/g, "")
                        : 0,
                    });
                  }}
                />
              </div>
            </div>

            <div className="mb-3 col-6 pr-1">
              <div className="">
                <NumberFormat
                  disabled={isFormDisabled() || isSPIUContract() || isLoading}
                  fullWidth
                  size="small"
                  label="Other All."
                  name="taxableAllowance"
                  variant="outlined"
                  placeholder={`${currency?.id} 0`}
                  prefix={`${currency?.id} `}
                  customInput={TextField}
                  value={formData.taxableAllowance}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.taxableAllowance === "")
                      setFormData({
                        ...formData,
                        taxableAllowance: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.taxableAllowance === 0)
                      setFormData({
                        ...formData,
                        taxableAllowance: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const taxableAllowance = e.target.value.split(
                      `${currency?.id} `
                    );

                    setFormData({
                      ...formData,
                      taxableAllowance: !!taxableAllowance[1]
                        ? taxableAllowance[1].replace(/,/g, "")
                        : 0,
                    });
                  }}
                />
              </div>
            </div>

            <div className="mb-3 col-6 pl-1">
              <div className="">
                <NumberFormat
                  disabled={isFormDisabled() || isSPIUContract() || isLoading}
                  fullWidth
                  size="small"
                  label="Lumpsum"
                  name="lumpsumAllowance"
                  variant="outlined"
                  placeholder={`${currency?.id} 0`}
                  prefix={`${currency?.id} `}
                  customInput={TextField}
                  value={formData.lumpsumAllowance}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.lumpsumAllowance === "")
                      setFormData({
                        ...formData,
                        lumpsumAllowance: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.lumpsumAllowance === 0)
                      setFormData({
                        ...formData,
                        lumpsumAllowance: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const lumpsumAllowance = e.target.value.split(
                      `${currency?.id} `
                    );

                    setFormData({
                      ...formData,
                      lumpsumAllowance: !!lumpsumAllowance[1]
                        ? lumpsumAllowance[1].replace(/,/g, "")
                        : 0,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-6 pr-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={isFormDisabled() || isLoading}
                  maxDate={new Date()}
                  label="From Date"
                  inputFormat="dd/MM/yyyy"
                  value={formData.fromDate}
                  onChange={(date) => {
                    setFormData({ ...formData, fromDate: date });

                    setErrors({ ...errors, fromDateHasError: false });
                  }}
                  renderInput={(params) => (
                    <TextField size="small" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
              {errors.fromDateHasError && (
                <small className="text-danger mt-1">
                  {errors.fromDateError || "From Date is required"}
                </small>
              )}
            </div>

            <div className="col-6 pl-1">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={!formData.fromDate || isFormDisabled() || isLoading}
                  minDate={new Date(formData.fromDate).setDate(
                    new Date(formData.fromDate).getDate() + 1
                  )}
                  label="To Date"
                  inputFormat="dd/MM/yyyy"
                  value={formData.toDate}
                  onChange={(date) => {
                    setFormData({ ...formData, toDate: date });
                    setErrors({ ...errors, toDateHasError: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={() => {
                        return;
                      }}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.toDateHasError && (
                <small className="text-danger mt-1">
                  {errors.toDateError || "To Date is required"}
                </small>
              )}
            </div>
            <div className="col-6 mt-2 pr-1">
              <Autocomplete
                size="small"
                id="costCenter"
                defaultValue={null}
                value={costCenter}
                options={costCenters}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                onChange={(event, costCenter) => {
                  setCostCenter(costCenter || null);
                  // setFormData({
                  //   ...formData,
                  //   costCenterId: costCenter.id,
                  // });

                  setErrors({
                    ...errors,
                    costCenterHasError: false,
                  });

                  // setEdited(true);
                }}
                getOptionLabel={(option) => option?.name}
                renderOption={(props, costCenter) => (
                  <Box component="li" {...props}>
                    {costCenter?.name}
                  </Box>
                )}
                disabled={isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cost Center"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "costCenter", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors.costCenterHasError && (
                <small className="text-danger">Required </small>
              )}
            </div>

            <div className="col-6 mt-2 pl-1">
              <Autocomplete
                size="small"
                id="payrollGroup"
                defaultValue={null}
                value={payrollGroup}
                disabled={disabled || isLoading}
                options={payrollGroupDropdown()}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                onChange={(event, payrollGroup) => {
                  setPayrollGroup(payrollGroup || null);
                  // setFormData({
                  //   ...formData,
                  //   payrollGroupId: payrollGroup.id,
                  // });

                  setErrors({
                    ...errors,
                    payrollGroupHasError: false,
                  });

                  // setEdited(true);
                }}
                getOptionLabel={(option) => option?.name}
                renderOption={(props, payrollGroup) => (
                  <Box component="li" {...props}>
                    {payrollGroup?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Payroll Group"
                    inputProps={{
                      ...params.inputProps,
                      // autoComplete: "payrollGroup", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors.payrollGroupHasError && (
                <small className="text-danger">Required </small>
              )}
            </div>

            {/* <div className="col-6 pr-1 mt-3 text-center"></div> */}
          </div>
        </div>

        <div className="col-6 row ">
          <div className="col-12">
            <div className="border-dashed border-gray-300 border-dashed px-2 text-center">
              <span className=" text-uppercase">
                <Chip
                  color="primary"
                  className="ml-1"
                  style={{ border: "none", fontWeight: "bold" }}
                  label={` Gross: ${currencyFormat(getGrossSalary())} ${"RWF"}`}
                  variant="outlined"
                />
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="user-contact">
              <div className="user-contact-2  border-dashed border-gray-300 border-dashed rounded">
                <h3>
                  <PaymentsIcon />
                  <span className="ml-1">Deductions</span>
                </h3>
                <div className="row no-gutters">
                  <div className="col-6 px-2">
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td
                            className="text-center text-uppercase"
                            colSpan={2}
                            style={{
                              backgroundColor: "rgb(255, 255, 255)",
                              border: "1px solid rgb(200, 198, 198)",
                              fontSize: "12px",
                            }}
                          >
                            Employer
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "64px" }}
                            className="contact_span text-right "
                          >
                            {currencyFormat(getPansionEmployer())}
                          </td>
                          <td className="contact_p">Pansion</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getMaternityEmployer())}
                          </td>
                          <td className="contact_p">Maternity</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getMedicalEmployer())}
                          </td>
                          <td className="contact_p">
                            Medical{" "}
                            {selectedContract?.payMedical &&
                              medicalInsuranceType?.name && (
                                <span className="badge badge-primary">
                                  {medicalInsuranceType?.name}
                                </span>
                              )}
                            {!(
                              selectedContract?.payMedical &&
                              medicalInsuranceType?.name
                            ) && (
                              <span className="badge badge-danger">None</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="text-center text-primary"
                            style={{
                              backgroundColor: "rgb(255, 255, 255)",
                              border: "1px solid rgba(25, 118, 210, 0.7)",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {currencyFormat(getTotalPatronalContribution())}{" "}
                            {"RWF"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6 pl-2 pr-3">
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td
                            className="text-center text-uppercase"
                            colSpan={2}
                            style={{
                              fontSize: "12px",
                              backgroundColor: "rgb(255, 255, 255)",
                              border: "1px solid rgb(200, 198, 198)",
                            }}
                          >
                            Employee
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{ width: "64px" }}
                            className="contact_span text-right "
                          >
                            {currencyFormat(getPansionEmployee())}
                          </td>
                          <td className="contact_p">Pansion</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getMaternityEmployee())}
                          </td>
                          <td className="contact_p">Maternity</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right">
                            {currencyFormat(getMedicalEmployee())}
                          </td>
                          <td className="contact_p">
                            Medical{" "}
                            {selectedContract?.payMedical &&
                              medicalInsuranceType?.name && (
                                <span className="badge badge-primary">
                                  {medicalInsuranceType?.name}
                                </span>
                              )}
                            {!(
                              selectedContract?.payMedical &&
                              medicalInsuranceType?.name
                            ) && (
                              <span className="badge badge-danger">None</span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getMutuelle())}
                          </td>
                          <td className="contact_p">Mutuelle</td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getTPR())}
                          </td>
                          <td className="contact_p">TPR</td>
                        </tr>
                        <tr>
                          <td
                            colSpan={2}
                            className="text-center text-primary"
                            style={{
                              backgroundColor: "rgb(255, 255, 255)",
                              border: "1px solid rgba(25, 118, 210, 0.7)",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {currencyFormat(getTotalEmployeeWithholds())}{" "}
                            {"RWF"}
                          </td>
                        </tr>
                        <tr>
                          <td className="contact_span text-right ">
                            {currencyFormat(getTprLumpSum())}
                          </td>
                          <td className="contact_p">TPR LumpSum</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex  pb-2 justify-content-center pr-3">
              <div className="d-flex  flex-wrap justify-content-end">
                <div className="border-dashed border-gray-300 border-dashed rounded  py-2 px-2 mx-2 mb-1 mt-1">
                  <div
                    style={{ fontSize: "13px" }}
                    className="fw-semibold text-gray-400  text-center  text-uppercase"
                  >
                    Net Lumpsum
                  </div>
                  <div
                    style={{ fontSize: "13px" }}
                    className="fs-6 text-gray-700  text-center"
                  >
                    {currencyFormat(getNetLumpSum())} {currency?.id}
                  </div>
                </div>

                <div className="border-dashed border-gray-300 border-dashed rounded  py-2 px-2 mx-2 mb-1 mt-1">
                  <div
                    style={{ fontSize: "13px" }}
                    className="fw-semibold text-gray-400  text-center  text-uppercase"
                  >
                    Net Salary
                  </div>
                  <div
                    style={{ fontSize: "13px" }}
                    className="fs-6 text-gray-700  text-center"
                  >
                    {currencyFormat(getPartialNet())} {currency?.id}
                  </div>
                </div>
                <div
                  style={{ backgroundColor: "#e6e8ff73" }}
                  className=" border-dashed border-gray-300 border-dashed rounded  py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div
                    style={{ fontSize: "13px" }}
                    className="fw-semibold fw-bold text-gray-400  text-center text-uppercase"
                  >
                    Total Net
                  </div>
                  <div
                    style={{ fontSize: "13px" }}
                    className="fs-6 fw-bold text-gray-700  text-center"
                  >
                    {currencyFormat(+getNetLumpSum() + +getPartialNet())}{" "}
                    {currency?.id}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row col-12 d-flex align-items-center">
          <div className="col-3">
            <Autocomplete
              size="small"
              id="source-of-fund"
              defaultValue={null}
              value={sourceOfFund}
              options={sourceOfFunds}
              disabled={isLoading}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(event, sourceOfFund) => {
                setSourceOfFund(sourceOfFund || null);
                // setFormData({
                //   ...formData,
                //   sourceOfFundId: sourceOfFund?.id || null,
                // });

                setErrors({
                  ...errors,
                  sourceOfFundHasError: false,
                });

                // setEdited(true);
              }}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, sourceOfFund) => (
                <Box component="li" {...props}>
                  {sourceOfFund?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source Of Fund"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "sourceOfFund", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {errors.sourceOfFundHasError && (
              <small className="text-danger">Required </small>
            )}
          </div>

          <div className="col-6 ">
            <FormGroup
              row
              className=" d-flex justify-content-center border border-info bg-light"
            >
              <FormControlLabel
                className="mb-0 "
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    // disabled
                    disabled={disabled || isLoading}
                    checked={sourceOfFund?.payTPR || false}
                  />
                }
                label="TPR"
              />
              <FormControlLabel
                className="mb-0"
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    disabled={disabled || isLoading}
                    checked={sourceOfFund?.payCSR || false}
                  />
                }
                label="CSR"
              />
              <FormControlLabel
                className="mb-0"
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    disabled={disabled || isLoading}
                    checked={sourceOfFund?.payMedical || false}
                  />
                }
                label="Med."
              />
              <FormControlLabel
                className="mb-0"
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    disabled={isLoading || disabled}
                    checked={sourceOfFund?.payMaternity || false}
                  />
                }
                label="Mat."
              />
              <FormControlLabel
                className="mb-0"
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    disabled={disabled || isLoading}
                    checked={sourceOfFund?.payCBHI || false}
                  />
                }
                label="CBHI"
              />
            </FormGroup>
          </div>
          <div className="col-3 pl-1 text-center">
            {/* {(!selectedContract.id ||
                (!selectedContract.isApproved && isHR() && !isExpired())) && (
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary btn-block text-uppercase  px-4 py-1"
                  disabled={loading ||selectedContract.isApproved}
                >
                  {loading ? "Wait..." : "Save"}
                </button>
              )} */}

            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary  btn-block text-uppercase  px-4 "
              disabled={loading || isFormDisabled() || isLoading || disabled}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </div>
        </div>

        {/* <div className="col-12 d-flex justify-content-center align-items-center mt-2">
          {selectedContract.id && selectedContract.status === "Unapproved" && (
            <button
              type="button"
              className="btn text-truncate pb-0 disabled btn-block btn-secondary  btn-sm"
              style={{ cursor: "default" }}
            >
              <span className="d-inline-flex justify-content-center align-items-center">
                <span className="material-icons">pending</span>
                <small>Unapproved</small>
              </span>
            </button>
          )}

          {!!selectedContract.id &&
            !selectedContract.isApproved &&
            isDAF() &&
            !isExpired() && (
              <button
                onClick={() => {
                  setIsApproved(true);
                  setConfirmApproveContract(true);
                }}
                type="button"
                className="btn text-truncate px-1 btn-block mt-0 mx-2 btn-success  btn-sm"
              >
                Approve contract
              </button>
            )}

          {!!selectedContract.id &&
            selectedContract.isApproved &&
            !isExpired() && (
              <button
                type="button"
                className="btn text-truncate px-1 disabled btn-block btn-success  btn-sm"
                style={{ cursor: "default" }}
              >
                <span className="d-inline-flex justify-content-center align-items-center">
                  <span className="material-icons">verified</span>
                  <small>Approved</small>
                </span>
              </button>
            )}

          {!!selectedContract.id && isExpired() && (
            <button
              type="button"
              className="btn text-truncate pb-0 disabled btn-block btn-danger  btn-sm"
              style={{ cursor: "default" }}
            >
              <span className="d-inline-flex justify-content-center align-items-center">
                <span className="material-icons">block</span>
                <small>Contract was expired</small>
              </span>
            </button>
          )}

          {!!selectedContract.id &&
            selectedContract.isApproved &&
            isDAF() &&
            !isExpired() && (
              <button
                onClick={() => {
                  setIsApproved(false);
                  setConfirmApproveContract(true);
                }}
                type="button"
                className={`btn btn-sm`}
              >
                <span className="badge badge-warning d-inline-flex align-items-center ">
                  <span
                    className="material-icons mr-1"
                    style={{ fontSize: "20px" }}
                  >
                    unpublished
                  </span>{" "}
                  Disapprove
                </span>
              </button>
            )}
        </div> */}
        {confirmApproveContract && (
          <ConfirmationDialog
            confirmationDialog={confirmApproveContract}
            message={`Are you sure you want to ${
              isApproved ? "approve" : "disapprove"
            } this contract?`}
            setConfirmationDialog={setConfirmApproveContract}
            onYes={() => {
              approveContract(
                { id: selectedContract.id, isApproved },
                setConfirmApproveContract
              );
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  selectedEmployeeProfilePicture,
  currencies,
  selectedContract,
  medicalInsuranceTypes,
  sourceOfFunds,
  payrollGroups,
  costCenters,
}) => {
  return {
    loading,
    user,
    selectedEmployeeProfilePicture,
    currencies,
    selectedContract,
    medicalInsuranceTypes,
    sourceOfFunds,
    payrollGroups,
    costCenters,
  };
};
export default connect(mapStateToProps, {
  saveContract,
  approveContract,
})(EmployeeContract);
