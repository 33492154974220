import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
  Step,
  Box,
  Stepper,
  StepLabel,
  Tabs,
  Tab,
  Input,
  Autocomplete,
  Checkbox,
  Alert,
  AlertTitle,
} from "@mui/material";
import moment from "moment";
import { a11yProps } from "../../common/components/TabPanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveExitRequest,
  rejectRequest,
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
  verifyExitRequest,
} from "../../../store/exit/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";

import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CommonLetter from "../../documents/LetterFormat/CommonLetter";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import EditExitRequestLetterDialog from "../../employees/components/EditExitRequestLetterDialog";
import PdfViewer from "../../common/components/PdfViewer";
import { getEntities } from "../../../store/structure/actions";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeDuplicates } from "../../common/components/Utils";
import ViewExitRequestAttachments from "./ViewExitRequestAttachments";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ApproveExitDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    rejectRequest,
    employeeExitRequest,
    approveExitRequest,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    getEmployeeExitRequest,
    cancelEmployeeExitRequest,
    entities,
    getEntities,
    verifyExitRequest,
    env,
  } = props;
  const [tabValue, setTabValue] = useState(0);
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useState(false);
  const [showRejectRequestForm, setShowRejectRequestForm] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [reason, setReason] = useState("");
  const [pkiPassword, setPkiPassword] = useState("");
  const [ccData, setCCData] = useState(
    employeeExitRequest && !!employeeExitRequest.cc
      ? JSON.parse(employeeExitRequest?.cc || [])
      : []
  );
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);

  const [employeeExitForm, setEmployeeExitForm] = useState({
    employeeId: employeeExitRequest?.employeeId || "",
    employeePositionId: employeeExitRequest?.employeePositionId || "",
    positionId: employeeExitRequest?.positionId || "",
    exitTypeId: employeeExitRequest?.exitTypeId || "",
    effectiveDate: moment(employeeExitRequest?.effectiveDate || null).format(
      "YYYY-MM-DD"
    ),
    blacklistPeriod: 0,
    reason: "",
    referenceLetterId: employeeExitRequest?.referenceLetterId || null,
    referenceLetterDate: moment(
      employeeExitRequest?.referenceLetterDate || null
    ).format("YYYY-MM-DD"),
    provisionalRefLetterId: employeeExitRequest?.provisionalRefLetterId || null,
    provisionalRefLetterDate:
      employeeExitRequest?.provisionalRefLetterDate || null,
    summaryConclusionsCommittee:
      employeeExitRequest?.summaryConclusionsCommittee || null,
    reportInternalDisciplinaryCommittee:
      employeeExitRequest?.reportInternalDisciplinaryCommittee || null,
    providingFeedbackRefLetterDate:
      employeeExitRequest?.providingFeedbackRefLetterDate || null,
    providingFeedbackRefLetterId:
      employeeExitRequest?.providingFeedbackRefLetterId || null,
    providingExplanationLetterId:
      employeeExitRequest?.providingExplanationLetterId || null,
    providingExplanationLetterDate:
      employeeExitRequest?.providingExplanationLetterDate || null,
    employeeExplanationLetterDate:
      employeeExitRequest?.employeeExplanationLetterDate || null,
    secondmentPositionName: employeeExitRequest?.secondmentPositionName || null,
    secondmentInstitutionName:
      employeeExitRequest?.secondmentInstitutionName || null,
    docLetterNo: employeeExitRequest?.docLetterNo || null,
    docReferenceId: !!requestDocumentSignerDetail?.docReferenceId
      ? requestDocumentSignerDetail.docReferenceId
      : null,
    cc:
      employeeExitRequest && !!employeeExitRequest.cc
        ? JSON.parse(employeeExitRequest?.cc || [])
        : [],
  });
  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    reasonHasError: false,
    effectiveDateHasError: false,
    entityHasError: false,
    unitHasError: false,
    positionHasError: false,
    referenceLetterIdError: false,
    referenceLetterDateError: false,
    provisionalRefLetterDateError: false,
    provisionalRefLetterIdError: false,
    summaryConclusionsCommitteeError: false,
    reportInternalDisciplinaryCommitteeError: false,
    providingFeedbackRefLetterDateError: false,
    providingFeedbackRefLetterIdError: false,
    providingExplanationLetterIdError: false,
    providingExplanationLetterDateError: false,
    employeeExplanationLetterDateError: false,
    secondmentInstitutionNameError: false,
    secondmentPositionNameError: false,
    effectiveDateError: false,
    docLetterNoError: false,
    ccHasError: false,
  });
  const formValidator1 = () => {
    const error = {
      referenceLetterIdError: false,
      referenceLetterDateError: false,
      provisionalRefLetterIdError: false,
      provisionalRefLetterDateError: false,
      summaryConclusionsCommitteeError: false,
      reportInternalDisciplinaryCommitteeError: false,
      providingFeedbackRefLetterDateError: false,
      providingFeedbackRefLetterIdError: false,
      providingExplanationLetterIdError: false,
      providingExplanationLetterDateError: false,
      employeeExplanationLetterDateError: false,
      secondmentInstitutionNameError: false,
      secondmentPositionNameError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      effectiveDateError: false,
    };

    // docLetterNoError:false,
    // if (
    //   !employeeExitForm.docLetterNo &&
    //   exitType &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }
    if (
      employeeExitRequest &&
      !employeeExitForm.referenceLetterId &&
      ![8, 9].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      employeeExitRequest &&
      !employeeExitForm.effectiveDate &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      employeeExitRequest &&
      !employeeExitForm.referenceLetterDate &&
      ![8, 9].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.referenceLetterDateError = true;
      error.hasError = true;
    }
    // start removal from office
    // if (
    //   !employeeExitForm.provisionalRefLetterId &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.provisionalRefLetterDate &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.reportInternalDisciplinaryCommittee &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.reportInternalDisciplinaryCommitteeError = true;
    //   error.hasError = true;
    // }
    if (
      employeeExitRequest &&
      !employeeExitForm.summaryConclusionsCommittee &&
      [8].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.summaryConclusionsCommitteeError = true;
      error.hasError = true;
    }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.providingExplanationLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.employeeExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.employeeExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    if (
      employeeExitRequest &&
      !employeeExitForm.secondmentInstitutionName &&
      [10].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.secondmentInstitutionNameError = true;
      error.hasError = true;
    }
    if (
      employeeExitRequest &&
      !employeeExitForm.secondmentPositionName &&
      [10].includes(employeeExitRequest.exitTypeId) &&
      !!employeeExitRequest.enableToGenerateLetter
    ) {
      error.secondmentPositionNameError = true;
      error.hasError = true;
    }

    // if (!ccData.length && !!exitType.enableToGenerateLetter) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }
    setErrors(error);

    if (error.hasError) {
      showError(`Please fill required field!`);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowCancelConfirmationDialog(false);
    setApproveRequestForm(false);
    setShowRejectRequestForm(false);
    setShowApproveRequestForm(false);
  };

  const [showEmployeeExitForm, setShowEmployeeExitForm] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  const onVerifierConfirm = () => {
    if (!formValidator1()) {
      verifyExitRequest(
        {
          ...employeeExitForm,
          requestId: employeeExitRequest.id,
          employeePositionId: employeeExitRequest.employeePositionId,
          cc: ccData || [],
        },
        onClose
      );
    }
  };
  // console.log(employeeExitRequest);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={"md"}
      >
        {showEmployeeExitForm && (
          <EditExitRequestLetterDialog
            showEmployeeExitForm={showEmployeeExitForm}
            setShowEmployeeExitForm={setShowEmployeeExitForm}
          />
        )}
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Exit request</span> /{" "}
            <span className="text-primary mr-1">
              {employeeExitRequest?.exitType || ""}
            </span>
            - {employeeExitRequest?.employeeNames || ""}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          <div className="mt-4">
            <Box sx={{ width: "100%", mt: 0 }}>
              <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                {employeeExitRequest &&
                  !!employeeExitRequest.enableToGenerateLetter && (
                    <Tab
                      className="text-capitalize"
                      label="View Letter"
                      {...a11yProps(0)}
                    />
                  )}

                <Tab
                  className="text-capitalize"
                  label="Details
             "
                  {...a11yProps(
                    employeeExitRequest &&
                      !employeeExitRequest.enableToGenerateLetter
                      ? 0
                      : 1
                  )}
                />

                <Tab
                  className="text-capitalize"
                  label="Attachments"
                  {...a11yProps(
                    employeeExitRequest &&
                      !employeeExitRequest.enableToGenerateLetter
                      ? 1
                      : 2
                  )}
                />
              </Tabs>
            </Box>
            {employeeExitRequest && (
              <span
                style={{ fontSize: "12px" }}
                className={`${
                  +employeeExitRequest.statusId === 2
                    ? "badge badge-success w-100"
                    : [3].includes(+employeeExitRequest.statusId)
                    ? "badge badge-danger w-100"
                    : [1].includes(+employeeExitRequest.statusId)
                    ? "badge badge-primary w-100"
                    : [1003].includes(+employeeExitRequest.statusId)
                    ? "badge badge-warning w-100"
                    : "badge badge-secondary w-100"
                }`}
              >
                {employeeExitRequest.statusName}
              </span>
            )}
          </div>
        </DialogTitle>

        <DialogContent>
          {employeeExitRequest && (
            <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
              {tabValue === 0 &&
                employeeExitRequest &&
                !!employeeExitRequest.enableToGenerateLetter &&
                [3, 2, 1002].includes(employeeExitRequest.statusId) && (
                  <>
                    {[3, 1002].includes(employeeExitRequest.statusId) && (
                      <>
                        <div
                          className="card  w-100"
                          style={{
                            backgroundColor: "#fff",
                            borderLeft: "2px solid #3f51b5",
                            borderLeft: "2px solid #3f51b5",
                            borderRadius: "4px",
                          }}
                        >
                          <div className="card-header w-100">
                            Request {employeeExitRequest.statusName}
                          </div>
                          <div className="card-body w-100">
                            <p className="small p-0 ms-2 mb-0 rounded-3">
                              Reason: {employeeExitRequest.reason}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {employeeExitRequest.statusId === 2 && (
                      <>
                        {!!employeeExitRequest?.docReferenceId && (
                          <>
                            <PdfViewer
                              url={
                                process.env.REACT_APP_FILES_URL +
                                "/preview/" +
                                employeeExitRequest?.docReferenceId
                              }
                            />
                          </>
                        )}
                        {!employeeExitRequest?.docReferenceId && (
                          <>
                            <div
                              className="card  w-100"
                              style={{
                                backgroundColor: "#fff",
                                borderLeft: "2px solid #3f51b5",
                                borderLeft: "2px solid #3f51b5",
                                borderRadius: "4px",
                              }}
                            >
                              <div className="card-body w-100">
                                <p className="small p-0 ms-2 mb-0 rounded-3">
                                  No letter was found because the request was
                                  approved when this feature wasn't in the
                                  system.
                                  <br />
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="mt-2"
                                    onClick={() => {
                                      setTabValue(1);
                                    }}
                                  >
                                    View request details
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              {tabValue === 0 &&
                employeeExitRequest &&
                !!employeeExitRequest.enableToGenerateLetter &&
                [1, 1003].includes(employeeExitRequest.statusId) && (
                  <>
                    <div className="mx-2 w-full">
                      {((!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                        [1003].includes(employeeExitRequest.statusId)) ||
                        !!hasPermissions([
                          "IS_HR",
                          "IS_HEAD_OF_INSTITUTION",
                        ])) && (
                        <Button
                          variant="outlined"
                          color="info"
                          size="small"
                          disabled={loading}
                          onClick={() => {
                            getEmployeeExitRequest(
                              employeeExitRequest.employeePositionId,
                              setShowEmployeeExitForm,
                              () => {}
                            );
                            // getNPSCReport(
                            //   npscReportDetail.id,
                            //   setShowSubmissionReport,
                            //   () => {}
                            // );
                          }}
                          className="m-2 text-capitalized float-right"
                        >
                          Edit Letter
                        </Button>
                      )}
                      <CommonLetter
                        exitTypeId={employeeExitRequest.exitTypeId}
                        recipientPositionName={employeeExitRequest.positionName}
                        institutionName={employeeExitRequest?.entityName}
                        transferredInstitutionName={
                          employeeExitRequest?.toEntityName
                        }
                        requestedOn={employeeExitRequest?.requestedOn}
                        transferredPositionName={
                          employeeExitRequest?.toPositionName
                        }
                        recipientPhone={employeeExitRequest.phoneNumber}
                        recipientEmail={employeeExitRequest.email}
                        currentPositionName={employeeExitRequest.positionName}
                        docReferenceId={
                          employeeExitRequest?.docReferenceId || ""
                        }
                        effectiveInDays={employeeExitRequest.effectiveInDays}
                        effectiveDate={moment(
                          employeeExitForm.effectiveDate
                        ).format("LL")}
                        recipientName={
                          employeeExitRequest.lastName.toUpperCase() +
                          " " +
                          employeeExitRequest.firstName
                        }
                        subTitle={
                          "Dear " +
                          (employeeExitRequest.genderId === "F"
                            ? "Madam"
                            : "Sir")
                        }
                        competentAuthorityName={
                          !!requestDocumentSignerDetail
                            ? requestDocumentSignerDetail.competentAuthorityUser
                                .names
                            : ""
                        }
                        competentAuthorityPosition={
                          !!requestDocumentSignerDetail
                            ? requestDocumentSignerDetail
                                ?.competentAuthorityUser?.positionName
                            : ""
                        }
                        enableToFillLetter={
                          !!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                          [1].includes(employeeExitRequest.statusId)
                        }
                        docLetterNoInput={
                          <>
                            <Input
                              autoFocus
                              error={!!errors.docLetterNoError}
                              value={employeeExitForm.docLetterNo}
                              style={{ width: "150px" }}
                              inputProps={{
                                "aria-label": "add doc No",
                              }}
                              onChange={(e) => {
                                const docLetterNo = e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  docLetterNo,
                                });

                                setErrors({
                                  ...errors,
                                  docLetterNoError: false,
                                });
                              }}
                            />
                          </>
                        }
                        docReferenceIdInput={
                          <>
                            <Input
                              autoFocus
                              error={!!errors.referenceLetterIdError}
                              value={employeeExitForm.referenceLetterId}
                              style={{ width: "150px" }}
                              inputProps={{
                                "aria-label": "add reference no",
                              }}
                              onChange={(e) => {
                                const referenceLetterId = e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  referenceLetterId,
                                });

                                setErrors({
                                  ...errors,
                                  referenceLetterIdError: false,
                                });
                              }}
                            />
                          </>
                        }
                        docReferenceDateInput={
                          <>
                            <Input
                              type="date"
                              error={!!errors.referenceLetterDateError}
                              value={employeeExitForm.referenceLetterDate}
                              style={{ width: "150px" }}
                              inputProps={{
                                "aria-label": "add reference date",
                              }}
                              onChange={(e) => {
                                const referenceLetterDate = e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  referenceLetterDate,
                                });

                                setErrors({
                                  ...errors,
                                  referenceLetterDateError: false,
                                });
                              }}
                            />
                          </>
                        }
                        effectiveDateInput={
                          <>
                            <Input
                              type="date"
                              error={!!errors.effectiveDateError}
                              value={employeeExitForm.effectiveDate}
                              style={{ width: "150px" }}
                              inputProps={{
                                "aria-label": "add reference date",
                              }}
                              onChange={(e) => {
                                const effectiveDate = e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  effectiveDate,
                                });

                                setErrors({
                                  ...errors,
                                  effectiveDateError: false,
                                });
                              }}
                            />
                          </>
                        }
                        summaryConclusionsCommitteeInput={
                          <>
                            <TextField
                              fullWidth
                              size="small"
                              multiline
                              rows={4}
                              maxLength={500}
                              name="summaryConclusionsCommittee"
                              label="Type the summary of the conclusions of the committee.."
                              variant="outlined"
                              placeholder="Type the summary of the conclusions of the committee.."
                              value={
                                employeeExitForm.summaryConclusionsCommittee
                              }
                              onChange={(e) => {
                                const summaryConclusionsCommittee =
                                  e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  summaryConclusionsCommittee,
                                });

                                setErrors({
                                  ...errors,
                                  summaryConclusionsCommitteeError: false,
                                });
                              }}
                            />
                            {errors.summaryConclusionsCommitteeError && (
                              <small className="text-danger mb-1">
                                This field is required
                              </small>
                            )}
                          </>
                        }
                        secondmentInstitutionNameInput={
                          <>
                            <Input
                              error={!!errors.secondmentInstitutionNameError}
                              value={employeeExitForm.secondmentInstitutionName}
                              style={{ width: "250px" }}
                              inputProps={{
                                "aria-label": "secondmentInstitutionName",
                              }}
                              onChange={(e) => {
                                const secondmentInstitutionName =
                                  e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  secondmentInstitutionName,
                                });

                                setErrors({
                                  ...errors,
                                  secondmentInstitutionNameError: false,
                                });
                              }}
                            />
                          </>
                        }
                        secondmentPositionNameInput={
                          <>
                            <Input
                              error={!!errors.secondmentPositionNameError}
                              value={employeeExitForm.secondmentPositionName}
                              style={{ width: "250px" }}
                              inputProps={{
                                "aria-label": "secondmentPositionName",
                              }}
                              onChange={(e) => {
                                const secondmentPositionName = e.target.value;
                                setEmployeeExitForm({
                                  ...employeeExitForm,
                                  secondmentPositionName,
                                });

                                setErrors({
                                  ...errors,
                                  secondmentPositionNameError: false,
                                });
                              }}
                            />
                          </>
                        }
                        ccSelectForm={
                          <>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              id="ccData"
                              options={entities.map(
                                ({ name, institutionEmail, id }) => ({
                                  name,
                                  institutionEmail,
                                  id,
                                })
                              )}
                              value={ccData}
                              onChange={(e, cc) => {
                                const uniqueCc = removeDuplicates(cc);
                                setCCData(uniqueCc);
                                const ccHasError = false;
                                setErrors({ ...errors, ccHasError });
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={
                                      selected ||
                                      !!ccData.find(
                                        ({ id }) => id === option.id
                                      )
                                    }
                                  />
                                  {option.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Select the institution to copy"}
                                  placeholder={"Select the institution to copy"}
                                />
                              )}
                            />

                            {errors.ccHasError && (
                              <small className="text-danger mb-1">
                                Please, select the institution to copy
                              </small>
                            )}
                          </>
                        }
                        reasonSuspension={employeeExitForm.reason}
                        ccData={ccData}
                        provisionalRefLetterId={
                          employeeExitRequest.provisionalRefLetterId
                        }
                        provisionalRefLetterDate={moment(
                          employeeExitRequest.provisionalRefLetterDate
                        ).format("LL")}
                        docLetterNo={employeeExitRequest.docLetterNo}
                        providingFeedbackRefLetterDate={moment(
                          employeeExitRequest.providingFeedbackRefLetterDate
                        ).format("LL")}
                        providingExplanationLetterDate={moment(
                          employeeExitRequest.providingExplanationLetterDate
                        ).format("LL")}
                        employeeExplanationLetterDate={moment(
                          employeeExitRequest.employeeExplanationLetterDate
                        ).format("LL")}
                        providingFeedbackRefLetterId={
                          employeeExitRequest.providingFeedbackRefLetterId
                        }
                        providingExplanationLetterId={
                          employeeExitRequest.providingExplanationLetterId
                        }
                        reportInternalDisciplinaryCommittee={
                          employeeExitRequest.reportInternalDisciplinaryCommittee
                        }
                        summaryConclusionsCommittee={
                          employeeExitRequest.summaryConclusionsCommittee
                        }
                        secondmentInstitutionName={
                          employeeExitRequest.secondmentInstitutionName
                        }
                        secondmentPositionName={
                          employeeExitRequest.secondmentPositionName
                        }
                        referenceLetterDate={moment(
                          employeeExitRequest.referenceLetterDate
                        ).format("LL")}
                        signContent={
                          <table>
                            <tr>
                              <td
                                style={{
                                  position: "relative",

                                  width: "20%",
                                  fontSize: "14px",
                                  textAlign: "left",
                                }}
                              >
                                {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                                [1003].includes(
                                  employeeExitRequest.statusId
                                ) ? (
                                  <>
                                    <SignLetter />
                                  </>
                                ) : (
                                  <>
                                    <span className="d-block text-danger">
                                      Not signed
                                    </span>
                                  </>
                                )}
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                  fontSize: "12px",
                                  textAlign: "left",
                                }}
                              ></td>
                            </tr>
                          </table>
                        }
                      />
                    </div>
                  </>
                )}
              {((!!employeeExitRequest.enableToGenerateLetter &&
                tabValue === 1) ||
                (!employeeExitRequest.enableToGenerateLetter &&
                  tabValue === 0)) && (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">Employee</th>
                        <td>
                          {employeeExitRequest.lastName +
                            " " +
                            employeeExitRequest.firstName}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Exit request</th>
                        <td>
                          <strong className="text-primary">
                            {employeeExitRequest.exitType}
                          </strong>
                          {employeeExitRequest.exitTypeId === 0 && (
                            <>
                              <span className="text-capitalize d-block">
                                To
                              </span>
                              <div className="text-capitalize d-block">
                                <div>{employeeExitRequest.toUnitName}</div>
                                <strong className="text-dark">
                                  ({employeeExitRequest.toPositionName})
                                </strong>
                              </div>
                            </>
                          )}
                          {employeeExitRequest.exitTypeId === 6 && (
                            <>
                              <span className="text-capitalize d-block">
                                To
                              </span>
                              <div className="t d-block">
                                <div>{employeeExitRequest.toEntityName}</div> on
                                the post of
                                <strong className="ml-1 text-dark">
                                  {employeeExitRequest.toPositionName}(
                                  {employeeExitRequest.toUnitName})
                                </strong>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Reason</th>
                        <td>{employeeExitRequest.reason}</td>
                      </tr>
                      <tr>
                        <th scope="row">Effective on</th>
                        <td>
                          {moment(employeeExitRequest.effectiveDate).format(
                            "LL"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Requested on</th>
                        <td>
                          {moment(employeeExitRequest.createdOn).format("LLL")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Request by</th>
                        <td>{employeeExitRequest.requestedBy}</td>
                      </tr>
                      {employeeExitRequest.verifiedBy && (
                        <>
                          <tr>
                            <th scope="row">Verified by</th>
                            <td>{employeeExitRequest.verifiedBy}</td>
                          </tr>
                          <tr>
                            <th scope="row">Verified On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.verifiedOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      {employeeExitRequest.statusId === 3 && (
                        <>
                          <tr>
                            <th scope="row">Rejected by</th>
                            <td>{employeeExitRequest.statusByName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Rejected On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.statusOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                      {employeeExitRequest.statusId === 2 && (
                        <>
                          <tr>
                            <th scope="row">Approved by</th>
                            <td>{employeeExitRequest.statusByName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Approved On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.statusOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <th scope="row">Status</th>
                        <td>
                          <span
                            className={`badge badge-${
                              employeeExitRequest.statusId === 1
                                ? "info"
                                : employeeExitRequest.statusId === 3
                                ? "danger"
                                : employeeExitRequest.statusId === 2
                                ? "success"
                                : employeeExitRequest.statusId === 1003
                                ? "warning"
                                : "primary"
                            }`}
                          >
                            {employeeExitRequest.statusName}
                          </span>

                          {/* {employeeExitRequest.statusId === 2 && (
                          <span className="badge badge-success">Approved</span>
                        )}
                        {employeeExitRequest.statusId === 3 && (
                          <span className="badge badge-danger">Rejected</span>
                        )}
                        {(employeeExitRequest.statusId === 1003 ||
                          employeeExitRequest.statusId === 1002) && (
                          <span className="badge badge-warning">Cancelled</span>
                        )} */}
                        </td>
                      </tr>
                      {!!employeeExitRequest.statusComment && (
                        <tr
                          className={`border ${
                            employeeExitRequest.statusId === 2
                              ? "border-success"
                              : "border-danger"
                          }`}
                        >
                          <th scope="row">Comment</th>
                          <td>{employeeExitRequest.statusComment}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {((!!employeeExitRequest.enableToGenerateLetter &&
                tabValue === 2) ||
                (!employeeExitRequest.enableToGenerateLetter &&
                  tabValue === 1)) && (
                <>
                  <ViewExitRequestAttachments
                    requestId={employeeExitRequest.id}
                    canEdit={
                      !!hasPermissions(["IS_HR"]) &&
                      [1].includes(employeeExitRequest.statusId)
                    }
                  />
                </>
              )}
            </Box>
          )}
        </DialogContent>
        {employeeExitRequest && (
          <DialogActions>
            <Stack direction="row" spacing={2} className="mx-auto">
              {[1, 1003].includes(employeeExitRequest.statusId) && (
                <>
                  {env === "prod" &&
                    !!hasPermissions(["IS_CBM"]) &&
                    [1].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={
                            <span className="material-icons mr-1">
                              done_all
                            </span>
                          }
                          onClick={() => {
                            approveExitRequest(
                              {
                                requestId: employeeExitRequest.id,
                                employeePositionId:
                                  employeeExitRequest.employeePositionId,
                              },
                              onClose
                            );
                          }}
                        >
                          Approve
                        </Button>

                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setShowRejectRequestForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {env !== "prod" &&
                    !!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                    [1003].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={
                            <span className="material-icons mr-1">
                              done_all
                            </span>
                          }
                          onClick={() => {
                            setShowApproveRequestForm(true);
                          }}
                        >
                          Approve
                        </Button>
                        env
                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setShowRejectRequestForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  {env !== "prod" &&
                    !!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    [1].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          disabled={loading}
                          onClick={onVerifierConfirm}
                          className="btn btn-xs"
                        >
                          Confirm
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setShowRejectRequestForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {!!hasPermissions(["IS_HR"]) &&
                    [1].includes(employeeExitRequest.statusId) && (
                      <Button
                        variant="contained"
                        color="warning"
                        startIcon={
                          <span className="material-icons">cancel</span>
                        }
                        onClick={() => setShowCancelConfirmationDialog(true)}
                        disabled={loading}
                      >
                        {loading ? "Canceling..." : "Cancel request"}
                      </Button>
                    )}
                </>
              )}
            </Stack>
          </DialogActions>
        )}
      </Dialog>
      {showCancelConfirmationDialog && employeeExitRequest && (
        <ConfirmationDialog
          confirmationDialog={showCancelConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            employeeExitRequest?.lastName + " " + employeeExitRequest?.firstName
          }?`}
          setConfirmationDialog={setShowCancelConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(employeeExitRequest, onClose);
          }}
        />
      )}
      {showRejectRequestForm && employeeExitRequest && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this request?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequestForm}
          onYes={() => {
            rejectRequest(
              {
                exitRequestId: employeeExitRequest.id,
                statusComment: reason,
              },
              onClose
            );
          }}
        />
      )}
      {showApproveRequestForm && employeeExitRequest && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>

              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            approveExitRequest(
              {
                requestId: employeeExitRequest.id,
                password: pkiPassword,
                employeePositionId: employeeExitRequest.employeePositionId,
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  requestDocumentSignerDetail,
  employeeExitRequest,
  entities,
  env,
  exitRequestAttachments,
}) => {
  return {
    loading,
    requestDocumentSignerDetail,
    employeeExitRequest,
    entities,
    env,
    exitRequestAttachments,
  };
};
export default connect(mapStateToProps, {
  approveExitRequest,
  rejectRequest,
  getRequestDocumentSignerDetails,
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
  getEntities,
  verifyExitRequest,
})(ApproveExitDialog);
