import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stack,
  Button,
  Box,
  Tabs,
  Tab,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import { a11yProps } from "../../common/components/TabPanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveExitRequest,
  signExitRequest,
  rejectRequest,
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
  verifyExitRequest,
  saveOrUpdateDraftRequest,
} from "../../../store/exit/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";

import { useRequiredAuth } from "../../common/guard/requiredAuth";
import EditExitRequestLetterDialog from "../../employees/components/EditExitRequestLetterDialog";
import PdfViewer from "../../common/components/PdfViewer";
import { getEntities } from "../../../store/structure/actions";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  containsKeyInReplacements,
  removeDuplicates,
  replaceTextPlaceholders,
} from "../../common/components/Utils";
import ViewExitRequestAttachments from "./ViewExitRequestAttachments";
import InstitutionDetails from "../../training/components/InstitutionDetails";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import ExitLetter from "../../documents/LetterFormat/ExitLetter";
import SignLetter from "../../documents/LetterFormat/SignLetter";
const maxCharCount = 1500;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ViewExitDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    rejectRequest,
    employeeExitRequest,
    approveExitRequest,
    cancelEmployeeExitRequest,
    entities,
    getEntities,
    verifyExitRequest,
    env,
    saveOrUpdateDraftRequest,
    signExitRequest,
  } = props;
  const [tabValue, setTabValue] = useState(0);
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useState(false);

  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(
    (employeeExitRequest?.statusId || null) === 1 ? true : false
  );
  const [newStatusId, setNewStatusId] = useState(null);
  const [showRejectRequestForm, setShowRejectRequestForm] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [reason, setReason] = useState("");
  const [pkiPassword, setPkiPassword] = useState("");
  const [replacements, setReplacements] = useState({
    appointingLetterNo: "",
    appointingLetterDate: "",
    currentPositionName: "",
    currentEntityName: "",
    tranferredPositionName: "",
    tranferredEntityName: "",
    effectiveDate: moment().format("LL"),
  });
  const [ccData, setCCData] = useState(
    employeeExitRequest && !!employeeExitRequest.cc
      ? JSON.parse(employeeExitRequest?.cc || [])
      : []
  );
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);

  const [employeeExitForm, setEmployeeExitForm] = useState({
    requestId: employeeExitRequest?.id || null,
    employeeId: employeeExitRequest?.employeeId || null,
    employeePositionId: employeeExitRequest?.employeePositionId || "",
    positionId: employeeExitRequest?.positionId || "",
    transferredPositionId: employeeExitRequest?.toPositionId || "",
    exitTypeId: employeeExitRequest?.exitTypeId || 0,
    effectiveDate: moment(employeeExitRequest?.effectiveOn || null).format(
      "YYYY-MM-DD"
    ),
    blacklistPeriod: employeeExitRequest?.blacklistPeriod || 0,
    reason: employeeExitRequest?.reason || "",
    letterBodyContent: employeeExitRequest?.letterBodyContent || "",
    docLetterNo: employeeExitRequest?.docLetterNo || "",
    entitySectorId:
      employeeExitRequest?.entitySectorId ||
      employeeExitRequest?.entitySectorId ||
      null,
    cc:
      employeeExitRequest && !!employeeExitRequest.cc
        ? JSON.parse(employeeExitRequest?.cc || [])
        : [],
    copiedInstitutionContent:
      employeeExitRequest?.copiedInstitutionContent || "",

    docReferenceId: !!employeeExitRequest?.docReferenceId
      ? employeeExitRequest.docReferenceId
      : null,
  });

  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    reasonHasError: false,
    effectiveDateHasError: false,
    entityHasError: false,
    unitHasError: false,
    positionHasError: false,
    effectiveDateError: false,
    docLetterNoError: false,
    ccHasError: false,
  });
  const formValidator1 = () => {
    const error = {
      exitTypeHasError: false,
      reasonHasError: false,
      effectiveDateHasError: false,
      entityHasError: false,
      unitHasError: false,
      positionHasError: false,
      effectiveDateError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
    };

    setErrors(error);

    if (error.hasError) {
      showError(`Please fill required field!`);
      return true;
    }
    return false;
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowCancelConfirmationDialog(false);
    setApproveRequestForm(false);
    setShowRejectRequestForm(false);
    setShowApproveRequestForm(false);
  };

  const [showEmployeeExitForm, setShowEmployeeExitForm] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  const onVerifierConfirm = () => {
    if (!formValidator1()) {
      verifyExitRequest(
        {
          ...employeeExitForm,
          requestId: employeeExitRequest.id,
          employeePositionId: employeeExitRequest.employeePositionId,
          cc: ccData || [],
        },
        onClose
      );
    }
  };

  const handleSubmitDraftRequest = () => {
    if (
      !!employeeExitForm.letterBodyContent &&
      !!errors.draftContentMaxCharHasError
    ) {
      showError(
        `The letter content should not exceed 1.5MB. Please reduce the number of characters.`
      );
      return false;
    }

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      replacePlaceholders(employeeExitForm.letterBodyContent, true)
    ) {
      showError(
        `Please update the following Placeholder: 1. {appointingLetterNo} - 2. {appointingLetterDate} `
      );
      return false;
    }
    // Regular expression to match one or more occurrences of <div><br></div>
    const regex = /(<div><br><\/div>)+/;

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      !!regex.test(employeeExitForm.copiedInstitutionContent) &&
      !!ccData.length
    ) {
      showError(
        `You've added the CC emails, but you didn't include the copied institution content.`
      );
      return false;
    }

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      !regex.test(employeeExitForm.copiedInstitutionContent) &&
      !ccData.length
    ) {
      showError(
        `You've included the copied institution content, but you didn't add the CC emails.`
      );
      return false;
    }

    // console.log( employeeExitForm );

    saveOrUpdateDraftRequest(
      {
        ...employeeExitForm,
        effectiveDate: moment(employeeExitForm.effectiveDate).format(
          "YYYY-MM-DD"
        ),
        requestId: employeeExitRequest?.id || null,
        cc: ccData || [],
        letterBodyContent: employeeExitForm.letterBodyContent,
        copiedInstitutionContent: employeeExitForm.copiedInstitutionContent,
      },
      (status) => {
        if (!!status) {
          if (
            !!employeeExitRequest.enableToGenerateLetter &&
            !!employeeExitRequest
          ) {
            window.localStorage.setItem(
              `${
                employeeExitRequest && employeeExitRequest.employeePositionId
              }-${
                employeeExitRequest && employeeExitRequest.exitTypeId
              }-draftExitContent`,
              employeeExitForm.letterBodyContent
            );
            setEmployeeExitForm({
              ...employeeExitForm,
              letterBodyContent: employeeExitForm.letterBodyContent,
            });
          }
        }
      }
    );
  };

  const handleConfirmRequest = () => {
    if (
      !!employeeExitForm.letterBodyContent &&
      !!errors.draftContentMaxCharHasError
    ) {
      showError(
        `The letter content should not exceed 1.5MB. Please reduce the number of characters.`
      );
      return false;
    }

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      replacePlaceholders(employeeExitForm.letterBodyContent, true)
    ) {
      showError(
        `Please update the following Placeholder: 1. {appointingLetterNo} - 2. {appointingLetterDate} `
      );
      return false;
    }
    // Regular expression to match one or more occurrences of <div><br></div>
    const regex = /(<div><br><\/div>)+/;

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      !!regex.test(employeeExitForm.copiedInstitutionContent) &&
      !!ccData.length
    ) {
      showError(
        `You've added the CC emails, but you didn't include the copied institution content.`
      );
      return false;
    }

    if (
      !!employeeExitRequest.enableToGenerateLetter &&
      !regex.test(employeeExitForm.copiedInstitutionContent) &&
      !ccData.length
    ) {
      showError(
        `You've included the copied institution content, but you didn't add the CC emails.`
      );
      return false;
    }

    // console.log( employeeExitForm );

    if (!formValidator1()) {
      verifyExitRequest(
        {
          ...employeeExitForm,
          requestId: employeeExitRequest.id,
          employeePositionId: employeeExitRequest.employeePositionId,
          cc: ccData || [],
          reason,
          newStatusId,
          letterBodyContent: employeeExitForm.letterBodyContent,
          copiedInstitutionContent: employeeExitForm.copiedInstitutionContent,
        },
        onClose
      );
    }
  };

  const getEditorContent = (data) => {
    let draftContentMaxCharHasError = false;
    setEmployeeExitForm({
      ...employeeExitForm,
      letterBodyContent: data.contents,
    });
    if (!!data.isMaxChar) draftContentMaxCharHasError = true;

    setErrors({
      ...errors,
      draftContentMaxCharHasError: draftContentMaxCharHasError,
    });
  };
  const getEditorContent3 = (data) => {
    console.log(data.contents);
    setEmployeeExitForm({
      ...employeeExitForm,
      copiedInstitutionContent: data.contents,
    });
    employeeExitRequest.copiedInstitutionContent = data.contents;
  };
  const replacePlaceholders = (
    template,
    isVerifyContainsKeyInReplacement = false
  ) => {
    if (!!isVerifyContainsKeyInReplacement)
      return containsKeyInReplacements(template, replacements);
    return replaceTextPlaceholders(template, replacements);
  };
  // console.log(employeeExitRequest);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={"md"}
      >
        {showEmployeeExitForm && (
          <EditExitRequestLetterDialog
            showEmployeeExitForm={showEmployeeExitForm}
            setShowEmployeeExitForm={setShowEmployeeExitForm}
          />
        )}
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Exit request</span> /{" "}
            <span className="text-primary mr-1">
              {employeeExitRequest?.exitType || ""}
            </span>
            - {employeeExitRequest?.employeeNames || ""}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          <div className="mt-4">
            <Box sx={{ width: "100%", mt: 0 }}>
              <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                {employeeExitRequest &&
                  !!employeeExitRequest.enableToGenerateLetter && (
                    <Tab
                      className="text-capitalize"
                      label="View Letter"
                      {...a11yProps(0)}
                    />
                  )}

                <Tab
                  className="text-capitalize"
                  label="Details
             "
                  {...a11yProps(
                    employeeExitRequest &&
                      !employeeExitRequest.enableToGenerateLetter
                      ? 0
                      : 1
                  )}
                />

                <Tab
                  className="text-capitalize"
                  label="Attachments"
                  {...a11yProps(
                    employeeExitRequest &&
                      !employeeExitRequest.enableToGenerateLetter
                      ? 1
                      : 2
                  )}
                />
              </Tabs>
            </Box>
            {employeeExitRequest && (
              <span
                style={{ fontSize: "12px" }}
                className={`${
                  +employeeExitRequest.statusId === 2
                    ? "badge badge-success w-100"
                    : [3].includes(+employeeExitRequest.statusId)
                    ? "badge badge-danger w-100"
                    : [1].includes(+employeeExitRequest.statusId)
                    ? "badge badge-primary w-100"
                    : [1003].includes(+employeeExitRequest.statusId)
                    ? "badge badge-warning w-100"
                    : "badge badge-secondary w-100"
                }`}
              >
                {employeeExitRequest.statusName}
              </span>
            )}
          </div>
        </DialogTitle>

        <DialogContent>
          {employeeExitRequest && (
            <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
              {tabValue === 0 &&
                employeeExitRequest &&
                !!employeeExitRequest.enableToGenerateLetter &&
                [3, 2, 1002].includes(employeeExitRequest.statusId) && (
                  <>
                    {[3, 1002].includes(employeeExitRequest.statusId) && (
                      <>
                        <div
                          className="card  w-100"
                          style={{
                            backgroundColor: "#fff",
                            borderLeft: "2px solid #3f51b5",
                            borderLeft: "2px solid #3f51b5",
                            borderRadius: "4px",
                          }}
                        >
                          <div className="card-header w-100">
                            Request {employeeExitRequest.statusName}
                          </div>
                          <div className="card-body w-100">
                            <p className="small p-0 ms-2 mb-0 rounded-3">
                              Reason: {employeeExitRequest.reason}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {employeeExitRequest.statusId === 2 && (
                      <>
                        {!!employeeExitRequest?.docReferenceId && (
                          <>
                            <PdfViewer
                              url={
                                process.env.REACT_APP_FILES_URL +
                                "/preview/" +
                                employeeExitRequest?.docReferenceId
                              }
                            />
                          </>
                        )}
                        {!employeeExitRequest?.docReferenceId && (
                          <>
                            <div
                              className="card  w-100"
                              style={{
                                backgroundColor: "#fff",
                                borderLeft: "2px solid #3f51b5",
                                borderLeft: "2px solid #3f51b5",
                                borderRadius: "4px",
                              }}
                            >
                              <div className="card-body w-100">
                                <p className="small p-0 ms-2 mb-0 rounded-3">
                                  No letter was found because the request was
                                  approved when this feature wasn't in the
                                  system.
                                  <br />
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="mt-2"
                                    onClick={() => {
                                      setTabValue(1);
                                    }}
                                  >
                                    View request details
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              {tabValue === 0 &&
                employeeExitRequest &&
                !!employeeExitRequest.enableToGenerateLetter &&
                [1, 1003, 1005].includes(employeeExitRequest.statusId) && (
                  <>
                    <div className="mx-2 w-full">
                      {[1003, 1, 1005].includes(employeeExitRequest.statusId) &&
                        !!hasPermissions([
                          "IS_HR",
                          "IS_HEAD_OF_INSTITUTION",
                          "IS_HEAD_OF_INSTITUTION_ACTING",
                          "IS_DOCUMENT_VERIFIER",
                        ]) && (
                          <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            disabled={
                              loading || employeeExitRequest.statusId === 1
                            }
                            onClick={() => {
                              setEnableToFillLetter(!enableToFillLetter);
                              // getNPSCReport(
                              //   npscReportDetail.id,
                              //   setShowSubmissionReport,
                              //   () => {}
                              // );
                            }}
                            className="m-2 text-capitalized float-right px-3"
                          >
                            {enableToFillLetter ? "View Letter" : "Edit Letter"}
                          </Button>
                        )}

                      <ExitLetter
                        enableToFillLetter={enableToFillLetter}
                        ccSelectForm={
                          <>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              id="ccData"
                              options={entities.map(
                                ({ name, institutionEmail, id }) => ({
                                  name,
                                  institutionEmail,
                                  id,
                                })
                              )}
                              value={ccData}
                              onChange={(e, cc) => {
                                const containsWord = cc.find(
                                  ({ institutionEmail }) =>
                                    !!institutionEmail.includes("example")
                                );
                                if (!!containsWord) {
                                  showError(
                                    `The institution email (${containsWord.institutionEmail}) does not seem correct. Please consult the IPPIS Team for assistance.`
                                  );
                                  return false;
                                }
                                const uniqueCc = removeDuplicates(cc);
                                setCCData(uniqueCc);
                                const ccHasError = false;
                                setErrors({ ...errors, ccHasError });
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) =>
                                option.institutionEmail
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={
                                      selected ||
                                      !!ccData.find(
                                        ({ id }) => id === option.id
                                      )
                                    }
                                  />
                                  {option.institutionEmail}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Select the institution email to copy"}
                                  placeholder={
                                    "Select the institution email to copy"
                                  }
                                />
                              )}
                            />

                            {errors.ccHasError && (
                              <small className="text-danger mb-1">
                                Please, select the institution to copy
                              </small>
                            )}
                          </>
                        }
                        ccData={ccData}
                        docLetterNo={employeeExitForm?.docLetterNo || ""}
                        signContent={
                          <>
                            <InstitutionDetails
                              headOfInstitution={{
                                isDelegated:
                                  employeeExitRequest?.isDelegated || false,
                                isMinster:
                                  employeeExitRequest?.isMinster || false,
                                firstName:
                                  employeeExitRequest?.ministryApproverFirstName,
                                lastName:
                                  employeeExitRequest?.ministryApproverLastName,
                                institutionName:
                                  employeeExitRequest?.ministryApproverInstitutionName,
                                positionName:
                                  employeeExitRequest?.ministryApproverPositionName,
                                institutionName:
                                  employeeExitRequest?.ministryApproverInstitutionName ||
                                  "",
                                canAddInstitutionName:
                                  employeeExitRequest?.ministryApproverCanAddInstitutionName ||
                                  false,
                              }}
                              userSignature={null}
                            />
                          </>
                        }
                        subTitle={
                          "Dear " +
                          (employeeExitRequest.lastName.toUpperCase() +
                            " " +
                            employeeExitRequest.firstName)
                        }
                        recipientContent={
                          <>
                            {employeeExitRequest.lastName.toUpperCase() +
                              " " +
                              employeeExitRequest.firstName}{" "}
                            <br />
                            Tel: {employeeExitRequest.phoneNumber} <br />
                            Email: {employeeExitRequest.email} <br />
                            C/O: {employeeExitRequest.entityName}
                            {!!employeeExitRequest.acronym && (
                              <>
                                {" "}
                                <span>({employeeExitRequest.acronym})</span>
                              </>
                            )}
                          </>
                        }
                        entitySectorId={
                          employeeExitForm?.entitySectorId || null
                        }
                        letterBodyContentInput={
                          <>
                            <TextEditorComponent
                              id="editor-exit-textarea"
                              options={{
                                autoSave: false,
                                localStorageKey: `${
                                  employeeExitRequest &&
                                  employeeExitRequest.employeePositionId
                                }-${
                                  employeeExitRequest &&
                                  employeeExitRequest.exitTypeId
                                }-draftExitContent`,
                                maxCharCount: maxCharCount,
                                defaultValue: !!window.localStorage.getItem(
                                  `${
                                    employeeExitRequest &&
                                    employeeExitRequest.employeePositionId
                                  }-${
                                    employeeExitRequest &&
                                    employeeExitRequest.exitTypeId
                                  }-draftExitContent`
                                )
                                  ? window.localStorage.getItem(
                                      `${
                                        employeeExitRequest &&
                                        employeeExitRequest.employeePositionId
                                      }-${
                                        employeeExitRequest &&
                                        employeeExitRequest.exitTypeId
                                      }-draftExitContent`
                                    )
                                  : employeeExitForm.letterBodyContent,
                              }}
                              onEditorChange={getEditorContent}
                            />
                          </>
                        }
                        letterBodyContent={
                          employeeExitForm?.letterBodyContent || ""
                        }
                        title={employeeExitRequest?.letterTitle || ""}
                        copiedInstitutionContentInput={
                          <>
                            <br />
                            <strong>Provide copied institution</strong>
                            <TextEditorComponent
                              id="editor-textarea6"
                              options={{
                                autoSave: false,
                                height: "auto",
                                localStorageKey: window.localStorage.getItem(
                                  `${
                                    employeeExitRequest &&
                                    employeeExitRequest.employeePositionId
                                  }-${
                                    employeeExitRequest &&
                                    employeeExitRequest.exitTypeId
                                  }-copiedInstitution`
                                ),
                                maxCharCount: maxCharCount,
                                defaultValue: !!window.localStorage.getItem(
                                  `${
                                    employeeExitRequest &&
                                    employeeExitRequest.employeePositionId
                                  }-${
                                    employeeExitRequest &&
                                    employeeExitRequest.exitTypeId
                                  }-copiedInstitution`
                                )
                                  ? window.localStorage.getItem(
                                      `${
                                        employeeExitRequest &&
                                        employeeExitRequest.employeePositionId
                                      }-${
                                        employeeExitRequest &&
                                        employeeExitRequest.exitTypeId
                                      }-copiedInstitution`
                                    )
                                  : employeeExitForm &&
                                    employeeExitForm.copiedInstitutionContent,
                              }}
                              onEditorChange={getEditorContent3}
                            />
                          </>
                        }
                        copiedInstitutionContent={
                          employeeExitForm?.copiedInstitutionContent || ""
                        }
                      />
                      {enableToFillLetter && (
                        <Button
                          disabled={loading}
                          onClick={handleSubmitDraftRequest}
                          color={"success"}
                          variant="contained"
                          size="sm"
                          className="float-right"
                        >
                          {loading ? "Wait.." : <> Update letter</>}
                        </Button>
                      )}
                    </div>
                  </>
                )}
              {((!!employeeExitRequest.enableToGenerateLetter &&
                tabValue === 1) ||
                (!employeeExitRequest.enableToGenerateLetter &&
                  tabValue === 0)) && (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">Employee</th>
                        <td>
                          {employeeExitRequest.lastName +
                            " " +
                            employeeExitRequest.firstName}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Exit request</th>
                        <td>
                          <strong className="text-primary">
                            {employeeExitRequest.exitType}
                          </strong>
                          {employeeExitRequest.exitTypeId === 0 && (
                            <>
                              <span className="text-capitalize d-block">
                                To
                              </span>
                              <div className="text-capitalize d-block">
                                <div>{employeeExitRequest.toUnitName}</div>
                                <strong className="text-dark">
                                  ({employeeExitRequest.toPositionName})
                                </strong>
                              </div>
                            </>
                          )}
                          {employeeExitRequest.exitTypeId === 6 && (
                            <>
                              <span className="text-capitalize d-block">
                                To
                              </span>
                              <div className="t d-block">
                                <div>{employeeExitRequest.toEntityName}</div> on
                                the post of
                                <strong className="ml-1 text-dark">
                                  {employeeExitRequest.toPositionName}(
                                  {employeeExitRequest.toUnitName})
                                </strong>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Reason</th>
                        <td>{employeeExitRequest.reason}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          {employeeExitRequest?.exitTypeId === 7
                            ? "Date of Death"
                            : "Effective Date"}
                        </th>
                        <td>
                          {moment(employeeExitRequest.effectiveDate).format(
                            "LL"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Requested Date</th>
                        <td>
                          {moment(employeeExitRequest.createdOn).format("LLL")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Request By</th>
                        <td>{employeeExitRequest.requestedBy}</td>
                      </tr>
                      {employeeExitRequest.verifiedBy && (
                        <>
                          <tr>
                            <th scope="row">Verified By</th>
                            <td>{employeeExitRequest.verifiedBy}</td>
                          </tr>
                          <tr>
                            <th scope="row">Verified On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.verifiedOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      {employeeExitRequest.statusId === 3 && (
                        <>
                          <tr>
                            <th scope="row">Rejected By</th>
                            <td>{employeeExitRequest.statusByName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Rejected On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.statusOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                      {employeeExitRequest.statusId === 2 && (
                        <>
                          <tr>
                            <th scope="row">Approved By</th>
                            <td>{employeeExitRequest.statusByName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Approved On</th>
                            <td>
                              {" "}
                              {moment(employeeExitRequest.statusOn).format(
                                "LLL"
                              )}
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <th scope="row">Status</th>
                        <td>
                          <span
                            className={`badge badge-${
                              employeeExitRequest.statusId === 1
                                ? "info"
                                : employeeExitRequest.statusId === 3
                                ? "danger"
                                : employeeExitRequest.statusId === 2
                                ? "success"
                                : employeeExitRequest.statusId === 1003
                                ? "warning"
                                : "primary"
                            }`}
                          >
                            {employeeExitRequest.statusName}
                          </span>

                          {/* {employeeExitRequest.statusId === 2 && (
                          <span className="badge badge-success">Approved</span>
                        )}
                        {employeeExitRequest.statusId === 3 && (
                          <span className="badge badge-danger">Rejected</span>
                        )}
                        {(employeeExitRequest.statusId === 1003 ||
                          employeeExitRequest.statusId === 1002) && (
                          <span className="badge badge-warning">Cancelled</span>
                        )} */}
                        </td>
                      </tr>
                      {!!employeeExitRequest.statusComment && (
                        <tr
                          className={`border ${
                            employeeExitRequest.statusId === 2
                              ? "border-success"
                              : "border-danger"
                          }`}
                        >
                          <th scope="row">Comment</th>
                          <td>{employeeExitRequest.statusComment}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {((!!employeeExitRequest.enableToGenerateLetter &&
                tabValue === 2) ||
                (!employeeExitRequest.enableToGenerateLetter &&
                  tabValue === 1)) && (
                <>
                  <ViewExitRequestAttachments
                    exitTypeId={employeeExitRequest.exitTypeId}
                    requestId={employeeExitRequest.id}
                    canEdit={
                      !!hasPermissions(["IS_HR"]) &&
                      [1, 1003, 1005].includes(employeeExitRequest.statusId)
                    }
                  />
                </>
              )}
            </Box>
          )}
        </DialogContent>
        {employeeExitRequest && (
          <DialogActions>
            <Stack direction="row" spacing={2} className="mx-auto">
              {[1, 1003, 1005, 1003].includes(employeeExitRequest.statusId) && (
                <>
                  {env === "prod" &&
                    !!hasPermissions(["IS_CBM"]) &&
                    [1].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={
                            <span className="material-icons mr-1">
                              done_all
                            </span>
                          }
                          onClick={() => {
                            approveExitRequest(
                              {
                                requestId: employeeExitRequest.id,
                                employeePositionId:
                                  employeeExitRequest.employeePositionId,
                              },
                              onClose
                            );
                          }}
                        >
                          Approve
                        </Button>

                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setShowRejectRequestForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {env !== "prod" &&
                    !!hasPermissions([
                      "IS_HEAD_OF_INSTITUTION",
                      "IS_HEAD_OF_INSTITUTION_ACTING",
                    ]) &&
                    [1003].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          startIcon={
                            <span className="material-icons mr-1">
                              done_all
                            </span>
                          }
                          onClick={() => {
                            if (employeeExitRequest.enableToGenerateLetter) {
                              setShowApproveRequestForm(true);
                            } else {
                              setNewStatusId(2);
                              setShowConfirmExit(true);
                            }
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setShowRejectRequestForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {env !== "prod" &&
                    !!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    [1005].includes(employeeExitRequest.statusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          disabled={loading}
                          onClick={() => {
                            setNewStatusId(1003);
                            setShowConfirmExit(true);
                          }}
                          className="btn btn-xs"
                        >
                          Confirm
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          endIcon={
                            <span className="material-icons mr-1">cancel</span>
                          }
                          onClick={() => {
                            setNewStatusId(1003);
                            setShowConfirmExit(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {!!hasPermissions(["IS_HR"]) &&
                    [1].includes(employeeExitRequest.statusId) && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          setNewStatusId(1005);
                          setShowConfirmExit(true);
                        }}
                        className="btn btn-xs"
                      >
                        Confirm
                      </Button>
                    )}

                  {!!hasPermissions(["IS_HR"]) &&
                    [1, 1005].includes(employeeExitRequest.statusId) && (
                      <Button
                        variant="contained"
                        color="warning"
                        startIcon={
                          <span className="material-icons">cancel</span>
                        }
                        onClick={() => setShowCancelConfirmationDialog(true)}
                        disabled={loading}
                      >
                        {loading ? "Canceling..." : "Cancel request"}
                      </Button>
                    )}
                </>
              )}
            </Stack>
          </DialogActions>
        )}
      </Dialog>

      {showConfirmExit && (
        <ConfirmationDialog
          confirmationDialog={setShowConfirmExit}
          disabled={!reason}
          size="lg"
          message={
            <>
              <div>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="reason"
                  label="Reason .."
                  variant="outlined"
                  placeholder="Reason .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </div>
            </>
          }
          setConfirmationDialog={setShowConfirmExit}
          onYes={() => {
            if (newStatusId === 2) {
              approveExitRequest(
                {
                  requestId: employeeExitRequest.id,
                  employeePositionId: employeeExitRequest.employeePositionId,
                },
                onClose
              );
            } else {
              handleConfirmRequest();
            }
          }}
        />
      )}
      {showCancelConfirmationDialog && employeeExitRequest && (
        <ConfirmationDialog
          confirmationDialog={showCancelConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            employeeExitRequest?.lastName + " " + employeeExitRequest?.firstName
          }?`}
          setConfirmationDialog={setShowCancelConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(employeeExitRequest, onClose);
          }}
        />
      )}
      {showRejectRequestForm && employeeExitRequest && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this
                employeeExitRequest?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequestForm}
          onYes={() => {
            rejectRequest(
              {
                exitRequestId: employeeExitRequest.id,
                statusComment: reason,
              },
              onClose
            );
          }}
        />
      )}
      {showApproveRequestForm && employeeExitRequest && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p className="px-4">
                <SignLetter />
              </p>

              <p>Enter your RISA Digital signature password to sign</p>

              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            if (
              !!employeeExitForm.letterBodyContent &&
              !!errors.draftContentMaxCharHasError
            ) {
              showError(
                `The letter content should not exceed 1.5MB. Please reduce the number of characters.`
              );
              return false;
            }

            if (
              !!employeeExitRequest.enableToGenerateLetter &&
              replacePlaceholders(employeeExitForm.letterBodyContent, true)
            ) {
              showError(
                `Please update the following Placeholder: 1. {appointingLetterNo} - 2. {appointingLetterDate} `
              );
              return false;
            }
            // Regular expression to match one or more occurrences of <div><br></div>
            const regex = /(<div><br><\/div>)+/;

            if (
              !!employeeExitRequest.enableToGenerateLetter &&
              !!regex.test(employeeExitForm.copiedInstitutionContent) &&
              !!ccData.length
            ) {
              showError(
                `You've added the CC emails, but you didn't include the copied institution content.`
              );
              return false;
            }

            if (
              !!employeeExitRequest.enableToGenerateLetter &&
              !regex.test(employeeExitForm.copiedInstitutionContent) &&
              !ccData.length
            ) {
              showError(
                `You've included the copied institution content, but you didn't add the CC emails.`
              );
              return false;
            }

            signExitRequest(
              {
                requestId: employeeExitRequest.id,
                password: pkiPassword,
                employeePositionId: employeeExitRequest.employeePositionId,
                cc: ccData || [],
                letterBodyContent: employeeExitForm.letterBodyContent,
                copiedInstitutionContent:
                  employeeExitForm.copiedInstitutionContent,
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  employeeExitRequest,
  entities,
  env,
  exitRequestAttachments,
}) => {
  return {
    loading,
    employeeExitRequest,
    entities,
    env,
    exitRequestAttachments,
  };
};
export default connect(mapStateToProps, {
  approveExitRequest,
  rejectRequest,
  getEmployeeExitRequest,
  cancelEmployeeExitRequest,
  getEntities,
  verifyExitRequest,
  saveOrUpdateDraftRequest,
  signExitRequest,
})(ViewExitDialog);
