import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Skeleton,
  Link,
} from "@mui/material";
import rwandaLogo from "../assets/rwanda-logo.png";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getLetterCustomization } from "../../store/structure/actions";
import axios from "axios";

const LetterTemplateHeader = (props) => {
  const {
    loading,
    entitySectorId,
    letterCustomization,
    getLetterCustomization,
    disableToEdit = false,
  } = props;
  const [showEditHeaderDialog, setShowEditHeaderDialog] = useState(false);

  useEffect(() => {
    getLetterCustomization(entitySectorId);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={80} />
        </>
      ) : (
        <>
          {!!letterCustomization &&
          !!letterCustomization.isHeaderLogoSettled ? (
            <>
              <div className="mb-3">
                <div className="w-100">
                  <img
                    style={{ width: "100%" }}
                    src={
                      "data:image/png;base64," +
                      letterCustomization.letterHeaderLogo
                    }
                  />
                  {!disableToEdit && (
                    <div className="w-100 text-center">
                      <Link
                        onClick={() => {
                          setShowEditHeaderDialog(true);
                        }}
                      >
                        {" "}
                        <span className="px-0 py-0">(Edit logo)</span>{" "}
                      </Link>{" "}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#078ece",
                    height: "3px",
                    border: "1px solid rgb(7, 142, 206)",
                    borderRadius: "2px",
                  }}
                ></div>
              </div>
            </>
          ) : (
            <>
              <div className="letterhead d-flex flex-column justify-content-center w-100 py-2">
                <div
                  className="letterhead-title w-100"
                  style={{ fontWeight: "bold" }}
                >
                  REPUBLIC OF RWANDA
                </div>
                <div className="w-100">
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={rwandaLogo}
                    alt="Logo"
                  />
                </div>
                <div className="letterhead-subtitle w-100">
                  <span
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  >
                    {(letterCustomization?.entity &&
                      letterCustomization?.entity?.name?.toUpperCase()) ||
                      ""}
                  </span>

                  {!disableToEdit && (
                    <>
                      {" "}
                      <br />{" "}
                      <Link
                        onClick={() => {
                          setShowEditHeaderDialog(true);
                        }}
                      >
                        {" "}
                        <span className="px-0 py-0">(Edit logo)</span>{" "}
                      </Link>{" "}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {showEditHeaderDialog && (
            <EditHeaderDialog
              showEditHeaderDialog={showEditHeaderDialog}
              setShowEditHeaderDialog={setShowEditHeaderDialog}
              entitySectorId={entitySectorId}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, onWait, letterCustomization }) => {
  return {
    loading,
    onWait,
    letterCustomization,
  };
};
export default connect(mapStateToProps, {
  getLetterCustomization,
})(LetterTemplateHeader);

const EditHeaderDialog = (props) => {
  const { showEditHeaderDialog, setShowEditHeaderDialog, entitySectorId } =
    props;
  const dispatch = useDispatch();

  const onClose = () => {
    setShowEditHeaderDialog(false);
  };
  const [imageBase64, setImageBase64] = useState("");
  const [error, setError] = useState("");

  const [avatar, setAvatar] = useState({
    selectedImage: "",
    scaleValue: 0.88,
  });

  const [isSaving, setIsSaving] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    // Load the image as a data URL
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      // Ensure the image dimensions meet the requirements
      img.onload = () => {
        if (img.width < 700 || img.height < 100) {
          setError("Image must be at least 700px wide and 100px tall.");
          setImageBase64("");
          return;
        }

        // Convert the image to Base64
        const canvas = document.createElement("canvas");
        canvas.width = img.width; // Keep original resolution
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const base64 = canvas.toDataURL(file.type); // Convert to Base64
        setImageBase64(base64);
        setError("");
      };

      img.onerror = () => {
        setError("Invalid image file.");
        setImageBase64("");
      };
    };

    reader.onerror = () => {
      setError("Error reading file.");
    };

    reader.readAsDataURL(file);
  };

  const onSave = () => {
    if (!!error) {
      //  setError("Try to fix error(s) to continue.");
      return;
    }
    if (!imageBase64) {
      setError("No file uploaded");
      return;
    }
    const formData = new FormData();
    formData.append("entitySectorId", entitySectorId);

    formData.append("logo", imageBase64.split(",")[1]);

    submit(formData);
  };

  const submit = async (formData) => {
    try {
      setIsSaving(true);

      const { data } = await axios.post("/api/auth/letter-header", formData);
      dispatch({
        type: "SET_LETTER_CUSTOMIZATION",
        data: data,
      });
      setShowEditHeaderDialog(false);

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditHeaderDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">Edit Header</span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          <form>
            <div className="row">
              <div className={`col-12`}>
                <small>Select image of your header section (PNG, JPG)</small>
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  {imageBase64 && (
                    <div>
                      <h3>Preview:</h3>
                      <img
                        src={imageBase64}
                        alt="Uploaded"
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center py-3">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase  px-4"
                  disabled={isSaving}
                >
                  {isSaving ? "Wait..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
