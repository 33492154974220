import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { getLetterCustomization } from "../../store/structure/actions";
import LetterTemplateHeader from "./LetterTemplateHeader";
import LetterTemplateFooter from "./LetterTemplateFooter";

const LetterTemplateDialog = (props) => {
  const { selectedEntitySector, showLetterTemplate, setShowLetterTemplate } =
    props;

  const [isDownloading, setIsDownloading] = useState(false);
  const onClose = () => {
    setShowLetterTemplate(false);
  };

  const downloadTemplate = async () => {
    try {
      setIsDownloading(true);

      const response = await fetch(
        "/api/structure/download-template-letter/" + selectedEntitySector.id
      );
      const data = await response.json();

      const { filename, mimeType, base64 } = data;
      // Convert Base64 to Blob
      const blob = new Blob(
        [Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))],
        { type: mimeType }
      );

      // Create a URL and trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showLetterTemplate}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">Letter Template | </span>{" "}
                <span className="ml-0">
                  {(selectedEntitySector && selectedEntitySector?.entityName) ||
                    ""}
                  {(selectedEntitySector &&
                    selectedEntitySector?.entity?.name) ||
                    ""}
                  <span className="badge badge-secondary">
                    {(selectedEntitySector &&
                      selectedEntitySector?.sectorName) ||
                      ""}
                    {(selectedEntitySector &&
                      selectedEntitySector?.sector?.name) ||
                      ""}
                  </span>
                </span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {" "}
          <div className="letterMainContent">
            <div className="letter2 ">
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <LetterTemplateHeader
                  entitySectorId={selectedEntitySector.id}
                />

                <div className="subletterhead">
                  <table>
                    <tr>
                      <td style={{ width: "70%" }}></td>
                      <td style={{ width: "30%" }}>
                        <p
                          className="date"
                          style={{
                            fontWeight: "bold",
                            float: "left",
                            textAlign: "left",
                          }}
                        ></p>
                      </td>
                    </tr>
                  </table>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => downloadTemplate()}
                >
                  {isDownloading
                    ? "Downloading..."
                    : "Download Template in PDF"}
                </button>
                <div className="recipient" style={{ height: "45vh" }}>
                  {/* <!-- Recipient's name and address go here --> */}
                </div>
              </div>
              <LetterTemplateFooter />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, letterCustomization }) => {
  return {
    loading,
    onWait,
    letterCustomization,
  };
};
export default connect(mapStateToProps, {
  getLetterCustomization,
})(LetterTemplateDialog);
