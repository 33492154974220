import React from "react";
import moment from "moment";
import rwandaLogo from "../../assets/rwanda-logo.png";
const AuthorizationPermit = (props) => {
  const {
    request,
    headOfInstitution,
    institutionDetail,
    draftAuthorizationPermitContent,
    draftAuthorizationPermitContentInput,
    permitReferenceNoInput,
    permitReferenceNo,
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {headOfInstitution?.institutionName?.toUpperCase() || ""}
            </div>
          </div>
          <div className="subletterhead">
            {/* <!-- Date and reference number go here --> */}
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:{" "}
                    {permitReferenceNoInput
                      ? permitReferenceNoInput
                      : permitReferenceNo
                      ? permitReferenceNo
                      : "........."}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div
            className={
              draftAuthorizationPermitContentInput ? "" : "auth-content"
            }
          >
            <div className="header">
              <h1 style={{ fontWeight: "bold" }}>Authorization for training</h1>
            </div>

            <div
              style={{ marginBottom: "30px" }}
              className={draftAuthorizationPermitContentInput ? "" : "content2"}
            >
              {draftAuthorizationPermitContentInput ? (
                draftAuthorizationPermitContentInput
              ) : draftAuthorizationPermitContent ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftAuthorizationPermitContent,
                  }}
                ></div>
              ) : (
                <>
                  {" "}
                  <p>
                    I, {headOfInstitution?.firstName || ""}{" "}
                    {headOfInstitution?.lastName || ""},{" "}
                    <span className="m_title">
                      {" "}
                      {headOfInstitution?.positionName ||
                        "Minister of Public Service and Labour"}
                    </span>
                    , do hereby certify that <br /> {request.employeeTitle}.{" "}
                    {request.lastName.toUpperCase()} {request.firstName},{" "}
                    {request.positionName} in the {request.entityName} is
                    authorised to attend a training on {request.trainingTitle}{" "}
                    in {request.countryForTraining}{" "}
                    <>
                      from {moment(request.trainingDurationFrom).format("ll")}{" "}
                      to {moment(request.trainingDurationTo).format("ll")}.{" "}
                      {!request.isTrainingInsideCountry && (
                        <>
                          The date of departure is{" "}
                          {moment(request.departureDate).format("ll")} and the
                          returning date is{" "}
                          {moment(request.returningDate).format("ll")}.
                        </>
                      )}
                    </>{" "}
                    {request.coveredRelatedCostsDetails}.
                  </p>
                  <p>
                    I highly appreciate any assistance accorded to{" "}
                    {request.genderId === "M" ? "him" : "her"} in this regard.
                  </p>
                </>
              )}
            </div>

            <div className="signature">{institutionDetail}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizationPermit;
