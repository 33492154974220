import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import {
  requestProvisionSuspension,
  approveProvisionSuspension,
  getDisciplineLetterNo,
} from "../../../store/discipline/actions";
import { getHeadOfUnit } from "../../../store/structure/actions";
import {
  removeDuplicates,
  textCapitalized,
} from "../../common/components/Utils";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import DisciplinaryProvisionalSuspensionBeforeSanction from "../../documents/LetterFormat/DisciplinaryProvisionalSuspensionBeforeSanction";

const RequestProvisionSuspension = (props) => {
  const {
    loading,
    showCaseReportingForm,
    setShowCaseReportingForm,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    requestProvisionSuspension,
    caseRequest,
    enableToFillLetter,
    action = "NEW",
    isProvisionSuspensionLetterRequired,
    faults,
    getDisciplineLetterNo,
    disciplineLetterNo,
    approveProvisionSuspension,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [reason, setReason] = useState("");
  const textareaRef = useRef(null);
  const [showCaseReasonForm, setShowCaseReasonForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");
  const [showProvSuspensionApproverForm, seShowProvSuspensionApproverForm] =
    useState(false);
  const [_caseRequest, setCaseRequest] = useState(caseRequest?.request || null);
  const formattingFaults = (faults) => {
    return faults
      .map((fault, index) => `${index + 1}. ${fault.faultName}\n`)
      .join("");
  };

  const [provisionalSuspensionCC, setProvisionalSuspensionCC] = useState(
    _caseRequest && !!_caseRequest.provisionalSuspensionCC
      ? JSON.parse(_caseRequest?.provisionalSuspensionCC || [])
      : []
  );

  const [formData, setFormData] = useState({
    caseId: _caseRequest?.id || "",
    actionStatusId: action==='APPROVE'?10: action==='NEW'?8:9,
    provisionalSuspensionNo:
      _caseRequest?.provisionalSuspensionNo || disciplineLetterNo,
    provisionalSuspensionReason:
      _caseRequest?.provisionalSuspensionReason ||
      `${formattingFaults(faults)}`,

    provisionalSuspensionCC:
      _caseRequest && !!_caseRequest.provisionalSuspensionCC
        ? JSON.parse(_caseRequest?.provisionalSuspensionCC || [])
        : [],

    provisionalSuspensionEffectDate:
      _caseRequest.provisionalSuspensionEffectDate
        ? moment(_caseRequest?.provisionalSuspensionEffectDate || null).format(
            "YYYY-MM-DD"
          )
        : null,
  });

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!_caseRequest.isProvisionSuspensionLetterRequired) {
      getDisciplineLetterNo();
    }
  }, [_caseRequest]);

  useEffect(() => {
    if (!_caseRequest.isProvisionSuspensionLetterRequired) {
      setFormData({ ...formData, provisionalSuspensionNo: disciplineLetterNo });
    }
  }, [_caseRequest, disciplineLetterNo]);

  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  useEffect(() => {
    // Initial height adjustment if there's any content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    // Initial height adjustment if there's any content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
    provisionalSuspensionEffectDateError: false,
    provisionalSuspensionReasonError: false,
    explanationFeedbackLetterNoError: false,
    provisionalSuspensionNoError: false,
    explanationFeedbackContentLetterError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      maxCharHasError: false,
      provisionalSuspensionEffectDateError: false,
      provisionalSuspensionReasonError: false,
      explanationFeedbackLetterNoError: false,
      provisionalSuspensionNoError: false,
      explanationFeedbackContentLetterError: false,
    };

    if (!formData.provisionalSuspensionEffectDate) {
      error.provisionalSuspensionEffectDateError = true;
      error.hasError = true;
    }

    if (!formData.provisionalSuspensionReason) {
      error.provisionalSuspensionReasonError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill the required field");
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowCaseReasonForm(false);
    seShowProvSuspensionApproverForm(false);
    setShowCaseReportingForm(false);
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.provisionalSuspensionCC = JSON.stringify(
        provisionalSuspensionCC
      );
      formData.reason = reason;

      requestProvisionSuspension(formData, caseRequest, onClose);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCaseReportingForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>{action} Provision Suspension Letter</span> /{" "}
            <span className="text-primary">
              {textCapitalized(_caseRequest?.lastName)}
              {textCapitalized(_caseRequest?.firstName)}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-2">
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <DisciplinaryProvisionalSuspensionBeforeSanction
              recipientName={
                _caseRequest.lastName + " " + _caseRequest.firstName
              }
              recipientEmail={_caseRequest.email}
              recipientPhone={_caseRequest.phoneNumber}
              institutionName={_caseRequest.entityName}
              recruitmentInstitutionName={_caseRequest.entityName}
              recruitmentPositionName={_caseRequest.positionName}
              effectiveDate={moment(
                _caseRequest.provisionalSuspensionEffectDate
              ).format("LL")}
              enableToFillLetter={enableToFillLetter || false}
              docLetterNo={formData.provisionalSuspensionNo}
              provisionalSuspensionReason={
                _caseRequest.provisionalSuspensionReason
              }
              isProvisionSuspensionLetterRequired={
                isProvisionSuspensionLetterRequired === 1 ? true : false
              }
              provisionalSuspensionEffectDate={moment(
                _caseRequest.provisionalSuspensionEffectDate
              ).format("LL")}
              subTitle={
                "Dear " + (_caseRequest.lastName + " " + _caseRequest.firstName)
              }
              provisionalSuspensionReasonInput={
                <>
                  <Input
                    autoFocus
                    error={!!errors.provisionalSuspensionReasonError}
                    value={formData.provisionalSuspensionReason}
                    style={{ width: "100%" }}
                    inputProps={{
                      "aria-label": "Provisional Suspension Reason",
                      maxLength: 300,
                    }}
                    inputRef={textareaRef}
                    multiline
                    onChange={(e) => {
                      const provisionalSuspensionReason = e.target.value;
                      setFormData({
                        ...formData,
                        provisionalSuspensionReason,
                      });

                      if (textareaRef.current) {
                        textareaRef.current.style.height = "auto";
                        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                      }

                      setErrors({
                        ...errors,
                        provisionalSuspensionReasonError: false,
                      });
                    }}
                  />
                </>
              }
              // effectiveDate={moment(formData.effectiveOn).format("LL")}
              competentAuthorityName={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail.competentAuthorityUser.names
                  : ""
              }
              competentAuthorityPosition={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail?.competentAuthorityUser
                      ?.positionName
                  : ""
              }
              ccSelectForm={
                <>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    id="ccData"
                    options={entities.map(({ name, institutionEmail, id,ccName }) => ({
                      name,
                      institutionEmail,
                      id,ccName
                    }))}
                    value={
                      Array.isArray(provisionalSuspensionCC)
                        ? provisionalSuspensionCC
                        : []
                    }
                    onChange={(e, cc) => {
                      const uniqueCc = removeDuplicates(cc);
                      setProvisionalSuspensionCC(uniqueCc);
                      const ccHasError = false;
                      setErrors({ ...errors, ccHasError });
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            selected ||
                            (!!Array.isArray(provisionalSuspensionCC)
                              ? provisionalSuspensionCC
                              : []
                            ).find(({ id }) => id === option.id)
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Select the institution to copy"}
                        placeholder={"Select the institution to copy"}
                      />
                    )}
                  />
                  {/* {errors.ccHasError && (
                    <small className="text-danger mb-1">
                      Please, select the institution to copy
                    </small>
                  )} */}
                </>
              }
              effectiveDateInput={
                <>
                  <Input
                    type="date"
                    error={!!errors.provisionalSuspensionEffectDateError}
                    value={
                      formData.provisionalSuspensionEffectDate ||
                      _caseRequest?.provisionalSuspensionEffectDate ||
                      null
                    }
                    style={{ width: "150px" }}
                    inputProps={{
                      "aria-label": "add reference date",
                    }}
                    onChange={(e) => {
                      const provisionalSuspensionEffectDate = e.target.value;
                      setFormData({
                        ...formData,
                        provisionalSuspensionEffectDate,
                      });

                      setErrors({
                        ...errors,
                        provisionalSuspensionEffectDateError: false,
                      });
                    }}
                  />
                </>
              }
              ccData={
                Array.isArray(provisionalSuspensionCC)
                  ? provisionalSuspensionCC
                  : []
              }
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        position: "relative",

                        width: "20%",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                      ["APPROVE"].includes(action) ? (
                        <>
                          <SignLetter />
                        </>
                      ) : (
                        <>
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </>
                      )}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
            />
          </Box>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-2">
          {!!hasPermissions([
            "IS_HR",
            "IS_DOCUMENT_VERIFIER",
            "IS_HEAD_OF_INSTITUTION",
          ]) &&
            enableToFillLetter && (
              <Button
                variant="contained"
                color="success"
                type="button"
                disabled={loading}
                onClick={() => {
                  if (!formValidator()) {
                    if (
                      ["APPROVE"].includes(action) &&
                      hasPermissions(["IS_HEAD_OF_INSTITUTION"])
                    ) {
                      seShowProvSuspensionApproverForm(true);
                    } else {
                      if( ["NEW"].includes(action)){
                        setShowCaseReasonForm(true)
                      }else{
                        onSave();
                      }
                     
                    }
                  }
                }}
                className="btn btn-xs"
              >
                {loading ? "Wait.." : "Submit"}
              </Button>
            )}
        </DialogActions>

        {showCaseReasonForm && (
          <ConfirmationDialog
            confirmationDialog={setShowCaseReasonForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReasonForm}
            onYes={() => {
              onSave();
            }}
          />
        )}

        {showProvSuspensionApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showProvSuspensionApproverForm}
            message={
              <>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={seShowProvSuspensionApproverForm}
            onYes={() => {
              if (!formValidator()) {
                formData.provisionalSuspensionCC = JSON.stringify(
                  provisionalSuspensionCC
                );

                formData.password = pkiPassword;

                approveProvisionSuspension(formData, caseRequest, onClose);
              }
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
  headOfUnit,
  disciplineLetterNo,
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
    headOfUnit,
    disciplineLetterNo,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  requestProvisionSuspension,
  approveProvisionSuspension,
  getHeadOfUnit,
  getDisciplineLetterNo,
})(RequestProvisionSuspension);
