import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SignLetter from "../../documents/LetterFormat/SignLetter";
import { a11yProps } from "../../common/components/TabPanel";
import AdvertisementReport from "./AdvertisementReport";
import SubmitReportDialog from "./SubmitReportDialog";
import { cancelNPSCReport,getNPSCReport } from "../../../store/recruitment/actions";
const AdvertisementReportDialog = (props) => {
  const {
    loading,
    showAdvertisementReport,
    setShowAdvertisementReport,
    advertisement,
    cancelNPSCReport,
    getNPSCReport
  } = props;
  const { hasPermissions } = useRequiredAuth();

  const [maxWidth, setMaxWidth] = useState("xl");
  const [showSubmissionReport, setShowSubmissionReport] = useState(false);
  const [showCancelRequestForm, setShowCancelRequestForm] = useState(false);
  
  const onClose = () => {
    setShowAdvertisementReport(false);
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAdvertisementReport}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>NPSC Report</span> /{" "}
          
            
              <span className="badge badge-info">
                {advertisement?.npscReportStatusName || 'Draft'}
              </span>
        
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <AdvertisementReport details={{...advertisement,advertisementId:advertisement.id,npscReportId:advertisement?.npscReportId || 0}}
            canApproveCandidates={false} />
          </Box>
        </DialogContent>
        {/* {npscReportDetail?.statusId === 4 && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Alert severity="error">
                <strong>Reason:</strong> {npscReportDetail.statusComment}
              </Alert>
            </DialogActions>
          </>
        )} */}
        {!!hasPermissions(["IS_HR"]) && !advertisement?.npscReportId && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={loading}
                onClick={() => {
                  setShowSubmissionReport(true);
                }}
                className="btn btn-xs"
              >
                Request To Submit Report To NPSC
              </Button>
            </DialogActions>
          </>
        )}
        {!!hasPermissions(["IS_HR"]) &&
          +advertisement?.npscReportStatusId===1 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    getNPSCReport(advertisement.npscReportId,setShowSubmissionReport,()=>{})
                  }}
                  className="btn btn-xs"
                >
                  Edit Requested Letter
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowCancelRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Cancel Request
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      {showSubmissionReport && (
        <SubmitReportDialog
          showSubmissionReport={showSubmissionReport}
          setShowSubmissionReport={setShowSubmissionReport}
          advertisement={{...advertisement,advertisementId:advertisement.id}}
          onCloseSubmitReport={onClose}
        />
      )}
       {showCancelRequestForm && (
        <ConfirmationDialog
          disabled={loading}
          confirmationDialog={showCancelRequestForm}
          message={
            <>
              <p>
                Are you sure you want to <strong className="text-danger">cancel</strong> this request?
              </p>
            </>
          }
          setConfirmationDialog={setShowCancelRequestForm}
          onYes={() => {
            cancelNPSCReport(
              {
                npscReportId: advertisement?.npscReportId,
                advertisementId: advertisement.id,
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {cancelNPSCReport,getNPSCReport})(AdvertisementReportDialog);
