import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Input,
  Checkbox,
  LinearProgress,
  Alert,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  saveRequest,
  cancelEmployeeExitRequest,
  saveOrUpdateDraftRequest,
  submitFinalRequest,
} from "../../../store/exit/actions";

import {
  getUnitPositions,
  getEntities,
  getEntitySectors,
} from "../../../store/structure/actions";

import moment from "moment";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  containsKeyInReplacements,
  removeDuplicates,
  replaceTextPlaceholders,
} from "../../common/components/Utils";
import axios from "axios";
import ExitLetter from "../../documents/LetterFormat/ExitLetter";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import ViewExitRequestAttachments from "../../exit/components/ViewExitRequestAttachments";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import InstitutionDetails from "../../training/components/InstitutionDetails";
const steps = [
  { key: 0, name: "Fill Request Form" },
  { key: 1, name: "Fill Attachment(s)" },
  { key: 2, name: "Fill Request Letter" },
];

const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const maxCharCount = 1500;
const NewExitRequestDialog = (props) => {
  const {
    loading,
    onWait,
    showEmployeeExitForm,
    setShowEmployeeExitForm,
    saveRequest,
    exitTypes,
    units,
    employeeExitRequest,
    cancelEmployeeExitRequest,
    getUnitPositions,
    positions,
    getEntities,
    entities,
    getEntitySectors,
    entitySectors,
    selectedEmployee,
    saveOrUpdateDraftRequest,
    submitFinalRequest,
    user,
  } = props;

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  //getEntities();

  const [replacements, setReplacements] = useState({
    secondedPositionName: "",
    secondedInstitutionName: "",
    appointingLetterNo: "",
    appointingLetterDate: "",
    currentPositionName: "",
    currentEntityName: "",
    tranferredPositionName: "",
    tranferredEntityName: "",
    effectiveDate: moment().format("LL"),
  });
  const [letterFooterContent, setLetterFooterContent] = useState(null);

  const [loadingEntitySectors, setLoadingEntitySectors] = useState(false);
  const [exitType, setExitType] = useState(null);

  const [localStorageKey, setLocalStorageKey] = useState({
    letter: `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
      employeeExitRequest && employeeExitRequest.exitTypeId
    }-draftExitContent`,
    cc: `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
      employeeExitRequest && employeeExitRequest.exitTypeId
    }-copiedInstitution`,
  });

  const [filteredSteps, setFilteredSteps] = useState([]);
  const [conditions, setConditions] = useState({
    isAttachmentRequired: true, // Change this to false or null to test removal of "Fill Attachment(s)"
    enableToGenerateLetter: true, // Change this to false to test removal of "Fill Request Letter"
  });
  const [enableToFillLetter, setEnableToFillLetter] = useState(true);

  const [entity, setEntity] = useState(null);
  const [unit, setUnit] = useState(null);
  const [position, setPosition] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [ccData, setCCData] = useState(
    employeeExitRequest && !!employeeExitRequest.cc
      ? JSON.parse(employeeExitRequest?.cc || [])
      : []
  );

  const [entitySectorUnits, setEntitySectorUnits] = useState([]);

  const [employeeExitForm, setEmployeeExitForm] = useState({
    requestId: employeeExitRequest?.id || null,
    employeeId: selectedEmployee.id,
    employeePositionId: selectedEmployee.employeePositionId,
    positionId: selectedEmployee.positionId,
    transferredPositionId: "",
    exitTypeId: employeeExitRequest?.exitTypeId || "",
    effectiveDate: moment().format("YYYY-MM-DD"),
    blacklistPeriod: 0,
    reason: employeeExitRequest?.reason || "",
    letterBodyContent: employeeExitRequest?.letterBodyContent || "",
    docLetterNo: employeeExitRequest?.docLetterNo || "",
    entitySectorId:
      employeeExitRequest?.entitySectorId ||
      selectedEmployee?.entitySectorId ||
      null,

    copiedInstitutionContent:
      employeeExitRequest?.copiedInstitutionContent || "",
    cc:
      employeeExitRequest && !!employeeExitRequest.cc
        ? JSON.parse(employeeExitRequest?.cc || [])
        : [],
  });

  useEffect(() => {
    if (!!entity) getEntitySectorUnits(entity.id);
  }, [entity]);

  useEffect(() => {
    if (!!unit) getUnitPositions(unit.unitId);
  }, [unit]);

  useEffect(() => {
    setActiveStep(
      (employeeExitRequest && employeeExitRequest?.requestFormStepper) || 0
    );

    if (employeeExitRequest) {
      setLocalStorageKey({
        letter: `${employeeExitRequest.employeePositionId}-${
          employeeExitRequest && employeeExitRequest.exitTypeId
        }-draftExitContent`,
        cc: `${employeeExitRequest.employeePositionId}-${
          employeeExitRequest && employeeExitRequest.exitTypeId
        }-copiedInstitution`,
      });
      setEmployeeExitForm({
        ...employeeExitForm,
        requestId: employeeExitRequest?.id || null,
        employeeId: employeeExitRequest?.employeeId || null,
        employeePositionId: employeeExitRequest.employeePositionId,
        positionId: employeeExitRequest.positionId,
        transferredPositionId: employeeExitRequest.toPositionId,
        toEntityId: employeeExitRequest.toEntityId,
        tranferredEntityName: employeeExitRequest.toEntityName,
        exitTypeId: employeeExitRequest.exitTypeId,
        effectiveDate: moment(employeeExitRequest.effectiveOn).format(
          "YYYY-MM-DD"
        ),
        blacklistPeriod: employeeExitRequest.blacklistPeriod,
        reason: employeeExitRequest.reason,
        // letterBodyContent: employeeExitRequest?.letterBodyContent || "",
        docLetterNo: employeeExitRequest?.docLetterNo || "",
        entitySectorId:
          employeeExitRequest?.entitySectorId ||
          selectedEmployee?.entitySectorId ||
          null,
        // cc:
        //   employeeExitRequest && !!employeeExitRequest.cc
        //     ? JSON.parse(employeeExitRequest?.cc || [])
        //     : [],
        // copiedInstitutionContent:
        //   employeeExitRequest?.copiedInstitutionContent || "",
      });

      const entity =
        entitySectors.find(
          ({ entity }) => entity.id === employeeExitRequest.toEntityId
        ) || null;
      setEntity(entity);
      const exitType =
        exitTypes.find(({ id }) => id === employeeExitRequest.exitTypeId) ||
        null;
      setExitType(exitType);

      setConditions({
        ...conditions,
        isAttachmentRequired: employeeExitRequest.isAttachmentRequired,
        enableToGenerateLetter: employeeExitRequest.enableToGenerateLetter,
      });
    }
  }, [employeeExitRequest]);
  // console.log(conditions);
  //
  useEffect(() => {
    if (employeeExitRequest) {
      const unit =
        units.find(({ unitId }) => unitId === employeeExitRequest.toUnitId) ||
        null;
      setUnit(unit);
    }
  }, [units]);

  useEffect(() => {
    if (employeeExitRequest) {
      const position =
        positions.find(({ id }) => id === employeeExitRequest.toPositionId) ||
        null;
      setPosition(position);
    }
  }, [positions]);

  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    reasonHasError: false,
    effectiveDateHasError: false,
    entityHasError: false,
    unitHasError: false,
    positionHasError: false,
    referenceLetterIdError: false,
    referenceLetterDateError: false,
    draftContentMaxCharHasError: false,
    docLetterNoError: false,
    effectiveDateError: false,
    ccHasError: false,
  });

  const formValidator = () => {
    const error = {
      exitTypeHasError: false,
      reasonHasError: false,
      effectiveDateHasError: false,
      entityHasError: false,
      unitHasError: false,
      positionHasError: false,
      hasError: false,
    };

    if (!exitType) {
      error.exitTypeHasError = true;
      error.hasError = true;
    }

    if (!employeeExitForm.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!employeeExitForm.effectiveDate) {
      error.effectiveDateHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 0 && !unit) {
      error.unitHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 0 && !position) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 6 && !entity) {
      error.entityHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Fill the required fields");
      return true;
    }
    return false;
  };

  const getBlacklistPeriod = () =>
    +(exitType?.blacklistPeriod || 0) > 1
      ? (exitType?.blacklistPeriod || 0).toString() + " Years"
      : (exitType?.blacklistPeriod || 0).toString() + " Year";

  const onClose = () => {
    setEmployeeExitForm({
      requestId: null,
      employeeId: "",
      employeePositionId: "",
      positionId: "",
      transferredPositionId: "",
      exitTypeId: "",
      effectiveDate: moment().format("YYYY-MM-DD"),
      blacklistPeriod: 0,
      reason: "",
      letterBodyContent: "",
      docLetterNo: "",
      entitySectorId: "",
      copiedInstitutionContent: "",
      cc: [],
    });
    setShowConfirmationDialog(false);
    setShowEmployeeExitForm(false);
  };

  const onSave = () => {
    if (!exitType) {
      showError(`Please fill the exit type!`);
      return false;
    }

    if ([0, 6].includes(+exitType.id)) {
      if (!position) {
        showError(`No position selected.`);
        return false;
      }
      if (+position.numberOfVacantPosts === 0) {
        showError(
          `This position isn't available. there is (${position.numberOfVacantPosts}) number of vacant posts`
        );
        return false;
      }
    }

    const error = {
      exitTypeHasError: false,
      reasonHasError: false,
      effectiveDateHasError: false,
      hasError: false,
    };

    if (!employeeExitForm.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!employeeExitForm.effectiveDate) {
      error.effectiveDateHasError = true;
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      showError("Fill the required fields");
      return true;
    }
    saveRequest(
      {
        ...employeeExitForm,
        effectiveDate: moment(employeeExitForm.effectiveDate).format(
          "YYYY-MM-DD"
        ),
        entityId: entity?.entity?.id || null,
        positionId: position?.id || null,
        exitTypeId: exitType.id,
        cc: ccData || [],
      },
      onClose
    );
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch) + 1;
  };

  useEffect(() => {
    let date = new Date();
    if (!!exitType) {
      if (exitType.effectiveInDays === 0) {
        setEmployeeExitForm({
          ...employeeExitForm,
          effectiveDate: moment().format("YYYY-MM-DD"),
        });
      } else {
        setEmployeeExitForm({
          ...employeeExitForm,
          effectiveDate: moment()
            .add(exitType.effectiveInDays, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [exitType]);

  useEffect(() => {
    // Filter steps based on the provided conditions.
    setFilteredSteps([
      ...steps.filter((step) => {
        if (step.key === 1 && !conditions.isAttachmentRequired) {
          return false; // Remove "Fill Attachment(s)" if isAttachmentRequired is false or null
        }
        if (step.key === 2 && !conditions.enableToGenerateLetter) {
          return false; // Remove "Fill Request Letter" if enableToGenerateLetter is false
        }
        return true;
      }),
    ]);
  }, [conditions, steps]);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getEntitySectorUnits = async (entitySectorId) => {
    try {
      setLoadingEntitySectors(true);
      const { data } = await axios.get(
        "/api/structure/entity-sector-units/" + entitySectorId
      );
      setEntitySectorUnits(data);
      setLoadingEntitySectors(false);
    } catch (error) {
      setLoadingEntitySectors(false);
      showError(error);
    }
  };
  const getEditorContent = (data) => {
    let draftContentMaxCharHasError = false;
    setEmployeeExitForm({
      ...employeeExitForm,
      letterBodyContent: data.contents,
    });
    if (!!data.isMaxChar) draftContentMaxCharHasError = true;

    setErrors({
      ...errors,
      draftContentMaxCharHasError: draftContentMaxCharHasError,
    });
  };
  const getEditorContent3 = (data) => {
    console.log(data.contents);
    setEmployeeExitForm({
      ...employeeExitForm,
      copiedInstitutionContent: data.contents,
    });
    // employeeExitRequest.copiedInstitutionContent = data.contents;
  };
  const replacePlaceholders = (
    template,
    isVerifyContainsKeyInReplacement = false
  ) => {
    const replacement = {
      ...replacements,

      currentPositionName: selectedEmployee?.positionName || "",
      currentEntityName: selectedEmployee?.entityName || "",
      tranferredPositionName: position ? position.name : "",
      tranferredEntityName:
        exitType && exitType.id === 0
          ? selectedEmployee?.entityName || ""
          : entity?.entity?.name || "",
      effectiveDate: moment(employeeExitForm.effectiveDate).format("LL"),
    };
    setReplacements(replacement);
    if (!!isVerifyContainsKeyInReplacement)
      return containsKeyInReplacements(template, replacement);
    return replaceTextPlaceholders(template, replacement);
  };
  const handleSubmitDraftRequest = (disableAddStepper = false) => {
    if (!formValidator()) {
      if (!exitType) {
        showError(`Please fill the exit type!`);
        return false;
      }

      if ([0, 6].includes(+exitType.id)) {
        if (!position) {
          showError(`No position selected.`);
          return false;
        }
        if (+position.numberOfVacantPosts === 0) {
          showError(
            `This position isn't available. there is (${position.numberOfVacantPosts}) number of vacant posts`
          );
          return false;
        }
      }
      // if (
      //   !exitRequestAttachments.length &&
      //   activeStep == 1 &&
      //   exitType.isAttachmentRequired
      // ) {
      //   showError(`The request document(s) are required`);
      //   return false;
      // }
      const requestFormStepper = !!disableAddStepper
        ? activeStep
        : activeStep + 1;
      if (!!exitType.enableToGenerateLetter) {
        setEnableToFillLetter(true);
      }

      if (!employeeExitRequest) {
        window.localStorage.removeItem(localStorageKey.letter);
        window.localStorage.removeItem(localStorageKey.cc);

        employeeExitForm.letterBodyContent = replacePlaceholders(
          exitType?.letterTempBody || ""
        );

        window.localStorage.setItem(
          localStorageKey.letter,
          employeeExitForm.letterBodyContent
        );
      }
      if (
        employeeExitRequest &&
        employeeExitRequest.exitTypeId !== exitType.id
      ) {
        window.localStorage.removeItem(localStorageKey.letter);
        window.localStorage.removeItem(localStorageKey.cc);
        if (employeeExitRequest.enableToFillLetter) {
          employeeExitForm.letterBodyContent = replacePlaceholders(
            exitType?.letterTempBody || ""
          );
          window.localStorage.setItem(
            localStorageKey.letter,
            employeeExitForm.letterBodyContent
          );
        }
      }

      if (
        employeeExitRequest &&
        !!position &&
        employeeExitRequest.toPositionId !== position.id
      ) {
        window.localStorage.removeItem(localStorageKey.letter);
        if (employeeExitRequest.enableToFillLetter) {
          employeeExitForm.letterBodyContent = replacePlaceholders(
            exitType?.letterTempBody || ""
          );
          window.localStorage.setItem(
            localStorageKey.letter,
            employeeExitForm.letterBodyContent
          );
        }
      }

      if (
        !!employeeExitForm.letterBodyContent &&
        !!errors.draftContentMaxCharHasError
      ) {
        showError(
          `The letter content should not exceed 1.5MB. Please reduce the number of characters.`
        );
        return false;
      }

      saveOrUpdateDraftRequest(
        {
          ...employeeExitForm,
          effectiveDate: moment(employeeExitForm.effectiveDate).format(
            "YYYY-MM-DD"
          ),
          requestId: employeeExitRequest?.id || null,
          entityId: entity?.entity?.id || null,
          currentPositionId: selectedEmployee.positionId || null,
          exitTypeId: exitType.id,
          cc: ccData || [],
          requestFormStepper: requestFormStepper,
          transferredPositionId: position?.id || null,
          letterBodyContent: employeeExitForm.letterBodyContent,
          employeeId: selectedEmployee?.id || null,
          enableToGenerateLetter: conditions.enableToGenerateLetter,
          isAttachmentRequired: conditions.isAttachmentRequired,
          copiedInstitutionContent: employeeExitForm.copiedInstitutionContent,
        },
        (status, data) => {
          if (!!status && !!data) {
            setActiveStep(requestFormStepper);

            if (!!exitType.enableToGenerateLetter) {
              // window.localStorage.setItem(
              //   `${data.employeePositionId}-${data.exitTypeId}-draftExitContent`,
              //   data.letterBodyContent
              // );
              setEmployeeExitForm({
                ...employeeExitForm,
                requestId: data?.id || null,
                employeeId: data?.employeeId || null,
                employeePositionId: data.employeePositionId,
                positionId: data.positionId,
                transferredPositionId: data.toPositionId,
                toEntityId: data.toEntityId,
                tranferredEntityName: data.toEntityName,
                exitTypeId: data.exitTypeId,
                effectiveDate: moment(data.effectiveOn).format("YYYY-MM-DD"),
                blacklistPeriod: data.blacklistPeriod,
                reason: data.reason,
                letterBodyContent: data?.letterBodyContent || "",
                docLetterNo: data?.docLetterNo || "",
                entitySectorId: data?.entitySectorId,
                cc: !!data.cc ? JSON.parse(data?.cc || []) : [],
                copiedInstitutionContent: data?.copiedInstitutionContent || "",
              });
              setCCData(!!data.cc ? JSON.parse(data?.cc || []) : []);
              const entity =
                entitySectors.find(
                  ({ entity }) => entity.id === data.toEntityId
                ) || null;

              setEntity(entity);

              const exitType =
                exitTypes.find(({ id }) => id === data.exitTypeId) || null;
              setExitType(exitType);

              setConditions({
                ...conditions,
                isAttachmentRequired: data.isAttachmentRequired,
                enableToGenerateLetter: data.enableToGenerateLetter,
              });

              setEmployeeExitForm({
                ...employeeExitForm,
                docLetterNo: data.docLetterNo,
                letterBodyContent: data.letterBodyContent,
                copiedInstitutionContent: data.copiedInstitutionContent,
              });
            }
          }
        }
      );
    }
  };
  const handleSubmitRequestAndAttachment = () => {
    if (!formValidator()) {
      if (!exitType) {
        showError(`Please fill the exit type!`);
        return false;
      }

      if ([0, 6].includes(+exitType.id)) {
        if (!position) {
          showError(`No position selected.`);
          return false;
        }
        if (+position.numberOfVacantPosts === 0) {
          showError(
            `This position isn't available. there is (${position.numberOfVacantPosts}) number of vacant posts`
          );
          return false;
        }
      }
      // if (!exitRequestAttachments.length) {
      //   showError(`The request document(s) are required`);
      //   return false;
      // }
      // employeeExitForm.letterBodyContent = window.localStorage.getItem(
      //   `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
      //     employeeExitRequest && employeeExitRequest.exitTypeId
      //   }-draftExitContent`
      // );
      // employeeExitForm.copiedInstitutionContent = window.localStorage.getItem(
      //   `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
      //     employeeExitRequest && employeeExitRequest.exitTypeId
      //   }-copiedInstitution`
      // );

      submitFinalRequest(
        {
          ...employeeExitForm,
          effectiveDate: moment(employeeExitForm.effectiveDate).format(
            "YYYY-MM-DD"
          ),
          requestId: employeeExitRequest?.id || null,
          entityId: entity?.entity?.id || null,
          currentPositionId: selectedEmployee.positionId || null,
          exitTypeId: exitType.id,
          cc: ccData || [],
          requestFormStepper: 0,
          transferredPositionId: position?.id || null,
          letterBodyContent: employeeExitForm.letterBodyContent,
          employeeId: selectedEmployee?.id || null,
          enableToGenerateLetter: conditions.enableToGenerateLetter,
          isAttachmentRequired: conditions.isAttachmentRequired,
          copiedInstitutionContent: employeeExitForm.copiedInstitutionContent,
        },
        (status) => {
          if (!!status) {
            onClose();
          }
        }
      );
    }
  };

  const handleSubmitRequestAndLetter = () => {
    if (!formValidator()) {
      if (!exitType) {
        showError(`Please fill the exit type!`);
        return false;
      }

      if ([0, 6].includes(+exitType.id)) {
        if (!position) {
          showError(`No position selected.`);
          return false;
        }
        if (+position.numberOfVacantPosts === 0) {
          showError(
            `This position isn't available. there is (${position.numberOfVacantPosts}) number of vacant posts`
          );
          return false;
        }
      }
      if (
        !!letterFooterContent &&
        !letterFooterContent?.entity?.institutionPhone
      ) {
        showError(`The institution phone number is required. Please update it`);
        return false;
      }

      if (
        letterFooterContent?.entity?.institutionPhone.includes("+25078XXXXXXX")
      ) {
        showError(
          `The institution phone number (${
            letterFooterContent?.entity?.institutionPhone || ""
          }) does not seem correct. Please update it.`
        );
        return false;
      }

      if (
        !!letterFooterContent &&
        !letterFooterContent?.entity?.institutionEmail
      ) {
        showError(`The institution email is required. Please update it`);
        return false;
      }

      if (letterFooterContent?.entity?.institutionEmail.includes("example")) {
        showError(
          `The institution email (${
            letterFooterContent?.entity?.institutionEmail || ""
          }) does not seem correct. Please update it.`
        );
        return false;
      }

      if (!!letterFooterContent && !letterFooterContent?.entity?.poBox) {
        showError(`The institution PO.Box is required. Please update it`);
        return false;
      }

      if (!!letterFooterContent && !letterFooterContent?.entity?.websiteUrl) {
        showError(`The institution website url is required. Please update it`);
        return false;
      }
      if (!!letterFooterContent && !letterFooterContent?.entity?.twitterPage) {
        showError(
          `The institution former(twitter) is required. Please update it`
        );
        return false;
      }
      //letterFooterContent

      employeeExitForm.letterBodyContent =
        window.localStorage.getItem(
          `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
            employeeExitRequest && employeeExitRequest.exitTypeId
          }-draftExitContent`
        ) || employeeExitForm.letterBodyContent;
      employeeExitForm.copiedInstitutionContent =
        window.localStorage.getItem(
          `${employeeExitRequest && employeeExitRequest.employeePositionId}-${
            employeeExitRequest && employeeExitRequest.exitTypeId
          }-copiedInstitution`
        ) || employeeExitForm.copiedInstitutionContent;

      // if (
      //   !exitRequestAttachments.length &&
      //   !!employeeExitRequest.isAttachmentRequired
      // ) {
      //   showError(`The request document(s) are required`);
      //   return false;
      // }
      console.log(employeeExitForm, ccData);
      if (
        !!employeeExitRequest.enableToGenerateLetter &&
        !employeeExitForm.letterBodyContent
      ) {
        showError(`The letter content is required`);
        return false;
      }
      // console.log(employeeExitForm.letterBodyContent);
      if (
        !!employeeExitRequest.enableToGenerateLetter &&
        replacePlaceholders(employeeExitForm.letterBodyContent, true)
      ) {
        showError(`Please update a letter placeholders`);
        return false;
      }
      // Regular expression to match one or more occurrences of <div><br></div>
      const regex = /(<div><br><\/div>)+/;

      if (
        !!employeeExitRequest.enableToGenerateLetter &&
        !!regex.test(employeeExitForm.copiedInstitutionContent) &&
        !!ccData.length
      ) {
        showError(
          `You've added the CC emails, but you didn't include the copied institution content.`
        );
        return false;
      }

      if (
        !!employeeExitRequest.enableToGenerateLetter &&
        !regex.test(employeeExitForm.copiedInstitutionContent) &&
        !ccData.length
      ) {
        showError(
          `You've included the copied institution content, but you didn't add the CC emails.`
        );
        return false;
      }

      submitFinalRequest(
        {
          ...employeeExitForm,
          effectiveDate: moment(employeeExitForm.effectiveDate).format(
            "YYYY-MM-DD"
          ),
          requestId: employeeExitRequest?.id || null,
          entityId: entity?.entity?.id || null,
          currentPositionId: selectedEmployee.positionId || null,
          exitTypeId: exitType.id,
          cc: ccData || [],
          requestFormStepper: 0,
          transferredPositionId: position?.id || null,
          letterBodyContent: employeeExitForm.letterBodyContent,
          employeeId: selectedEmployee?.id || null,
          enableToGenerateLetter: conditions.enableToGenerateLetter,
          isAttachmentRequired: conditions.isAttachmentRequired,
          copiedInstitutionContent: employeeExitForm.copiedInstitutionContent,
        },
        (status) => {
          if (!!status) {
            onClose();
          }
        }
      );
    }
  };

  const calculateFutureDate = (effectiveInDays) => {
    const futureDate = moment(user.currentDate).add(effectiveInDays, "days");
    return futureDate.format("YYYY-MM-DD"); // Returns the date in 'YYYY-MM-DD' format
  };
  const handleLoadFooterContent = (data) => {
    // console.log(data);
    setLetterFooterContent(data);
  };
  // console.log(employeeExitForm);
  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeExitForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">
                  EXIT REQUEST{" "}
                  {employeeExitRequest && employeeExitRequest?.exitType
                    ? " OF " + employeeExitRequest.exitType
                    : ""}{" "}
                  |{" "}
                </span>
                <span className="ml-0">
                  {selectedEmployee?.lastName} {selectedEmployee?.firstName}
                </span>
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          {exitType && (
            <div className="mt-4">
              <Box sx={{ width: "100%", mt: 0 }}>
                <Stepper activeStep={activeStep}>
                  {filteredSteps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    // stepProps.completed = isCompleted;
                    return (
                      <Step key={label.name} {...stepProps}>
                        <StepLabel {...labelProps}>{label.name}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>
          )}
          {!!employeeExitRequest && (
            <Alert
              className="mt-2"
              variant="outlined"
              severity={"warning"}
              sx={{
                padding: "1px 15px 0px",
                width: "100%",
              }}
              icon={<CircleNotificationsIcon style={{ color: "#0099e8" }} />}
              action={
                <>
                  <Button
                    className="mt-0 mr-3"
                    onClick={() => setShowConfirmationDialog(true)}
                  >
                    Cancel this request
                  </Button>
                </>
              }
            >
              You can also cancel this {employeeExitRequest.statusName} exit
              request!
            </Alert>
          )}
        </DialogTitle>
        <DialogContent className="pb-2">
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <div className="py-3">
              {/* {!isEmpty(employeeExitRequest) && ( */}
              <>
                {activeStep === 0 && (
                  <>
                    <div className="row px-3 mt-4">
                      <div className="col-12 mb-2">
                        {" "}
                        {(loading || loadingEntitySectors) && (
                          <LinearProgress />
                        )}
                      </div>
                      <div className="col-12">
                        <Autocomplete
                          size="small"
                          id="exitType"
                          defaultValue={null}
                          value={exitType}
                          options={exitTypes}
                          disabled={loading}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, exitType) => {
                            setExitType(exitType || null);
                            setPosition(null);
                            setUnit(null);
                            setEntity(null);

                            if (exitType.effectiveInDays === 0) {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: calculateFutureDate(
                                  exitType.effectiveInDays
                                ),
                              });
                            } else {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: calculateFutureDate(
                                  exitType.effectiveInDays
                                ),
                              });
                            }

                            setConditions({
                              ...conditions,
                              isAttachmentRequired:
                                exitType.isAttachmentRequired,
                              enableToGenerateLetter:
                                exitType.enableToGenerateLetter,
                            });

                            setEmployeeExitForm({
                              ...employeeExitForm,
                              exitTypeId: exitType?.id || "",
                            });
                            if (exitType.id === 6 && !entitySectors.length)
                              getEntitySectors();
                            setErrors({ ...errors, exitTypeHasError: false });
                            // if (!!exitType && exitType.id === 6) getEntities();
                          }}
                          getOptionLabel={(option) =>
                            getNo(option?.id, exitTypes) + ":" + option?.name
                          }
                          renderOption={(props, exitType) => (
                            <Box component="li" {...props}>
                              {getNo(exitType?.id, exitTypes)}: {exitType?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              required={true}
                              disabled={loading}
                              {...params}
                              label="Select exit type"
                              placeholder="Select exit type"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "ExitType", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {errors.exitTypeHasError && (
                          <small className="text-danger mb-1">
                            Please, select exit type
                          </small>
                        )}
                      </div>

                      {!!exitType && exitType.id === 6 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="entity"
                            defaultValue={null}
                            value={entity}
                            options={entitySectors}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, entity) => {
                              setEntity(entity || null);
                              setPosition(null);
                              setUnit(null);
                              setEntitySectorUnits([]);
                              getEntitySectorUnits(entity.id);

                              setErrors({ ...errors, entityHasError: false });
                            }}
                            getOptionLabel={(option) =>
                              (option?.entity?.name || "") +
                              (entity?.entity?.acronym
                                ? "(" + entity?.entity.acronym + ")"
                                : "")
                            }
                            renderOption={(props, entity) => (
                              <Box component="li" {...props}>
                                {entity?.entity?.name || ""}
                                {entity?.entity?.acronym && (
                                  <>({entity?.entity.acronym})</>
                                )}{" "}
                                <span className="ml-1 badge badge-primary">
                                  {" "}
                                  {entity?.sector?.name || ""}{" "}
                                </span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select the institution to transfer"
                                placeholder="Select the institution to transfer"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "entity", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.entityHasError && (
                            <small className="text-danger mb-1">
                              Please, select the institution to transfer
                            </small>
                          )}
                        </div>
                      )}

                      {!!exitType && exitType.id === 6 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="unit"
                            defaultValue={null}
                            disabled={!entitySectorUnits.length}
                            value={unit}
                            options={entitySectorUnits}
                            isOptionEqualToValue={(option, value) =>
                              option.unitId === value.unitId
                            }
                            onChange={(event, unit) => {
                              setUnit(unit || null);
                              setPosition(null);

                              setErrors({ ...errors, unitHasError: false });

                              if (!!unit) getUnitPositions(unit.unitId);
                            }}
                            getOptionLabel={(option) => option?.unitName}
                            renderOption={(props, unit) => (
                              <Box component="li" {...props}>
                                {unit?.unitName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select unit to transfer"
                                placeholder="Select unit to transfer"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "unit", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.unitHasError && (
                            <small className="text-danger mb-1">
                              Please, Select unit to transfer
                            </small>
                          )}
                        </div>
                      )}

                      {!!exitType && exitType.id === 0 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="unit"
                            defaultValue={unit}
                            value={unit}
                            options={units}
                            isOptionEqualToValue={(option, value) =>
                              option.unitId === value.unitId
                            }
                            onChange={(event, unit) => {
                              setUnit(unit || null);
                              setPosition(null);

                              setErrors({ ...errors, unitHasError: false });

                              if (!!unit) getUnitPositions(unit.unitId);
                            }}
                            getOptionLabel={(option) => option?.unitName}
                            renderOption={(props, unit) => (
                              <Box component="li" {...props}>
                                {unit?.unitName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select unit to transfer"
                                placeholder="Select unit to transfer"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "unit", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.unitHasError && (
                            <small className="text-danger mb-1">
                              Please,Select unit to transfer
                            </small>
                          )}
                        </div>
                      )}

                      {((!!exitType && exitType.id === 0) ||
                        (!!exitType && exitType.id === 6)) && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            disabled={!positions.length}
                            size="small"
                            id="position"
                            defaultValue={null}
                            value={position}
                            options={positions.filter(
                              ({ id }) => id != selectedEmployee.positionId
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, position) => {
                              setPosition(position || null);
                              // setEmployeeExitForm({
                              //   ...employeeExitForm,
                              //   positionId: position?.id || "",
                              // });

                              setErrors({ ...errors, positionHasError: false });
                            }}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, position) => (
                              <>
                                <Box component="li" {...props}>
                                  {position?.name}{" "}
                                  <span className="badge badge-secondary ml-1">
                                    {position?.employeeGroupName}
                                  </span>{" "}
                                  <span className="badge badge-primary ml-1">
                                    ({position?.numberOfVacantPosts})VacantPosts
                                  </span>
                                </Box>
                                <Divider />
                                <Divider />
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select position to transfer"
                                placeholder="Select position to transfer"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "position", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.positionHasError && (
                            <small className="text-danger mb-1">
                              Please, select position to transfer
                            </small>
                          )}
                        </div>
                      )}

                      {(!exitType ||
                        (!!exitType &&
                          exitType.id !== 0 &&
                          exitType.id !== 6)) && (
                        <div className="col-12 mt-3 ">
                          <TextField
                            style={{ backgroundColor: "#eee" }}
                            fullWidth
                            size="small"
                            name="blacklistPeriod"
                            label="Blacklist Period"
                            value={getBlacklistPeriod()}
                            disabled
                            onChange={(e) => {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                blacklistPeriod: e.target.value,
                              });
                            }}
                          />
                          {!!exitType?.blacklistPeriod ||
                            (0 > 0 && (
                              <span className="badge badge-primary">
                                To be Blacklisted in {getBlacklistPeriod()}
                              </span>
                            ))}
                        </div>
                      )}
                      <div className="col-12 mt-3 ">
                        <TextField
                          fullWidth
                          size="small"
                          name="reason"
                          required={true}
                          multiline
                          rows={3}
                          value={employeeExitForm.reason}
                          label={`Exit reason for ${exitType?.name || ""}`}
                          placeholder={`Exit reason for ${
                            exitType?.name || ""
                          }`}
                          inputProps={{ maxLength: 459 }}
                          onChange={(e) => {
                            setEmployeeExitForm({
                              ...employeeExitForm,
                              reason: e.target.value,
                            });

                            setErrors({ ...errors, reasonHasError: false });
                          }}
                        />
                        {errors.reasonHasError && (
                          <small className="text-danger mb-1">
                            Please, type exit reason.
                          </small>
                        )}
                      </div>

                      <div className="col-12 mt-3 ">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label={
                              exitType?.id === 7
                                ? "Date of Death"
                                : "Effective Date"
                            }
                            inputFormat="dd/MM/yyyy"
                            // disabled={
                            //   !exitType ||
                            //   (exitType && exitType.effectiveInDays > 0)
                            // }
                            value={employeeExitForm.effectiveDate}
                            onChange={(date) => {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: date,
                              });

                              setErrors({
                                ...errors,
                                effectiveDateHasError: false,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                required={true}
                                fullWidth
                                size="small"
                                {...params}
                              />
                            )}
                          />

                          {errors.effectiveDateHasError && (
                            <small className="text-danger mt-1">
                              {exitType?.id === 7
                                ? "Date of Death"
                                : "Effective Date"}{" "}
                              is required{" "}
                            </small>
                          )}
                        </LocalizationProvider>
                        {exitType && exitType.effectiveInDays > 0 && (
                          <span className="badge badge-primary">
                            This request will be effective after{" "}
                            {exitType.effectiveInDays} days if you don't adjust
                            the date
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {activeStep === 1 &&
                  employeeExitRequest &&
                  !!employeeExitRequest.isAttachmentRequired && (
                    <>
                      <ViewExitRequestAttachments
                        exitTypeId={employeeExitRequest.exitTypeId}
                        requestId={
                          employeeExitRequest && employeeExitRequest.id
                        }
                        canEdit={true}
                      />
                    </>
                  )}
                {((activeStep === 2 &&
                  employeeExitRequest &&
                  !!employeeExitRequest.enableToGenerateLetter &&
                  !!employeeExitRequest.isAttachmentRequired) ||
                  (activeStep === 1 &&
                    employeeExitRequest &&
                    !!employeeExitRequest.enableToGenerateLetter &&
                    !employeeExitRequest.isAttachmentRequired)) && (
                  <>
                    <div className="mx-2 w-full">
                      <ExitLetter
                        disableToEditLetterHeader={false}
                        enableToFillLetter={enableToFillLetter}
                        ccSelectForm={
                          <>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              id="ccData"
                              options={[
                                ...entities.map(
                                  ({ name, institutionEmail, id }) => ({
                                    name,
                                    institutionEmail,
                                    id,
                                  })
                                ),
                                // Optionally, add a placeholder for new additions
                              ]}
                              value={ccData}
                              onChange={(e, cc, reason) => {
                                if (reason === "create-option") {
                                  const newEmail = e.target.value; // Extract input value
                                  const newObject = {
                                    id: Date.now(), // Generate a unique ID
                                    name: "Custom Name", // Use default or dynamic name
                                    institutionEmail: newEmail, // Use the input email
                                  };

                                  // Add the new object
                                  cc.push(newObject);
                                }

                                const containsWord = cc.find(
                                  ({ institutionEmail }) =>
                                    institutionEmail.includes("example")
                                );

                                if (containsWord) {
                                  showError(
                                    `The institution email (${containsWord.institutionEmail}) does not seem correct. Please consult the IPPIS Team for assistance.`
                                  );
                                  return false;
                                }

                                const uniqueCc = removeDuplicates(cc); // Ensure no duplicates
                                setCCData(uniqueCc);
                                const ccHasError = false;
                                setErrors({ ...errors, ccHasError });
                              }}
                              disableCloseOnSelect
                              getOptionLabel={(option) =>
                                option.institutionEmail || "Add a new email..."
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={
                                      selected ||
                                      !!ccData.find(
                                        ({ id }) => id === option.id
                                      )
                                    }
                                  />
                                  {option.institutionEmail}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    "Select or Add an institution email to copy"
                                  }
                                  placeholder={"Type to add or select"}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter" && e.target.value) {
                                      const newObject = {
                                        id: Date.now(), // Unique ID
                                        name: "Custom Name",
                                        institutionEmail: e.target.value,
                                      };
                                      const updatedCcData = [
                                        ...ccData,
                                        newObject,
                                      ];
                                      setCCData(
                                        removeDuplicates(updatedCcData)
                                      );
                                    }
                                  }}
                                />
                              )}
                            />

                            {errors.ccHasError && (
                              <small className="text-danger mb-1">
                                Please, select the institution to copy
                              </small>
                            )}
                          </>
                        }
                        ccData={ccData}
                        docLetterNo={employeeExitRequest?.docLetterNo || ""}
                        signContent={
                          <>
                            <InstitutionDetails
                              headOfInstitution={{
                                isDelegated:
                                  employeeExitRequest?.isDelegated || false,
                                isMinster:
                                  employeeExitRequest?.isMinster || false,
                                firstName:
                                  employeeExitRequest?.ministryApproverFirstName,
                                lastName:
                                  employeeExitRequest?.ministryApproverLastName,
                                institutionName:
                                  employeeExitRequest?.ministryApproverInstitutionName,
                                positionName:
                                  employeeExitRequest?.ministryApproverPositionName,
                                institutionName:
                                  employeeExitRequest?.ministryApproverInstitutionName ||
                                  "",
                                canAddInstitutionName:
                                  employeeExitRequest?.ministryApproverCanAddInstitutionName ||
                                  false,
                              }}
                              userSignature={null}
                            />
                          </>
                        }
                        subTitle={
                          "Dear " +
                          (selectedEmployee.lastName.toUpperCase() +
                            " " +
                            selectedEmployee.firstName)
                        }
                        recipientContent={
                          <>
                            {selectedEmployee.lastName.toUpperCase() +
                              " " +
                              selectedEmployee.firstName}{" "}
                            <br />
                            Tel: {selectedEmployee.phoneNumber} <br />
                            Email: {selectedEmployee.email} <br />
                            C/O: {selectedEmployee.entityName}
                            {!!selectedEmployee.acronym && (
                              <>
                                {" "}
                                <span>({selectedEmployee.acronym})</span>
                              </>
                            )}
                          </>
                        }
                        entitySectorId={
                          employeeExitForm?.entitySectorId || null
                        }
                        letterBodyContentInput={
                          <>
                            <TextEditorComponent
                              id="editor-exit-textarea"
                              options={{
                                autoSave: false,
                                localStorageKey: localStorageKey.letter,
                                maxCharCount: maxCharCount,
                                defaultValue: !!window.localStorage.getItem(
                                  localStorageKey.letter
                                )
                                  ? window.localStorage.getItem(
                                      localStorageKey.letter
                                    )
                                  : employeeExitForm.letterBodyContent,
                              }}
                              onEditorChange={(data) => {
                                getEditorContent(data);
                              }}
                            />
                          </>
                        }
                        letterBodyContent={
                          employeeExitForm?.letterBodyContent || ""
                        }
                        title={exitType?.letterTitle || ""}
                        copiedInstitutionContentInput={
                          <>
                            <br />
                            <strong>Provide copied institution</strong>
                            <TextEditorComponent
                              id="editor-textarea6"
                              options={{
                                autoSave: false,
                                height: "auto",
                                localStorageKey: localStorageKey.cc,
                                maxCharCount: maxCharCount,
                                defaultValue: !!window.localStorage.getItem(
                                  localStorageKey.cc
                                )
                                  ? window.localStorage.getItem(
                                      localStorageKey.cc
                                    )
                                  : employeeExitForm.copiedInstitutionContent,
                              }}
                              onEditorChange={(data) => {
                                getEditorContent3(data);
                              }}
                            />
                          </>
                        }
                        copiedInstitutionContent={
                          employeeExitForm?.copiedInstitutionContent || ""
                        }
                        loadFooterContent={(data) =>
                          handleLoadFooterContent(data)
                        }
                      />
                    </div>
                  </>
                )}
              </>
              {/* )} */}
            </div>
          </Box>
        </DialogContent>
        {/* {!isEmpty(employeeExitRequest) && ( */}
        <DialogActions className="d-flex justify-content-center pb-3">
          {activeStep > 0 &&
            exitType &&
            (!!exitType.enableToGenerateLetter ||
              !!exitType.isAttachmentRequired) && (
              <Button
                style={{
                  ...InactiveButton,
                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="md"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
          {exitType &&
            (!!exitType.enableToGenerateLetter ||
              !!exitType.isAttachmentRequired) && (
              <Box sx={{ flex: "1 1 auto" }} />
            )}

          {/* WHEN NO FILE REQUIRED AND NO LETTER REQUIRED */}
          {exitType &&
            !exitType.enableToGenerateLetter &&
            !exitType.isAttachmentRequired && (
              <>
                <Button
                  color="success"
                  variant="contained"
                  disabled={loading || onWait || !exitType}
                  onClick={onSave}
                  size="md"
                >
                  {onWait ? "Wait.." : <>Submit</>}
                </Button>
              </>
            )}
          {/* WHEN FILE REQUIRED OR LETTER REQUIRED, BUT ONE OF THEM SHOULD BE NOT REQUIRED */}
          {/* {exitType &&
            [0].includes(activeStep) &&
            (!!exitType.enableToGenerateLetter ||
              !!exitType.isAttachmentRequired) && (
              <Button
                disabled={loading || onWait || !exitType}
                onClick={() => handleSubmitDraftRequest(false)}
                color={"primary"}
                variant="contained"
                size="md"
              >
                {onWait ? "Wait.." : <> Next</>}
              </Button>
            )} */}
          {/* WHEN FILE REQUIRED AND LETTER REQUIRED BUT ON STEP 1 OR 2 */}
          {[0, 1].includes(activeStep) &&
            ((exitType && !!exitType?.enableToGenerateLetter) ||
              !!exitType?.isAttachmentRequired ||
              (!!employeeExitRequest &&
                !!employeeExitRequest?.enableToGenerateLetter &&
                !!employeeExitRequest?.isAttachmentRequired)) && (
              <Button
                disabled={loading || onWait || !exitType}
                onClick={() => handleSubmitDraftRequest(false)}
                color={"primary"}
                variant="contained"
                size="md"
              >
                {onWait ? "Wait.." : <> Next </>}
              </Button>
            )}
          {/* SUBMIT REQUEST WITH ATTACHMENTS BUT NO LETTER REQUIRED */}
          {employeeExitRequest &&
            activeStep === 1 &&
            ((!!employeeExitRequest.isAttachmentRequired &&
              !employeeExitRequest.enableToGenerateLetter) ||
              (!employeeExitRequest.isAttachmentRequired &&
                !!employeeExitRequest.enableToGenerateLetter)) && (
              <>
                <Button
                  disabled={loading || onWait || !exitType}
                  onClick={handleSubmitRequestAndAttachment}
                  color={"success"}
                  variant="contained"
                  size="md"
                >
                  {onWait ? "Wait.." : <> Submit</>}
                </Button>
              </>
            )}
          {/* SUBMIT REQUEST WHEN FILES REQUIRED AND LETTER ALSO WHEN FILES NOT REQUIRE BUT LETTER REQUIRED */}
          {((activeStep === 2 &&
            employeeExitRequest &&
            !!employeeExitRequest.enableToGenerateLetter &&
            !!employeeExitRequest.isAttachmentRequired) ||
            (activeStep === 1 &&
              employeeExitRequest &&
              !!employeeExitRequest.enableToGenerateLetter &&
              !employeeExitRequest.isAttachmentRequired)) && (
            <>
              <Button
                color={enableToFillLetter ? "warning" : "primary"}
                variant="outlined"
                onClick={() => {
                  if (
                    !!employeeExitRequest.enableToGenerateLetter &&
                    replacePlaceholders(
                      employeeExitForm.letterBodyContent,
                      true
                    )
                  ) {
                    showError(`Please update a letter placeholders`);
                    return false;
                  }
                  // Regular expression to match one or more occurrences of <div><br></div>
                  const regex = /(<div><br><\/div>)+/;

                  if (
                    !!employeeExitRequest.enableToGenerateLetter &&
                    !!regex.test(employeeExitForm.copiedInstitutionContent) &&
                    !!ccData.length
                  ) {
                    showError(
                      `You've added the CC emails, but you didn't include the copied institution content.`
                    );
                    return false;
                  }

                  if (
                    !!employeeExitRequest.enableToGenerateLetter &&
                    !regex.test(employeeExitForm.copiedInstitutionContent) &&
                    !ccData.length
                  ) {
                    showError(
                      `You've included the copied institution content, but you didn't add the CC emails.`
                    );
                    return false;
                  }
                  setEnableToFillLetter(!enableToFillLetter);
                }}
                size="md"
              >
                {enableToFillLetter ? "View Draft Letter" : "Edit Draft Letter"}
              </Button>
              <Button
                disabled={loading || onWait || !exitType}
                onClick={handleSubmitRequestAndLetter}
                color={"success"}
                variant="contained"
                size="md"
              >
                {onWait ? "Wait.." : <> Submit</>}
              </Button>
            </>
          )}
        </DialogActions>
        {/* )} */}
      </Dialog>

      {showConfirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            selectedEmployee?.lastName + " " + selectedEmployee?.firstName
          }?`}
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            if (employeeExitRequest && employeeExitRequest.enableToFillLetter) {
              window.localStorage.removeItem(localStorageKey.letter);
              window.localStorage.removeItem(localStorageKey.cc);
            }
            cancelEmployeeExitRequest(
              employeeExitRequest,

              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  exitTypes,
  employeeExitRequest,
  units,
  positions,
  entities,
  entitySectors,
  user,
  exitRequestAttachments,
}) => {
  return {
    loading,
    onWait,
    exitTypes,
    employeeExitRequest,
    units,
    positions,
    entities,
    entitySectors,
    user,
    exitRequestAttachments,
  };
};
export default connect(mapStateToProps, {
  saveRequest,
  saveOrUpdateDraftRequest,
  cancelEmployeeExitRequest,
  getUnitPositions,
  getEntities,
  getEntitySectors,
  submitFinalRequest,
})(NewExitRequestDialog);
