import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showError } from "./../app/toastify";

import {
  login,
  selectModule,
  // resetPassword,
  logout,
  selectEntitySector,
  requestOTP,
} from "../store/common/actions";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SafetyCheckRoundedIcon from "@mui/icons-material/SafetyCheckRounded";
import { useRequiredAuth } from "./common/guard/requiredAuth";
import CustomCountdown from "./common/components/CustomCountdown";
import { blue, red } from "@mui/material/colors";

const Home = (props) => {
  const {
    env,
    user,
    loading,
    ippisModules,
    login,
    requestOTP,
    selectModule,
    selectEntitySector,
  } = props;
  useEffect(() => {
    document.title =
      "WELCOME TO IPPIS - THE MANAGEMENT SYSTEM FOR THE ENTIRE RWANDA PUBLIC SERVICE";
  });
  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [resending, setResending] = useState(false);
  const [otpMethod, setOtpMethod] = useState(null);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const [verificationCode, setVerificationCode] = useState("");
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const handleCleanForm = () => {
    setCredentials({
      email: "",
      password: "",
      showPassword: false,
    });
    setVerificationCode("");
    setRemainingSeconds(120);
    setFormStep(1);
    setForgotPassword(false);
    setChangePassword(false);
    setResending(false);
    setOtpMethod(null);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      handleCleanForm();
    }
  }, [user]);

  const canVerify = () => {
    return (
      remainingSeconds > 0 &&
      verificationCode.length >= 4 &&
      !loading &&
      !resending
    );
  };

  const handleRequestOTP = (notificationMethod) => {
    setCredentials((prev) => ({
      ...prev,
      isNew: false,
      errMsg: null,
    }));
    requestOTP(
      {
        email: credentials.email,
        password: credentials.password,
        notificationMethod: notificationMethod || otpMethod || null,
      },
      (status, res, data) => {
        setResending(status);
        if (data && res) {
          if (!!data.isCredentialValid) {
            setFormStep(2);
          } else {
            if (notificationMethod === "mail" && !!data.seconds) {
              setRemainingSeconds(data.seconds);
            }
            if (notificationMethod === "authenticator" && !!data.isNew) {
              setCredentials({
                ...credentials,
                isNew: data.isNew,
                errMsg: data.message,
              });
            }
          }
        }
      }
    );
  };

  const onLogin = async (e) => {
    e.preventDefault();

    if (env !== "prod" || window.location.hostname === "localhost") {
      login({
        email: credentials.email,
        password: credentials.password,
        wasLoggedIn: false,
      });
    } else {
      if (formStep === 1) {
        handleRequestOTP();
      } else if (formStep === 2) {
        login({
          email: credentials.email,
          notificationMethod: otpMethod,
          otpCode: env === "prod" ? `${verificationCode}` : undefined,
          password: credentials.password,
          wasLoggedIn: false, //env !== "prod" ? false : true,
        });
      }
    }
  };

  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);

  const resetPassword = async () => {
    try {
      setOnWait(true);
      setWasReset(false);
      await axios.post("/api/auth/reset-password", {
        email: credentials.email,
      });
      setOnWait(false);
      setWasReset(true);
    } catch (error) {
      setOnWait(false);
      setWasReset(false);
      showError(error);
    }
  };

  const { hasPermissions } = useRequiredAuth();

  const filteredModules = () => {
    if (!isEmpty(user?.selectedEntitySector))
      return ippisModules.filter(
        ({ isActive, permit }) =>
          isActive &&
          (user?.selectedEntitySector?.permissions[permit] ||
            hasPermissions(["IS_ADMIN"]))
      );
    else return [];
  };

  useEffect(() => {
    if (!!user?.selectedEntitySector && filteredModules()?.length === 1)
      selectModule(filteredModules()[0], history);
  }, [user.selectedEntitySector, ippisModules]);

  const handleGetHashedEmail = (email) => {
    const emailArray = email.split("@");
    const firstPart = emailArray[0];
    const atPart = emailArray[1];
    if (firstPart.length <= 3) return `${firstPart[0]}******@${atPart}`;
    else if (firstPart.length <= 5)
      return `${firstPart.substring(0, 2)}******@${atPart}`;
    else return `${firstPart.substring(0, 3)}******@${atPart}`;
  };
  const inputRefs = useRef([]);

  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const changeOTPMethod = (value) => {
    setOtpMethod(value);
    handleRequestOTP(value);
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    const cleanedData = pasteData.replace(/\D/g, "").slice(0, 6);

    const newOtpValues = [...otpValues];
    cleanedData.split("").forEach((char, index) => {
      if (index < otpValues.length) {
        newOtpValues[index] = char;
      }
    });

    setOtpValues(newOtpValues);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";

      if (index > 0) {
        setOtpValues(newOtpValues);
        inputRefs.current[index - 1].focus();
      } else {
        setOtpValues(newOtpValues);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div
          className="card "
          style={{
            borderRadius: "0px",
            backgroundColor: "#078ece",
          }}
        >
          <div
            className="card-body d-flex align-items-center justify-content-center"
            style={{
              borderRadius: "0px",
              height: "192px",
            }}
          >
            <p
              className="text-uppercase text-center mb-5 mt-n3"
              style={{
                fontWeight: "400",
                color: "#f1f1f1",
                fontSize: "x-large",
              }}
            >
              WELCOME TO IPPIS -{" "}
              {!!user?.selectedEntitySector
                ? user.selectedEntitySector.entityName
                : "THE MANAGEMENT SYSTEM FOR THE ENTIRE RWANDA PUBLIC SERVICE"}
            </p>
          </div>
        </div>

        <div className="row justify-content-center mt-n5 mx-0 px-0 text-center">
          {isEmpty(user) && (
            <>
              {!forgotPassword && !changePassword && (
                <div className="col-12 col-md-6 col-lg-5 border  px-5 py-2 pb-5 border-primary rounded bg-light">
                  {formStep === 1 ? (
                    <form onSubmit={onLogin}>
                      <h1 className="h3 my-3 font-weight-normal text-uppercase text-primary">
                        Login
                      </h1>

                      {/* <Alert severity="success" className="mb-3">
                    Your password was changed successfully
                  </Alert> */}

                      {/* <Alert
                      icon={false}
                      severity="info"
                      className="mb-3 bg-secondary text-light"
                    >
                      Please, login using your personal email and password
                    </Alert> */}

                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="mb-3 mt-2 bg-white"
                      >
                        <InputLabel htmlFor="email">
                          Enter your email
                        </InputLabel>
                        <OutlinedInput
                          autoFocus
                          id="email"
                          placeholder="Enter your email"
                          name="email"
                          type="email"
                          value={credentials.email}
                          onChange={(e) => {
                            const email = e.target.value;
                            setCredentials({ ...credentials, email });
                          }}
                          disabled={loading}
                          label="Enter your email"
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        variant="outlined"
                        className=" bg-white"
                      >
                        <InputLabel htmlFor="password">
                          Enter your password
                        </InputLabel>
                        <OutlinedInput
                          id="password"
                          placeholder="Enter your password"
                          name="password"
                          type={credentials.showPassword ? "text" : "password"}
                          value={credentials.password}
                          onChange={(e) => {
                            const password = e.target.value;
                            setCredentials({ ...credentials, password });
                          }}
                          disabled={loading}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setCredentials({
                                    ...credentials,
                                    showPassword: !credentials.showPassword,
                                  })
                                }
                                edge="end"
                              >
                                {credentials.showPassword ? (
                                  <span className="material-icons">
                                    visibility_off
                                  </span>
                                ) : (
                                  <span className="material-icons">
                                    visibility
                                  </span>
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Enter your password"
                        />
                      </FormControl>

                      <div className="text-right">
                        <Button
                          type="button"
                          onClick={() => setForgotPassword(true)}
                          className="mb-3 pr-0"
                        >
                          Reset Password
                        </Button>
                      </div>

                      {!loading && (
                        <Button
                          type="submit"
                          disabled={loading}
                          // onClick={() => onLogin()}
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                        >
                          Login
                        </Button>
                      )}

                      {loading && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                        >
                          Connecting...
                        </Button>
                      )}

                      {/* <p className="mt-4 mb-3 text-muted">
                        If you don't remember your email. <br />
                        Please, contact your HR for help.
                      </p> */}
                    </form>
                  ) : (
                    <div>
                      <h3 className="font-weight-bold mt-3">
                        Where to send notifications
                      </h3>
                      {!otpMethod && (
                        <Alert
                          variant="filled"
                          severity="info"
                          className="mt-3"
                        >
                          Please choose 2 Factor Authentication option.
                        </Alert>
                      )}
                      <div className="card bg-white mb-2 mt-4 border-0">
                        <div className="card-body px-5 py-0 text-center">
                          <List>
                            <ListItem disablePadding>
                              <ListItemButton
                                onClick={() => changeOTPMethod("mail")}
                                selected={otpMethod === "mail"}
                                disabled={resending || otpMethod === "mail"}
                              >
                                <ListItemAvatar>
                                  <Avatar sx={{ bgcolor: blue[500] }}>
                                    <EmailRoundedIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Email" />
                              </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding>
                              <ListItemButton
                                onClick={() => changeOTPMethod("authenticator")}
                                selected={otpMethod === "authenticator"}
                                disabled={
                                  resending || otpMethod === "authenticator"
                                }
                              >
                                <Avatar
                                  sx={{ bgcolor: red[500] }}
                                  className="mr-3"
                                >
                                  <SafetyCheckRoundedIcon />
                                </Avatar>
                                <ListItemText primary="Authenticator App" />
                              </ListItemButton>
                            </ListItem>
                          </List>

                          {!!otpMethod && (
                            <form onSubmit={onLogin}>
                              {otpMethod === "mail" && (
                                <p style={{ color: "#696969" }}>
                                  A One-Time <strong>verification code</strong>{" "}
                                  has been sent <br />
                                  to your email{" "}
                                  <strong>
                                    {handleGetHashedEmail(credentials.email)}
                                  </strong>
                                </p>
                              )}

                              {otpMethod === "authenticator" &&
                                !!credentials.isNew &&
                                !!credentials.errMsg && (
                                  <>
                                    <p
                                      style={{
                                        color: "#950505",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                      className="mt-1"
                                    >
                                      {credentials.errMsg}
                                    </p>
                                    <Alert
                                      variant="filled"
                                      severity="info"
                                      className="mt-3"
                                    >
                                      To enable the Authenticator App option,
                                      you must first log in and navigate to your
                                      account settings to set it up.
                                    </Alert>
                                  </>
                                )}

                              <div className="mb-3 w-100">
                                {/* <article
                                  id="otp"
                                  className="flex flex-row justify-center text-center"
                                  onPaste={handlePaste}
                                >
                                  {otpValues.map((value, index) => (
                                    <input
                                      key={index}
                                      ref={(ref) =>
                                        (inputRefs.current[index] = ref)
                                      }
                                      className="m-2 border h-8 w-8 text-center form-control rounded focus:border-none outline-none focus:outline-primary max-md:w-8 max-md:h-8"
                                      type="text"
                                      value={value}
                                      onChange={(e) =>
                                        handleInputChange(index, e.target.value)
                                      }
                                      onKeyDown={(e) => handleKeyDown(e, index)}
                                      maxLength="1"
                                    />
                                  ))}
                                </article> */}
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  className="mb-3 mt-2w-100 mt-3"
                                >
                                  <InputLabel htmlFor="verificationCode">
                                    Enter Verification Code
                                  </InputLabel>
                                  <OutlinedInput
                                    autoFocus
                                    id="verificationCode"
                                    placeholder="Enter Verification Code"
                                    name="verificationCode"
                                    type="number"
                                    className="w-100"
                                    value={verificationCode}
                                    onChange={(e) =>
                                      setVerificationCode(e.target.value)
                                    }
                                    disabled={
                                      (remainingSeconds <= 0 &&
                                        otpMethod === "mail") ||
                                      loading
                                    }
                                    label="Enter Verification Code"
                                  />
                                </FormControl>
                              </div>
                              <div className="d-flex align-items-center justify-content-center mx-auto mb-2">
                                <Button
                                  className="mx-2"
                                  variant="contained"
                                  type="submit"
                                  disabled={canVerify() ? false : true}
                                >
                                  Verify
                                </Button>
                                {otpMethod === "mail" && (
                                  <Button
                                    className="mx-2"
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    disabled={remainingSeconds > 0}
                                    onClick={() => {
                                      setResending(true);
                                      handleRequestOTP();
                                    }}
                                  >
                                    Resend
                                  </Button>
                                )}
                              </div>

                              {remainingSeconds > 0 &&
                                !resending &&
                                otpMethod === "mail" && (
                                  <div className="mb-3">
                                    <CustomCountdown
                                      remainingSeconds={remainingSeconds}
                                      setRemainingSeconds={setRemainingSeconds}
                                    />
                                  </div>
                                )}
                            </form>
                          )}
                        </div>
                      </div>
                      <Button
                        variant="text"
                        type="button"
                        className="mt-4"
                        onClick={handleCleanForm}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {forgotPassword && (
                <div className="col-12 col-md-6 col-lg-5 border px-5 py-3 border-primary rounded bg-light">
                  <h1 className="h4 my-3 font-weight-normal text-uppercase text-primary">
                    Reset your password
                  </h1>
                  {wasReset && (
                    <Alert severity="success" className="mb-3">
                      <AlertTitle>
                        Please, check your email to continue.
                      </AlertTitle>
                      The link to reset your password was sent to your email.{" "}
                    </Alert>
                  )}

                  {/* {!wasReset && (
                    <Alert severity="success" className="mb-3">
                      The link to reset your password was sent to your email.{" "}
                      <br /> Please, check your email to continue.
                    </Alert>
                  )} */}

                  {!wasReset && (
                    <>
                      <TextField
                        fullWidth
                        autoFocus
                        name="email"
                        type="email"
                        variant="outlined"
                        label="Enter your email"
                        placeholder="Enter your email"
                        className="mt-2 mb-3 bg-white"
                        value={credentials.email}
                        onChange={(e) => {
                          const email = e.target.value;
                          setCredentials({ ...credentials, email });
                        }}
                      />

                      {!onWait && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                          onClick={() => resetPassword()}
                        >
                          Reset password
                        </Button>
                      )}

                      {onWait && (
                        <Button
                          type="button"
                          variant="contained"
                          disableElevation
                          className="btn btn-block text-light"
                          size="large"
                          onClick={() => resetPassword()}
                        >
                          Connecting...
                        </Button>
                      )}
                    </>
                  )}

                  <Button
                    type="button"
                    onClick={() => {
                      setForgotPassword(false);
                      setOnWait(false);
                      setWasReset(false);
                    }}
                    className="pr-3 my-3"
                  >
                    <span className="material-icons">
                      keyboard_double_arrow_left
                    </span>{" "}
                    Back to Login
                  </Button>

                  {/* {!wasReset && (
                    <p className="text-muted">
                      If you don't remember your email. <br />
                      Please, contact your HR for help.
                    </p>
                  )} */}
                </div>
              )}
            </>
          )}

          {!isEmpty(user) && (
            <>
              {isEmpty(user.selectedEntitySector) && (
                <div className="col-12 col-md-11 col-lg-9 bg-light p-5 border border-primary rounded">
                  <h2
                    className=" p-2 text-center"
                    style={{
                      fontWeight: "50",
                      color: "white",
                      backgroundColor: "#078ece",
                      fontSize: "x-large",
                      border: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    Select Institution
                  </h2>

                  <div className="row justify-content-center">
                    {user.entitySectors.map((entitySector) => {
                      return (
                        <React.Fragment key={entitySector.id}>
                          <div className="col-12 mb-2">
                            <button
                              type="button"
                              disabled={loading}
                              onClick={() => {
                                selectEntitySector(entitySector.id, history);
                              }}
                              className={`btn btn-outline-primary  text-left btn-block p-3`}
                            >
                              {entitySector.entityName} - ({" "}
                              {entitySector.sectorName})
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}

              {!isEmpty(user.selectedEntitySector) && (
                <div className="col-12 col-md-11 col-lg-9 bg-light p-5 border border-primary rounded ">
                  <h2
                    className=" p-2 text-center text-uppercase"
                    style={{
                      fontWeight: "50",
                      color: "white",
                      backgroundColor: "#078ece",
                      fontSize: "x-large",
                      border: "1px solid rgba(0, 0, 0, 0.125)",
                    }}
                  >
                    Select module
                  </h2>

                  <div className="row justify-content-center">
                    {filteredModules()?.length > 1 &&
                      filteredModules().map((mod) => {
                        return (
                          <React.Fragment key={mod.shortName}>
                            {(mod.id !== "TRAINING" ||
                              (!!user.selectedEntitySector
                                .canUseTrainingModule &&
                                mod.id === "TRAINING")) &&
                              (mod.id !== "RECRUITMENT" ||
                                (!!user?.canAccessRecruitment &&
                                  mod.id === "RECRUITMENT")) && (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                                  <button
                                    disabled={loading}
                                    onClick={() => selectModule(mod, history)}
                                    className={`btn btn-outline-primary  text-left btn-block p-3`}
                                  >
                                    <img
                                      src={require(`./assets/icons/${mod.icon}`)}
                                      alt=""
                                    />{" "}
                                    {mod.shortName}
                                  </button>
                                </div>
                              )}
                          </React.Fragment>
                        );
                      })}
                    {hasPermissions(["IS_ADMIN"]) && (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                        <button
                          disabled={loading}
                          onClick={() => history.push("/admin")}
                          className={`btn btn-outline-primary  text-left btn-block p-3`}
                        >
                          <span className="d-inline-flex align-items-center py-0">
                            <span className="material-icons ">security</span>
                            Admin
                          </span>
                        </button>
                      </div>
                    )}
                    {hasPermissions(["IS_SUPPORTER"]) && (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                        <button
                          disabled={loading}
                          onClick={() => history.push("/support")}
                          className={`btn btn-outline-primary  text-left btn-block p-3`}
                        >
                          <span className="d-inline-flex align-items-center py-0">
                            <span className="material-icons ">help</span>
                            Support
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  env,
  user,
  isFetchingUser,
  loading,
  ippisModules,
}) => {
  return { env, user, isFetchingUser, loading, ippisModules };
};
export default connect(mapStateToProps, {
  login,
  requestOTP,
  // resetPassword,
  logout,
  selectModule,
  selectEntitySector,
})(Home);
