import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Skeleton } from "@mui/material";
const LetterTemplateFooter = (props) => {
  const { loading, letterCustomization, loadFooterContent } = props;
  useEffect(() => {
    if (loadFooterContent)
      loadFooterContent(letterCustomization);
  }, [letterCustomization]);
  return (
    <>
      {loading ? (
        <>
          {" "}
          <p style={{ borderTop: "3px solid #078ece" }}>
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </p>
        </>
      ) : (
        <>
          <div
            className="footer mt-5 pb-1"
            style={{
              position: "relative",
              bottom: "1px",
              left: 0,
              right: 0,
              padding: "0px",
              borderTop: "3px solid #078ece",
              backgroundColor: "#f4f4f4",
              fontSize: "12px",
              textAlign: "justify",
            }}
          >
            <div className="details" style={{ marginTop: "5px" }}>
              {/* PO Box */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "2px",
                    marginTop: "-4px",
                  }}
                  xmlSpace="preserve"
                  fill="#078ece"
                  stroke="#078ece"
                >
                  <path
                    d="M16,25H2v-8c0-3.9,3.1-7,7-7h0c3.9,0,7,3.1,7,7V25z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></path>
                  <path
                    d="M23,10L23,10c3.9,0,7,3.1,7,7v8H16"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></path>
                  <line
                    x1="9"
                    y1="10"
                    x2="23"
                    y2="10"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></line>
                  <rect
                    x="13"
                    y="25"
                    width="6"
                    height="6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></rect>
                  <polyline
                    points="22,18 22,4.9 22,1 30,1 30,5 24,5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  ></polyline>
                </svg>
                <a
                  href="#"
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                  }}
                >
                  {letterCustomization?.entity?.poBox?.toUpperCase() || ""}
                </a>
              </strong>

              {/* Phone */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "2px",
                    marginTop: "-4px",
                  }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 12C19.7614 12 22 9.76142 22 7C22 4.23858 19.7614 2 17 2C14.2386 2 12 4.23858 12 7C12 7.79984 12.1878 8.55582 12.5217 9.22624C12.6105 9.4044 12.64 9.60803 12.5886 9.80031L12.2908 10.9133C12.1615 11.3965 12.6035 11.8385 13.0867 11.7092L14.1997 11.4114C14.392 11.36 14.5956 11.3895 14.7738 11.4783C15.4442 11.8122 16.2002 12 17 12Z"
                    fill="#078ece"
                  ></path>
                  <path
                    d="M8.03759 7.31617L8.6866 8.4791C9.2723 9.52858 9.03718 10.9053 8.11471 11.8278C8.11459 11.8279 6.99588 12.9468 9.02451 14.9755C11.0525 17.0035 12.1714 15.8861 12.1722 15.8853C13.0947 14.9628 14.4714 14.7277 15.5209 15.3134L16.6838 15.9624C18.2686 16.8468 18.4557 19.0692 17.0628 20.4622C16.2258 21.2992 15.2004 21.9505 14.0669 21.9934C12.1588 22.0658 8.91828 21.5829 5.6677 18.3323C2.41713 15.0817 1.93421 11.8412 2.00655 9.93309C2.04952 8.7996 2.7008 7.77423 3.53781 6.93723C4.93076 5.54428 7.15317 5.73144 8.03759 7.31617Z"
                    fill="#078ece"
                  ></path>
                </svg>
                <a
                  href={`tel:${
                    letterCustomization?.entity?.institutionPhone || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                  }}
                >
                  {letterCustomization?.entity?.institutionPhone || ""}
                </a>
              </strong>

              {/* Website */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "2px",
                    marginTop: "-4px",
                  }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#078ece"
                >
                  <path
                    d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <a
                  href={`https://${
                    letterCustomization?.entity?.institutionEmail || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                  }}
                >
                  {letterCustomization?.entity?.institutionEmail || ""}
                </a>
              </strong>

              {/* Twitter */}
              <strong
                style={{
                  paddingRight: "10px",
                  marginRight: "2px",
                  fontSize: "12px",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "8px",
                    verticalAlign: "middle",
                    marginRight: "2px",
                    marginTop: "-4px",
                  }}
                  fill="#078ece"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0016.29 3a4.48 4.48 0 00-4.39 5.52A12.94 12.94 0 013 4.14a4.48 4.48 0 001.39 5.96A4.47 4.47 0 012 9.71v.05a4.48 4.48 0 003.6 4.39 4.52 4.52 0 01-2 .08 4.48 4.48 0 004.18 3.12A9 9 0 010 19.54 12.8 12.8 0 007 21c8.1 0 12.54-6.7 12.54-12.54 0-.19 0-.39-.02-.58A9 9 0 0023 3z"
                    fill="#078ece"
                  />
                </svg>
                <a
                  href={`https://x.com/${
                    letterCustomization?.entity?.twitterPage || ""
                  }`}
                  style={{
                    color: "#363636",
                    textDecoration: "none",
                    fontSize: "12px",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {letterCustomization?.entity?.twitterPage || ""}
                </a>
              </strong>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, letterCustomization }) => {
  return {
    loading,
    letterCustomization,
  };
};

export default connect(mapStateToProps, {})(LetterTemplateFooter);
