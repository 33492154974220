import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Input,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  getRecruitmentRequestTypes,
  saveRecruitmentRequest,
  getRecruitmentRequestAttachmentTypes,
} from "../../../store/recruitment/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";

import { getEntities } from "../../../store/structure/actions";

import { isEmpty } from "lodash";
import moment from "moment";
import { showError } from "../../toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeDuplicates } from "../../common/components/Utils";
import ProvisionalAppointmentLetter from "../../documents/LetterFormat/ProvisionalAppointmentLetter";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import NoResults from "../../common/components/NoResults";
import BackdropLoader from "../../common/components/BackdropLoader";

const steps = ["Fill Request Form", "Fill Request Letter", "Add files"];

const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const NewRecruitmentRequestDialog = (props) => {
  const {
    loading,
    onWait,
    showRequestForm,
    setShowRequestForm,
    selectedApplication,
    saveRecruitmentRequest,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    recruitmentRequestTypes,
    getRecruitmentRequestTypes,
    requestTypeId,
    getEntities,
    entities,
    recruitmentRequestAttachmentTypes,
    getRecruitmentRequestAttachmentTypes,
  } = props;
  const [recruitmentRequestType, setRecruitmentRequestType] = useState(null);
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  useEffect(() => {
    if (!recruitmentRequestTypes.length) getRecruitmentRequestTypes();
  }, []);
  useEffect(() => {
    if (!recruitmentRequestAttachmentTypes.length)
      getRecruitmentRequestAttachmentTypes();
  }, []);

  const recruitmentRequestAttachmentTypesGroupDropdown = () => {
    return recruitmentRequestAttachmentTypes.filter(
      (recruitmentRequestAttachmentType) =>
        recruitmentRequestAttachmentType?.requestTypeId === requestTypeId
    );
  };

  const recruitmentRequestTypeGroupDropdown = () => {
    return recruitmentRequestTypes.find(
      (recruitmentRequestType) => recruitmentRequestType?.id === requestTypeId
    );
  };
  useEffect(() => {
    setRecruitmentRequestType(recruitmentRequestTypeGroupDropdown());
  }, [recruitmentRequestTypes]);

  const [activeStep, setActiveStep] = useState(0);
  const [ccData, setCCData] = useState([]);
  const [pdfAttachment, setPdfAttachment] = useState(null);
  const [
    recruitmentRequestAttachmentType,
    setRecruitmentRequestAttachmentType,
  ] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({
    userId: selectedApplication.userId,
    applicationId: selectedApplication.applicantId,
    positionName: selectedApplication.positionName,
    employeeNames:
      selectedApplication.lastName + " " + selectedApplication.firstName,
    requestTypeId: recruitmentRequestType?.id || requestTypeId,
    title: recruitmentRequestType?.name || "",
    reason: recruitmentRequestType?.name || "",
    effectiveOn: moment().format("YYYY-MM-DD"),
    docLetterNo: "",
    cc: null,
    files: [],
  });
  useEffect(() => {
    if (recruitmentRequestType) {
      const title = recruitmentRequestType.name || "";
      const reason = recruitmentRequestType.name || "";
      setFormData({
        ...formData, // Spread the existing formData object
        title: title, // Update title property
        reason: reason, // Update reason property
      });
    }
  }, [recruitmentRequestType]);

  const [errors, setErrors] = useState({
    reasonHasError: false,
    effectiveOnHasError: false,
    requestTypeIdHasError: false,
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    attachmentHasErrors: [],
    pdfAttachmentHasError: false,
    fileNameHasError: false,
    existingFileHasError: false,
  });

  const formValidator = () => {
    const error = {
      reasonHasError: false,
      effectiveOnHasError: false,
      requestTypeIdHasError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      attachmentHasErrors: [],
      pdfAttachmentHasError: false,
      fileNameHasError: false,
      existingFileHasError: false,
    };

    if (!recruitmentRequestType) {
      error.requestTypeIdHasError = true;
      error.hasError = true;
    }

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!formData.effectiveOn) {
      error.effectiveOnHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const formValidator1 = () => {
    const error = {
      reasonHasError: false,
      effectiveOnHasError: false,
      requestTypeIdHasError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      attachmentHasErrors: [],
      pdfAttachmentHasError: false,
      fileNameHasError: false,
    };

    if (!recruitmentRequestType) {
      error.requestTypeIdHasError = true;
      error.hasError = true;
    }

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!formData.effectiveOn) {
      error.effectiveOnHasError = true;
      error.hasError = true;
    }
    // if (!ccData.length) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }

    // if (!formData.docLetterNo) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const formValidator2 = () => {
    const error = {
      attachmentHasErrors: [],
      hasError: false,
      pdfAttachmentHasError: false,
      fileNameHasError: false,
    };

    if ((files || [])?.length === 0) {
      error.attachmentHasErrors.push({
        id: 0,
        name: "Attachment is required",
      });
      error.hasError = true;
    }

    if ((files || [])?.length < 3) {
      error.attachmentHasErrors.push({
        id: 0,
        name: "You should upload At least 3 documents",
      });
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setFormData({
      userId: "",
      applicationId: "",
      requestTypeId: "",
      reason: "",
      effectiveOn: moment().format("YYYY-MM-DD"),
      docLetterNo: "",
      cc: [],
      files: [],
    });
    setFiles([]);
    setPdfAttachment(null);
    setRecruitmentRequestAttachmentType(null);
    setFileUrl(null);
    setShowRequestForm(false);
    setActiveStep(0);
  };

  const onSave = () => {
    const payload = new FormData();
    formData.files = files;
    formData.cc = JSON.stringify(ccData);

    const keys = Object.keys(formData);
    for (const key of keys) {
      if (formData.hasOwnProperty(key)) {
        if (key === "files" && formData[key] && !!formData[key].length)
          formData[key].forEach((row, i) => {
            payload.append(key, row.file);
            payload.append(`fileIds`, row.id);
          });
        else payload.append(`${key}`, `${formData[key]}`);
      }
    }
    saveRecruitmentRequest(payload, onClose, setActiveStep);
  };

  const handleNext = () => {
    let allowNextStep = false;

    if (activeStep === 0) {
      if (!formValidator()) {
        allowNextStep = true;
      }
    }

    if (activeStep === 1) {
      if (!formValidator1()) allowNextStep = true;
    }
    if (activeStep === 2) {
      if (!formValidator2()) allowNextStep = true;
    }

    if (allowNextStep && activeStep === 2) onSave();

    if (
      allowNextStep &&
      (activeStep === 0 || activeStep === 1 || activeStep === 2)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    setErrors({ ...errors, pdfAttachmentHasError: false });
    setPdfAttachment(null);
    let pdfAttachment = event.target.files[0];

    if (
      convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf" &&
      pdfAttachment?.type !== "application/pdf"
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };

      reader.readAsDataURL(pdfAttachment);

      setPdfAttachment(pdfAttachment);
    }
  };

  const addPDFFile = () => {
    const error = {
      pdfAttachmentHasError: false,
      fileNameHasError: false,
      hasError: false,
    };
    if (!pdfAttachment) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }
    if (!recruitmentRequestAttachmentType) {
      error.fileNameHasError = true;
      error.hasError = true;
    }
    if (
      !!files?.find(({ id }) => id === recruitmentRequestAttachmentType?.id)
    ) {
      error.existingFileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    const tmpDocuments = [...files];

    const document = { id: 0, name: "", file: null, url: "" };
    document.name = recruitmentRequestAttachmentType?.name;
    document.url = fileUrl;
    document.id = recruitmentRequestAttachmentType?.id;
    document.file = pdfAttachment;

    tmpDocuments.push(document);
    setFiles(tmpDocuments);
    setPdfAttachment(null);
    setRecruitmentRequestAttachmentType(null);
    setFileUrl(null);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRequestForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">
                  REQUEST
                  {recruitmentRequestType && recruitmentRequestType?.name
                    ? " OF " + recruitmentRequestType?.name
                    : ""}
                </span>{" "}
                /
                <span className="ml-2">
                  {selectedApplication?.lastName}{" "}
                  {selectedApplication?.firstName}
                </span>
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mt-4">
            <Box sx={{ width: "100%", mt: 0 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  // stepProps.completed = isCompleted;

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <div className="py-3">
              {isEmpty(recruitmentRequestType) && <AdvertSkeleton />}
              {!isEmpty(recruitmentRequestType) && (
                <>
                  {activeStep === 0 && (
                    <>
                      <div className="row px-3 mt-4">
                        <div className="col-12">
                          <Autocomplete
                            size="small"
                            id="recruitmentRequestType"
                            defaultValue={null}
                            value={recruitmentRequestType}
                            options={recruitmentRequestTypes}
                            disabled={true}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, recruitmentRequestType) => {
                              setRecruitmentRequestType(
                                recruitmentRequestType || null
                              );

                              setFormData({
                                ...formData,
                                requestTypeId: recruitmentRequestType?.id || "",
                              });

                              setErrors({
                                ...errors,
                                requestTypeIdHasError: false,
                              });
                            }}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, requestType) => (
                              <Box component="li" {...props}>
                                {requestType?.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                disabled={loading}
                                {...params}
                                label="Select Request Type"
                                placeholder="Select Request Type"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "requestType", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.requestTypeIdHasError && (
                            <small className="text-danger mb-1">
                              Please, select request type
                            </small>
                          )}
                        </div>

                        <div className="col-12 mt-3 ">
                          <TextField
                            fullWidth
                            size="small"
                            name="reason"
                            required={true}
                            multiline
                            rows={3}
                            value={formData.reason || ""}
                            label={`Type reason for ${
                              recruitmentRequestType?.name || ""
                            }`}
                            placeholder={`Type reason for ${
                              recruitmentRequestType?.name || ""
                            }`}
                            inputProps={{ maxLength: 459 }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                reason: e.target.value,
                              });

                              setErrors({ ...errors, reasonHasError: false });
                            }}
                          />
                          {errors.reasonHasError && (
                            <small className="text-danger mb-1">
                              Please,type reason.
                            </small>
                          )}
                        </div>

                        <div className="col-12 mt-3 ">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              label="Effective Date"
                              inputFormat="dd/MM/yyyy"
                              // disabled={
                              //   !exitType ||
                              //   (exitType && exitType.effectiveInDays > 0)
                              // }
                              value={formData.effectiveOn}
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  effectiveOn: date,
                                });

                                setErrors({
                                  ...errors,
                                  effectiveOnHasError: false,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  onKeyDown={(e) => e.preventDefault()}
                                  required={true}
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                            />

                            {errors.effectiveOnHasError && (
                              <small className="text-danger mt-1">
                                Effective date is required{" "}
                              </small>
                            )}
                          </LocalizationProvider>
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <div className="mx-2 w-full">
                        {recruitmentRequestType.id === 1 && (
                          <ProvisionalAppointmentLetter
                            recipientName={
                              selectedApplication.lastName +
                              " " +
                              selectedApplication.firstName
                            }
                            recipientEmail={selectedApplication.email}
                            recipientPhone={selectedApplication.phoneNumber}
                            institutionName={selectedApplication.entityName}
                            recruitmentInstitutionName={
                              selectedApplication.entityName
                            }
                            recruitmentPositionName={
                              selectedApplication.positionName
                            }
                            enableToFillLetter={true}
                            subTitle={
                              "Dear " +
                              (selectedApplication.lastName +
                                " " +
                                selectedApplication.firstName)
                            }
                            docLetterNoInput={
                              <>
                                <Input
                                  autoFocus
                                  error={!!errors.docLetterNoError}
                                  value={formData.docLetterNo}
                                  style={{ width: "150px" }}
                                  inputProps={{
                                    "aria-label": "add doc No",
                                  }}
                                  onChange={(e) => {
                                    const docLetterNo = e.target.value;
                                    setFormData({
                                      ...formData,
                                      docLetterNo,
                                    });

                                    setErrors({
                                      ...errors,
                                      docLetterNoError: false,
                                    });
                                  }}
                                />
                              </>
                            }
                            effectiveDate={moment(formData.effectiveOn).format(
                              "LL"
                            )}
                            competentAuthorityName={
                              !!requestDocumentSignerDetail
                                ? requestDocumentSignerDetail.competentAuthorityUser.lastName.toUpperCase() +
                                  " " +
                                  requestDocumentSignerDetail
                                    .competentAuthorityUser.firstName
                                : ""
                            }
                            competentAuthorityPosition={
                              !!requestDocumentSignerDetail
                                ? requestDocumentSignerDetail
                                    .competentAuthorityUser.positionName
                                : ""
                            }
                            ccSelectForm={
                              <>
                                <Autocomplete
                                  size="small"
                                  fullWidth
                                  multiple
                                  id="ccData"
                                  options={entities.map(
                                    ({ name, institutionEmail, id }) => ({
                                      name,
                                      institutionEmail,
                                      id,
                                    })
                                  )}
                                  value={ccData}
                                  onChange={(e, cc) => {
                                    const uniqueCc = removeDuplicates(cc);
                                    setCCData(uniqueCc);
                                    const ccHasError = false;
                                    setErrors({ ...errors, ccHasError });
                                  }}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon fontSize="small" />
                                        }
                                        checked={
                                          selected ||
                                          !!ccData.find(
                                            ({ id }) => id === option.id
                                          )
                                        }
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={"Select the institution to copy"}
                                      placeholder={
                                        "Select the institution to copy"
                                      }
                                    />
                                  )}
                                />

                                {errors.ccHasError && (
                                  <small className="text-danger mb-1">
                                    Please, select the institution to copy
                                  </small>
                                )}
                              </>
                            }
                            ccData={ccData}
                            signContent={
                              <table>
                                <tr>
                                  <td
                                    style={{
                                      width: "2%",
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      position: "relative",
                                      left: "-66px",

                                      width: "20%",
                                      fontSize: "14px",
                                      textAlign: "left",
                                    }}
                                  >
                                    <span className="d-block text-danger">
                                      Not signed
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "20%",
                                      fontSize: "12px",
                                      textAlign: "left",
                                    }}
                                  ></td>
                                </tr>
                              </table>
                            }
                          />
                        )}
                      </div>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <div className="card service-card valid mb-2 mt-1">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Add Attachment
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-5 mt-1">
                              <Autocomplete
                                size="small"
                                id="recruitmentRequestAttachmentType"
                                defaultValue={null}
                                value={recruitmentRequestAttachmentType}
                                options={recruitmentRequestAttachmentTypesGroupDropdown()}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, element) => {
                                  const existingFileHasError = false;
                                  if (
                                    !!files?.find(
                                      ({ id }) => id === element?.id
                                    )
                                  ) {
                                    showError(
                                      "Please, This file already added"
                                    );
                                  }
                                  if (
                                    !files?.find(({ id }) => id === element?.id)
                                  ) {
                                    setRecruitmentRequestAttachmentType(
                                      element || null
                                    );
                                  }

                                  setErrors({
                                    ...errors,
                                    fileNameHasError: false,
                                    existingFileHasError: existingFileHasError,
                                  });
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, element) => (
                                  <Box component="li" {...props}>
                                    {element.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="--Select attachment type--"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "payrollGroup", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />

                              {errors.fileNameHasError && (
                                <small className="text-danger mb-1">
                                  Please, Type attachment name
                                </small>
                              )}
                              {errors.existingFileHasError && (
                                <small className="text-danger mb-1">
                                  Please, This file already added
                                </small>
                              )}
                            </div>
                            <div className="col-lg-5 mt-1">
                              <input
                                type="file"
                                name="file"
                                className="form-control"
                                accept="application/pdf"
                                placeholder="Select pdf file"
                                onChange={(e) => handleUploadedPDFFile(e)}
                              />
                              {errors.pdfAttachmentHasError && (
                                <small className="text-danger mb-1">
                                  Please, Type attachment name
                                </small>
                              )}
                            </div>
                            <div className="col-lg-2 mt-1">
                              <Button
                                color="primary"
                                variant="contained"
                                disabled={
                                  !!errors.fileNameHasError ||
                                  !!errors.existingFileHasError ||
                                  !!errors.pdfAttachmentHasError
                                }
                                onClick={() => addPDFFile()}
                                size="md"
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card service-card valid mb-2 mt-1">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Attachments
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {Array.isArray(files) &&
                              (files || [])?.length > 0 && (
                                <div className="col-lg-12">
                                  {(files || [])?.map((document, index) => (
                                    <div key={index}>
                                      <div className="form-group1 mb-2">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              <i className="fas fa-paperclip"></i>
                                            </span>
                                          </div>

                                          <div
                                            className="form-control"
                                            style={{
                                              backgroundColor:
                                                "rgb(229, 246, 253)",

                                              color: "#007bff",
                                            }}
                                          >
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setShowUploadedDocument(true);
                                                setUploadedDocument({
                                                  name: document.name,
                                                  url: document.url,
                                                });
                                              }}
                                            >
                                              {document.name}
                                            </span>

                                            <IconButton
                                              onClick={() => {
                                                const tmpDocuments = [...files];
                                                const index =
                                                  tmpDocuments.findIndex(
                                                    ({ id }) =>
                                                      id === document.id
                                                  );

                                                tmpDocuments.splice(index, 1);
                                                setFiles(tmpDocuments);
                                              }}
                                              style={{ marginTop: "-6px" }}
                                              color="error"
                                              className="float-right"
                                              aria-label="delete"
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                              onClick={() => {
                                                setShowUploadedDocument(true);
                                                setUploadedDocument({
                                                  name: document.name,
                                                  url: document.url,
                                                });
                                              }}
                                              style={{ marginTop: "-6px" }}
                                              color="info"
                                              className="float-right"
                                              aria-label="view"
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}

                            {Array.isArray(files) &&
                              (files || [])?.length === 0 && (
                                <div className="col-lg-12">
                                  <NoResults />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {!!errors?.attachmentHasErrors?.length && (
                        <>
                          {errors.attachmentHasErrors.map((document, index) => (
                            <div key={index}>
                              <div
                                className={`form-group border border-danger p-2`}
                              >
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                    color: `red `,
                                  }}
                                >
                                  {index + 1}.{" "}
                                  <span className="ml-1">{document.name}</span>
                                </legend>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {activeStep === 3 && !!onWait && (
                    <>
                      <BackdropLoader isLoading={onWait} />
                    </>
                  )}
                </>
              )}
              {showUploadedDocument && uploadedDocument && (
                <PreviewPdfUrl
                  isBase64={true}
                  showDocument={showUploadedDocument}
                  setShowDocument={setShowUploadedDocument}
                  document={{
                    name: `View ${uploadedDocument?.name || ""}`,
                    url: uploadedDocument?.url || "",
                  }}
                />
              )}
            </div>
          </Box>
        </DialogContent>
        {!isEmpty(recruitmentRequestType) && !onWait && (
          <DialogActions className="d-flex justify-content-center pb-3">
            {activeStep > 0 && (
              <Button
                style={{
                  ...InactiveButton,
                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="md"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
              >
                Back
              </Button>
            )}

            <Box sx={{ flex: "1 1 auto" }} />

            {!!recruitmentRequestType ? (
              <Button
                disabled={loading || onWait}
                onClick={handleNext}
                color={activeStep === 2 ? "success" : "primary"}
                variant="contained"
                size="md"
              >
                {onWait ? (
                  "Wait.."
                ) : (
                  <>{activeStep === 2 ? "Submit" : "Next"}</>
                )}
              </Button>
            ) : (
              <Button
                color="success"
                variant="contained"
                disabled={loading || onWait}
                onClick={onSave}
                size="md"
              >
                {onWait ? "Wait.." : <>Submit</>}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>

      {/* {showConfirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            selectedApplication?.lastName + " " + selectedApplication?.firstName
          }?`}
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(
              employeeExitRequest.id,
              setShowConfirmationDialog
            );
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  requestDocumentSignerDetail,
  recruitmentRequestTypes,
  recruitmentRequestAttachmentTypes,
  entities,
}) => {
  return {
    loading,
    onWait,
    requestDocumentSignerDetail,
    recruitmentRequestTypes,
    recruitmentRequestAttachmentTypes,
    entities,
  };
};
export default connect(mapStateToProps, {
  saveRecruitmentRequest,
  getRequestDocumentSignerDetails,
  getRecruitmentRequestTypes,
  getEntities,
  getRecruitmentRequestAttachmentTypes,
})(NewRecruitmentRequestDialog);
