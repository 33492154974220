import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import { updateRecruitmentRequestLetter } from "../../../store/recruitment/actions";
import { removeDuplicates } from "../../common/components/Utils";
import ProvisionalAppointmentLetter from "../../documents/LetterFormat/ProvisionalAppointmentLetter";
import AppointmentLetter from "../../documents/LetterFormat/AppointmentLetter";
import { showError } from "../../toastify";

const ModifyRecruitmentLetterDialog = (props) => {
  const {
    loading,
    showRecruitmentLetter,
    setShowRecruitmentLetter,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    updateRecruitmentRequestLetter,
    recruitmentRequestDetail,
  } = props;
  const { hasPermissions } = useRequiredAuth();

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);
  // console.log(requestDocumentSignerDetail);
  const [formData, setFormData] = useState({
    requestId: recruitmentRequestDetail.id,
    effectiveDate: recruitmentRequestDetail.effectiveDate
      ? moment(recruitmentRequestDetail.effectiveDate).format("YYYY-MM-DD")
      : null,
    docLetterNo: recruitmentRequestDetail.docLetterNo,
    cc: null,
  });

  const [ccData, setCCData] = useState(
    recruitmentRequestDetail &&
      recruitmentRequestDetail &&
      recruitmentRequestDetail.cc
      ? JSON.parse(recruitmentRequestDetail.cc)
      : []
  );

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
    effectiveDateError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      maxCharHasError: false,
      effectiveDateError: false,
    };
    if (!formData.docLetterNo) {
      error.docLetterNoError = true;
      error.hasError = true;
    }

    if (!formData.effectiveDate) {
      error.effectiveDateError = true;
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      showError('Please fill the required field');
      return true;
    }
    return false;
  };
  const onClose = () => {
    setShowRecruitmentLetter(false);
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.cc = JSON.stringify(ccData);

      updateRecruitmentRequestLetter(formData, onClose);
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRecruitmentLetter}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>{recruitmentRequestDetail?.requestType || ""}</span> /{" "}
            <span className="text-primary">
              {recruitmentRequestDetail?.employeeNames || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            {recruitmentRequestDetail.requestTypeId === 1 && (
              <ProvisionalAppointmentLetter
                recipientName={recruitmentRequestDetail?.employeeNames}
                recipientEmail={recruitmentRequestDetail.email}
                recipientPhone={recruitmentRequestDetail.phoneNumber}
                institutionName={recruitmentRequestDetail.entityName}
                recruitmentInstitutionName={recruitmentRequestDetail.entityName}
                recruitmentPositionName={recruitmentRequestDetail.positionName}
                enableToFillLetter={true}
                subTitle={
                  "Dear " +
                  (recruitmentRequestDetail.genderId === "F" ? "Madam" : "Sir")
                }
                docLetterNoInput={
                  <>
                    <Input
                      autoFocus
                      error={!!errors.docLetterNoError}
                      value={formData.docLetterNo}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add doc No",
                      }}
                      onChange={(e) => {
                        const docLetterNo = e.target.value;
                        setFormData({
                          ...formData,
                          docLetterNo,
                        });

                        setErrors({
                          ...errors,
                          docLetterNoError: false,
                        });
                      }}
                    />
                  </>
                }
                effectiveDate={moment(formData.effectiveDate).format("LL")}
                competentAuthorityName={
                  !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail.competentAuthorityUser.lastName.toUpperCase() +
                      " " +
                      requestDocumentSignerDetail.competentAuthorityUser
                        .firstName
                    : ""
                }
                competentAuthorityPosition={
                  !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail.competentAuthorityUser
                        .positionName
                    : ""
                }
                ccSelectForm={
                  <>
                    <Autocomplete
                      size="small"
                      fullWidth
                      multiple
                      id="ccData"
                      options={entities.map(
                        ({ name, institutionEmail, id }) => ({
                          name,
                          institutionEmail,
                          id,
                        })
                      )}
                      value={ccData}
                      onChange={(e, cc) => {
                        const uniqueCc = removeDuplicates(cc);
                        setCCData(uniqueCc);
                        const ccHasError = false;
                        setErrors({ ...errors, ccHasError });
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={
                              selected ||
                              !!ccData.find(({ id }) => id === option.id)
                            }
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select the institution to copy"}
                          placeholder={"Select the institution to copy"}
                        />
                      )}
                    />

                    {errors.ccHasError && (
                      <small className="text-danger mb-1">
                        Please, select the institution to copy
                      </small>
                    )}
                  </>
                }
                effectiveDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.effectiveDateError}
                      value={formData.effectiveDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference date",
                      }}
                      onChange={(e) => {
                        const effectiveDate = e.target.value;
                        setFormData({
                          ...formData,
                          effectiveDate,
                        });

                        setErrors({
                          ...errors,
                          effectiveDateError: false,
                        });
                      }}
                    />
                  </>
                }
                ccData={ccData}
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "2%",
                        }}
                      ></td>
                      <td
                        style={{
                          position: "relative",
                          left: "-66px",

                          width: "20%",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      ></td>
                    </tr>
                  </table>
                }
              />
            )}
            {recruitmentRequestDetail.requestTypeId === 2 && (
              <AppointmentLetter
                provisionalDocLetterNo={
                  recruitmentRequestDetail.provisionalDocLetterNo
                }
                provisionalEffectiveOn={moment(
                  recruitmentRequestDetail.provisionalEffectiveOn
                ).format("LL")}
                provisionalDocLetterDate={moment(
                  recruitmentRequestDetail.provisionalApprovedOn
                ).format("LL")}
                npscDocLetterNo={recruitmentRequestDetail.npscDocLetterNo}
                npsclDocLetterDate={moment(
                  recruitmentRequestDetail.npscApprovedOn
                ).format("LL")}
                recipientName={
                  recruitmentRequestDetail.lastName +
                  " " +
                  recruitmentRequestDetail.firstName
                }
                docLetterNo={recruitmentRequestDetail.docLetterNo}
                recipientEmail={recruitmentRequestDetail.email}
                recipientPhone={recruitmentRequestDetail.phoneNumber}
                institutionName={recruitmentRequestDetail.entityName}
                recruitmentInstitutionName={recruitmentRequestDetail.entityName}
                recruitmentPositionName={recruitmentRequestDetail.positionName}
                enableToFillLetter={true}
                subTitle={
                  "Dear " +
                  (recruitmentRequestDetail.genderId === "F" ? "Madam" : "Sir")
                }
                ccData={
                  recruitmentRequestDetail.cc
                    ? JSON.parse(recruitmentRequestDetail.cc)
                    : []
                }
                effectiveDate={moment(
                  recruitmentRequestDetail.effectiveDate
                ).format("LL")}
                competentAuthorityName={recruitmentRequestDetail.signerNames}
                competentAuthorityPosition={
                  recruitmentRequestDetail.signerPosition
                }
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "2%",
                        }}
                      ></td>
                      <td
                        style={{
                          position: "relative",
                          left: "-66px",

                          width: "20%",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      ></td>
                    </tr>
                  </table>
                }
                docLetterNoInput={
                  <>
                    <Input
                      autoFocus
                      error={!!errors.docLetterNoError}
                      value={formData.docLetterNo}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add doc No",
                      }}
                      onChange={(e) => {
                        const docLetterNo = e.target.value;
                        setFormData({
                          ...formData,
                          docLetterNo,
                        });

                        setErrors({
                          ...errors,
                          docLetterNoError: false,
                        });
                      }}
                    />
                  </>
                }
                ccSelectForm={
                  <>
                    <Autocomplete
                      size="small"
                      fullWidth
                      multiple
                      id="ccData"
                      options={entities.map(
                        ({ name, institutionEmail, id }) => ({
                          name,
                          institutionEmail,
                          id,
                        })
                      )}
                      value={ccData}
                      onChange={(e, cc) => {
                        const uniqueCc = removeDuplicates(cc);
                        setCCData(uniqueCc);
                        const ccHasError = false;
                        setErrors({ ...errors, ccHasError });
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={
                              selected ||
                              !!ccData.find(({ id }) => id === option.id)
                            }
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select the institution to copy"}
                          placeholder={"Select the institution to copy"}
                        />
                      )}
                    />

                    {errors.ccHasError && (
                      <small className="text-danger mb-1">
                        Please, select the institution to copy
                      </small>
                    )}
                  </>
                }
                effectiveDateInput={
                  <>
                    <Input
                      type="date"
                      error={!!errors.effectiveDateError}
                      value={formData.effectiveDate}
                      style={{ width: "150px" }}
                      inputProps={{
                        "aria-label": "add reference date",
                      }}
                      onChange={(e) => {
                        const effectiveDate = e.target.value;
                        setFormData({
                          ...formData,
                          effectiveDate,
                        });

                        setErrors({
                          ...errors,
                          effectiveDateError: false,
                        });
                      }}
                    />
                  </>
                }
              />
            )}
          </Box>
        </DialogContent>

        {!!hasPermissions(["IS_HR", "IS_DOCUMENT_VERIFIER"]) && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Button
                variant="contained"
                color="success"
                type="button"
                disabled={loading}
                onClick={onSave}
                className="btn btn-xs"
              >
                {loading ? "Wait.." : "Confirm"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  updateRecruitmentRequestLetter,
})(ModifyRecruitmentLetterDialog);
