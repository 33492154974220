import React, { useState, useEffect } from "react";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Divider,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import PercentIcon from "@mui/icons-material/Percent";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import BadgeIcon from "@mui/icons-material/Badge";
import EditIcon from "@mui/icons-material/Edit";
import {
  CardContent,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getApplicantWrittenExamResults,
  publishWrittenExamMarks,
  downloadWrittenExamResults,
  publishOpenExamQuestionMarks,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import QuizIcon from "@mui/icons-material/Quiz";
import TopupScoreDialog from "./TopupScoreDialog";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import ApplicantWrittenExamAnswerDialog from "./ApplicantWrittenExamAnswerDialog";
import { connect, useDispatch } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import ApplicationCv from "../../common/components/ApplicationCv";
import UserResume from "../../profile/UserResume";
import { showError } from "../../toastify";

const WrittenExamMarking = (props) => {
  const {
    loading,
    exam,
    setShowWrittenExamMarking,
    showWrittenExamMarking,
    getApplicantWrittenExamResults,
    applicantWrittenExamResults,
    publishWrittenExamMarks,
    userType,
    downloadWrittenExamResults,
    publishOpenExamQuestionMarks,
    getUserDetails,
  } = props;
  const theme = useTheme();
  const defaultIds = "";
  const { hasPermissions } = useRequiredAuth();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [filterStatus, setFilterStatus] = useState({
    id: "",
    name: "All Candidates",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [confirmPublish, setConfirmPublish] = useState(false);

  const [showApplicantWrittenExamAnswers, setShowApplicantWrittenExamAnswers] =
    useState(false);

  const [selectedApplicantMarks, setSelectedApplicantMarks] = useState(null);
  const [confirmToPublishMarks, setConfirmToPublishMarks] = useState(false);
  useEffect(() => {
    dispatch({
      type: "SET_APPLICANT_WRITTEN_EXAM_RESULTS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getApplicantWrittenExamResults(
      exam.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getApplicantWrittenExamResults(
      exam.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getApplicantWrittenExamResults(
      exam.id,
      query,
      setTotalRequests,
      setIsLoading1
    );
  };

  const onClose = () => {
    setShowWrittenExamMarking(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showWrittenExamMarking}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark  mr-2">Written Exam</span>
            <span style={{ color: "rgb(7, 142, 206)" }}>results</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "5px" }}
              >
                chevron_right
              </span>
            </span>

            <span className="text-primary">
              {exam?.positionName}
              {exam.advertisement
                ? exam.advertisement.requisition.position.name
                : ""}
            </span>
            {!!hasPermissions(["IS_CBM"]) && exam?.examQuestionTypeId === 2 && (
              <Button
                disabled={exam.wasExamMarkingPublished}
                onClick={() => {
                  setConfirmPublish(true);
                }}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                className="mr-5 float-right"
                style={{
                  borderRadius: "8px",
                  height: "38px",
                }}
              >
                <span className="material-icons">publish</span>
                {isPublishing ? "ShotListing..." : "Publish Marks"}
              </Button>
            )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          {confirmPublish && (
            <ConfirmationDialog
              disabled={isPublishing}
              confirmationDialog={confirmPublish}
              message={`Are you sure you want to publish exam?`}
              setConfirmationDialog={setConfirmPublish}
              noPreFormat={true}
              onYes={() => {
                publishOpenExamQuestionMarks(
                  exam.id,
                  setShowWrittenExamMarking,
                  setIsPublishing
                );
              }}
            />
          )}
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ display: "flex", width: "100%" }}>
            {/*  */}

            <div
              className="w-100"
              style={{
                height: "520px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    // padding: ".6em",
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <Typography variant="h6" noWrap component="div">
                        <span style={{ color: "#2a7790" }}>
                          <QuizIcon className="text-dark mr-2" />
                        </span>
                        <span style={{ color: "rgb(7, 142, 206)" }}>
                          {searchTerm
                            ? "Filtered Candidates"
                            : filterStatus
                            ? filterStatus.name
                            : "List of Candidates"}
                          ({totalRequests})
                        </span>
                      </Typography>
                    </div>

                    <div className="col-lg-6">
                      <div className="d-flex justify-content-right  align-items-right">
                        <Autocomplete
                          sx={{ width: 300 }}
                          className="mr-2"
                          size="small"
                          id="filterStatus"
                          defaultValue={null}
                          value={filterStatus || null}
                          options={[
                            {
                              id: "Passed Written Exam",
                              name: "Passed Written Exam",
                            },
                            {
                              id: "Failed Written Exam",
                              name: "Failed Written Exam",
                            },
                            { id: "", name: "All Candidates" },
                          ]}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, currentStatus) => {
                            const query = {
                              searchTerm: "",
                              currentStatus: currentStatus?.id || defaultIds,
                              page: currentPage,
                              size: itemPerPage,
                            };
                            setFilterStatus(currentStatus);
                            getApplicantWrittenExamResults(
                              exam.id,
                              query,
                              setTotalRequests,
                              setIsLoading1
                            );
                          }}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, filterStatus) => (
                            <Box
                              component="li"
                              {...props}
                              key={filterStatus.id}
                            >
                              {filterStatus.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                width: "100%",
                                height: "35px!important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px!important",
                              }}
                              {...params}
                              label="Filter By"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "filterStatus", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        <SearchBox
                          onSearch={(search) => {
                            setSearchTerm(search);

                            if (!search) {
                              const currentPage = 1;
                              setCurrentPage(currentPage);
                              const query = {
                                searchTerm: "",
                                page: currentPage,
                                currentStatus: filterStatus?.id || defaultIds,
                                size: itemPerPage,
                              };
                              getApplicantWrittenExamResults(
                                exam.id,
                                query,
                                setTotalRequests,
                                setIsLoading1
                              );
                            }
                          }}
                          placeholder="Search…"
                        />
                        <Button
                          disabled={!searchTerm}
                          onClick={() => onSearch()}
                          variant="contained"
                          size="small"
                          disableElevation
                          className="mr-2 "
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                            border: `1px solid rgb(7, 142, 206)`,
                            color: "#fff",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                            backgroundColor: "rgb(7, 142, 206)",
                            marginLeft: "5px",
                            "&:hover": {
                              backgroundColor: "#f0f2f5",
                            },
                          }}
                        >
                          <span className="material-icons">search</span>GO
                        </Button>
                        <span className="ml-0 btn btn-outline-primary btn-sm">
                          <Tooltip title="Download">
                            <IconButton
                              disabled={
                                isLoading || isLoading1
                                // !applicantOverallResults.length
                              }
                              className="p-0"
                              color="primary"
                              onClick={() => {
                                const query = {
                                  searchTerm: searchTerm,
                                  currentStatus: filterStatus?.id || defaultIds,
                                  examId: exam.id,
                                  examName: exam.advertisement
                                    ? exam.advertisement.requisition.position
                                        .name
                                    : "",
                                };
                                downloadWrittenExamResults(
                                  query,
                                  setIsDownloading
                                );
                              }}
                            >
                              {isDownloading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ fontSize: "28px" }}
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  cloud_download
                                </span>
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col" className="text-left">
                          Last Name
                        </th>
                        <th scope="col" className="text-left">
                          First Name
                        </th>
                        <th scope="col" className="text-left">
                          IdNumber
                        </th>
                        <th scope="col" className="text-left">
                          Phone Number
                        </th>

                        <th scope="col" className="text-center">
                          Score
                        </th>
                        <th scope="col" className="text-center">
                          Topup Score
                        </th>

                        <th scope="col" className="text-center">
                          Total Marks
                        </th>

                        <th scope="col" className="text-right">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <TableSkeleton cols={10} />
                    ) : (
                      <tbody>
                        <BackdropLoader isLoading={isLoading1} />
                        {applicantWrittenExamResults.map(
                          (writtenExamMark, index) => (
                            <WrittenExamMarkingCard
                              writtenExamMark={writtenExamMark}
                              setSelectedApplicantMarks={
                                setSelectedApplicantMarks
                              }
                              setShowApplicantWrittenExamAnswers={
                                setShowApplicantWrittenExamAnswers
                              }
                              key={index}
                              userType={userType}
                              getUserDetails={getUserDetails}
                              loading={loading}
                            />
                          )
                        )}
                      </tbody>
                    )}
                  </table>

                  <NoRecordMessage
                    isEmpty={!applicantWrittenExamResults.length && !isLoading}
                    title="No record found"
                  />
                </CardContent>
              </div>

              {showApplicantWrittenExamAnswers && (
                <ApplicantWrittenExamAnswerDialog
                  exam={exam}
                  showApplicantWrittenExamAnswers={
                    showApplicantWrittenExamAnswers
                  }
                  setShowApplicantWrittenExamAnswers={
                    setShowApplicantWrittenExamAnswers
                  }
                  writtenExamMark={selectedApplicantMarks}
                  loading={loading}
                />
              )}
            </div>
          </Box>
        </DialogContent>

        {confirmToPublishMarks && (
          <ConfirmationDialog
            confirmationDialog={confirmToPublishMarks}
            message={`Are you sure you want to publish written marks?`}
            setConfirmationDialog={setConfirmToPublishMarks}
            onYes={() => {
              publishWrittenExamMarks(
                exam.id,
                setConfirmToPublishMarks,
                onClose
              );
            }}
          />
        )}

        <DialogActions className="d-flex justify-content-center py-1">
          {!isLoading && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRequests / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!applicantWrittenExamResults.length && (
                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      currentStatus: filterStatus?.id || defaultIds,
                    };
                    getApplicantWrittenExamResults(
                      exam.id,
                      query,
                      setTotalRequests,
                      setIsLoading
                    );
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, applicantWrittenExamResults }) => {
  return { user, loading, applicantWrittenExamResults };
};

export default connect(mapStateToProps, {
  getApplicantWrittenExamResults,
  publishWrittenExamMarks,
  downloadWrittenExamResults,
  publishOpenExamQuestionMarks,
  getUserDetails,
})(WrittenExamMarking);

const ITEM_HEIGHT = 60;

const WrittenExamMarkingCard = (props) => {
  const {
    writtenExamMark,
    setShowApplicantWrittenExamAnswers,
    getUserDetails,
    loading,
  } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [showTopupScore, setShowTopupScore] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };
  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {writtenExamMark.userId}
        </th>
        <td className="text-left"> {writtenExamMark.firstName}</td>
        <td className="text-left"> {writtenExamMark.lastName}</td>
        <td className="text-left"> {writtenExamMark.idNumber}</td>

        <td className="text-left">{writtenExamMark.phoneNumber}</td>

        <td className="text-center">
          {writtenExamMark.score}
          {writtenExamMark.totalMarks === 100 ? (
            <PercentIcon style={{ fontSize: "13px" }} />
          ) : (
            <>/{writtenExamMark.totalMarks}</>
          )}
        </td>

        <td className="text-center text-primary">
          {writtenExamMark.topupScore > 0 && (
            <> Top-Up [ {writtenExamMark.topupScore}% ]</>
          )}
        </td>

        <td className="text-center">
          <div
            className="text-center"
            style={{
              margin: "0 auto",
              padding: "2px",
              width: "57px",
              height: "auto",
              border: `solid 3px ${
                writtenExamMark.isPassed === 1 ? "#28a745" : "#dc3545"
              }`,
              borderRadius: "50px",
              textAlign: "center",
            }}
          >
            {writtenExamMark.totalScore}
            {writtenExamMark.totalMarks === 100 ? (
              <PercentIcon style={{ fontSize: "13px" }} />
            ) : (
              <>/{writtenExamMark.totalMarks}</>
            )}
          </div>
        </td>

        <td className="text-right">
          {!!writtenExamMark.isMarkedAsCheated && (
            <span className="badge  badge-warning mr-1">Cheated</span>
          )}
          {writtenExamMark.isPassed === 1 ? (
            <span className="badge  badge-success">
              {writtenExamMark.status}
            </span>
          ) : (
            <span className="badge  badge-danger">
              {writtenExamMark.status}
            </span>
          )}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                // disabled={
                //   !writtenExamMark.wasExamMarkingPublished ||
                //   !hasPermissions(["IS_HR", "IS_CBM", "IS_DAF", "IS_NPSC"])
                // }
                onClick={() => {
                  handleCloseMenu();
                  // setSelectedApplicantMarks(writtenExamMark);
                  dispatch({
                    type: "SET_APPLICANT_WRITTEN_EXAM_RESULT",
                    data: writtenExamMark,
                  });
                  setShowApplicantWrittenExamAnswers(true);
                }}
                className="text-success font-weight-light"
              >
                <QuestionAnswerIcon className="mr-2 text-primary" />
                View Answer Sheet
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplicationCv(true);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Application Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                getUserDetails(
                  writtenExamMark.userId,
                  handleOpenCurrentProfile
                );
              }}
              className="text-dark font-weight-light"
            >
              <BadgeIcon className="mr-2 text-primary" />
              {loading ? "Wait...." : "Currently Profile"}
            </MenuItem>

            <span>
              <MenuItem
                disabled={
                  !!writtenExamMark.isMarkedAsCheated ||
                  !writtenExamMark.wasExamMarkingPublished
                }
                onClick={() => {
                  handleCloseMenu();
                  if (!hasPermissions(["IS_CBM"])) {
                    showError(
                      "You are not allowed to add top-up marks, only CBM allowed"
                    );
                  } else {
                    setShowTopupScore(true);
                  }
                }}
                className="text-dark font-weight-light"
              >
                <EditIcon className="mr-2 text-primary" />
                Topup Score
              </MenuItem>
            </span>
          </Menu>
          {showTopupScore && hasPermissions(["IS_CBM"]) && (
            <TopupScoreDialog
              showTopupScore={showTopupScore}
              setShowTopupScore={setShowTopupScore}
              writtenExamMark={writtenExamMark}
            />
          )}
          {showCurrentProfile && (
            <UserResume
              showProfileDialog={showCurrentProfile}
              setShowProfileDialog={setShowCurrentProfile}
            />
          )}
          {showApplicationCv && (
            <ApplicationCv
              showApplicationCv={showApplicationCv}
              setShowApplicationCv={setShowApplicationCv}
              application={{
                id: writtenExamMark.applicationId,
                idNumber: writtenExamMark.idNumber,
                phoneNumber: writtenExamMark.phoneNumber,
                userId: writtenExamMark.userId,
                lastName: writtenExamMark.lastName,
                firstName: writtenExamMark.firstName,
                email: writtenExamMark.email,
              }}
            />
          )}
        </td>
      </tr>
    </>
  );
};
