import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  LinearProgress,
} from "@mui/material";

import React from "react";
import { connect } from "react-redux";

import "react-quill/dist/quill.snow.css";
import AuthorizationByMinistry from "./AuthorizationByMinistry";
import SignTrainingByMinistryDialog from "./SignTrainingByMinistryDialog";
import AuthorizationPermit from "./AuthorizationPermit";
import InstitutionDetails from "./InstitutionDetails";
const ApproveTrainingRequestByMinistryDialog = (props) => {
  const {
    showApprovalModal,
    setShowApprovalModal,
    setShowTrainingRequestDetails,
    request,
    loading,
    userSignature,
    setUserSignature,
  } = props;

  const onClose = () => {
    setShowApprovalModal(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApprovalModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="d-flex align-items-center">
              <span className="material-icons mr-1">draw</span> Sign on Request
              for authorization to conduct a training
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          {loading && (
            <div className="col-12">
              <LinearProgress />
            </div>
          )}
        </DialogTitle>

        <DialogContent dividers style={{ maxHeight: "100vh" }}>
          <div className="col-12">
            {request?.employmentCategory === "Contractual staff" ? (
              <>
                <AuthorizationPermit
                  headOfInstitution={{
                    firstName: request?.ministryApproverFirstName || "",
                    lastName: request?.ministryApproverLastName || "",
                    title: request?.ministryApproverTitle || "",
                    institutionName:
                      request?.ministryApproverInstitutionName || "",
                    positionName: request?.ministryApproverPositionName || "",
                    canAddInstitutionName:
                      !!request?.ministryApproverCanAddInstitutionName
                        ? true
                        : false,
                  }}
                  permitReferenceNo={request.permitReferenceNo}
                  request={request}
                  draftAuthorizationPermitContent={
                    request.draftAuthorizationPermitContent
                  }
                  institutionDetail={
                    <InstitutionDetails
                      headOfInstitution={{
                        isDelegated: request?.isDelegated || false,
                        isMinster: request?.isMinster || false,
                        firstName: request?.ministryApproverFirstName,
                        lastName: request?.ministryApproverLastName,
                        institutionName:
                          request?.ministryApproverInstitutionName,
                        positionName: request?.ministryApproverPositionName,
                      }}
                      userSignature={null}
                    />
                  }
                />
              </>
            ) : (
              <>
                <AuthorizationByMinistry
                  minstryLetterReferenceNo={request?.minstryLetterReferenceNo}
                  draftAuthorizationSubjectByMinistry={
                    request?.draftAuthorizationSubjectByMinistry
                  }
                  draftAuthorizationContentByMinistry={
                    request?.draftAuthorizationContentByMinistry
                  }
                  headOfInstitution={{
                    firstName: request?.ministryApproverFirstName || "",
                    lastName: request?.ministryApproverLastName || "",
                    title: request?.ministryApproverTitle || "",
                    institutionName:
                      request?.ministryApproverInstitutionName || "",
                    positionName: request?.ministryApproverPositionName || "",
                    canAddInstitutionName:
                      !!request?.ministryApproverCanAddInstitutionName
                        ? true
                        : false,
                  }}
                  isMifotraEntitySector={
                    request?.ministryEntitySectorId ===
                    "2000000000-010200000000000000-02"
                      ? true
                      : false
                  }
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "2%",
                          }}
                        ></td>
                        <td
                          style={{
                            position: "relative",
                            left: "-190px",

                            width: "20%",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  }
                />
              </>
            )}

            <table className="w-100">
              <tr>
                <td
                  style={{
                    width: "10%",
                  }}
                ></td>
                <td
                  style={{
                    position: "relative",

                    width: "30%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  <SignTrainingByMinistryDialog
                    request={request}
                    onClose={onClose}
                    userSignature={userSignature}
                    setUserSignature={setUserSignature}
                    setShowTrainingRequestDetails={
                      setShowTrainingRequestDetails
                    }
                    level={"ministry"}
                  />
                </td>
                <td
                  style={{
                    width: "10%",
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(
  mapStateToProps,
  {}
)(ApproveTrainingRequestByMinistryDialog);
