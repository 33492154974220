import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import NewUpload from "./pages/NewUpload";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ESign from "./pages/ESign";
const Layout = () => {
  const { hasPermissions } = useRequiredAuth();
  const history = useHistory();
  const drawerWidth = 240;
  const location = useLocation();
  return (
    <React.Fragment>
      <>
        <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#078ece", height: "6px" }}></div>
          <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
        </div>
        <div className={`container-fluid pt-0 px-0 `} id="main-container">
          <div className="row">
            <div className="col-xs-12 col-lg-2">
              {" "}
              <Drawer
                variant="permanent"
                open={1}
                anchor='left'
                sx={{
                  width: drawerWidth,
                  height: { sm: `100vh` },
                  position: "relative",
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    // height: { sm: `calc(100% - 105px)` },
                    position: "relative",
                    // top: { sm: `calc(105px)` },
                    boxSizing: "border-box",
                  },
                }}
              >
                <Box sx={{ overflow: "auto" }}>
                  <List>
                    {[
                      {
                        text: "New Doc",
                        icon: AddIcon,
                        id: 1,
                        link: "/documents/new",
                      },
                      {
                        text: "Signed Docs",
                        icon: FolderZipIcon,
                        id: 2,
                        link: "/documents/e-sign",
                      },
                    ].map((item, index) => (
                      <ListItem key={item.id} disablePadding>
                        <ListItemButton
                          selected={
                            location.pathname.includes(item.link) ? true : false
                          }
                          onClick={() => history.push(item.link)}
                        >
                          <ListItemIcon>
                            <item.icon />
                          </ListItemIcon>
                          <ListItemText primary={item.text} />
                        </ListItemButton>
                        <Divider />
                      </ListItem>
                    ))}
                  </List>
                  {/* <Divider />
              <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List> */}
                </Box>
              </Drawer>
            </div>
     
          <div className="col-xs-12 col-lg-10">
            <Box
              component="main"
              className="pr-3"
              sx={{
                width: { sm: `100%` },
                ml: { flexGrow: 1, p: 3 },
              }}
            >
              <Typography sx={{ marginBottom: 2 }}>
                <Switch>
                  <Route path="/documents/new" component={NewUpload} />
                  <Route path="/documents/e-sign" component={ESign} />

                  <Route
                    path="/documents"
                    component={() => <Redirect to="/documents/new" />}
                  />
                </Switch>
              </Typography>
            </Box>
          </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default Layout;
