import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SubmissionRecruitmentReportLetter from "../../documents/LetterFormat/SubmissionRecruitmentReportLetter";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import {
  submitNPSCReport,
  editNPSCReport,
} from "../../../store/recruitment/actions";
import { removeDuplicates } from "../../common/components/Utils";

const mxWords = 2000;
const SubmitReportDialog = (props) => {
  const {
    loading,
    showSubmissionReport,
    setShowSubmissionReport,
    advertisement,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    onCloseSubmitReport,
    submitNPSCReport,
    editNPSCReport,
    npscReport,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  // console.log('npscReport',npscReport);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);
  // console.log(requestDocumentSignerDetail);
  const [formData, setFormData] = useState({
    requestId: npscReport && npscReport.details ? npscReport.details.id : "",
    advertisementId: advertisement.advertisementId,
    docLetterNo:
      npscReport && npscReport.details ? npscReport.details.docLetterNo : "",
    docContent:
      npscReport && npscReport.details
        ? npscReport.details.docContent
        : !!window.localStorage.getItem(
            `${advertisement.advertisementId}-docContentTextEditor`
          )
        ? window.localStorage.getItem(
            `${advertisement.advertisementId}-docContentTextEditor`
          )
        : `
    Reference is made to job positions namely ${
      advertisement.positionName
    } through Ministry of Public Service and Labour (MIFOTRA) E-Recruitment on ${moment(
            advertisement.openingDate
          ).format("Do MMMM YYYY")};<br>
  We would like to inform you that the recruitment process for the above-mentioned position has been closed, and we are waiting for the approval of the competent authority to place the successful candidates on
  their respective position.<br>
In this regard, we hereby submit the detailed report for the aforementioned recruitment. Kindly, find the reports details from IPPIS herewith the shortlisting situation, oral exam marks,practical exam marks, list of candidates who 
scored above 70%, the recorded video and audio of the oral and practical sessions, authorization to use only oral and practical exams, and qualifications of the examiners for the consideration.
`,
    cc: null,
  });
  const [ccData, setCCData] = useState(
    npscReport && npscReport.details && npscReport.details.cc
      ? JSON.parse(npscReport.details.cc)
      : []
  );
  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      docContentHasError: false,
      maxCharHasError: false,
    };
    // if (!formData.docLetterNo) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }
    if (!formData.docContent) {
      error.docContentHasError = true;
      error.hasError = true;
    }

    // if (!ccData.length) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [hasTextEditorChanged, setHasTextEditorChanged] = useState(false);
  const [maxWidth, setMaxWidth] = useState("lg");

  const onClose = () => {
    setShowSubmissionReport(false);
  };
  const getEditorContent = (data) => {
    //  console.log(data);
    let maxCharHasError = false;
    setHasTextEditorChanged(data.isChanged);
    setFormData({
      ...formData,
      docContent: data.contents,
    });

    if (!!data.isMaxChar) maxCharHasError = true;

    setErrors({
      ...errors,
      maxCharHasError: maxCharHasError,
    });
  };
  const onSave = () => {
    if (!formValidator()) {
      formData.cc = JSON.stringify(ccData);
      if (npscReport && npscReport.details) {
        editNPSCReport(formData, onClose);
      } else {
        submitNPSCReport(formData, onClose, onCloseSubmitReport);
      }
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSubmissionReport}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Submission of Recruitment Report</span> /{" "}
            <span className="text-primary">
              {advertisement?.positionName || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <SubmissionRecruitmentReportLetter
              enableToFillLetter={true}
              docContentTextEditor={
                <>
                  <TextEditorComponent
                    id="editor-textarea5"
                    options={{
                      autoSave: false,
                      hasChanged: hasTextEditorChanged,
                      localStorageKey: `${advertisement.id}-docContentTextEditor`,
                      maxCharCount: mxWords,
                      defaultValue: formData.docContent,
                    }}
                    onEditorChange={getEditorContent}
                  />

                  <ErrorMessage
                    hasError={errors.docContentHasError}
                    message="This field is required"
                  />
                </>
              }
              docLetterNoInput={
                <>
                  <Input
                    autoFocus
                    error={!!errors.docLetterNoError}
                    value={formData.docLetterNo}
                    style={{ width: "150px" }}
                    inputProps={{
                      "aria-label": "add doc No",
                    }}
                    onChange={(e) => {
                      const docLetterNo = e.target.value;
                      setFormData({
                        ...formData,
                        docLetterNo,
                      });

                      setErrors({
                        ...errors,
                        docLetterNoError: false,
                      });
                    }}
                  />
                </>
              }
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        position: "relative",

                        width: "20%",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      <span className="d-block text-danger">Not signed</span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
              institutionName={
                requestDocumentSignerDetail?.competentAuthorityUser?.institutionName.toUpperCase() ||
                ""
              }
              competentAuthorityName={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail.competentAuthorityUser.lastName.toUpperCase() +
                    " " +
                    requestDocumentSignerDetail.competentAuthorityUser.firstName
                  : ""
              }
              competentAuthorityPosition={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail.competentAuthorityUser
                      .positionName
                  : ""
              }
              ccSelectForm={
                <>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    id="ccData"
                    options={entities.map(({ name, institutionEmail, id }) => ({
                      name,
                      institutionEmail,
                      id,
                    }))}
                    value={ccData}
                    onChange={(e, cc) => {
                      const uniqueCc = removeDuplicates(cc);
                      setCCData(uniqueCc);
                      const ccHasError = false;
                      setErrors({ ...errors, ccHasError });
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            selected ||
                            !!ccData.find(({ id }) => id === option.id)
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Select the institution to copy"}
                        placeholder={"Select the institution to copy"}
                      />
                    )}
                  />

                  {errors.ccHasError && (
                    <small className="text-danger mb-1">
                      Please, select the institution to copy
                    </small>
                  )}
                </>
              }
              ccData={ccData}
            />
          </Box>
        </DialogContent>
        {/* {npscReportDetail?.statusId === 4 && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Alert severity="error">
                <strong>Reason:</strong> {npscReportDetail.statusComment}
              </Alert>
            </DialogActions>
          </>
        )} */}
        {!!hasPermissions(["IS_HR", "IS_DOCUMENT_VERIFIER"]) && (
          <>
            <DialogActions className="d-flex justify-content-center py-2">
              <Button
                variant="contained"
                color="success"
                type="button"
                disabled={loading}
                onClick={onSave}
                className="btn btn-xs"
              >
                {loading ? "Wait.." : "Confirm"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
  npscReport,
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
    npscReport,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  submitNPSCReport,
  editNPSCReport,
})(SubmitReportDialog);
