import React from "react";
import {
  textCapitalized,
  textCapitalizing,
} from "../../common/components/Utils";
import moment from "moment";
import rwandaLogo from "../../assets/rwanda-logo.png";

const AuthorizationLetter = (props) => {
  const {
    request,
    institutionDetail,
    coveredRelatedCostsDetails,
    copiedInstitution,
    headOfInstitution,
    draftAuthorizationLetterContent,
    draftAuthorizationLetterContentInput,
    copiedInstitutionEmailInput,
    copiedInstitutionInput,
    letterReferenceNoInput,
    letterReferenceNo,
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {headOfInstitution.institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:{" "}
                    {letterReferenceNoInput
                      ? letterReferenceNoInput
                      : letterReferenceNo
                      ? letterReferenceNo
                      : "........."}
                  </p>
                </td>
              </tr>
            </table>
            {/* <!-- Date and reference number go here --> */}
          </div>
          <div
            className={
              draftAuthorizationLetterContentInput ? "" : "auth-content"
            }
          >
            <div className="header2">
              <p>
                <span>Right Hon. Prime Minister</span> <br />
                <strong
                  style={{
                    fontSize: "14.5px",
                    textDecoration: "underline",
                  }}
                >
                  KIGALI
                </strong>
              </p>
              <p style={{ paddingTop: "10px" }}>
                <span>Right Hon. Prime Minister,</span>
              </p>
              <p style={{ paddingTop: "10px" }}>
                <strong className="tn-subject">Subject:</strong>{" "}
                <strong>Authorization for training</strong>
              </p>
            </div>

            <div
              style={{ marginBottom: "30px" }}
              className={draftAuthorizationLetterContentInput ? "" : "content2"}
            >
              {draftAuthorizationLetterContentInput ? (
                draftAuthorizationLetterContentInput
              ) : draftAuthorizationLetterContent ? (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: draftAuthorizationLetterContent,
                    }}
                  ></div>
                </>
              ) : (
                <>
                  <p>
                    Reference is made to letter N<sup>o</sup>{" "}
                    {request.referenceNo} of{" "}
                    {moment(request.ministryApprovedDate).format("ll")} from the
                    Hon.{" "}
                    {request.ministryEntityName.replace("Ministry", "Minister")}{" "}
                    requesting authorization for training in favour of{" "}
                    {request.lastName.toUpperCase()}{" "}
                    {textCapitalizing(request.firstName)};
                  </p>

                  <p>
                    I would like to acknowledge to Your Excellency that{" "}
                    {request.employeeTitle}. {request.lastName.toUpperCase()}{" "}
                    {textCapitalizing(request.firstName)},{" "}
                    {request.positionName},{request.unitName} in the{" "}
                    {request.entityName} is authorised to attend a training on{" "}
                    {request.trainingTitle}, {request.countryForTraining} from{" "}
                    {moment(request.trainingDurationFrom).format("ll")} to{" "}
                    {moment(request.trainingDurationTo).format("ll")}.{" "}
                    {!request.isTrainingInsideCountry && (
                      <>
                        The date of departure is{" "}
                        {moment(request.departureDate).format("ll")} and the
                        returning date is{" "}
                        {moment(request.returningDate).format("ll")}.
                      </>
                    )}
                    {coveredRelatedCostsDetails
                      ? coveredRelatedCostsDetails
                      : request.coveredRelatedCostsDetails}
                    .
                  </p>

                  <p>Attached are the documents related to the training.</p>
                  <p>
                    Please accept, Right Hon. Prime Minister, the assurances of
                    my usual highest consideration and esteem.
                  </p>
                </>
              )}
            </div>

            <div className="signature">
              {institutionDetail}

              {!copiedInstitutionInput && (
                <p className="mt-4">
                  <strong style={{ textDecoration: "underline" }}>Cc:</strong>
                </p>
              )}

              {copiedInstitutionInput ? (
                copiedInstitutionInput
              ) : (
                <p
                  style={{
                    lineHeight: "21.5px",
                    marginLeft: "-20px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: copiedInstitution
                      ? copiedInstitution
                      : request.copiedInstitution,
                  }}
                ></p>
              )}
              {!copiedInstitutionEmailInput && (
                <strong
                  style={{
                    fontSize: "14.5px",
                    textDecoration: "underline",
                    marginTop: "-15px",
                  }}
                >
                  KIGALI
                </strong>
              )}
              {copiedInstitutionEmailInput && (
                <>{copiedInstitutionEmailInput}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizationLetter;
